// Be careful when adding imports to this list. Imports must be as small as possible, with few or
// no imported dependencies.
import React from 'react';
import { Redirect } from 'react-router-dom';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import PropTypes from 'prop-types';
import { SettingsRoutes } from 'pages/earner/settings';
import { Dashboard as Earned } from 'pages/earner/earned_badges/dashboard';
import { Dashboard } from 'pages/earner/dashboard';
import { EarnerLayout } from './earner_layout';
import { DashboardLayout } from './dashboard_layout';

/**
 * Routes for the earner layout. See routes.jsx for documentation on how to safely add a new route.
 */
export const EarnerRoutes = ({ match }) => {
  const matchUrlTrimmed = () => match.url.replace(/^(.*?)\/*$/, '$1');

  return (
    <CredlySwitch suppressContainer>
      <Redirect
        from={match.url}
        exact
        to={`${matchUrlTrimmed()}/dashboard`}
      />
      <CredlyRoute
        path={`${match.url}/dashboard`}
        render={routeProps =>
          <DashboardLayout path={match.url}>
            <Dashboard path={routeProps.match.url}/>
          </DashboardLayout>
        }
        title="Dashboard"
      />
      <CredlyRoute
        path={`${match.url}/earned`}
        render={routeProps =>
          <EarnerLayout path={match.url}>
            <Earned path={routeProps.match.url}/>
          </EarnerLayout>
        }
        title="Badges"
      />
      {/* Anonymous needed for the confirm_delete_profile flow. */}
      <CredlyRoute
        path={`${match.url}/settings`}
        render={routeProps =>
          <EarnerLayout path={match.url}>
            <SettingsRoutes {...routeProps} />
          </EarnerLayout>}
        title="Settings"
      />
    </CredlySwitch>
  );
};

EarnerRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};
