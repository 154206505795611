import { useState, useEffect } from 'react';
import * as objUtils from 'utils/object';

/**
 * Hook that extracts form errors from a React Query mutation and prepares them
 * for use by a <Form> component.
 *
 * This is typically used as part of `useQueryForm` rather than as a standalone hook.
 *
 * Errors are stored only in state. Nothing fancy!
 *
 * @param {Object} mutation - An object of the shape returned by `useMutation`
 * @returns {[Object, Function, Function]} - A threeple of props for interacting with error state:
 *   * `errors` - An object mapping field names to arrays of error messages
 *   * `addErrors` - Function used to add errors to the state
 *   * `clearErrors` - Function used to clear all errors
 */
export const useErrors = (mutation) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mutation.isError) {
      setErrors(parseErrors(mutation.error.response));
    } else {
      setErrors({});
    }
  }, [mutation.isError]);

  return [
    getErrors(mutation, errors),
    (newErrors) => {
      setErrors({ ...errors, ...newErrors });
    },
    (fieldNames = '') => {
      setErrors(clearErrors(fieldNames, errors));
    }
  ];
};

// Surprisingly, the form never actually clears errors, it just expects
// them to go away once a new submission is made. This hides errors
// while a request is in flight, and then if the request returns with an
// error, the `useEffect` hook will overwrite any existing results with
// the new parsed set.
const getErrors = ({ isLoading }, errors) => (isLoading ? {} : errors);

// Adapted from Action.clearErrors
const clearErrors = (fieldNames, oldErrors) => {
  if (!fieldNames) {
    return {};
  }

  fieldNames = Array.isArray(fieldNames)
    ? objUtils.arrayToSet(fieldNames)
    : { [fieldNames]: 1 };

  return objUtils.filter(oldErrors, (name, _errs) => !(name in fieldNames));
};

// Extracted from Action.getErrors
const parseErrors = (response) => {
  const data = response.data.data;
  if (data?.errors) {
    const errors = {};
    data.errors.forEach(errProps => {
      const attribute = errProps.attribute;
      const messages = errProps.messages;
      errors[attribute] || (errors[attribute] = []);
      errors[attribute].push(...messages);
    });
    return errors;
  } else if (data?.message) {
    return { base: [data.message] };
  } else {
    return { base: ['An error occurred.'] };
  }
};
