/**
 * Utilities for browser support.
 */
import { memo } from 'utils';

/**
 * @return {Boolean} The browser is IE.
 */
export const isIE = memo(() => /MSIE|rv:11/.test(navigator.userAgent));

/**
 * @return {Boolean} The browser is Webkit.
 */
export const isWebkit = memo(() => /AppleWebkit/.test(navigator.userAgent));

/**
 * Are we on a mobile device?
 *
 * @returns {Boolean}
 */
export const isMobile = memo(() =>
  /Android|webOs|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent));

const checkOS = (os) =>
  navigator.userAgent.indexOf(os) !== -1;

export const isAndroid = memo(() => isMobile() && checkOS("Android"));

export const isIOS = memo(
  () =>
    isMobile() && (checkOS("iPhone") || checkOS("iPod") || checkOS("iPad")));


/**
 * Namespace for browser feature-detection functions.
 */
export const browserFeatures = {
  /**
   * @return {Boolean} CSS line-clamp is available.
   */
  lineClamp: memo(() => '-webkit-line-clamp' in document.documentElement.style)
};

/**
 * Check if the operating system is macOS.
 *
 * @returns {Boolean} True if the OS is macOS.
 */
export const isMac = memo(() => /Mac/i.test(navigator.userAgent));
