import { lazyLoadWithSuspense } from 'app_utils/lazy_load';
export const WorkforceDispatch = lazyLoadWithSuspense('WorkforceDispatch', () =>
  import(/* webpackChunkName: "workforce" */ './workforce_layout')
    .then(module => ({ default: module.WorkforceDispatch }))
);

export const EmployeeLearningActivity = lazyLoadWithSuspense('EmployeeLearningActivity', () =>
  import(/* webpackChunkName: "workforce" */ './components/employee_learning_activity')
    .then(module => ({ default: module.EmployeeLearningActivity }))
);

export const ManagerRecommendedBadges = lazyLoadWithSuspense('ManagerRecommendedBadges', () =>
  import(/* webpackChunkName: "workforce" */ './components/manager_recommended_badges')
    .then(module => ({ default: module.ManagerRecommendedBadges }))
);
