/* global TrackJS, window */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
// This component is always loaded. Make sure to load the lazy versions of any other components.
// (lazy versions are in '.').
import { InternalError } from '.';
import { NotFound } from '.';
import { getRailsEnv } from 'app_utils';
import { ResourceNotFoundError } from 'utils/resource_not_found_error';
import { SigninRequiredError } from 'utils/signin_required_error';
import { SignIn } from 'pages/signin';
import { Homepage } from 'pages/homepage/homepage';

/**
 * Catches exceptions thrown by child-nodes and instead renders an error page
 *
 * @property {boolean} [suppressContainer=false] - whether to embed the error view inside of a
 *   div with the container class; forwarded to ErrorView
 * @property {function(props)} [notFoundRenderer] - a custom "not found" error page component
 *   to render
 * @property {String} [pathName] - the current location pathname
 * @property {String} [homePath] - the "home" path of the current section; used to
 *   provide a link to return home; forwarded to ErrorView
 */
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  /**
   * Component caught an error. Update state so that we can display it appropriately.
   *
   * @param {Error} error - the error that was caught
   * @param {{componentStack:string[]}} errorInfo - additional information about the error, such as
   *   the React component tree
   */
  componentDidCatch(error, errorInfo) {
    if (!SigninRequiredError.errorIs(error)) {
      try {
        window.TrackJS && TrackJS.track(error);

        if (getRailsEnv() !== 'production') {
          // Do not change this to console.error. It may cause Casper tests to fail.
          console.warn('ErrorBoundary caught exception', error);
          console.warn(errorInfo.componentStack);
        }
      } catch (_e) {
        // swallow - we really don't want reporting the error to raise new errors
      }
    }
    this.setState({ hasError: true, error });
  }

  /**
   * Component changed. If a new route was selected (e.g from the header), then
   * switch off hasError so as to allow child components to render.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.pathName !== prevProps.pathName) {
      this.setState(state => {
        if (state.hasError) {
          return {
            hasError: false,
            error: null
          };
        } else {
          return null;
        }
      }
      );
    }
  }

  /**
   * Render a view for "not found" / 404.  The not found error can be overridden by specific views
   * to provide better information about the error.  For example, badge views override it with
   * an "unverifiable" message, rather than the usual not found.
   *
   * @returns {React.element}
   */
  renderNotFound() {
    const suppressContainer = this.props.suppressContainer;
    if (this.props.notFoundRenderer) {
      return this.props.notFoundRenderer({
        error: this.state.error, suppressContainer: suppressContainer
      });
    } else {
      return (
        <NotFound
          homePath={this.props.homePath}
          suppressContainer={this.props.suppressContainer}
        />
      );
    }
  }

  /**
   * Renders the component.
   *
   * @returns {React.element}
   */
  render() {
    if (this.state.hasError) {
      if (ResourceNotFoundError.errorIs(this.state.error)) {
        return this.renderNotFound();
      } else if (SigninRequiredError.errorIs(this.state.error)) {
        return <Suspense fallback="">
          <Homepage><SignIn /></Homepage>
        </Suspense>;
      } else {
        return <InternalError
          suppressContainer={this.props.suppressContainer}
          homePath={this.props.homePath}
          error={this.state.error}
        />;
      }
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  notFoundRenderer: PropTypes.func,
  pathName: PropTypes.string,
  homePath: PropTypes.string,
  suppressContainer: PropTypes.bool
};
