import React from 'react';
import PropTypes from 'prop-types';
import FocusTrapReact from 'focus-trap-react';
import { DataSetObserver } from 'utils/data_set_observer';
import { array } from 'utils/array';

/**
 * For as long as this component (and its children) exist on the page, it is the only thing that
 * can handle mouse, keyboard and touch events. Exceptions can be created using
 * FocusTrap.registerException.
 *
 * @param {Object} props - The properties supported by focus-trap-react, except for
 *   focusTrapOptions.allowOutsideClick.
 */
export const FocusTrap = props => {
  const ref = React.useRef();
  const exceptions = exceptionsObserver.use();

  // Because focus-trap-react accepts containerElements or children, but not both, we have to
  // trigger a second render through state.
  // See https://github.com/focus-trap/focus-trap-react/issues/183
  // When the bug is fixed, the <div> below can be removed, along with much of this component.
  const [containerElements, setContainerElements] = React.useState([]);
  React.useEffect(() => {
    // Transform css exceptions into elements for `containerElements`. When specified, this property
    // prevents the child node from being included in the element list, so that has to be added back
    // in with a ref.
    setContainerElements(
      array.compact(array.flatten([
        exceptions.map(s =>
          typeof s === 'string' ? Array.from(document.querySelectorAll(s)) : s
        ),
        ref.current
      ]))
    );
  }, [exceptions, ref.current]);

  const {children, ...passThrough} = props;
  return <FocusTrapReact {...passThrough} containerElements={containerElements}>
    <div ref={ref}>{children}</div>
  </FocusTrapReact>;
};

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired
};

const exceptionsObserver = new DataSetObserver();

/**
 * Globally register an exception to all focus traps. Elements that have parents with this
 * selector will be allowed to function. The selector is reference counted, so it's safe to add
 * and remove the same selector more than once.
 *
 * @param {Node|String} selector - A DOM node or valid CSS selector.
 */
FocusTrap.registerException = selector => exceptionsObserver.add(selector);

/**
 * Globally unregister an exception to all focus traps.
 *
 * @param {Node|String} selector - A DOM node or valid CSS selector.
 */
FocusTrap.removeException = selector => exceptionsObserver.remove(selector);

/**
 * React hook to add an exception node or selector to all focus traps.
 * Because refs don't exist until after the first mount
 *
 * @param {Node|String} selector - A DOM node or CSS selector.
 * @param {Array} dependencies - Hook dependencies.
 */
FocusTrap.useException = (selector, dependencies) => {
  React.useEffect(() => {
    exceptionsObserver.add(selector);
    return () => exceptionsObserver.remove(selector);
  }, [...dependencies, selector]);
};

// For unit tests.
export const testing = {observer: exceptionsObserver};
