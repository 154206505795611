import React from 'react';
import { lazyLoadWithSuspense, lazyWithRetry } from 'app_utils/lazy_load';
import { uiTweaks } from './helpers';

export { MAX_GRID_ROW_LENGTH } from './helpers';

// lazyLoadWithSuspense prevents page flicker for low-level components.
export const DataTable = lazyLoadWithSuspense('DataTable', () =>
  import(/* webpackChunkName: "data_table" */ './data_table')
    .then(module => ({ default: module.DataTable }))
);

export const CollapsibleDataTable = lazyLoadWithSuspense('CollapsibleDataTable', () =>
  import(/* webpackChunkName: "data_table" */ './collapsible_data_table')
    .then(module => ({ default: module.CollapsibleDataTable }))
);

DataTable.Col = lazyWithRetry(() =>
  import(/* webpackChunkName: "data_table" */ './data_table_col')
    .then(module => ({ default: module.DataTableCol }))
);

// Merge in all extensions. It's done this way so that DataTable.js can also access the extensions.
for (const key of Object.keys(uiTweaks)) {
  DataTable[key] = uiTweaks[key];
}

/**
 * Shortcut to render DataTable as a list (one column per row).
 *
 * @see DataTable
 */
export const DataList = props =>
  <DataTable {...props}>
    <DataTable.Col width="12" renderer={props.renderer}/>
  </DataTable>;

for (const key of Object.keys(uiTweaks)) {
  DataList[key] = uiTweaks[key];
}

/**
 * Shortcut to render DataTable as a grid.
 *
 * @property {String} cellClassName - Alias to rowClassName.
 * @property {String} headerColumnName - The header name to pass in for the column (for accessibility)
 * @see DataTable
 */
export const DataGrid = props =>
  <DataTable {...props} layout="grid" rowClassName={props.cellClassName || props.rowClassName}>
    <DataTable.Col header={props.headerColumnName || ''} renderer={props.renderer}/>
  </DataTable>;
DataGrid.defaultProps = {
  uiTweaks: [DataTable.NO_MARGIN]
};

/**
 * Shortcut to render DataTable as cards.
 *
 * @property {String} cardClassName - Alias to rowClassName.
 * @see DataTable
 */
export const DataCards = props =>
  <DataTable {...props} layout="cards" rowClassName={props.cardClassName || props.rowClassName}>
    <DataTable.Col renderer={props.renderer}/>
  </DataTable>;
