import React, { useContext, useEffect } from 'react';
import { SiteHeaderContext } from './site_header_context';

export const useCustomHeader = (HeaderComponent) => {
  const headerContext = useContext(SiteHeaderContext);
  useEffect(() => {
    headerContext.setCustomHeader(<HeaderComponent/>);
    return () => headerContext.setCustomHeader(null);
  }, []);
};

/**
 * HOC that takes care of setting the provided custom header when the wrapped component is mounted,
 * and removing it when the wrapped component is unmounted.  Header must be a React component class
 * or function.  That is, if your custom header requires some context from the wrapped component,
 * you'll have to implement that yourself.
 *
 * @param {Function} HeaderComponent - the React component type to render as the header
 * @returns {Function} - function that takes a component and returns a new component that will
 *   automatically set/clear the custom header when the component is mounted/unmounted, respectively
 */
export const withCustomHeader = (HeaderComponent) => {
  return (WrappedComponent) => {
    const Result = (props) => {
      useCustomHeader(HeaderComponent);
      return <WrappedComponent {...props}/>;
    };
    Result.contextType = SiteHeaderContext;
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Result.displayName = `WithCustomHeader(${displayName})`;
    return Result;
  };
};
