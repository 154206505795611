import React from 'react';
import { useAlerts } from 'alert';
import { useLocalForm } from 'form/use_local_form';


/**
 * Hook to create a <Form> properties using an Action object from ActionManager.
 * If you are using React Query instead of ActionManager, please see `useQueryForm`
 * for an equivalent hook.
 *
 * This may be used instead of connectForm() to create a
 * redux-connected form, or instead of <LocalForm> to make a non-redux form.
 *
 * Normal usage:
 * const MyCustomForm = props => {
 *   const [formProps] = useFormProps(action, {initialValues: {...}});
 *   return <Form {...formProps}>...</Form>
 * }
 *
 * Usage for a pass-through form:
 * const MyCustomForm = props => {
 *   const [formProps] = useFormProps(action, objUtils.except(props, ['localPropName1']));
 *   return <Form {...formProps}>...</Form>
 * }
 *
 * @param {Action} action - An Action with a POST request. See action.js. Omit this to create a
 *   form without Redux (equivalent to <LocalForm>).
 * @param {Object} otherProps - Properties to pass to the form.
 * @returns {[Object, ActionState]} properties to pass into <Form>, and the current ActionState
 *   for the action. The latter is provided so that components that need redux data can avoid the
 *   performance hit of calling both useAction() and useFormProps().
 */
export const useFormProps = (action = null, otherProps = {}) => {
  const prevAction = React.useRef(action);
  if (!prevAction.current !== !action) {
    throw new Error('useFormProps cannot switch between connected and non-connected forms.');
  } else if (prevAction.current !== action) {
    prevAction.current = action;
  }

  const alerts = useAlerts();

  // Using this kind of conditional hook breaks the rules of React hooks. However, the exception
  // above prevents the case where this wouldn't cause any real problem.
  /* eslint-disable react-hooks/rules-of-hooks */
  if (action) {
    const [actionState, submit] = action.useAction();
    const [errors, addErrors, clearErrors] = action.useErrors();

    const formProps = {
      submit,
      submitStatus: actionState.status,
      errors: errors,
      addAlert: alerts.add,
      addClientErrors: addErrors,
      clearClientErrors: clearErrors,
      ...otherProps
    };

    // If initialValues is not provided, default to the action's resources, provided that the
    // resources are an object (not an array).
    if (!('initialValues' in otherProps) && !Array.isArray(actionState.resources)) {
      formProps.initialValues = actionState.resources;
    }

    return [formProps, actionState];
  } else {
    const formProps = {...useLocalForm(otherProps), addAlert: alerts.add};
    return [formProps, {
      status: formProps.submitStatus,
      errors: formProps.errors,
      resources: {},
      requestDetails: {},
      metadata: {}
    }];
  }
};
