/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const FooterLink = ({ linkTarget, linkId, linkDefaultMessage }) => {
  return (
    <div className="footer-link-wrapper">
      <a className="footer-link" href={linkTarget}>
        <FormattedMessage
          id={linkId}
          defaultMessage={linkDefaultMessage}
        />
      </a>
    </div>
  );
};

FooterLink.propTypes = {
  linkTarget: PropTypes.string,
  linkId: PropTypes.string,
  linkDefaultMessage: PropTypes.string
};
