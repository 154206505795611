import generateDefaultInitialState from 'redux-resource/lib/utils/generate-default-initial-state';
import { API_REDUCER_OPTS } from 'utils/reduxUtils';

/**
 * A static object that represents the complete initial state for an organization management
 * resource slice.
 */
const orgMgmtResetState = {
  ...generateDefaultInitialState(),
  ...(API_REDUCER_OPTS().initialState)
};

const ORG_MGMT_SWITCH_ORG_CONTEXT_ACTION_TYPE = 'ORG_MGMT_SWITCH_ORG_CONTEXT';

export const orgMgmtSwitchContextAction = (dispatch) => {
  return () => dispatch({ type: ORG_MGMT_SWITCH_ORG_CONTEXT_ACTION_TYPE });
};

/**
 * Reducer plugin for org mgmt resource slices. See redux resource plugins for further info.
 * Listens for a dispatch to signal
 * switching organization context and resets the state to its initial empty state.
 * Useful for viewing "common" resources such as authorized badge templates but also
 * generally good for cleaning up unneeded store data related to non current organizations
 */
export function orgMgmtSwitchContextPlugin(_resourceType) {
  return function(state, action) {
    if (action.type === ORG_MGMT_SWITCH_ORG_CONTEXT_ACTION_TYPE) {
      return orgMgmtResetState;
    } else {
      return state;
    }
  };
}
