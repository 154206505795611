import React from 'react';
import { useLocation } from 'react-router';
import { QueryString } from 'utils/query_string';
import { useEffectExceptOnMount } from 'utils/react_utils';

/**
 * Watch changes to the URL search string, and output QueryString objects. Warning: Only use this
 * with locations that trigger updates, such as the `routeParams` output of `<CredlyRoute>`. Do
 * not use it with the `history` object from `withRouter()`.
 *
 * class MyComponent extends Component {
 *   componentDidMount() {
 *     if (!this.props.queryString.isEmpty()) {
 *       // Do something
 *     }
 *   }
 *
 *   componentDidUpdate(prevProps) {
 *     if (!this.props.queryString.isEqual(prevProps.queryString)) {
 *       // Do something.
 *     }
 *   }
 *
 *   render() {
 *     return '<div>Current search is {this.props.queryString.toString()}</div>';
 *   }
 * }
 * withQueryString(MyComponent)
 */
export const withQueryString =
  (WrappedComponent, inputParam = 'location', outputParam = 'queryString') => {
    const WithQueryString = props => {
      const params = {
        ...props,
        [outputParam]: useQueryString(props[inputParam])
      };
      return <WrappedComponent {...params}/>;
    };
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithQueryString.displayName = `WithQueryString(${displayName})`;
    return WithQueryString;
  };

/**
 * React state hook version of withQueryString.
 *
 * @param {Object} location - The router's location, or document.location.
 * @returns {QueryString}
 */
export const useQueryString = location => {
  const [params, setParams] = React.useState(new QueryString(location.search));

  useEffectExceptOnMount(() => {
    setParams(new QueryString(location.search));
  }, [location.search]);

  return params;
};

/**
 * React state hook version of withQueryString, which uses the browser's location instead of a
 * passed-in location.
 *
 * @returns {QueryString}
 */
export const useBrowserQueryString = () => useQueryString(useLocation());
