import { apiReducer } from 'utils/reduxUtils';
import * as actionTypes from './action_types';

export const searchResultsReducer = apiReducer('searchResults');

export const globalSearchUIReducer = (state = { open: false }, action = {}) => {
  switch (action.type) {
    case actionTypes.CLOSE_GLOBAL_SEARCH:
      return { ...state, open: false };
    case actionTypes.OPEN_GLOBAL_SEARCH:
      return { ...state, open: true };
    case actionTypes.TOGGLE_GLOBAL_SEARCH:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
