import { useInfiniteQuery } from '@tanstack/react-query';
import { getOtherBadges } from '../badge_actions_api/other_badges';

export const useOtherBadges = (userId) => {
  const queryInfo = useInfiniteQuery(
    ['otherBadges', 'list', userId],
    ({ pageParam }) => getOtherBadges(userId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.metadata?.['next_page_url'] || undefined,
      staleTime: 300000
    }
  );

  return { ...queryInfo };
};
