import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeClassName } from 'utils';
import { LoadingSpinner } from './loading_spinner';

import './button.sass';

/**
 * A simple button.
 *
 * @property {String} to - Go to this url on click
 * @property {function} onClick - Called when clicked
 * @property {String} type - The type of button. One of {primary, secondary, tertiary, min,
 *   min-danger, cancel, filter, slim}
 * @property {String} size - The size of button. One of {full, auto(default)}
 * @property {String} mobileSize - Choose a different size for mobile. full is the default
 * @property {Boolean} disabled - Show the disabled state
 * @property {Boolean} isSubmit - Should this button submit a form?
 * @property {Boolean} loading - Show a loading spinner instead of text
 * @property {Number} tabIndex - The tab index
 */
const Button = (props) => {
  const { to, onClick, disabled, isSubmit, loading, tabIndex, history, children } = props;
  /**
   * Handle a click on the button (preventing behavior if the button is disabled)
   *
   * @param {Event} e - the object describing the click event
   */
  const buttonOnClick = (e) => {
    // Submit buttons have to propagate to the form.
    if (!isSubmit) {
      e.preventDefault();
    }

    // onClick can cancel routing.
    if (!onClick || onClick(e)) {
      to && history.push(to);
    }
  };

  const handleDisabledEvent = (e) => {
    e.preventDefault();
  };

  // Several Casper tests depend on this label existing. It's probably not needed otherwise.
  let label;
  if (loading) {
    label = 'Loading';
  } else if (typeof children === 'string') {
    label = children;
  } else if (props.label) {
    label = props.label;
  }

  return (
    <button
      className={buttonClassName(props)}
      type={isSubmit ? 'submit' : 'button'}
      onClick={disabled || loading ? handleDisabledEvent : buttonOnClick}
      tabIndex={tabIndex}
      aria-disabled={disabled || loading}
      aria-label={label}
    >
      <div className="cr-button__content">{children}</div>
      {loading && <LoadingSpinner position="center" size="small" inheritColor/>}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(
    ['primary', 'secondary', 'tertiary', 'min', 'min-danger', 'cancel', 'filter', 'slim']
  ),
  size: PropTypes.oneOf(['full', 'auto']),
  mobileSize: PropTypes.oneOf(['full', 'auto']),
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
  // withRouter()
  history: PropTypes.shape({ push: PropTypes.func.isRequired })
};

Button.defaultProps = {
  size: 'auto',
  mobileSize: 'full'
};

const RouterButton = withRouter(Button);
export { RouterButton as Button };
export { Button as SimpleButton };



/**
 * Try to lay out buttons intelligently on mobile and desktop. When using this component, it's
 * rarely necessary to use the size and mobileSize properties of Button.
 *
 * @param {Object} props
 *   @param {String} props.className
 *   @param {*} props.children
 */
export const AutoButtons = ({ children }) => <div className="auto-buttons">{children}</div>;
AutoButtons.propTypes = {
  children: PropTypes.node
};

/**
 * Lay out buttons in a single row, regardless of screen size. size and mobileSize properties can
 * be omitted.
 *
 * @param {Object} props
 *   @param {String} props.className
 *   @param {*} props.children
 */
export const ButtonRow = ({ children }) => <div className="cr-button-row">{children}</div>;
ButtonRow.propTypes = {
  children: PropTypes.node
};

/**
 * This component looks like a button, but doesn't act like one.
 */
export const FakeButton = props =>
  <div className={buttonClassName(props)}>
    <div className="cr-button__content">{props.children}</div>
  </div>;
FakeButton.propTypes = Button.propTypes;

/**
 *
 * @param {object} props - same props as Button, except for "type", which will be "filter"
 *   @param {number} [props.appliedFilters] - if present, the number of filters applied, which will
 *     be shown as a number inside of a circle within the button
 * @constructor
 */
export const FilterButton = (props) => {
  const propsWithoutAppliedFilters = { ...props };
  delete propsWithoutAppliedFilters.appliedFilters;
  const appliedFilters = props.appliedFilters;

  return (
    <Button {...propsWithoutAppliedFilters} type="filter">
      {props.children}
      {
        !!appliedFilters && (
          <div className="cr-button__applied-filter-count">
            {appliedFilters > 9 ? (<>9<span>+</span></>) : appliedFilters}
          </div>
        )
      }
    </Button>
  );
};

FilterButton.propTypes = {
  ...Button.proptypes,
  appliedFilters: PropTypes.number,
  children: PropTypes.any
};

/**
 * Returns the full className for the top-level button element, based on the various conditions
 * of the button.
 *
 * @returns {string}
 */
const buttonClassName = ({ className, type, size, mobileSize, isSubmit, loading, disabled }) => makeClassName([
  className,
  'cr-button',
  'cr-button__' + (type || 'primary'),
  `cr-button__${size || 'auto'}`,
  `cr-button__mobile-${mobileSize || 'full'}`,
  isSubmit && 'cr-button__submit-wrap',
  loading && 'cr-button__loading',
  disabled && 'cr-button__disabled'
]);
