import React from 'react';
import { Heading } from 'controls/heading';
import { Button } from 'controls/button';
import DashboardBannerImage from 'images/dashboard_banner_image.png';
import { ReviewProfileDialog } from './review_profile_dialog';
import './dashboard_banner.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useAddUserFeatureDecisionOptOut } from 'utils/enabled_features';
import { useOnOff } from 'utils/react_utils';
import { FormattedMessage } from "react-intl";

export const DashboardBanner = () => {
  const [
    showReviewProfileDialog,
    openReviewProfileDialog,
    hideReviewProfileDialog
  ] = useOnOff(false);
  const optOutOfDashboardBanner = useAddUserFeatureDecisionOptOut('earner-dashboard-banner');

  const onSuccess = (msg) => {
    hideReviewProfileDialog();
    optOutOfDashboardBanner(msg);
  };

  return (
    <div>
      <div className="dashboard-banner__wrapper">
        <div className="dashboard-banner__call-to-action-wrapper">
          <div
            className="dashboard-banner__close-button"
            aria-label="Hide Banner"
            tabIndex={0}
            onClick={() => optOutOfDashboardBanner('dashboard-banner-dismissed')}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Heading appearance="custom" className="dashboard-banner__header">
            <FormattedMessage
              id="earner.dashboard_banner.header"
              defaultMessage="Level up your career"
            />
          </Heading>
          <p className="dashboard-banner__subtext">
            <FormattedMessage
              id="earner.dashboard_banner.subtext"
              defaultMessage="Make sure your account details and
                              preferences are up to date so that
                              you can get the most out of your
                              credentials and their new home at Credly."
            />
          </p>
          <Button
            onClick={openReviewProfileDialog}
            type="secondary"
            className="dashboard-banner__button"
            mobileSize="auto"
          >
            <FormattedMessage
              id="earner.dashboard_banner.button"
              defaultMessage="Review profile"
            />
          </Button>
        </div>
        <img
          src={DashboardBannerImage}
          className="dashboard-banner__image"
        />
      </div>
      {showReviewProfileDialog &&
        <ReviewProfileDialog
          shouldClose={hideReviewProfileDialog}
          onSuccess={onSuccess}
        />
      }
    </div>
  );
};
