import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './collapsible_section.sass';

/**
 * This component can be used to create a collapsible section that can be animated open or closed
 * according to the collapsed property.
 *
 * @property {String} alwaysExpandedAboveBreakpoint - a Bootstrap breakpoint above which the
 *   section should always appear expanded, regardless of collapsed value
 * @property {Boolean} collapsed - whether the section should be expanded or collapsed
 * @property {String} className - an additional class to include on the HTML tag for section
 * @property {String} tagName - which HTML tag to use for the CollapsibleSection
 * @property {Boolean} allowOverflow - Don't put overflow:hidden on the section. This is useful
 *   when content extends slightly outside the box (for example, a focus outline), and the content's
 *   height can be changed safely.
 * @property {Object} extraProps - additional props to include on section tag (e.g. ARIA attributes,
 *   event handlers, etc.)
 */
export class CollapsibleSectionWithInnerRef extends Component {
  constructor(props){
    super(props);
    this.ownCollapsibleContent = React.createRef();

    this.state = {
      collapsedClassName: props.collapsed && 'c-collapsible-section--is-collapsed',
      style: {height: ''}
    };
    this.mounted = false;
  }

  /**
   * If props indicate that - above a certain breakpoint - the section should always be expanded,
   * this function returns a class that provides the necessary CSS.
   * @returns {string}
   */
  alwaysExpandedAboveBreakpointClass(){
    if (this.props.alwaysExpandedAboveBreakpoint){
      return `c-collapsible-section--always-expanded-${this.props.alwaysExpandedAboveBreakpoint}`;
    }
  }

  /**
   * Returns the React Ref to the root element; either using the same ref as provided by props (in
   * case of a forwarded ref) or the components own ref, if not.
   * @returns {React.RefObject}
   */
  collapsibleContent() {
    return this.props.innerRef || this.ownCollapsibleContent;
  }

  /**
   * Component mounted; make note of that.
   */
  componentDidMount() {
    this.mounted = true;
  }

  /**
   * The component updated.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps){
    if (prevProps.collapsed !== this.props.collapsed){
      this.toggleSection(this.props.collapsed);
    }
  }

  /**
   * The component is about to unmount.  Record that, so that animation callbacks will refrain from
   * invoking callbacks.
   */
  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Expands (makes section occupy its natural vertical space) or collapses (makes component occupy
   * no vertical space) the section, according to the collapse parameter.
   *
   * @param {Boolean} collapse - whether the section is to be expanded or collapsed
   */
  toggleSection(collapse) {
    const onTransitioned = () => {
      // if we're unmounting, don't do any of this
      if (!this.mounted) {
        return;
      }

      const collapsibleContentEl = this.collapsibleContent().current;
      collapsibleContentEl.removeEventListener('transitionend', onTransitioned);

      if (collapse && this.props.onCollapsed){
        this.props.onCollapsed(collapsibleContentEl);
      } else if (!collapse && this.props.onExpanded){
        this.props.onExpanded(collapsibleContentEl);
      }

      if (!collapse) {
        // Reset the height to what's in the stylesheet, to prepare for the next transition.
        // This is important in case the content size changes.
        this.setState({height: ''});
      }
    };

    const collapsibleContentEl = this.collapsibleContent().current;
    collapsibleContentEl.addEventListener('transitionend', onTransitioned);

    // Remove the transition for height prior to setting it to the initial/current height, below;
    // we only want the transition and handler to occur
    collapsibleContentEl.style.transition = 'none';

    requestAnimationFrame(() => {
      // if we're unmounting, don't do any of this
      if (!this.mounted) {
        return;
      }

      // Always re-fetch 'current' in case there was a re-render in the interim which invalidated
      // the element.
      const collapsibleContentEl = this.collapsibleContent().current;
      const sectionHeight = `${collapsibleContentEl.scrollHeight}px`;

      // First, set height to its actual, current height (either natural height of the element
      // in case of expanded, or reset to 0 in case of collapsed)
      collapsibleContentEl.style.height = collapse ? sectionHeight : 0;
      requestAnimationFrame(() => {
        // if we're unmounting, don't do any of this
        if (!this.mounted) {
          return;
        }

        // Restore the transition from the stylesheet.
        collapsibleContentEl.style.transition = '';

        // After having set initial height and ensured a transition for that property,
        // set the height to the desired height (either 0 in case of collapsing a currently-
        // expanded section, or natural height in case of expanding a collapsed section).
        // Save it in state so that a re-render won't reset the value.
        this.setState({
          collapsedClassName: collapse && 'c-collapsible-section--is-collapsed',
          height: collapse ? 0 : sectionHeight
        });
      });
    });
  }

  /**
   * Render the component.
   *
   * @returns {*}
   */
  render(){
    const className = makeClassName([
      this.props.className,
      'c-collapsible-section',
      this.props.allowOverflow && 'c-collapsible-section--allow-overflow',
      this.state.collapsedClassName,
      this.alwaysExpandedAboveBreakpointClass()
    ]);
    return React.createElement(
      this.props.tagName || 'div',
      {
        ...this.props.extraProps,
        ref: this.collapsibleContent(),
        className,
        style: { height: this.state.height },
        id: this.props.id
      },
      this.props.children
    );
  }
}

CollapsibleSectionWithInnerRef.propTypes = {
  alwaysExpandedAboveBreakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  collapsed: PropTypes.bool.isRequired,
  tagName: PropTypes.string,
  allowOverflow: PropTypes.bool,
  extraProps: PropTypes.object
};

const CollapsibleSection = React.forwardRef((props, ref) => (
  <CollapsibleSectionWithInnerRef {...props} innerRef={ref}/>
));

CollapsibleSection.propTypes = { ...CollapsibleSectionWithInnerRef.propTypes };

CollapsibleSection.displayName = 'CollapsibleSection';

export default CollapsibleSection;
