import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeClassName } from 'utils';
import { toggleElement } from 'utils/array';
import { useIntl } from 'react-intl';

import './multi_select_list.sass';

/**
 * Represents an "empty" option in the list, such as an "All" or "None" option, which can be
 * automatically added to the list of "real" values.
 *
 * @type {string}
 */
const EMPTY_SELECTION_VALUE = '';

/**
 * Component that allows multiple items to be selected from a list.
 *
 * @param {Object} props
 *   @param {{label:string, value:string}[]} props.items - All of the possible options to select
 *     from
 *   @param {String[]} props.value - An array containing current selections (values should be from
 *     the list provided in items)
 *   @param {Function(string[])} props.onChange - Function to call when the selections should
 *     change; this component is expected to be used in a controlled way, so this should trigger a
 *     change of props.value
 *   @param {boolean} [props.allowDeselectAll=false] - if true, allows the last item to be
 *     deselected, even if there is no "empty" selection
 *   @param {string} [props.className] - an optional class name to add to the top-level element
 *   @param {string} [props.emptySelectionLabel='All'] - if an option is included for an empty
 *     selection, this is the label for (e.g. "All" or "None" might be appropriate values, depending
 *     upon the semantics of usage)
 *   @param {boolean} [props.skipEmptySelection=false] - whether to include an empty selection
 *     option
 *   @param {boolean} [props.revertAllSelectedToEmpty=false] - whether to revert a selection of
 *     every available option to an empty list (can be useful for a short list of options where the
 *     empty option represents "all", but not recommended for longer lists)
 */
export const MultiSelectList = (props) => {
  // helper to check whether a given option should be visually marked as selected or not
  const intl = useIntl();
  const isSelected = useCallback((optionValue) => {
    return (optionValue === EMPTY_SELECTION_VALUE && props.value.length === 0) ||
      props.value.includes(optionValue);
  }, [props.value]);

  // helper to check whether all possible items are selected
  const allItemsSelected = useCallback(makeAllItemsSelected(props.items), [props.items]);

  // handler for when an item is clicked to update the selected values appropriately by calling
  // props.onChange with a new set of selections
  const handleClick = useCallback((evt) => {
    const clickedValue = evt.currentTarget.dataset.value;
    if (clickedValue === EMPTY_SELECTION_VALUE) {
      if (props.value.length > 0) {
        props.onChange([]);
      }
    } else if (props.value.length === 1 && clickedValue === props.value[0]) {
      if (!props.skipEmptySelection || props.allowDeselectAll) {
        props.onChange([]);
      }
    } else {
      let value = toggleElement(props.value, clickedValue);
      if (props.revertAllSelectedToEmpty && allItemsSelected(value)) {
        value = [];
      }
      props.onChange(value);
    }
  }, [
    props.value,
    props.onChange,
    props.skipEmptySelection,
    props.revertAllSelectedToEmpty,
    allItemsSelected
  ]);

  const handleKeyDown = evt => {
    if (evt.key === " " || evt.key === "Enter") {
      handleClick(evt);
    }
  };

  // all available options, including a special empty option, if props indicate to do so
  const allOptions = useMemo(() => {
    if (props.skipEmptySelection) {
      return props.items;
    } else {
      const label = props.emptySelectionLabel ? props.emptySelectionLabel
        : intl.formatMessage({
          id: 'multi_select_list.empty_selection_label_default',
          defaultMessage: 'All'
        });
      return [{ value: EMPTY_SELECTION_VALUE, label: label }, ...props.items];
    }
  }, [props.items]);

  return (
    <div className={makeClassName('c-multi-select-list', props.className)} role="listbox" aria-multiselectable="true">
      {allOptions.map((option) => {
        const selected = isSelected(option.value);
        const itemClassName = makeClassName(
          "c-multi-select-list__item",
          selected && "c-multi-select-list__item--selected"
        );
        return (
          <div
            aria-selected={selected}
            data-value={option.value}
            role="option"
            tabIndex={0}
            className={itemClassName}
            key={option.value}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            {selected &&
              <FontAwesomeIcon className="c-multi-select-list__item-selected-icon" icon={faCheck}/>
            }
            {!selected &&
              <div className="c-multi-select-list__item-selected-icon"/>
            }
            <div title={option.label} className="c-multi-select-list__item-value">
              {option.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

MultiSelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  allowDeselectAll: PropTypes.bool,
  className: PropTypes.string,
  emptySelectionLabel: PropTypes.string,
  skipEmptySelection: PropTypes.bool,
  revertAllSelectedToEmpty: (props, propName, componentName) => {
    if (props.skipEmptySelection && props[propName]) {
      return new Error(
        `Invalid prop ${propName} passed to ${componentName}. ` +
        `${propName} cannot be true if skipEmptySelection is also true.`
      );
    }
  }
};

/**
 * Internal helper to check whether a list of selected values represents all possible items selected
 * from the list of options. Structured as a factory function for memoization.
 *
 * @param {{label:string, value:string}[]} items - list of possible options that the user can
 *   select from, against which to check whether a given selection list includes all items
 * @returns {function(string[]):boolean}
 */
const makeAllItemsSelected = (items) => (selected) => {
  if (selected.length < items.length) {
    return false;
  }

  const selectionsAsSet = selected.reduce((acc, item) => acc[item] = 1 && acc, {});
  for (const val of items) {
    if (!Object.prototype.hasOwnProperty.call(selectionsAsSet, val.value)) {
      return false;
    }
  }
  // assume that selections that aren't actually in items don't matter
  return true;
};
