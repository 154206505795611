import { useAlerts } from 'alert';
import { useErrors } from './use_errors';

/**
 * Compatibility layer between @tanstack/query and the in-house Form components
 *
 * Provides an equivalent set of functionality as `useFormProps` but sourced from
 * a Tanstack Mutation object rather than the in-house Action object. If you are
 * using ActionManager, please see `useFormProps` instead.
 *
 * Will handle form submit, errors, and alerts for your typical form.
 *
 * This does not support <LocalForm> or some of the other more unusual fucntionality
 * of `useFormProps`.
 */
export const useQueryForm = (mutation) => {
  const alerts = useAlerts();

  const [errors, addErrors, clearErrors] = useErrors(mutation);

  const formProps = {
    submit: mutation.mutate,
    submitStatus: statusFieldTranslation(mutation.status),
    errors: errors,
    addAlert: alerts.add,
    addClientErrors: addErrors,
    clearClientErrors: clearErrors
  };

  return formProps;
};

const statusFieldTranslation = (reactQueryStatus) => {
  return {
    failed: reactQueryStatus === "error",
    idle: reactQueryStatus === "enabled",
    pending: reactQueryStatus === "loading",
    succeeded: reactQueryStatus === "success"
  };
};
