import { lazyLoadWithSuspense } from 'app_utils/lazy_load';

// lazyLoadWithSuspense prevents page flicker for low-level components.
export const BadgeIssuer = lazyLoadWithSuspense('BadgeIssuer', () =>
  import(/* webpackChunkName: "badges" */ './badge_issuer')
    .then(module => ({default: module.BadgeIssuer})),
  () => ''
);
export const BadgeCard = lazyLoadWithSuspense('BadgeCard', () =>
  import(/* webpackChunkName: "badges" */ './badge_card')
    .then(module => ({default: module.BadgeCard})),
  () => ''
);
export const OrgCard = lazyLoadWithSuspense('OrgCard', () =>
  import(/* webpackChunkName: "badges" */ './org_card')
    .then(module => ({default: module.OrgCard})),
  () => ''
);
export const Evidence = lazyLoadWithSuspense('Evidence', () =>
  import(/* webpackChunkName: "badges" */ './evidence')
    .then(module => ({default: module.Evidence})),
  () => ''
);
export const FullBadge = lazyLoadWithSuspense('FullBadge', () =>
  import(/* webpackChunkName: "badges" */ './full_badge')
    .then(module => ({default: module.FullBadge})),
  () => ''
);
export const Medium = lazyLoadWithSuspense('Medium', () =>
  import(/* webpackChunkName: "badges" */ './medium')
    .then(module => ({default: module.Medium})),
  () => ''
);
export const ExpiredBadgeLabel = lazyLoadWithSuspense('ExpiredBadgeLabel', () =>
  import(/* webpackChunkName: "badges" */ './expired_badge_label')
    .then(module => ({default: module.ExpiredBadgeLabel})),
  () => ''
);

export { badgeToBadgeMedium, findPrimaryIssuer, isExpired } from './selectors';
export * from './badge_actions';
