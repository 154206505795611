import React from 'react';
import PropTypes from 'prop-types';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from 'translations';
import './error_view.sass';

/**
 * The generic error page
 *
 * @property {String} title - title of the message
 * @property {String} subTitle - subtitle of the message
 * @property {String} message - error message
 * @property {Object} icon - icon used to display
 * @property {Boolean} [suppressContainer=false] - whether to embed the error view inside of a
 *   div with the container class
 * @property {Array} [links] - links to display on page; if absent, will instead display a link to
 *   the home page
 *    @property {String} link - URL to page
 *    @property {string} test - text to display in link
 * @property {Error} [error] - An exception to display in a hidden div (useful for debugging).
 * @property {String} [homePath] - the "home" path of the current senction; used to
 *   provide a link to return home
 */
const ErrorView = (props) => {
  const links = props.links;
  const homePath = props.homePath || '/';
  const error = props.error;

  const innerContent = (
    <div className="row align-items-center">
      <div className="col-7 col-sm-8">
        <Heading appearance="custom" className="error-view__title">
          <FormattedMessage
            id={intlKeyFromValue(props.title, 'error_page.title')}
            defaultMessage="{title}"
            values={{
              title: props.title
            }}
          />
        </Heading>

        <NewHeadingLevel>
          <Heading appearance="custom" className="error-view__sub-title">
            <FormattedMessage
              id={intlKeyFromValue(props.subTitle, 'error_page.subtitle')}
              defaultMessage="{subTitle}"
              values={{
                subTitle: props.subTitle
              }}
            />
          </Heading>
        </NewHeadingLevel>

        <div className="error-view__error-message">
          <FormattedMessage
            id={intlKeyFromValue(props.message, 'error_page.message')}
            defaultMessage="{message}"
            values={{
              message: props.message
            }}
          />
        </div>

        {
          links &&
          links.map((link, idx) => (
            <div key={idx}><a href={link.link}>{link.text}</a></div>
          ))
        }
        {!links &&
          <a href={homePath}>
            <FormattedMessage
              id="error_page.return_home_page"
              defaultMessage="Return to the homepage"
            />
          </a>}
      </div>

      <div className="error-view__image-wrap col-5 col-sm-4">
        {props.icon}
      </div>
      {
        error &&
        <div style={{ display: 'none' }} data-exception="error">
          <div data-error-type="Error">{error.toString()}</div>
          <div data-error-type="message">{error.message}</div>
          <div data-error-type="stack">{error.stack}</div>
        </div>
      }
    </div>
  );

  if (!props.suppressContainer) {
    return (
      <div className="container error-view__container">
        {innerContent}
      </div>
    );
  } else {
    return innerContent;
  }
};

ErrorView.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  icon: PropTypes.object.isRequired,
  suppressContainer: PropTypes.bool,
  links: PropTypes.array,
  homePath: PropTypes.string
};

export { ErrorView };
