import { Action } from 'utils/action';
import { actionTypes } from 'redux-resource';
import { requestDownloadable, downloadUrl, getStatus, poll } from 'utils/download';

const POLL_INTERVAL = 1000;
const POLL_MAX_ATTEMPTS = 3 * 60 * 1000 / POLL_INTERVAL; // Process for 3 minutes at most.

/**
 * The image profile touches three separate endpoints, including one on s3. Rather than create
 * three global reducers or wedge this into the current redux slice scheme, create a custom reducer.
 */
export class DownloadBadgeActions extends Action {
  /**
   * @param {Object} options - See Action
   * @param {String} url - The download url
   * @see Action.constructor
   */
  constructor(options, url) {
    super({
      resourceType: 'temp',
      url: url,
      effect: 'placeholder',
      ...options
    });
  }

  /**
   * Override Action.action to make a multi-part request.
   *
   * @override
   * @param {String} id - The badge id.
   * @param {Object=null} requestBody - Additional parameters to send with the url.
   * @param {Element=} domContext - Sometimes, the global anchor this creates is not clickable due
   *   to other click handlers (for example, focusTrap). Use this to put the anchor in a known
   *   clickable element.
   * @returns {Function}
   */
  action = (id, requestBody, domContext = null) => {
    return dispatch => {
      let aborted = false;
      const options = this.getOptions();
      const url = options.url.replace('[id]', id);

      const setStatus = (action, more = {}) => dispatch({
        type: action,
        requestKey: options.requestKey,
        resourceType: options.resourceType,
        resources: [{ id: 'downloadBadge' + id }],
        ...more
      });

      setStatus(actionTypes.UPDATE_RESOURCES_PENDING);

      const promise = (async () => {
        const result = await requestDownloadable(url, requestBody);
        const requestId = result.body && result.body.data && result.body.data.id;
        let status = getStatus(result);

        if (status === 'unauthorized') {
          // The base Action class retries the request on a 401, before reloading. This one doesn't,
          // so just reload to force the signin page to render.
          document.location.reload(true);
        } else {
          const requestUrl = `${url}/${requestId}`;
          if (status === 'pending') {
            status = await poll(requestUrl, POLL_INTERVAL, POLL_MAX_ATTEMPTS, () => aborted);
          }

          if (status === 'failed') {
            setStatus(actionTypes.UPDATE_RESOURCES_FAILED, {
              requestProperties: {
                result: {message: 'Download failed'}
              }
            });
          } else if (status === 'succeeded') {
            setStatus(actionTypes.UPDATE_RESOURCES_SUCCEEDED);
            downloadUrl(requestUrl + '/download', null, domContext);
          }
        }
      })();

      return {
        abort: () => aborted = true,
        promise: promise
      };
    };
  };
}

export const downloadPdfAction = new DownloadBadgeActions({
  requestKey: 'downloadBadgePdf'
}, '/api/v1/users/self/badges/[id]/printable_pdfs');

export const downloadBadgeImageAction = new DownloadBadgeActions({
  requestKey: 'downloadBadgeImage'
}, '/api/v1/users/self/badges/[id]/images');

export const downloadWalletPassAction = new DownloadBadgeActions({
  requestKey: 'downloadWalletPass'
}, '/api/v1/users/self/badges/[id]/wallet');

export const downloadTranscriptAction = new DownloadBadgeActions({
  requestKey: 'downloadBadgeImage'
}, '/api/v1/transcript_distributions/[id]/transcript_badge_pdfs');
