import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import { Heading } from "controls/heading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { SkillsSelect } from 'form/complex_fields/skills_select';
import * as objectUtils from 'utils/object';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { FileUpload } from "form/file_upload";
import { userSkillExtractionActions } from './action_creators';
import { useDebounced, useOnOff, useShallowEquals } from "utils/react_utils";
import { MultiSelectTags, TagsLoading, useMultiSelectTags, TAG_UIS } from "controls/tags";
import { makeClassName } from 'utils/index';

import "./user_skills.sass";

const MAX_SKILLS = 30;
const MAX_SKILLS_BEFORE_SHOWING_COUNT = 27;

export const UserSkills = (props) => {
  const [showSkillExtractionDialog, openDialog, closeDialog] = useOnOff();
  const [resume, setResume] = useState();
  const [getExtractedSkillsState, getExtractedSkillsPerform] =
    userSkillExtractionActions.useAction('fetch', 'self');
  const [postResumeForExtractionState, postResumeForExtraction] =
    userSkillExtractionActions.useAction('update', 'self');
  const debouncedExtractedSkillsPerform = useDebounced(getExtractedSkillsPerform, 200);

  const typeaheadMultiSelectProps = objectUtils.except(props,
    [
      'currentUserId',
      'showResumeSection'
    ]
  );

  // one of: ['none', 'pending', 'failed', 'succeeded']
  const resumeExtractionStatus = (() => {
    if (postResumeForExtractionState.status.pending) {
      return 'pending';
    }
    if (postResumeForExtractionState.status.failed) {
      return 'failed';
    }
    if (postResumeForExtractionState.status.succeeded) {
      return getExtractedSkillsState.resources?.state;
    }
    return 'none';
  })();

  // Poll every 200ms if we are waiting for a response
  useEffect(() => {
    if (getExtractedSkillsState.resources?.state === 'pending') {
      debouncedExtractedSkillsPerform();
    }
  });

  // Close the dialog if we have errored out OR if there are 0 suggestions
  useEffect(() => {
    if (showSkillExtractionDialog) {
      if (resumeExtractionStatus === 'failed') {
        closeDialog();
        setResume(null);
      }
      if ((resumeExtractionStatus === 'succeeded') &&
        (getExtractedSkillsState.resources?.results?.length === 0)) {
        closeDialog();
        setResume(null);
      }
    }
  });

  const dialogClassName = resumeExtractionStatus === 'pending'
    ? 'user-skills__dialog--loading' : 'user-skills__dialog--multi-select-skills';

  const resumeUploaded = (_name, file) => {
    setResume(file);
    openDialog();
  };

  const willCloseDialog = () => {
    setResume(null);
    closeDialog();
  };

  const saveSkills = (allSelectedTags) => {
    closeDialog();
    typeaheadMultiSelectProps.handleChange(typeaheadMultiSelectProps.name, allSelectedTags);
  };

  const numOfAddedSkills = typeaheadMultiSelectProps.value?.length || 0;
  const typeaheadSkillCountClassName = makeClassName([
    'user-skills__typeahead-counter',
    (numOfAddedSkills < MAX_SKILLS_BEFORE_SHOWING_COUNT) && 'user-skills__typeahead-counter--hidden',
    (numOfAddedSkills > MAX_SKILLS) && 'user-skills__typeahead-counter--invalid'
  ]);

  useEffect(() => {
    if (resume) {
      postResumeForExtraction({ file: resume });
    }
  }, [resume]);

  const renderHeading = (heading) => {
    if (heading) {
      return heading;
    } else {
      return (
        <Heading appearance="settings-head" className="user-skills__heading-text" >
          Skills
        </Heading>
      );
    };
  };

  const renderSubheading = (subheading) => {
    if (subheading) {
      return subheading;
    } else {
      return (
        <span>
          Add skills to help us match you with professional growth and
          career opportunities.
        </span>
      );
    };
  };

  return (
    <>
      <SkillsSelect
        trackData={{
          type: 'user.user_skills',
          object_id: props.currentUserId,
          object_type: 'User'
        }}
        tagUi="skill"
        heading={renderHeading(props.heading)}
        subheading={renderSubheading(props.subheading)}
        {...typeaheadMultiSelectProps}
      />
      {props.showResumeSection && <div className="user-skills__suggested-skills">
        <Heading appearance="settings-head" className="user-skills__heading-text">
          Suggested skills
        </Heading>

        <p>
          If you need some help thinking up skills we can analyze your resume or CV and suggest
          skills to get you started. File Types: .doc, .docx, or .pdf
        </p>

        <FileUpload
          name="resume_upload"
          handleChange={resumeUploaded}
          ui="custom"
          accept=".pdf,.doc,.docx"
          className="user-skills__file-upload"
          value={resume}
        >
          <FontAwesomeIcon icon={faUpload} className="user-skills__file-upload-icon"/>
          Upload Resume
        </FileUpload>
        {resumeExtractionStatus === 'failed' &&
          <p className="user-skills__file-upload-errors">
            Unable to extract skills. Please try again with a different file or try
            again later.
          </p>
        }
        <Dialog
          show={showSkillExtractionDialog}
          shouldClose={willCloseDialog}
          title={resumeExtractionStatus === 'pending' ? 'Processing' : 'Skills'}
          size="custom"
          className={dialogClassName}
        >
          {resumeExtractionStatus === 'pending' &&
            <Dialog.Content>
              <TagsLoading/>
            </Dialog.Content>
          }
          {resumeExtractionStatus === 'succeeded' &&
            <UserSkillsDialogContent
              initialSelected={typeaheadMultiSelectProps.value}
              extractedTags={getExtractedSkillsState.resources?.results}
              save={saveSkills}
            />
          }
        </Dialog>
      </div>}
    </>
  );
};

UserSkills.propTypes = {
  heading: PropTypes.node,
  subheading: PropTypes.node,
  currentUserId: PropTypes.string,
  showResumeSection: PropTypes.bool,
  required: PropTypes.bool,
  tagUi: PropTypes.oneOf(TAG_UIS)
};

export const UserSkillsDialogContent = (props) => {
  const initialTags = useRef(props.initialSelected || []);
  const initialTagIds = useRef();
  if (!initialTagIds.current) {
    initialTagIds.current = initialTags.current.map((t) => t.id);
  }

  const allTags = useUnionTags(initialTags.current, props.extractedTags);
  const mstOpts = { maxSelections: MAX_SKILLS };
  const multiSelectTagProps = useMultiSelectTags(allTags, initialTagIds.current, mstOpts);

  const handleSave = useCallback(() => {
    const selectedTags = allTags.filter((t) => {
      return multiSelectTagProps.selectedTagIds.find((selectedId) => selectedId === t.id);
    });
    props.save(selectedTags);
  }, [multiSelectTagProps.selectedTagIds, allTags, props.save]);

  return (
    <>
      <Dialog.Content>
        <MultiSelectTags
          ui="skill"
          aria-label="Skills"
          {...multiSelectTagProps}
          showSelectedCount={allTags.length > mstOpts.maxSelections}
          maxSelections={mstOpts.maxSelections}
        />
      </Dialog.Content>
      <Dialog.Footer>
        <Dialog.Action action={handleSave}>Save Skills</Dialog.Action>
      </Dialog.Footer>
    </>
  );
};

UserSkillsDialogContent.propTypes = {
  save: PropTypes.func,
  extractedTags: PropTypes.arrayOf(PropTypes.object),
  initialSelected: PropTypes.arrayOf(PropTypes.string)
};

const useUnionTags = (tags1, tags2) => {
  const tags1Memoized = useShallowEquals(tags1);
  const tags2Memoized = useShallowEquals(tags2);

  return useMemo(() => {
    const result = tags1Memoized.slice();
    for (const tag of tags2Memoized) {
      if (!result.find((existingTag) => existingTag.id === tag.id)) {
        result.push(tag);
      }
    }
    return result;
  }, [tags1Memoized, tags2Memoized]);
};
