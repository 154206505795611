import { normalizeCaseInsensitive } from 'utils/string';

/**
 * Used to check that all fields in a form are present. This currently will not work with objects.
 *
 * const allFieldsPresent = useCheckAllFieldsPresent('field1', 'field2')(propsForFieldGroup);
 *
 * @param {Array} fields - A list of field names to validate.
 * @returns {Boolean} Whether all fields are present.
 */
export const useCheckAllFieldsPresent = (...fields) => (propsForFieldGroup) => {
  let result = true;
  for (const fieldName of fields) {
    const props = propsForFieldGroup(fieldName);
    if (typeof props.value === 'string') {
      result = result && !!normalizeCaseInsensitive(props.value);
    } else if (Array.isArray(props.value)) {
      result = result && props.value.length > 0;
    } else if (typeof props.value !== 'boolean' && typeof props.value !== 'number') {
      // numbers and booleans have falsey values that (at least in the default implementation), I'm
      // going to consider to be present
      result = result && !!props.value;
    }
  }
  return result;
};
