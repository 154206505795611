import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SiteFooter } from './site_footer';

export const PortaledFooter = () => {
  useEffect(() => {
    const hamlFooter = document.querySelector('.non-react-footer');
    hamlFooter && (hamlFooter.hidden = true);

    return () => {
      const hamlFooter = document.querySelector('.non-react-footer');
      hamlFooter && (hamlFooter.hidden = false);
    };
  }, []);

  /**
   * Renders the component
   * @returns {React.element}
   */

  const portalRoot = document.querySelector('#footer');

  if (portalRoot) {
    return ReactDOM.createPortal(<SiteFooter/>, portalRoot);
  } else {
    return null;
  }
};
