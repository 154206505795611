import { queryClient } from "../../queries/client";
import { useCredlyBadges } from "./use_credly_badges";
import { useCredlyPublicBadges } from "./use_credly_public_badges";
import { useOtherBadges } from "./use_other_badges";
import { useOtherPublicBadges } from "./use_other_public_badges";

/**
 * Invalidate the query cache for the badges.
 */
export const invalidateAllBadges = () => {
  queryClient.invalidateQueries(['credlyBadges', 'list']);
  queryClient.invalidateQueries(['credlyPublicBadges', 'list']);
  queryClient.invalidateQueries(['otherBadges', 'list']);
};


export {
  useCredlyBadges,
  useCredlyPublicBadges,
  useOtherBadges,
  useOtherPublicBadges
};
