import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { fetchEarnedBadges, resetEarnedBadges } from './action_creators';
import { EarnedBadgeGridItem } from './earned_badge_grid_item';
import SortSelector from './sort_selector';
import { makeEarnedBadgesSelector, makeEarnedBadgesSortBySelector } from './selectors';
import { DataGrid, MAX_GRID_ROW_LENGTH } from 'controls/data_table';
import { toggleGlobalSearchUI } from 'global_search';
import { FormattedMessage } from "react-intl";

import './badges.sass';

const DISCOVER_BADGES_ITEM = { id: 'discover' };

export class Badges extends Component {
  abortCurrentListRequest() {
    if (this.listRequest) {
      this.listRequest.abort();
    }
  }

  componentDidMount() {
    this.props.reset();
    this.listRequest = this.props.list({ sort: this.props.earnedBadgesSortBy });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.earnedBadgesSortBy !== this.props.earnedBadgesSortBy) {
      this.props.reset();
      this.listRequest = this.props.list({ sort: this.props.earnedBadgesSortBy });
    }
  }

  componentWillUnmount() {
    this.abortCurrentListRequest();
  }

  dataSelector = createSelector(
    props => props.earnedBadges,
    props => props.loader.pending,
    (badges, loading) => {
      if (!loading && badges.length < MAX_GRID_ROW_LENGTH) {
        return [...badges, DISCOVER_BADGES_ITEM];
      } else {
        return badges;
      }
    }
  );

  data() {
    return this.dataSelector(this.props);
  }

  loadMore = () => {
    this.abortCurrentListRequest();
    this.listRequest = this.props.list({
      page: this.props.earnedBadgesMetadata.current_page + 1, sort: this.props.earnedBadgesSortBy
    });
  };

  onSort = (sortBy) => {
    const search = new URLSearchParams({ sortBy: sortBy });
    this.props.history.replace({ ...this.props.location, search: search.toString() });
  };

  shouldShowGrid() {
    return !!this.data()?.filter(d => d?.id !== "discover")?.length;
  }

  render() {
    const meta = this.props.earnedBadgesMetadata;

    return (
      this.shouldShowGrid()
        ? (
          <div>
            <div className="row justify-content-between c-earner-earned-badges-badges__count-row">
              <div className="col-auto">
                {meta.total_count && meta.total_count > 0
                  ? (
                    <FormattedMessage
                      id="dashboard.view.items"
                      defaultMessage="{itemCount, plural, =0 {<num>0</num> badges} one {<num>1</num> badge} other {<num>{itemCount,number}</num> badges}}"
                      values={{
                        itemCount: meta.total_count,
                        num: (msg) => <span className="c-earner-earned-badges-badges__count">{msg}</span>
                      }}
                    />
                  )
                  : null}
              </div>
              <SortSelector
                className="col-auto"
                onSort={this.onSort}
                selectedSortValue={this.props.earnedBadgesSortBy}
              />
            </div>
            <DataGrid
              data={this.data()}
              header={{}}
              loader={{
                loading: this.props.loader.pending,
                moreAvailable: meta.current_page < meta.total_pages,
                loadMore: this.loadMore
              }}
              renderer={data => {
                if (data === DISCOVER_BADGES_ITEM) {
                  return null;
                }
                return <EarnedBadgeGridItem {...data} />;
              }}
            />
          </div >
        ) : null);
  }
}

export const makeMapStateToProps = () => {
  const sortBySelector = makeEarnedBadgesSortBySelector();
  const transformBadges = makeEarnedBadgesSelector();
  return (state, props) => {
    return {
      earnedBadges: transformBadges(fetchEarnedBadges.getResources(state), props),
      earnedBadgesMetadata: fetchEarnedBadges.getMetadata(state, 'general'),
      loader: fetchEarnedBadges.getStatus(state),
      earnedBadgesSortBy: sortBySelector(props)
    };
  };
};

const mapDispatchToProps = {
  list: fetchEarnedBadges.action,
  reset: resetEarnedBadges,
  toggleGlobalSearchUI
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(Badges));

export const testing = { DISCOVER_BADGES_ITEM, Badges };

Badges.propTypes = {
  reset: PropTypes.func,
  earnedBadgesMetadata: PropTypes.object,
  list: PropTypes.func,
  earnedBadgesSortBy: PropTypes.string,
  loader: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  toggleGlobalSearchUI: PropTypes.func,
  intl: PropTypes.object
};
