import React, { createContext, lazy, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import { sleep } from 'utils';

/**
 * React Context to hold override for lazy-load fallback content.
 *
 * @type {React.Context<null>}
 */
const LazyLoadWithSuspenseFallbackContext = createContext(null);

/**
 * Wrapper to use to display custom fallback content when a descendant is lazy-loaded.
 *
 * @param {object} props
 *   @param {JSX.Element} props.children - child content that may lazy load and cause Suspense to be
 *     invoked
 *   @param {JSX.Element} props.fallbackContent - content to display instead of loading spinner when
 *     suspense is invoked
 * @returns {JSX.Element}
 * @constructor
 */
export const WithLazyLoadFallbackContent = (props) => {
  return (
    <LazyLoadWithSuspenseFallbackContext.Provider value={props.fallbackContent}>
      {props.children}
    </LazyLoadWithSuspenseFallbackContext.Provider>
  );
};
WithLazyLoadFallbackContent.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackContent: PropTypes.node.isRequired
};

/**
 * Lazy-load a component, and immediately wrap it in Suspense. Usage:
 *
 * export const ComponentName = lazyWithSuspense('ComponentName', () => import('./path'));
 *
 * == When should you use this? ==
 * Use it with lay-loaded components that don't take up the whole page. lazy() triggers the closest
 * Suspense in the ReactDOM tree. If there are multiple lazy-loaded components on the page, this
 * can cause the page to flicker while loading, resulting in bad UX and poor performance due to
 * multiple page reflowes.
 *
 * == When should you not use this? ==
 * Don't use it with full-page components or routes. They will trigger a single Suspense fallback,
 * and won't benefit much from immediate loading states.
 *
 * == Testing notes ==
 * Using this creates two versions of the component -- one with the HOC, usually loaded from the
 * component's index.js file, and one without the HOC, loaded from the component's actual path.
 * Because of this, tests must import components the same way the tested component does. For
 * example:
 * - Comp1 loads LazyComp from './index', where it's created using the HOC. The test must also load
 *   it from './index'.
 * - Comp3 loads a non-lazy version of LazyComp from './lazy_comp'. The test must also load it from
 *   './lazy_comp'.
 * - If this is too confusing, you can usually get around it by putting the component name in
 *   quotes. For example: wrapper.find('LazyComp');
 * - This can also be avoided by always loading components from their index files.
 *
 * @param {String} name - The name of the component. Important for tests.
 * @param {Function} componentImport - An import callback.
 * @param {function: React.element|function: String} fallback - A fallback element while the
 *   component is loading. Defaults to a LoadingSpinner.
 * @param {boolean} debug - If true, log errors to the console.
 * @returns {React.Component}
 */
export const lazyLoadWithSuspense = (name, componentImport, fallback = null, debug = false) => {
  const LazyLoaded = lazyWithRetry(componentImport, debug);
  LazyLoaded.displayName = name;

  const WithSuspense = (props) => {
    let placeholderContentFromContext = useContext(LazyLoadWithSuspenseFallbackContext);
    const placeholderContent = fallback ? fallback() : placeholderContentFromContext;
    return (
      <Suspense fallback={placeholderContent}>
        {/* reset placeholder back to default for descendants */}
        <LazyLoadWithSuspenseFallbackContext.Provider value={null}>
          <LazyLoaded {...props}/>
        </LazyLoadWithSuspenseFallbackContext.Provider>
      </Suspense>
    );
  };

  WithSuspense.displayName = `WithSuspense(${name})`;
  return WithSuspense;
};

/**
 * Lazy-load a component, retrying for up to 10 seconds before showing an error page. This handles
 * several types of network issues, such as:
 * - Navigating between pages that load different chunks, on a flaky network connection.
 * - Loading a chunk while connecting to the network, then connecting in time to track the error.
 *
 * This function should always be used instead of React.lazy(), or we'll get additional live site
 * errors, and some unit tests may fail.
 *
 * @param {function(): React.Component} fn - The code to lazy load. This function should return
 *   a call to import() or require().
 * @param {boolean} debug - If true, log errors to the console.
 * @returns {Promise<Component>} The not-yet-loaded React component.
 */
export const lazyWithRetry = (fn, debug = false) => lazy(async () => {
  for (let x = LAZY_RETRIES; x > 0; x--) {
    try {
      return await fn();
    } catch (e) {
      if (debug) {
        console.warn('Error lazy loading chunk', e);
      }
      if (x === 1) {
        // Clean up the error message for TrackJS.
        const chunk = e.message.replace(/Loading chunk.*\(.*\/packs\/js\/([^)]+).+?/, '$1');
        throw new Error(`Too many retries loading JS chunk (${chunk})`);
      }
      await sleep(LAZY_RETRY_INTERVAL);
    }
  }
});
const LAZY_RETRIES = 10;
const LAZY_RETRY_INTERVAL = 1000;

export const testing = {
  lazyLoadWithSuspense: (name, componentImport, fallback) => lazyLoadWithSuspense(
    name, componentImport, fallback = null, true
  ),
  lazyWithRetry: (fn) => lazyWithRetry(fn, true)
};
