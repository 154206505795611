import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Capture onBlur events from clicks and focus outside a container. This should be declared
 * inside the DOM node specified by props.container.
 *
 * @property {Object} container - A React ref of the container to watch.
 * @property {Function} onBlur - Call when an event causes blur from outside the container.
 */
export class HandleBlurFromExternalEvent extends Component {
  constructor(props) {
    super(props);

    this.externalMousedown = false;
    this.attachedEvents = [];
  }

  /**
   * The component has been mounted to the React virtual DOM.
   */
  componentDidMount() {
    // Add handlers to detect when user interacts with UI outside of the component.
    this.attachedEvents = [
      { name: 'mousedown', handler: this.onDocumentMousedown },
      { name: 'mouseup', handler: this.onDocumentMouseup },
      { name: 'focusin', handler: this.onDocumentFocus },
      { name: 'keydown', handler: this.onDocumentKeydown }
    ];

    this.attachedEvents.forEach(data => document.addEventListener(data.name, data.handler));
  }

  /**
   * The component is about to be unmounted.
   */
  componentWillUnmount() {
    this.attachedEvents.forEach(data => document.removeEventListener(data.name, data.handler));
    this.attachedEvents = [];
  }

  /**
   * Record that a mousedown event has occurred, to prevent focusout events related to mousedown on
   * links, etc. from triggering blur prematurely.
   */
  onDocumentMousedown = () => {
    this.externalMousedown = true;
  };

  /**
   * Fire blur if the container loses focus due to an external click.
   *
   * @param {Event} evt - React Event representing the interaction
   */
  onDocumentMouseup = evt => {
    if (this.elementOutsideContainer(evt.target)) {
      this.props.onBlur(evt);
    }
    this.externalMousedown = false;
  };

  /**
   * Fire blur if the container loses focus due to an external key press.
   *
   * @param {Event} evt - React Event representing the interaction
   */
  onDocumentKeydown = evt => {
    if (this.elementOutsideContainer(evt.target)) {
      this.props.onBlur(evt);
    }
  };

  /**
   * Fire blur if the container loses focus due to an external element receiving focus.
   *
   * @param {Event} evt - React Event representing the interaction
   */
  onDocumentFocus = evt => {
    // If this focus event was triggered by mousedown, wait until mouseup to blur.
    if (!this.externalMousedown) {
      const container = this.props.container.current;
      if (this.elementOutsideContainer(evt.target) && container.contains(evt.relatedTarget)) {
        this.props.onBlur(evt);
      }
    }
  };

  /**
   * Is the referenced element outside props.container?
   *
   * @param {Element|EventTarget} elt
   * @returns {Boolean}
   */
  elementOutsideContainer = elt => {
    const container = this.props.container.current;
    return container && !container.contains(elt);
  };

  /**
   * Render nothing at all.
   *
   * @returns {React.element}
   */
  render() {
    return null;
  }
}

HandleBlurFromExternalEvent.propTypes = {
  container: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired
};
