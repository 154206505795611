import React from 'react';
import PropTypes from 'prop-types';

const SIZE_WHITELIST = {
  // eslint-disable-next-line max-len
  24: true, 30: true, 44: true, 48: true, 64: true, 80: true, 88: true, 100: true, 110: true, 120: true, 140: true, 200: true, 300: true, 340: true, 400: true
};

/**
 * A sized image, produced by the acclaim-resizer-lambda function.
 * See the acclaim-image-resizer github project for more information.
 *
 * If a new image size will be requested frequently, make sure to add it to the Lambda's size
 * whitelist.
 *
 * Locally, this is a no-op due to a redirect route in routes.rb. To test it locally, enable
 * SIZED_IMAGES in .env, and see README.md in the acclaim-image-resizer project.
 */
export const SizedImage = props => {
  if (props.width && !SIZE_WHITELIST[props.width]) {
    console.warn(`Non-whitelisted image size ${props.width} used. Update the list on CloudFront.`);
  }

  return <img
    {...props}
    src={props.width ? sizedImageUrl(props.src, props.width, props.height) : props.src}
    width={props.width}
    height={props.height || props.width}
  />;
};

SizedImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  maxwidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  height: PropTypes.number
};


/**
 * Create a sized image URL.
 *
 * @param {String} url
 * @param {int} width
 * @param {int=} height. Defaults to width (for square images).
 * @returns {String} the sized URL
 */
export const sizedImageUrl = (url, width, height) => {
  if (url && initialAppValues.config.sizedImages) {
    // Use 2x images when supported.
    if ((window.devicePixelRatio || 1) > 1) {
      width = width * 2;
      height = height * 2;
    }

    return url.replace(/^(https?:\/\/[^/]+)/, `$1/size/${width}x${height || width}`);
  }

  return url;
};
