import React, { useCallback, useRef } from 'react';

/**
 * A hidden footer.
 */
export const HiddenFooter = () => {
  const ref = useRef(null);
  const originalDisplayStyle = useRef(null);

  // We're creating a callback function that is being used on the ref property of a node.
  // That means that the callback (setRef) is called when that node is rendered/updated
  // passing in the element that the ref is on/for.  This is called when committing the
  // node, not when virtually rendering it so IF there is a node, it is passed in.
  // The ref will be called when unmounting the component as well with null so we can
  // remove our changes.
  //
  // We use a `useCallback` function for the ref because the callback function is not
  // updated on subsequent renders and therefore won't affect the rendering of the reffed node
  // if we didn't memoize the function with useCallback, it would affect the rendering
  // and create a real mess.
  //
  // source: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  // see: https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node for the
  // closest I could find to official documentation on using a callback for refs.
  const setRef = useCallback(node => {
    if (ref.current?.parentElement) {
      ref.current.parentElement.style.display = originalDisplayStyle.current || '';
    }

    if (node?.parentElement) {
      originalDisplayStyle.current = node.parentElement.style.display;
      node.parentElement.style.display = 'none';
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return (<div ref={setRef}/>);
};
