import { ActionManager } from 'utils/action_manager';

export const userSkillExtractionActions = new ActionManager('userSkillExtractions')
  .add({
    requestKey: 'fetch',
    effect: 'update',
    method: 'GET',
    url: '/api/v1/users/self/user_skill_extractions/self',
    alwaysRefresh: true
  }, true)
  .add({
    requestKey: 'update',
    effect: 'read',
    method: 'PUT',
    url: '/api/v1/users/self/user_skill_extractions/self',
    alwaysRefresh: true
  }, true);

export const userExperienceSocialProfileActions = new ActionManager('userExperienceSocialProfiles')
  .add({
    requestKey: 'fetch',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/users/self/user_experiences/social_profiles',
    alwaysRefresh: true
  }, false);

export const userExperienceActions = new ActionManager('userExperiences')
  .add({
    requestKey: 'fetch',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/users/self/user_experiences/[id]',
    alwaysRefresh: true
  }, false)
  .add({
    requestKey: 'list',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/users/self/user_experiences',
    list: 'list',
    alwaysRefresh: true
  }, false)
  .add({
    requestKey: 'update',
    method: 'PUT',
    effect: 'update',
    url: '/api/v1/users/self/user_experiences/[id]'
  }, true)
  .add({
    requestKey: 'delete',
    method: 'DELETE',
    effect: 'delete',
    url: '/api/v1/users/self/user_experiences/[id]'
  }, true)
  .add({
    requestKey: 'create',
    effect: 'create',
    method: 'POST',
    url: '/api/v1/users/self/user_experiences'
  }, false);
