import { apiReducer } from 'utils/reduxUtils';

export const rulesIntegrationReducers = {
  // Rules Integration UI: Badge Template List
  rulesIntegrationBadgeTemplates: apiReducer('rulesIntegrationBadgeTemplates'),
  // Rules Integration UI: Badge Template Details
  rulesIntegrationBadgeTemplate: apiReducer('rulesIntegrationBadgeTemplate'),
  // Rules Integration UI: Rules List
  rulesIntegrationRules: apiReducer('rulesIntegrationRules'),
  // Rules Integration UI: Rule Creation
  rulesIntegrationRule: apiReducer('rulesIntegrationRule'),
  // Rules Integration UI: Allowed options for rules
  rulesIntegrationRuleOptions: apiReducer('rulesIntegrationRuleOptions'),
  // Rules Integration UI: Allowed conditions for rules
  rulesIntegrationRuleConditions: apiReducer('rulesIntegrationRuleConditions'),
  // Rules Integration UI: Issue Badge
  rulesIntegrationStudents: apiReducer('rulesIntegrationStudents'),
  rulesIntegrationIssueBadge: apiReducer('rulesIntegrationIssueBadge'),
  // Rules Integration UI: Connect Account
  rulesIntegrationConnect: apiReducer('rulesIntegrationConnect')
};
