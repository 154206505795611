import xhr from 'xhr';
import * as Routes from './routes';
import { array } from './array';

export { Routes };

/**
 * Resolve the value of val by either returning a plain value or by calling it as a function and
 * returning the result.
 *
 * @param {*|function:*} val - either a plain value or a function which will be called to produce
 *   the value
 * @param {...*} [args] - optional arguments to pass as arguments to val, if that parameter is a
 *   function
 * @returns {*}
 */
export const callbackOrValue = (val, ...args) => typeof val === 'function' ? val(...args) : val;

const collator = (() => {
  // In theory, this fallback shouldn't be necessary, since Intl is implemented pretty broadly, but
  // it doesn't work in PhantomJS, and this preserves test compatibility
  if (typeof Intl !== 'undefined' && typeof Intl.Collator !== 'undefined') {
    return new Intl.Collator('en-US');
  } else {
    return {
      compare: (a, b) => a.localeCompare(b)
    };
  }
})();

/**
 * Generic compare predicate for functions like Array.sort().
 *
 * @param {*} left
 * @param {*} right
 * @returns {number}
 */
export const stdCompare = (left, right) => {
  if (typeof left === 'string') {
    return collator.compare(left, right);
  }
  if (left < right) {
    return -1;
  } else if (left === right) {
    return 0;
  } else {
    return 1;
  }
};

/**
 * Escape a string for use in a dynamically-constructed regular expression.
 *
 * @param {String} s - The string to escape.
 * @returns {String}
 */
export const escapeForRegex = s => s.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');

// Create a pair of functions to generate and reset a sequence of unique integer IDs.
// Exported and documented below as uniqueId and testResetUnitId.
const uniqueIdOperators = (() => {
  let nextId = 0;
  return [() => nextId++, (nextVal = 0) => nextId = nextVal];
})();

/**
 * Generate a numeric id which is unique to the page load.
 * @returns {int}
 */
export const uniqueId = uniqueIdOperators[0];

/**
 * Reset the uniqueId function to return the specified value.
 *
 * NOTE: this is currently ONLY intended for use in tests, to ensure stable identifiers for snapshot
 * tests, etc.  In future, it may be also useful for ensuring that client-rendered React IDs match
 * server-rendered content, but even then it should be used with caution, since employing it
 * incorrectly could result in accessibility problems due to non-unique IDs, broken React
 * optimizations due to non-unique keys, etc.
 *
 * @function testResetUniqueId
 * @param {number=0} nextVal - value to return the next time uniqueId is called (defaults to 0)
 */
export const testResetUniqueId = uniqueIdOperators[1];

/**
 * Get the current CSRF token used for requests.
 *
 * @returns {String}
 */
export const getCsrfToken = () => document.querySelector('meta[name="csrf-token"]').content;


/**
 * Update the CSRF token stored in the page's HTML.
 *
 * @param {String} newValue - The new token
 * @private
 */
export const updateCsrfToken = newValue => {
  document.querySelector('meta[name="csrf-token"]').content = newValue;
};


/**
 * Turn an array of strings into a class name, omitting empty values.
 *
 * @param {string|array...} a - An array of class names. Multidimensional arrays will be flattened,
 *   so both of these syntaxes are acceptable:
 *   makeClassName(['a', 'b'])
 *   makeClassName('a', 'b')
 *
 * @return {string}
 */
export const makeClassName = (...a) => array.joinCompact(array.flatten(a), ' ');


/**
 * Add a data attribute to facilitate WebDriver testing. Use this sparingly, since it increases
 * page size without any benefit to the React client.
 *
 * @param {String} name - A name to use. This does not have to be globally unique, but it should
 *   be possible to use this as part of a more complex selector to uniquely select the element.
 * @param {String=} val - Optional additional data.
 * @returns {{}}
 */
export const makeWebdriverId = (name, val) => ({ ['data-webdriver-' + name]: val || '' });


/**
 * Memoize the return value of a function. The function will only be called once, and not until
 * the value is needed.
 *
 * @param {function(void):*} fn
 */
export const memo = fn => {
  let val;
  let called = false;

  return () => {
    if (!called || memo.testing_unoptimize) {
      val = fn();
      called = true;
    }
    return val;
  };
};
// Tests can remove memoization.
memo.testing_unoptimize = false;

/**
 * Make an AJAX request.
 *
 * @param {object} options
 *   @param {string=} [options.method='GET'] - The method
 *   @param {string} options.uri - The URI
 *   @param {string|FormData|object=} options.body - A POST string, a FormData object (which results
 *     in a multipart POST) or set of key-value pairs.
 *   @param {object=} options.headers - Custom headers
 *   @param {number=} options.timeout - Timeout, in miliseconds
 *   @param {string=} options.responseType - text, json etc.
 * @returns {Promise.<{success: boolean, statusCode: int, body: string|object}>}
 * @see https://github.com/naugtur/xhr
 */
export const ajax = options => {
  if (!options.uri) {
    throw new Error('utils:ajax(): Missing option: uri');
  }

  return new Promise((resolve, reject) => {
    // Add the token for local URLs.
    options = {
      ...options,
      headers: { ...options.headers }
    };

    if (options.uri.indexOf('/') === 0) {
      if (!options.headers['X-CSRF-Token']) {
        options.headers['X-CSRF-Token'] = getCsrfToken();
      }
    }

    const bodyIsObject = options.body && typeof options.body === 'object';
    const jsonRequest =
      (options.headers || {})['Content-Type'] === 'application/json' && bodyIsObject;

    // FormData
    if (options.body && options.body.append && !options.body.hasOwnProperty('append')) {
      if (options.headers['Content-Type'] === 'multipart/form-data') {
        console.warn('Warning: Sending POST with an explicit multipart/form-data header ' +
          'may result in a failed request, due to mismatched content boundaries.');
      }
    } else if (bodyIsObject && !jsonRequest) {
      const a = [];
      for (const k of Object.keys(options.body)) {
        a.push(k + '=' + encodeURIComponent(options.body[k]));
      }
      options.body = a.join('&');

      if (!options.headers['Content-Type']) {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      }
    } else if (jsonRequest) {
      options.body = JSON.stringify(options.body);
    }

    try {
      xhr(options, (err, response, body) => {
        if (err) {
          reject(err);
        } else {
          if (typeof body === 'string' && options.headers.Accept === 'application/json') {
            try {
              body = JSON.parse(body);
            } catch (e) {
              console.error('Failed to parse JSON response for ' + options.uri);
            }
          }

          resolve({
            success: Math.floor(response.statusCode / 100) === 2,
            statusCode: response.statusCode,
            body: body
          });
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};


/**
 * Generate a somewhat uniqueish UUID.
 *
 * @returns {String}
 * @see https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-js
 */
export const generateUID = () => {
  const part = () => ("000" + ((Math.random() * 46656) | 0).toString(36)).slice(-3);
  // Two parts to guarantee a minimum number of bits.
  return part() + part();
};


/**
 * Sleep for the given duration.
 *
 * @param {int} ms - The time to sleep, in miliseconds.
 * @returns {Promise}
 */
export const sleep = async ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * Wait for a condition to be true. Return false on timeout.
 *
 * @param {function:*|function:Promise<*>} condition - Wait for this function to return truthy.
 * @param {int=100} interval - Delay between tests, in miliseconds.
 * @param {int=1000} maxTries - If more than this many attempts are made, return false.
 * @returns {Promise.<*>} the truthy value returned by the callback
 */
export const waitFor = async (condition, interval, maxTries) => {
  maxTries = maxTries || 1000;
  do {
    const result = await condition();
    if (result) {
      return result;
    }

    await sleep(interval || 100);

    maxTries--;
  } while (maxTries > 0);

  return false;
};


/**
 * Copy text to the clipboard. This will only work inside a user-initiated event, like onClick.
 *
 * @param {String} text - text to copy to the clipboard
 * @param {HTMLElement} [container=document.body] - optional container for the hidden element used
 *   to facilitate the copy, defaults to the body element, but can be overridden in cases where that
 *   won't work, such as within a modal
 * @returns {Boolean} success
 */
export const copyToClipboard = (text, container = document.body) => {
  try {
    let selected;
    const elt = document.createElement('textarea');
    elt.value = text;
    elt.readonly = true;
    elt.style.position = 'fixed';
    elt.style.opacity = '0';

    // On iOS, it's nearly impossible to prevent the window from scrolling to this invisible element
    // on copy. Center the element as best as possible to reduce the amount of scrolling.
    elt.style.top = window.scrollY + (window.innerHeight / 2) - 10 + 'px';
    elt.style.left = window.scrollX + 'px';
    elt.style.height = '1px';
    elt.style.overflow = 'hidden';

    container.appendChild(elt);

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      const range = document.createRange();
      range.selectNodeContents(elt);

      // Select the content
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      elt.setSelectionRange(0, elt.value.length);
    } else {
      // Get the current selection state.
      selected = document.getSelection().rangeCount > 0 && document.getSelection().getRangeAt(0);
      // Select the content
      elt.select();
    }

    document.execCommand('copy');
    container.removeChild(elt);

    // Restore selection
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  } catch (e) {
    return false;
  }
  return true;
};


/**
 * Load the facebook JS.
 */
export const loadFacebook = (() => {
  let loaded = false;

  return function loadFacebook(facebookClientId) {
    return new Promise((resolve, reject) => {
      // If it's already loaded, we're done.
      if (loaded) {
        resolve();
        return;
      }

      // This function is required by the Facebook API.
      window.fbAsyncInit = () => {
        /** @external FB */
        // eslint-disable-next-line no-undef
        FB.init({
          appId: facebookClientId, // eslint-disable-line no-undef
          autoLogAppEvents: false,
          xfbml: false,
          version: 'v8.0'
        });
        loaded = true;
        resolve();
      };


      const js = document.createElement('script');
      js.onerror = reject;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      const firstJS = document.getElementsByTagName('script')[0];
      firstJS.parentNode.insertBefore(js, firstJS);
    });
  };
})();

/**
 * Similar to Rails' `.empty?`, this checks for falsy values, empty arrays and empty objects.
 */
export const isEmpty = val => {
  if (!val) {
    return true;
  } else if (Array.isArray(val)) {
    return val.length === 0;
  } else if (typeof val === 'object') {
    return Object.keys(val).length === 0;
  }
  return false;
};

/**
 * Simple shortcut to cancel an event.
 *
 * @param {Event} e
 */
export const cancelEvent = e => e.preventDefault();
