import { ActionManager } from 'utils/action_manager';

/*
  This class handles fetching enabled features (for checking if a certain feature is enabled)
  as well as adding a user decision to opt in or opt out of a certain feature

  NOTE: There is a __mock__/enabled_features.jsx that will also need to be edited if we change the
  way this file works
 */
export const enabledFeaturesActions = new ActionManager('enabledFeatures')
  .add({
    url: '/api/v1/feature_controls',
    requestKey: 'all',
    method: 'GET',
    effect: 'read',
    list: 'all'
  }, false)
  .add({
    url: '/api/v1/feature_controls',
    requestKey: 'optOut',
    method: 'POST',
    effect: 'delete'
  }, true)
  .add({
    url: '/api/v1/feature_controls',
    requestKey: 'optIn',
    method: 'POST',
    effect: 'create'
  }, true);

/**
 * Custom hook to check whether a given feature is enabled or not, by pulling
 * the enabled feature list from the store
 *
 * Return value is a function that can take a string and will return true if the specified
 * feature is enabled.
 *
 * Usage of this is like:
 *
 * const isFeatureEnabled = useEnabledFeatures();
 * if (isFeatureEnabled('my-new-feature')) {
 *     // Conditionally render some content
 * }
 *
 * @returns {function(string): boolean}
 */
export const useEnabledFeatures = () => {
  const [featuresData] = enabledFeaturesActions.useOnMount("all");
  const features = featuresData.resources.map(feat => feat.id);

  return (featureName) => {
    return features.includes(featureName);
  };
};

/**
 * Custom hook to add a user decision (opt.in or opt.out) to an enabledFeature
 *
 * Return value is a function that will perform the post to
 * features_controls_controller#add_user_decision
 *
 * Usage of this is like:
 *
 * const dismissDashboardBanner = useAddUserFeatureDecisionOptOut('earner-dashboard-banner');
 * .
 * dismissDashboardBanner('close-button-pressed');
 *
 * @param {String} featureName - the name of the feature to add a user decision for
 *
 * @returns {function(string): void}
 */

export const useAddUserFeatureDecisionOptOut = (featureName) => {
  const [userFeatureDecision, performUserFeatureDecision] =
    enabledFeaturesActions.useAction("optOut", featureName);
  return makeUserFeatureDecision(
    featureName, 'opt.out', userFeatureDecision, performUserFeatureDecision
  );
};

export const useAddUserFeatureDecisionOptIn = (featureName) => {
  const [userFeatureDecision, performUserFeatureDecision] =
    enabledFeaturesActions.useAction("optIn", featureName);
  return makeUserFeatureDecision(
    featureName, 'opt.in', userFeatureDecision, performUserFeatureDecision
  );
};

const makeUserFeatureDecision = (featureName, decisionType, state, perform) => {
  return (decisionReason) => {
    if (!state.status.pending && !state.status.succeeded) {
      perform({
        entity_id: featureName,
        decision_type: decisionType,
        decision_reason: decisionReason
      });
    }
  };
};
