import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router';
import { useAlerts } from 'alert';
import { asRelativeUrl, DEFAULT_URL } from 'utils/routes';
import { downloadUrl } from 'utils/download';

export const DownloadRedirect = (props) => {
  const ref = useRef();
  const [downloadOpened, setDownloadOpened] = useState(false);

  const alerts = useAlerts();

  useEffect(() => {
    downloadUrl(asRelativeUrl(props.downloadUrl), true, ref.current);
    setDownloadOpened(true);
  }, []);

  useEffect(() => {
    if (downloadOpened) {
      alerts.add({
        type: 'info',
        text: "Redirected after attempting to download. Check your downloads to see if it was successful."
      });
    }
  }, [downloadOpened]);

  if (!downloadOpened) {
    return <div ref={ref}/>;
  } else {
    return <Redirect to={props.redirectUrl || DEFAULT_URL}/>;
  }
};
