import { Action } from './action';
import { ActionManager } from './action_manager';
import { actionTypes } from 'redux-resource';

/**
 * Extension of the Action class to deal with data that's stored locally (no AJAX requests).
 */
export class LocalAction extends Action {
  constructor(options) {
    super({...options, url: '/dummy'});
  }

  /**
   * Dispatch locally, ignoring the URL.
   *
   * @param {Object} values - URL parameters
   *   @param {String} values.id - id is required for delete and update actions.
   * @returns {function:ActionResponse}
   * @override
   */
  action = (values = {}) => {
    return (dispatch, getState) => {
      const options = this.getOptions();

      if (options.effect === 'delete') {
        dispatch({
          type: actionTypes.DELETE_RESOURCES_PENDING,
          requestKey: options.requestKey,
          resourceType: options.resourceType
        });

        // Flush the event pipe with a setTimeout, so that both states (PENDING and the following
        // state) can be dispatched and acted on.
        setTimeout(() => {
          const state = getState();
          const resources = this.getResources(state);
          const id = values.id || options.id;
          if (findById(resources, id)) {
            dispatch({
              type: actionTypes.DELETE_RESOURCES_SUCCEEDED,
              requestKey: options.requestKey,
              resourceType: options.resourceType,
              resources: [id]
            });
          } else {
            dispatch({
              type: actionTypes.DELETE_RESOURCES_FAILED,
              requestKey: options.requestKey,
              resourceType: options.resourceType,
              resources: [id]
            });
          }
        }, 0);
      } else if (options.effect === 'read') {
        const state = getState();
        dispatch({
          type: actionTypes.READ_RESOURCES_SUCCEEDED,
          requestKey: options.requestKey,
          resourceType: options.resourceType,
          resources: this.getResources(state)
        });
      } else if (options.effect === 'update') {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_PENDING,
          requestKey: options.requestKey,
          resourceType: options.resourceType
        });

        // Flush the event pipe with a setTimeout, so that both states (PENDING and the following
        // state) can be dispatched and acted on.
        setTimeout(() => {
          dispatch({
            type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
            requestKey: options.requestKey,
            resourceType: options.resourceType,
            resources: [values]
          });
        }, 0);
      } else {
        console.log(`LocalAction with effect=${options.effect} has not been implemented`);
      }

      return {abort: () => null};
    };
  };
}

/**
 * Extension of ActionManager for local actions.
 */
export class LocalActionManager extends ActionManager {
  constructor(resourceType, actionFactory = options => new LocalAction(options)) {
    super(resourceType, actionFactory);
  }
}

const findById = (resources, id) => {
  if (Array.isArray(resources)) {
    return resources.find(r => r.id === id);
  } else if (resources && resources.id === id) {
    return resources;
  }
  return null;
};
