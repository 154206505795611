/**
 * These functions should be considered private to the DataTable component. If they are needed somewhere else,
 * move them to helpers.js.
 */

import { makeClassName } from 'utils';

/**
 * Make a class name for the 'hideAt' property, using global classes defined in base.sass.
 *
 * @param {String|Array} hideAt - Hide the column in one or more views (mobile, tablet, desktop)
 * @returns {string}
 */
export const makeHideAtClassName = hideAt => {
  // Add .hide-mobile, .hide-tablet and/or .hide-desktop.
  return makeClassName(
    hideAt ? (Array.isArray(hideAt) ? hideAt : [hideAt]).map(s => 'hide-' + s) : []
  );
};

/*
*   NO_MARGIN: Remove left and right margins.
*   ROW_BORDERS: Separate rows with a horizontal line.
*   SIDE_BORDERS: Add borders to the left and right of the table.
*   ALTERNATE_ROW_COLORS: Alternate white and gray rows.
*   NO_ROW_HEIGHT: Don't give rows a height.
*   GROWING_ROW_HEIGHT: Expand row height to accommodate the content.
*   TIGHT_COLUMNS: Use less horizontal padding on columns.
*   UNSTYLED_HEADERS: See css definition for styles it removes
*   TALL_ROWS: Increase height from 70px to 84px.
 */
export const uiTweaks = {
  // UI customizations
  NO_MARGIN: 'no-margin',
  NO_BORDERS: 'no-borders',
  ROW_BORDERS: 'row-borders',
  SIDE_BORDERS: 'side-borders',
  ALTERNATE_ROW_COLORS: 'alternate-row-colors',
  GROWING_ROW_HEIGHT: 'growing-row-height',
  NO_ROW_HEIGHT: 'no-row-height',
  UNSTYLED_HEADERS: 'unstyled-headers',
  TIGHT_COLUMNS: 'tight-columns',
  TALL_ROWS: 'tall-rows'
};

/**
 * Construct class names based on UI tweaks. Constructing class names is generally a bad idea,
 * because it makes code harder to search. However, it would be difficult to maintain a set of
 * class names in multiple files, based on an expanding list.
 *
 * @param {array<string>} tweaks - A list of UI tweaks, from the uiTweaks list above.
 * @param {string} prefix - A class name prefix.
 */
export const makeUITweaksClassNames =
  (tweaks, prefix) => tweaks && tweaks.map(t => `${prefix}-${t}`).join(' ');

// This is the maximum number of grid items that can be displayed on a single row at the largest
// screen size. It matches the .data-table-row-grid class. If the component is changed to make the
// number of columns configurable, this should be replaced accordingly.
export const MAX_GRID_ROW_LENGTH = 3;
