import { useLocationData } from 'utils/location';
import { getProfileAction } from './action_creators';

/**
 * Hook to grab location from query params or fall back to user profile country if location query
 * params are not present.
 *
 * @returns {{country: String, state: String|null}}
 */
export const useUserLocationData = () => {
  const [user] = getProfileAction.useAction();

  return useLocationData(user?.resources?.['country_iso_alpha2'] || 'US');
};
