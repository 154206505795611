import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "controls/dialog";
import { Button } from "controls/button";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { jobPostApplicationsActions } from './action_creators';

import "./apply_to_job.sass";

export const ApplyToJob = ({ jobId, appliedToJob }) => {
  const [applicationModalState, setApplicationModalState] = useState(false);
  const [_jobApplicationState, jobApplyAction] = jobPostApplicationsActions.useAction('create');

  const submitApplicationAndOpenConfirmationModal = () => {
    jobApplyAction({ job_id: jobId });
    setApplicationModalState(true);
  };

  const closeModalAndUpdateList = () => {
    setApplicationModalState(false);
  };

  return (
    <>
      <Button
        className="jb-apply-now"
        onClick={() => submitApplicationAndOpenConfirmationModal()}
        disabled={appliedToJob}
      >
        {appliedToJob ? (
          <>
            <FormattedMessage
              id="talent_match.job_board.applied"
              defaultMessage="Applied"
            />
            <FontAwesomeIcon className="applied-job-check" icon={faCheck} />
          </>
        ) : (
          <FormattedMessage
            id="talent_match.job_board.apply_now"
            defaultMessage="Apply Now"
          />
        )}
      </Button>
      <Dialog
        className="jb-application-modal"
        size="half"
        show={applicationModalState}
        shouldClose={() => closeModalAndUpdateList()}
        title={
          <FormattedMessage
            id="talent_match.job_board.application_sent"
            defaultMessage="Application Sent!"
          />
        }
      >
        <div className="jb-application-copy-wrapper">
          <FormattedMessage
            id="talent_match.job_board.application_sent_subtext"
            defaultMessage="You’ve successfully shared your profile with
              the recruiter for this position. They will review skills
              and experience, and get in touch soon!"
          />
        </div>
        <div className="jb-application-actions-wrapper">
          <Button
            onClick={() => closeModalAndUpdateList()}
          >
            <FormattedMessage
              id="talent_match.job_board.done"
              defaultMessage="Done"
            />
          </Button>
        </div>
      </Dialog>
    </>
  );
};

ApplyToJob.propTypes = {
  jobId: PropTypes.string,
  appliedToJob: PropTypes.bool,
};
