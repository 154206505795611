import * as objUtils from 'utils/object';

/**
 * Custom error class for conveying resource not found (404) errors to ErrorBoundary.
 *
 * NOTE: the errorType and static errorIs methods are a workaround for a Babel issue with extending
 * builtin classes, such as Error.  https://github.com/babel/babel/issues/4480  Babel 7 supposedly
 * has a fix for this, so we should be able to remove some of this when we upgrade.
 */
export class ResourceNotFoundError extends Error {
  constructor(message, statusPath = null) {
    super(message);
    this.errorType = ResourceNotFoundError;
    this.statusPath = statusPath;
  }

  toString() {
    const superValue = super.toString();
    if (this.statusPath) {
      return `${superValue} [path=${this.statusPath.toString()}]`;
    } else {
      return superValue;
    }
  }
}

ResourceNotFoundError.errorIs = (error) => error && error.errorType === ResourceNotFoundError;

ResourceNotFoundError.raiseOnFailed = (prevProps, nextProps, resourceStatusPaths) => {
  const shouldRaise = resourceStatusPaths.find(path => (
    objUtils.propChangedTo(prevProps, nextProps, [ ...path, 'failed' ], true)
  ));
  if (shouldRaise) {
    throw new ResourceNotFoundError(`Resource could not be retrieved`, shouldRaise);
  }
};

ResourceNotFoundError.raise = () => {
  throw new ResourceNotFoundError('Resource could not be retrieved');
};
