import React from 'react';
import * as redux from 'react-redux';
import { FocusTrap } from 'controls/focus_trap';
import { Alert, useAlerts } from 'alert';

import './alert_stack.sass';

/**
 * A fixed-position stack of alert messages.
 */
export const AlertStack = () => {
  const alerts = useAlerts();
  const alertsData = redux.useSelector(state => state.alerts.resources);

  // useState/callback instead of useRef to trigger an update when the ref is first set.
  const [ref, setRef] = React.useState();
  const onRefChanged = React.useCallback(newRef => setRef(newRef), []);

  // Prevent FocusTrap from blocking alerts.
  // Once this bug in focus-trap is fixed, the `length` test can be removed.
  // https://github.com/focus-trap/focus-trap/issues/223
  FocusTrap.useException(Object.keys(alertsData).length > 0 ? ref : null, []);

  return (
    <div className="alert-stack" ref={onRefChanged}>
      <div className="alert-stack__stack">
        {Object.values(alertsData).map(alert =>
          <Alert
            {...alert}
            className="alert-stack__alert"
            key={alert.id}
            onClose={() => alerts.remove(alert.id)}
          />
        )}
      </div>
    </div>
  );
};
