import { apiReducer } from 'utils/reduxUtils';
import { orgMgmtSwitchContextPlugin } from 'management/organizations/org_mgmt_switch_context_plugin';


/**
 * Create a reducer for org management resource slices.
 * Wraps apiReducer with a by default included plugin
 * `orgMgmtSwitchContextPlugin`. Accepts an option to not
 * include the plugin.
 *
 * @param {String} key - The name of the resource in the redux store.
 * @param {Object=} options - See resourceReducer().
 *  @param {boolean|undefined} options.preserveOnOrgSwitch - If set to true will not include
 *  the `orgMgmtSwitchContextPlugin` as a plugin. Treated as false by default.
 * @returns {*}
 */

export const orgMgmtReducer = (key, options = {}) => {
  const plugins = [...(options.plugins || [])];
  if (!options.preserveOnOrgSwitch) {
    plugins.push(orgMgmtSwitchContextPlugin);
  }
  const newOpts = {
    ...options,
    plugins: plugins
  };
  return apiReducer(key, newOpts);
};

export const managementOrganizationsReducers = {
  // Stores information about organizations loaded by privileged users (such as platform managers
  // or members of the organizations)
  managementOrganizationsOrganizations: orgMgmtReducer(
    'managementOrganizationsOrganizations',
    { preserveOnOrgSwitch: true }
  ),
  // Stores information about authorization tokens associated with an organization and accessed via
  // org management
  managementOrganizationsAuthorizationTokens: orgMgmtReducer(
    'managementOrganizationsAuthorizationTokens'
  ),

  // Stores information about Analytics
  managementOrganizationsAnalyticsFilterOptions: orgMgmtReducer(
    'managementOrganizationsAnalyticsFilterOptions'
  ),


  // Stories information about Badge Earners associated with an organization and accessed
  // via org management
  managementOrganizationsBadgeEarners: orgMgmtReducer(
    'managementOrganizationsBadgeEarners'
  ),
  // Stores information about Badge Earner filter options associated with an organization and accessed via org
  // management
  managementOrganizationsBadgesFilterOptions: orgMgmtReducer(
    'managementOrganizationsBadgesFilterOptions'
  ),

  // Stores information about Badge Templates associated with an organization and accessed via org
  // management
  managementOrganizationsBadgeTemplates: orgMgmtReducer('managementOrganizationsBadgeTemplates'),
  managementOrganizationsBadgeTemplateAdditionalDetails: orgMgmtReducer('managementOrganizationsBadgeTemplateAdditionalDetails'),
  managementOrganizationsBadgeTemplatesList: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesList'
  ),
  managementOrganizationSelectedBadgeTemplates: orgMgmtReducer(
    'managementOrganizationSelectedBadgeTemplates'
  ),
  managementOrganizationsBadgeTemplateSkills: orgMgmtReducer(
    'managementOrganizationsBadgeTemplateSkills'
  ),

  managementOrganizationsBadgeTemplateHistory: orgMgmtReducer(
    'managementOrganizationsBadgeTemplateHistory'),

  managementOrganizationsBadgeTemplatesFilterOptions: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesFilterOptions'
  ),

  managementOrganizationsBadgeTemplatesSearch: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesSearch'
  ),
  managementOrganizationsBadgeTemplatesExport: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesExport'
  ),

  managementOrganizationsBadgeTemplatesHRISObjectId: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesHRISObjectId'
  ),

  managementOrganizationsBadgeTemplatesIssuing: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesIssuing'
  ),

  managementOrganizationsBadgeTemplatesReplace: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesReplace'
  ),

  managementOrganizationsBadgeTemplatesVariantSet: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatesVariantSet'
  ),

  managementOrganizationsBadgeTemplatePdfs: orgMgmtReducer(
    'managementOrganizationsBadgeTemplatePdfs'
  ),

  managementOrganizationsCertificates: orgMgmtReducer(
    'managementOrganizationsCertificates'
  ),

  managementOrganizationsCertificatePdfs: orgMgmtReducer(
    'managementOrganizationsCertificatePdfs'
  ),

  managementOrganizationsDirectoryExports: orgMgmtReducer(
    'managementOrganizationsDirectoryExports'
  ),

  managementOrganizationsBadgeExports: orgMgmtReducer(
    'managementOrganizationsBadgeExports'
  ),

  managementOrganizationsEmployees: orgMgmtReducer(
    'managementOrganizationsEmployees'
  ),

  managementOrganizationsWorkforceSubscription: orgMgmtReducer(
    'managementOrganizationsWorkforceSubscription'
  ),

  managementOrganizationsEmployeesBadgeTemplates: orgMgmtReducer(
    'managementOrganizationsEmployeesBadgeTemplates'
  ),

  managementOrganizationsEmployeeProfiles: orgMgmtReducer(
    'managementOrganizationsEmployeeProfiles'
  ),

  managementOrganizationsEmployeeBadges: orgMgmtReducer(
    'managementOrganizationsEmployeeBadges'
  ),

  managementOrganizationsEmployeesFilterOptions: orgMgmtReducer(
    'managementOrganizationsEmployeesFilterOptions'
  ),

  managementOrganizationsEmployeeInsights: orgMgmtReducer(
    'managementOrganizationsEmployeeInsights'
  ),

  managementOrganizationsEmployeeOccupations: orgMgmtReducer(
    'managementOrganizationsEmployeeOccupations'
  ),

  managementOrganizationsEmployeeSkillsSearch: orgMgmtReducer(
    'managementOrganizationsEmployeeSkillsSearch'
  ),

  managementOrganizationsEmployeeOccupationsSearch: orgMgmtReducer(
    'managementOrganizationsEmployeeOccupationsSearch'
  ),

  // skills related to all employees across an entire Workforce Organization
  managementOrganizationsEmployeeSkills: orgMgmtReducer(
    'managementOrganizationsEmployeeSkills'
  ),

  // skills related to a single employee
  managementOrganizationsEmployeeProfileSkills: orgMgmtReducer(
    'managementOrganizationsEmployeeProfileSkills'
  ),

  // Stores information about Endorsements
  managementOrganizationsEndorsements: orgMgmtReducer('managementOrganizationsEndorsements'),

  // Stores information about Bridge API keys associated with an organization and accessed via org
  // management
  managementOrganizationsBridgeApiKeys: orgMgmtReducer('managementOrganizationsBridgeApiKeys'),

  // Stores information about the current organizations available for the organization selector
  managementShortOrganizations: orgMgmtReducer('managementShortOrganizations', { preserveOnOrgSwitch: true }),

  // Stores information about Integration Access Tokens associated with an organization and
  // accessed via org management
  managementOrganizationsIntegrationAccessTokens: orgMgmtReducer('managementOrganizationsIntegrationAccessTokens'),

  // Stores information about pending invitations that have been sent to prospective admins of the
  // organization
  managementOrganizationsInvitations: orgMgmtReducer('managementOrganizationsInvitations'),

  // Stores information about IssuerAuthorizations associated with an organization and accessed via
  // org management
  managementOrganizationsIssuerAuthorizations: orgMgmtReducer(
    'managementOrganizationsIssuerAuthorizations'
  ),
  managementOrganizationsIssuerAuthorizationBadgeTemplatesFilterOptions: orgMgmtReducer(
    'managementOrganizationsIssuerAuthorizationBadgeTemplatesFilterOptions'
  ),
  managementOrganizationsIssuerAuthorizationCandidateBadgeTemplatesFilterOptions: orgMgmtReducer(
    'managementOrganizationsIssuerAuthorizationCandidateBadgeTemplatesFilterOptions'
  ),

  // Stores information about IssuerCollections associated with an organization and accessed via
  // org management
  managementOrganizationsIssuerCollections: orgMgmtReducer('managementOrganizationsIssuerCollections'),
  managementOrganizationsIssuerCollectionsList: orgMgmtReducer(
    'managementOrganizationsIssuerCollectionsList'
  ),
  managementOrganizationsIssuerCollectionsHistory: orgMgmtReducer(
    'managementOrganizationsIssuerCollectionsHistory'
  ),
  managementOrganizationsIssuerCollectionsBadgeTemplates: orgMgmtReducer(
    'managementOrganizationsIssuerCollectionsBadgeTemplates'
  ),
  managementOrganizationsIssuerCollectionBadgeTemplatesFilterOptions: orgMgmtReducer(
    'managementOrganizationsIssuerCollectionBadgeTemplatesFilterOptions'
  ),
  managementOrganizationsIssuerCollectionCandidateBadgeTemplatesFilterOptions: orgMgmtReducer(
    'managementOrganizationsIssuerCollectionCandidateBadgeTemplatesFilterOptions'
  ),

  managementOrganizationsPdfCertificates: orgMgmtReducer('managementOrganizationsPdfCertificates'),

  // Stores information about Saml settings associated with an organization and accessed via org
  // management
  managementOrganizationsSaml: orgMgmtReducer(
    'managementOrganizationsSaml'
  ),

  managementOrganizationsSamlLogs: orgMgmtReducer(
    'managementOrganizationsSamlLogs'
  ),

  managementOrganizationsSamlMetadata: orgMgmtReducer(
    'managementOrganizationsSamlMetadata'
  ),

  // Stores information about Integrations associated with an organization and accessed via org
  // management
  managementOrganizationsIntegrations: orgMgmtReducer(
    'managementOrganizationsIntegrations'
  ),

  // Stores information about LTI keys associated with an organization and accessed via org
  // management
  managementOrganizationsLtiAuthTokens: orgMgmtReducer('managementOrganizationsLtiAuthTokens'),
  // Stores information about recommendations
  managementOrganizationsRecommendations: orgMgmtReducer('managementOrganizationsRecommendations'),
  managementOrganizationsRecommendationBadgeTemplatesFilterOptions: orgMgmtReducer('managementOrganizationsRecommendationBadgeTemplatesFilterOptions'),
  managementOrganizationsRecommendationRemainingBadgeTemplatesFilterOptions: orgMgmtReducer('managementOrganizationsRecommendationRemainingBadgeTemplatesFilterOptions'),
  managementOrganizationsRecommendationAnalytics: orgMgmtReducer('managementOrganizationsRecommendationAnalytics'),
  // Stores information about Subscriptions associated with an organization and accessed via org
  // management
  managementOrganizationsSubscriptions: orgMgmtReducer('managementOrganizationsSubscriptions'),

  // Stores information about users with some type of administrative privileges in the organization
  managementOrganizationsUsers: orgMgmtReducer('managementOrganizationsUsers'),

  managementOrganizationsUsersSearch: orgMgmtReducer('managementOrganizationsUsersSearch'),

  managementOrganizationsWebhookHeaders: orgMgmtReducer('managementOrganizationsWebhookHeaders'),

  managementOrganizationsReportingTags: orgMgmtReducer('managementOrganizationsReportingTags'),

  managementOrganizationsPdfDesignExports: orgMgmtReducer('managementOrganizationsPdfDesignExports'),

  managementOrganizationsPdfTemplates: orgMgmtReducer('managementOrganizationsPdfTemplates'),

  // Stores informaiton about recently viewed organizations in org management
  managementRecentlyViewedOrganizations: orgMgmtReducer(
    'managementRecentlyViewedOrganizations',
    { preserveOnOrgSwitch: true }
  ),

  // Stores information related to Enhanced Analytics summary data, including requests for signature
  // data to submit to stats
  managementOrganizationsEnhancedAnalyticsSummarySignatures:
    orgMgmtReducer('managementOrganizationsEnhancedAnalyticsSummarySignatures'),

  managementOrganizationsEnhancedAnalyticsSummary:
    orgMgmtReducer('managementOrganizationsEnhancedAnalyticsSummary'),

  // Stores information related to Enhanced Analytics trends data, including requests for signature
  // data to submit to stats
  managementOrganizationsEnhancedAnalyticsTrendsSignatures:
    orgMgmtReducer('managementOrganizationsEnhancedAnalyticsTrendsSignatures'),

  managementOrganizationsEnhancedAnalyticsTrends:
    orgMgmtReducer('managementOrganizationsEnhancedAnalyticsTrends'),

  managementOrganizationsAnalyticsBenchmarks:
    orgMgmtReducer('managementOrganizationsAnalyticsBenchmarks'),

  managementOrganizationsIssuedBadgesMetadataCount:
    orgMgmtReducer('managementOrganizationsIssuedBadgesMetadataCount'),

  // Stores information related to template analytics, including requests for signature data to
  // submit to stats
  managementOrganizationsTemplateAnalyticsSignatures:
    orgMgmtReducer('managementOrganizationsTemplateAnalyticsSignatures'),

  managementOrganizationsTemplateAnalytics:
    orgMgmtReducer('managementOrganizationsTemplateAnalytics'),

  managementOrganizationsTemplateInteractionsSignatures:
    orgMgmtReducer('managementOrganizationsTemplateInteractionsSignatures'),

  managementOrganizationsTemplateInteractions:
    orgMgmtReducer('managementOrganizationsTemplateInteractions'),

  managementOrganizationsBadgeViewAnalyticsSignatures:
    orgMgmtReducer('managementOrganizationsBadgeViewAnalyticsSignatures'),

  managementOrganizationsBadgeViewAnalytics:
    orgMgmtReducer('managementOrganizationsBadgeViewAnalytics'),

  // Stores information related to analytics reports, including requests for signature data
  // submitted to stats
  managementOrganizationsSummaryAnalyticsReportSignatures:
    orgMgmtReducer('managementOrganizationsSummaryAnalyticsReportSignatures'),

  managementOrganizationsSummaryAnalyticsReports:
    orgMgmtReducer('managementOrganizationsSummaryAnalyticsReports'),

  managementOrganizationsDetailAnalyticsReportSignatures:
    orgMgmtReducer('managementOrganizationsDetailAnalyticsReportSignatures'),

  managementOrganizationsDetailAnalyticsReports:
    orgMgmtReducer('managementOrganizationsDetailAnalyticsReports'),

  managementOrganizationsWorkforceLearningPlans:
    orgMgmtReducer('managementOrganizationsWorkforceLearningPlans'),

  managementOrganizationsWorkforceLearningPlanEmployments:
    orgMgmtReducer('managementOrganizationsWorkforceLearningPlanEmployments'),

  managementOrganizationsDownloadBulkIssueTemplate:
    orgMgmtReducer('managementOrganizationsDownloadBulkIssueTemplate'),

  managementOrganizationsBulkIssues:
    orgMgmtReducer('managementOrganizationsBulkIssues'),

  // Stores info related to countries summary analytics
  managementOrganizationsCountriesSummarySignatures:
    orgMgmtReducer('managementOrganizationsCountriesSummarySignatures'),

  managementOrganizationsCountriesSummary:
    orgMgmtReducer('managementOrganizationsCountriesSummary'),

  managementOrganizationsIssuerDashboard:
    orgMgmtReducer('managementOrganizationsIssuerDashboard')
};
