import { ActionManager } from 'utils/action_manager';

export const employerActions = new ActionManager('employers')
  .add({
    requestKey: 'list',
    url: '/api/v1/reference/employers',
    effect: 'read',
    method: 'GET',
    alwaysRefresh: true,
    list: 'search'
  });
