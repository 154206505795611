import { ActionManager } from 'utils/action_manager';

export const savedDirectorySearchActionManager = new ActionManager('savedDirectorySearches')
  .add({
    requestKey: 'get',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/saved_directory_searches/[id]'
  },
  true
  ).add({
    requestKey: 'create',
    method: 'POST',
    effect: 'create',
    url: '/api/v1/saved_directory_searches',
    list: 'create',
    alwaysRefresh: true
  }).add({
    requestKey: 'update',
    method: 'PATCH',
    effect: 'update',
    url: '/api/v1/saved_directory_searches/[id]'
  }, true
  ).add({
    requestKey: 'update_last_viewed',
    method: 'PATCH',
    effect: 'update',
    url: '/api/v1/saved_directory_searches/[id]/update_last_viewed'
  }, true
  ).add({
    requestKey: 'default',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/saved_directory_searches'
  }).add({
    requestKey: 'list',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/saved_directory_searches',
    list: 'list',
    alwaysRefresh: true
  }).add({
    requestKey: 'update',
    method: 'PUT',
    effect: 'update',
    url: '/api/v1/saved_directory_searches/[id]'
  }, true).add({
    requestKey: 'delete',
    method: 'DELETE',
    effect: 'delete',
    url: '/api/v1/saved_directory_searches/[id]'
  }, true).add({
    requestKey: 'get',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/saved_directory_searches/[id]'
  }, true);
