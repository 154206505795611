import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import { actionTypes } from 'redux-resource';

export const fetchAcceptedEmployments = new ActionManager('acceptedEmployments')
  .add({
    requestKey: 'fetchAcceptedEmployments',
    url: '/api/v1/users/self/employments/accepted_employments',
    effect: 'read',
    method: 'GET'
  });

export const fetchUserEmploymentHistory = new Action({
  resourceType: 'employmentHistories',
  requestKey: 'fetchEmploymentHistories',
  url: '/api/v1/users/self/employments/employment_histories',
  effect: 'read',
  method: 'GET'
});

const updateFetchAcceptedEmploymentsAccept = (dispatch, getState, action) => {
  const resource = action.resources[0];
  const employmentId = resource.id;
  const getEmployment = fetchAcceptedEmployments.get('fetchAcceptedEmployments');
  const employments = getEmployment.getResources(getState());
  const employmentExists = employments.some(employment => employment.id === employmentId);

  if (!employmentExists) {
    const updatedEmployments = [...employments, resource];
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        acceptedEmployments: updatedEmployments
      }
    });
  }
};

const updateFetchAcceptedEmploymentsReject = (dispatch, getState, action) => {
  const resource = action.resources[0];
  const employmentId = resource.id;
  const getEmployment = fetchAcceptedEmployments.get('fetchAcceptedEmployments', employmentId);
  const employment = getEmployment.getResources(getState());

  if (employment) {
    dispatch({
      type: 'DELETE_RESOURCES',
      resources: {
        acceptedEmployments: [employment]
      }
    });
  }
};

export const employments = new ActionManager('employments')
  .add({
    requestKey: 'list',
    url: '/api/v1/users/self/employments',
    effect: 'read',
    method: 'GET'
  })
  .add({
    requestKey: 'accept',
    url: '/api/v1/users/self/employments/[id]/accept',
    effect: 'update',
    method: 'PUT',
    onSuccess: updateFetchAcceptedEmploymentsAccept
  }, true)
  .add({
    requestKey: 'reject',
    url: '/api/v1/users/self/employments/[id]/reject',
    effect: 'update',
    method: 'PUT',
    onSuccess: updateFetchAcceptedEmploymentsReject
  }, true)
  .add({
    requestKey: 'details',
    url: '/api/v1/users/self/employments/[id]/details',
    effect: 'read',
    method: 'GET'
  }, false);
