import React from 'react';
import BaseSortSelector from 'controls/sort_selector';

export default function SortSelector(props){
  return (
      <BaseSortSelector {...props}
        sortValues={[
          { sortValue: 'updated_at' , displayValue: 'Last Updated' },
          { sortValue: 'issued_at' , displayValue: 'Date Earned' },
          { sortValue: 'issuer_name' , displayValue: 'Issuer' },
          { sortValue: 'name' , displayValue: 'Badge Name' }
        ]}
      />
  );
}
