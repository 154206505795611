import axios from 'axios';

export const getCredlyPublicBadges = async (vanitySlug, url) => {
  url = url || `/users/${vanitySlug}/badges?page=1&page_size=48&sort=rank`;
  const { data } = await axios.get(url, {
    headers: {
      Accept: 'application/json'
    }
  });
  return data;
};
