import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const publicUsers = new ActionManager('publicUsers').add({
  requestKey: 'get',
  url: '/users/[id]',
  effect: 'read',
  method: 'GET'
}, true);

export const fetchPublicUserBadges = new Action({
  resourceType: 'publicUserBadges',
  requestKey: 'fetchUserBadges',
  url: '/users/[user_id]/badges',
  urlSubs: new stringUtils.BracketSubs({
    user_id: { param: 'user_id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'general',
  alwaysRefresh: true
});

export const recruitMessages = new ActionManager('temp')
  .add({
    requestKey: 'sendRecruitMessage',
    url: '/api/v1/recruiters/send-message',
    effect: 'create',
    method: 'POST'
  }, true);
