import React from 'react';
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import JobBoardEmptyState from 'images/job_board_empty_state.png';
import JobBoardEmptyStateSaved from 'images/job_board_empty_state_saved.png';
import JobBoardEmptyStateApplied from 'images/job_board_empty_state_applied.png';

import './empty_job_board.sass';

export const EmptyJobBoard = ({ filter }) => {
  const applied = (filter === 'applied');
  const saved = (filter === 'saved');

  const emptyBoardImageSelector = () => {
    if (applied) {
      return (JobBoardEmptyStateApplied);
    } else if (saved) {
      return (JobBoardEmptyStateSaved);
    } else {
      return (JobBoardEmptyState);
    }
  };

  const emptyBoardMainCopySelector = () => {
    if (applied) {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.header_applied"
          defaultMessage="Looks like you haven’t applied to any jobs yet!"
        />
      );
    } else if (saved) {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.header_saved"
          defaultMessage="Looks like you haven’t saved any jobs yet!"
        />
      );
    } else {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.header"
          defaultMessage="There are no jobs currently posted."
        />
      );
    }
  };

  const emptyBoardSubCopySelector = () => {
    if (applied) {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.subtext_applied"
          defaultMessage="Jobs you applied for will appear on this page."
        />
      );
    } else if (saved) {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.subtext_saved"
          defaultMessage="Your saved jobs will appear on this page."
        />
      );
    } else {
      return (
        <FormattedMessage
          id="talent_match.job_board.empty_board.subtext"
          defaultMessage="New jobs are posted regularly - please check back in a few days."
        />
      );
    }
  };

  return (
    <div className="empty-board-wrapper">
      <div className="empty-board-contents-wrapper">
        <img className="empty-board-image" src={emptyBoardImageSelector()} />
        <div className="empty-board-main-copy">
          {emptyBoardMainCopySelector()}
        </div>
        <div className="empty-board-sub-copy">
          {emptyBoardSubCopySelector()}
        </div>
      </div>
    </div>
  );
};

EmptyJobBoard.propTypes = {
  filter: PropTypes.string
};
