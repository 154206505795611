import React from 'react';
import { crudRequest } from 'redux-resource-xhr';
import * as reduxResource from 'redux-resource';
import * as redux from 'react-redux';
import { reset } from 'redux-resource-plugins';
import { getCsrfToken, updateCsrfToken, isEmpty } from 'utils';
import { QueryString } from 'utils/query_string';
import * as stringUtils from 'utils/string';
import { getMetadata } from 'utils/redux_resource_metadata_plugin';
import { currentPath, isSigninUrl, reload, setHref } from 'utils/routes';
import * as objUtils from 'utils/object';
import { array } from 'utils/array';
import { useEffectExceptOnMount, useShallowEquals, useIsMounted } from 'utils/react_utils';

const MAX_RETRIES = 1;
// Use these to prevent pointers from changing between two empty objects. This can reduce the
// number of renders due to redux updates.
const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

/**
 * @typedef ResourceStatus
 * @property {Boolean} idle
 * @property {Boolean} pending
 * @property {Boolean} succeeded
 * @property {Boolean} failed
 * @property {Object} errors
 */
let ResourceStatusDocOnly;

/**
 * @typedef ActionState
 * @property {Array} resources - Resource array from redux-resource.
 * @property {ResourceStatus} status - redux-resource status
 * @property {Object} requestDetails - Request details from redux-resource.
 * @property {Object} metadata - The {meta} object from an API response.
 */
let ActionStateDocOnly;

/**
 * @typedef ActionResponse
 * @property {function} abort
 */
let ActionResponseDocOnly;

/**
 * The function returned by useAction() to perform an action. It returns an unmount function, which
 * aborts the request.
 *
 * @typedef PerformAction
 * @type {function(values: object):function}
 */
let PerformActionDocOnly;

/**
 * Wrapper around a redux-resource action.
 */
export class Action {
  /**
   * Create a redux action to make an API request, and possibly modify the redux store.
   *
   * @param {Object} options
   *   @param {String} options.resourceType
   *   @param {String} options.effect - How this action effects the data in the store. One of:
   *     {update, create, delete, read}
   *   @param {String=} [options.method='GET'] - The HTTP method to use.
   *   @param {String} options.url - The URL. It can include substitution strings in square brackets.
   *     These will be replaced based on the subst parameter passed into .action().
   *   @param {String} options.requestKey
   *   @param {String=} [options.id] - This request affects a specific resource id.
   *     For example, if the action is limited to modifying one badge, this should be the badge id.
   *     If you want the id to be different for each request, consider using the ActionManager
   *     class.
   *   @param {String=} [options.list] - The result of the request should be stored in a list with
   *     this name. Lists can be used to distinguish between two sets of data associated with the
   *     same reducer -- for example, earned badges and issued badges.
   *   @param {Boolean=false} [options.alwaysRefresh] - if true, data is always replaced on fetch.
   *   @param {Object=} [options.defaultParams] - Default parameters to pass in with every request.
   *   @param {function(function, object, object, object)=} [options.onSuccess] - Called when the
   *     operation is successful. Callback parameters are:
   *       dispatch: The redux dispatch function.
   *       getState: Redux's `getState`.
   *       action: The action that triggered the request.
   *       res: The response.
   *       body: The response body.
   *   @param {function=} [options.preTransform] - Intercept the data coming from the API, and
   *     return a transformed version.
   *   @param {function=} [options.transform] - Intercept data after some basic transformations have
   *     been run to force the results into an array with ids. Return a transformed version.
   *   @param {String=} [options.defaultError] - The error message to display when an unknown error
   *     occurs.
   *   @param {function=} [options.onFailure] - Called when the operation fails.
   *   @param {function=} [options.onUnauthorized] - Called when operations fails with 401 error.
   *   @param {function=} [options.addCustomHeaders] - Callback to add custom headers parameters.
   *   @param {BracketSubs=} [options.urlSubs] - Substitutions, from stringUtils. These can be used
   *     to copy the 'values' parameter of action() into the url, and optionally remove them from
   *     the values list. For example:
   *       urlSubs: new stringUtils.BracketSubs({id: 'special_id', remove: true})
   *     An "[id]" string in the url will be replaced with values.special_id, but values.special_id
   *     will not be sent to the server as a parameter, due to the remove option.
   * @param {String=} [env='production'] - The Rails environment. This is used to determine whether to warn on abort.
   */
  constructor(options, env = 'production') {
    const missing = ['resourceType', 'effect', 'url', 'requestKey'].filter(p => !options[p]);
    if (missing.length) {
      throw(new Error(
        `Missing parameters for new Action(${JSON.stringify(options)}): ${missing.join(',')}`
      ));
    }

    this.m_options = {...options}; // Don't allow external modification.
    this.m_requestPathBase = `${options.resourceType}.requests.${options.requestKey}`;
    this._getId = this._makeGetId(options.id);
    this._env = env;
  }


  /**
   * Return all state, appropriate for passing into redux's mapStateToProps().
   *
   * @param {Object} state - The current redux state.
   * @returns {ActionState}
   */
  allState(state) {
    return {
      resources: this.getResources(state),
      status: this.getStatus(state),
      metadata: this.getMetadata(state),
      requestDetails: this.getRequestDetails(state)
    };
  }


  /**
   * Create a redux dispatcher to make the HTTP request.
   *
   * @param {Object} values - URL parameters
   * @returns {function:ActionResponse}
   */
  action = (values = {}) => {
    const options = this.m_options;

    return (dispatch, getState) => {
      const makeRequest = (attempts = 0) => {
        const id = this._getId(getState()[options.resourceType]);
        let params = {...options.defaultParams, ...values};

        // Process URL substitution strings.
        let url = options.url;
        // Top-level substitution strings come out of params. This can modify params.
        if (options.urlSubs) {
          url = options.urlSubs.substitute(url, params);
        }
        if (id) {
          // Always substitute the id.
          url = stringUtils.substituteBracketKeys(url, {id});
        }

        let crudOptions = {
          actionDefaults: (() => {
            let defaults = {};

            // Copy some options out of this.m_options.
            ['requestKey', 'resourceType', 'list'].forEach(opt => defaults[opt] = options[opt]);

            if (id) {
              // If id was supplied, this request might want to modify the data that id represents.
              defaults.resources = [id];
            }

            if (options.alwaysRefresh) {
              defaults.mergeResources = false;
              defaults.mergeListIds = false;
            }
            return defaults;
          })(),
          dispatch,
          transformData: this._makeTransformData(id),
          onFailed: this._makeFailureHandler(
            dispatch,
            makeRequest,
            attempts + 1,
            {
              onFailure: options.onFailure,
              onUnauthorized: options.onUnauthorized
            }
          ),
          onSucceeded: (action, res, body) => {
            options.onSuccess && options.onSuccess(dispatch, getState, action, res, body);
            dispatch(action);
          },
          xhrOptions: this._makeXhrOptions(
            url,
            params,
            options.method
          )
        };
        let request = crudRequest(options.effect, crudOptions);
        request._origAbort = request.abort;
        request.abort = () => dispatch(this._abort(request));
        return request;
      };
      return makeRequest();
    };
  };


  /**
   * Get request details out of redux-resource state.
   *
   * @param {Object} state - Redux state
   * @returns {Object}
   */
  getRequestDetails = state => {
    if (!state) {
      throw(new Error('Action::getRequestDetails called without redux state'));
    }

    const splitPath = this.m_requestPathBase.split('.');
    let result;
    let currentVal = state;
    for (let i = 0; i < splitPath.length; i++) {
      const pathValue = currentVal[splitPath[i]];
      if (typeof pathValue === 'undefined') {
        break;
      } else if (i === splitPath.length - 1) {
        result = pathValue;
      }

      currentVal = pathValue;
    }

    return result;
  };


  /**
   * Get the resources from redux state. This is the main data object.
   *
   * @param {Object} state - Redux state.
   * @returns {*}
   */
  getResources = state => {
    if (!state) {
      throw new Error('Action::getResources called without redux state');
    }

    const options = this.m_options;
    const slice = state[options.resourceType];

    if (options.id) {
      // ID was passed in. Return a single object from the loaded resources.
      return slice.resources[this._getId(slice)];
    } else if (options.list) {
      // A list was passed in. Return an array based on that list.
      return reduxResource.getResources(slice, options.list) || EMPTY_ARRAY;
    } else {
      // This is neither an id-based or list-based action. Return the whole resources array.
      return reduxResource.getResources(slice) || EMPTY_ARRAY;
    }
  };


  /**
   * Get a status object for the current request.
   *
   * @param {Object} state - Redux state.
   * @param {Boolean=false} treatIdleAsPending - Change "idle" states to "pending."
   * @returns {ResourceStatus} - True flags for the current state.
   */
  getStatus = (state, treatIdleAsPending) => {
    if (!state) {
      throw new Error('Action::getStatus called without redux state');
    }

    const path = `${this.m_requestPathBase}.status`;
    return reduxResource.getStatus(state, [path], treatIdleAsPending);
  };


  /**
   * Get errors from the request. This is only relevant when `status.failed`.
   *
   * @param {Object} state - Redux state.
   * @returns {Object} - Current errors.
   */
  getErrors = state => {
    if (!state) {
      throw new Error('Action::getErrors called without redux state');
    }

    // Add an error object. Error data is created by makeFailureHandler()
    const requestDetails = this.getRequestDetails(state);
    if (requestDetails && requestDetails.status === 'FAILED') {
      if (!requestDetails.result) {
        // Sample case to cause this: Merge accounts, but enter an incorrect password.
        return {base: [this.m_options.defaultError || 'An error occurred.']};
      } else if (requestDetails.result.errors) {
        let errors = {};
        requestDetails.result.errors.forEach(errProps => {
          let attribute = errProps.attribute;
          const messages = errProps.messages;
          // If the error is for an array-type field, treat it as such.
          // This may have to change when we add a `SubForm` component. As of 2019-08-12, the only
          // component which depends on this is `IssueForm`, for server-generated evidence errors.
          if ('index' in errProps && 'model' in errProps) {
            attribute = `${errProps.model}[${errProps.index}][${attribute}]`;
          }
          errors[attribute] || (errors[attribute] = []);
          errors[attribute].push(...messages);
        });
        return errors;
      } else if (requestDetails.result.message) {
        return {base: [requestDetails.result.message]};
      }
    }

    return EMPTY_OBJECT;
  };


  /**
   * Get metadata out of the redux state. This is available for any reducer created with the
   * redux_resource_metadata_plugin plugin, or with apiReducer(). If the action was created with
   * the "list" option, this function will fetch metadata specific to that list.
   *
   * @param {Object} state - Redux state
   * @returns {Object}
   */
  getMetadata = state => {
    if (!state) {
      throw new Error('Action::getMetadata called without redux state');
    }

    const resourceSlice = state[this.m_options.resourceType];
    const id = this._getId(resourceSlice);
    const opts = { id };
    if (!opts.id) {
      opts.list = this.m_options.list;
    }
    return getMetadata(resourceSlice, opts);
  };


  /**
   * Get the options used to create this object.
   *
   * @returns {Object}
   */
  getOptions = () => ({...this.m_options});


  /**
   * Return a dispatcher that resets all data.
   * This works with reducers created using apiReducer, or that install the reset plugin.
   *
   * @returns {Function} A redux dispatcher
   */
  reset = () => {
    return dispatch => {
      if (this.m_options.list) {
        return dispatch(reset.resetResource(
          this.m_options.resourceType, {
            list: this.m_options.list
          }
        ));
      }
      return dispatch(reset.resetResource(this.m_options.resourceType));
    };
  };


  /**
   * Add client-generated errors into the return value of getState().
   * This allows you to merge client validation into the server-generated redux state.
   *
   * Calling this will move the request into the FAILED state, or create a FAILED request if
   * one does not exist.
   *
   * @param {object<string, array>} errors - A map of field name to a list of error messages.
   *   Use a null or otherwise unused key to add global errors.
   * @returns {Function} A redux dispatch.
   */
  addErrors = errors => (dispatch, getState) => {
    const options = this.m_options;
    const commonProps = {
      requestKey: options.requestKey,
      resourceType: options.resourceType,
      resources: []
    };
    const oldErrors = this.getErrors(getState());
    const errorsArray = objUtils.mapToArray({...oldErrors, ...errors}, (name, errs) => (
      {attribute: name, messages: errs}
    ));
    // Treat it like a real request. pending -> failed.
    dispatch({...commonProps, type: this._getActionType('pending')});
    // setTimeout to wait for the event queue to flush. Otherwise, the pending state won't register.
    setTimeout(
      () => {
        dispatch({
          ...commonProps,
          type: this._getActionType('failed'),
          requestProperties: {result: {errors: errorsArray}}
        });
      }, 1
    );
  };


  /**
   * Clear all errors, until another request is made, or the addErrors is called.
   *
   * @param {string|array} [fieldNames] - only clear errors for this field or set of fields.
   * @returns {Function} A redux dispatch.
   */
  clearErrors = (fieldNames = '') => (dispatch, getState) => {
    const options = this.m_options;
    const commonProps = {
      requestKey: options.requestKey,
      resourceType: options.resourceType,
      resources: []
    };

    let errors = [];
    // Remove specific errors.
    if (fieldNames) {
      const oldErrors = this.getErrors(getState());
      if (oldErrors) {
        fieldNames = Array.isArray(fieldNames)
          ? objUtils.arrayToSet(fieldNames)
          : {[fieldNames]: 1};
        objUtils.forEach(oldErrors, (name, errs) => {
          if (!(name in fieldNames)) {
            errors.push({attribute: name, messages: errs});
          }
        });
      }
    }

    let status = reduxResource.getStatus(getState(), [`${this.m_requestPathBase}.status`]);

    dispatch({
      ...commonProps,
      type: this._getActionType(getStatusString(status)),
      requestProperties: {result: {errors: errors}}
    });
  };


  /**
   * React hook which returns all state relevant to this action. It is meant to only be called by
   * useAction(), but could be called externally.
   *
   * The return value and all sub-values are memoized (pointers only change when data changes).
   *
   * @returns {ActionState}
   */
  useAllState = () => {
    /** @type {{current: ActionState}} */
    const allState = React.useRef();
    /** @type {{current: ResourceStatus}} */
    const status = React.useRef();
    /** @type {{current: Object|Array}} */
    const resources = React.useRef();

    // useSelector's second parameter is a comparison function which is _supposed_ to prevent the
    // return value from changing when it returns true. This doesn't work correctly -- it always
    // uses strict ===. To get around this, we have to use our own shallow equal comparisons for
    // anything that calls into redux:
    // - reduxResource.getResources and reduxResource.getStatus return new pointers every time, so
    //   they need special handling, using refs and shallowEquals comparisons.
    // - this.getMetadata and this.getRequestDetails reach into the store directly. Those pointers
    //   only change when data changes, so no additional handling is needed.
    return redux.useSelector(state => {
      // Update status on change.
      const newStatus = this.getStatus(state);
      if (!objUtils.shallowEquals(status.current, newStatus)) {
        status.current = newStatus;
      }

      // Update resources on change.
      const newResources = this.getResources(state);
      if (Array.isArray(newResources)) {
        if (!array.shallowEquals(resources.current, newResources)) {
          resources.current = newResources;
        }
      } else if (!objUtils.shallowEquals(resources.current, newResources)) {
        resources.current = newResources;
      }

      // Keep the final return value consistent, to minimize updates from useSelector.
      const newAllState = {
        status: status.current,
        resources: resources.current,
        metadata: this.getMetadata(state),
        requestDetails: this.getRequestDetails(state)
      };
      if (!objUtils.shallowEquals(allState.current, newAllState)) {
        allState.current = newAllState;
      }

      return allState.current;
    });
  };


  /**
   * React hook for use with this class. In functional components, this negates the need for
   * connect(). All return values are memoized (pointers only change when data changes), so they
   * can safely be used as dependencies.
   *
   * Usage:
   * const MyComponent = props => {
   *   const [request, doIt] = someAction.useAction();
   *   doIt();
   *   return request.resources.map(r => <div key={r.id}>{r.id}</div>);
   * }
   *
   * @returns {[ActionState, PerformAction, function]}
   *   The return value consists of:
   *   - An object containing all available state. All sub-values are memoized.
   *   - A function to invoke the action, which takes all action() parameters. It returns an abort
   *     function, which allows it to be used as the return value in a useEffect() hook.
   *     See PerformAction documentation above.
   *   - A reset function.
   */
  useAction = () => {
    const dispatch = redux.useDispatch();
    const all = this.useAllState();

    // The currently active request.
    /** @type {{current: ActionResponse}} */
    const pendingRequest = React.useRef(null);
    // Remove the request when it's complete, so we won't try to abort it.
    if (all.status.succeeded || all.status.failed) {
      pendingRequest.current = null;
    }

    const abort = () => {
      // Non-read requests can't be safely aborted, since the requested operation, or part of it,
      // may have already taken place on the server.
      if (pendingRequest.current && this.m_options.effect === 'read') {
        pendingRequest.current.abort();
      }
      pendingRequest.current = null;
    };

    // Perform the action. Returns an unmount function for useEffect
    // The reason "this" is in the dependency list is that React remembers the order in which
    // hooks are declared, not the scope of those hooks. Because of this, you could call useAction
    // in the same spot but with a different action, and React would operate on the previous action.
    const performAction = React.useCallback((...args) => {
      abort();
      pendingRequest.current = dispatch(this.action(...args));
      return abort;
    }, [this]);

    // Reset redux data
    const reset = React.useCallback(() => {
      abort();
      dispatch(this.reset());
    }, [this]);

    return [all, performAction, reset];
  };


  /**
   * Alternate version of useAction, useful for fetch operations. This performs the action on mount,
   * and whenever parameters change.
   *
   * Usage:
   * const MyComponent = props => {
   *   const [request, action] = someAction.useOnMount();
   *   if (request.status.idle || request.status.pending) {
   *     return <LoadingSpinner/>;
   *   } else {
   *     return request.resources.map(d => <div key={d.id}>{d.name}</div>);
   *   }
   * }
   *
   * @param {Object} values - Query parameters to pass to the request. If these change, a re-fetch
   *   occurs.
   * @param {Array} dependencies - Also fetch when any of these dependencies change.
   * @returns {[ActionState, function]}
   *   The return value consists of:
   *   - An object containing all available state.
   *   - An action function.
   *   - A reset function.
   */
  useOnMount = (values = EMPTY_OBJECT, dependencies = []) => {
    const fetchPending = React.useRef(false);
    const mounted = useIsMounted();

    let [actionState, action, reset] = this.useAction();

    // Fetch on mount (and change status to pending), unless:
    // - The request is pending.
    // - The request has already succeeded.
    // - Data is already available. This can happen if data is pre-loaded.
    // This will cause an inefficient second request in the case where data is pre-loaded but
    // empty.
    if (!mounted) {
      const status = actionState.status;
      const shouldFetchOnMount =
        this.m_options.alwaysRefresh ||
        ((status.idle || status.failed) && isEmpty(actionState.resources));

      if (shouldFetchOnMount) {
        fetchPending.current = true;
        if (!actionState.status.pending) {
          actionState = {
            ...actionState,
            status: {idle: false, pending: true, succeeded: false, failed: false}
          };
        }
      }
    }

    // Fetch when needed on mount.
    React.useEffect(() => {
      if (fetchPending.current) {
        fetchPending.current = false;
        return action(values);
      }
    }, []);

    // Unconditionally fetch at a later time, if dependencies or values change.
    useEffectExceptOnMount(() => action(values), [...dependencies, useShallowEquals(values)]);

    return [actionState, action, reset];
  };


  /**
   * React hook for the errors of the current request. This data is only meaningful when
   * `status.failed`.
   *
   * Usage:
   * const [errors, addErrors, clearErrors] = myAction.useErrors()
   *
   * @returns {[Object, function(object<string, array> errors], function(string fieldNames)}
   *   Error data, an addErrors function and a clearErrors function.
   */
  useErrors = () => {
    const dispatch = redux.useDispatch();
    return [
      redux.useSelector(this.getErrors, objUtils.deepEquals),
      (...args) => dispatch(this.addErrors(...args)),
      (...args) => dispatch(this.clearErrors(...args))
    ];
  };


  /**
   * Get the action name for a given status.
   *
   * @param {String} status - One of {succeeded, failed, pending, idle}
   * @returns {string}
   * @private
   */
  _getActionType = status =>
    `${this.m_options.effect.toUpperCase()}_RESOURCES_${status.toUpperCase()}`;


  /**
   * Handle HTTP failures.
   *
   * @param {function} dispatch - Redux dispatch function
   * @param {function=} retryFn
   * @param {int=} attempts=0
   * @param {Object} callbacks
   *   @param {function=} callbacks.onUnauthorized - called when request fails with 401 response
   *   @param {function=} callbacks.onFailure - called when request fails
   * @returns {function:array}
   * @private
   */
  _makeFailureHandler(dispatch, retryFn, attempts, callbacks) {
    return (action, err, res) => {
      const body = res.body;

      if (body && body.data) {
        action.requestProperties = {
          ...action.requestProperties,
          result: body.data
        };
      }

      if (res.statusCode === 401) {
        const handleUnauthorized = callbacks.onUnauthorized || this._handleUnauthorized;
        const result = handleUnauthorized(action, err, res, attempts, retryFn);
        if (result) {
          return result;
        }
      }

      /* Handle the need for upgraded permissions */
      const metadata = (body && body.metadata) || {};
      if ((res.statusCode === 403) && (metadata.reason === 'elevated-permissions-required')) {
        setHref('/signin/' + metadata.organization_id + '?redirect_url=' + currentPath());
        return false;
      }

      if (body) {
        let errMessage = '';
        let hasError = false;

        // HTML response for an error the API didn't handle, such as an invalid route.
        if (typeof body == 'string') {
          hasError = true;
          const h1 = stringUtils.substrBetween(body, '<h1>', '</h1>');
          const h2 = stringUtils.substrBetween(body, '<h2>', '</h2>');
          if (h1 && h2) {
            errMessage = h1 + ': ' + h2;
          }
          else if (h1 || h2) {
            errMessage = h1 || h2;
          }
        }
        // API responses
        else if (body.data) {
          // Some controllers produce errors that only have a "message" property. For example,
          // ApiController, ExternalFilesController and PasswordsController.
          if (body.data.message) {
            hasError = true;
            const message = body.data.message;

            // An unexpected error created as stack trace. Use the first line as the error message.
            if (/acclaim-server-bundle/.test(message)) {
              console.error('Unexpected error for: ' + res.url);
              console.error(message.replace(/\\n/g, '\n'));

              const errMap = {
                'Resource not found.': 'Invalid API request. See console for more information.'
              };

              const firstLine = message.replace(/(\\n|\n).*/, '');
              errMessage = firstLine in errMap ? errMap[firstLine] : firstLine;
            }
          }
        }

        // We can never get here without an error, but only handle specific cases anyway,
        // in case there are expected errors (like checking whether a badge exists).
        if (hasError) {
          action.requestProperties = {
            ...action.requestProperties,
            result: {
              message: errMessage || 'An unexpected error occurred.',
              ...action.requestProperties.result
            }
          };
        }
      }

      const rval = dispatch(action);
      callbacks.onFailure && callbacks.onFailure();
      return rval;
    };
  }


  /**
   * Handle HTTP failures.
   *
   * @param {Object=} action
   * @param {Object=} err
   * @param {Object=} res
   * @param {int=} attempts=0
   * @param {function=} retryFn
   * @private
   */
  _handleUnauthorized = (action, err, res, attempts, retryFn) => {
    // automatically retry in case of CSRF failure
    const meta = res.body.metadata;
    if (meta && meta.reason === 'csrf-error' && attempts <= MAX_RETRIES) {
      updateCsrfToken(meta.csrf_token);
      retryFn(attempts);
      return true;
    }
    // Too many failures. Show the signin page.
    else {
      if (!isSigninUrl(action.res.url)) {
        // Reload the page, so that signin will be displayed, after which the user will
        // be returned to the curret page.
        reload();
        return true;
      }
    }
  };

  /* Abort an active request, and reset the status back to IDLE. This is exposed as a method
   * on the Action.action() response.
   *
   * @param {xhr} request
   * @returns {Function} A redux dispatcher
   * @private
   */
  _abort = request => {
    const options = this.m_options;
    if (options.effect !== 'read') {
      if (this._env === 'development') {
        console.warn(
          'Action.abort() only supports read actions. Other actions cannot be reliably aborted. ' +
          options.resourceType + ':' + options.requestKey
        );
      }

      // Jest aborts all xhr requests on shutdown, so make sure something happens here, even though
      // there's no easy and reliable way to do this for real requests.
      return () => request._origAbort();
    }

    return (dispatch, getState) => {
      const status = this.getStatus(getState());
      if (status.idle || status.pending) {
        dispatch({
          type: reduxResource.actionTypes.READ_RESOURCES_IDLE,
          requestKey: options.requestKey,
          resourceType: options.resourceType
        });
        request._origAbort();
      }
    };
  };


  /**
   * This is here just in case Eli didn't remove all references to the old abort() function.
   * Added 2019-09-26. Remove if this error isn't seen by 2020-01-01.
   */
  abort = request => {
    console.warn('Calling deprecated version of abort()', new Error().stack);
    request.abort();
  };


  /**
   * Intercept data coming in from the crudRequest, and transform the data.
   *
   * @param {String} id
   * @returns {function(*=): Object}
   * @private
   */
  _makeTransformData = id => {
    /**
     * @param {Object} body
     *   @param {*} body.data
     * @returns {Object}
     * @private
     */
    return body => {
      // Needed for atypical API responses.
      if (typeof body === 'string') {
        try { body = JSON.parse(body); } catch (e) {}
      }

      let data = body.data;

      if (this.m_options.preTransform) {
        data = this.m_options.preTransform(data);
      }

      if (data) {
        // Normal API response.
        data = Array.isArray(data) ? data : [data];
      } else {
        // Atypical response, or non-API response.
        data = Array.isArray(body) ? body : [body];
      }

      // Some endpoints return custom data that redux-resource can't deal with. Add an ID.
      if (
        (!('disableSingleItemBehavior' in this.m_options) || !this.m_options.disableSingleItemBehavior) &&
        data.length === 1 && data[0] && !data[0].id
      ) {
        data[0].id = id || 'data';
      }

      if (this.m_options.transform) {
        data = this.m_options.transform(data);
      }

      return data;
    };
  };


  /**
   * Make options required for an XHR request.
   *
   * @param {String} url - The request URL
   * @param {Object} values - URL parameters or body.
   * @param {String} [method=GET] - the HTTP method.
   * @returns {Object}
   * @private
   */
  _makeXhrOptions(url, values, method = 'GET') {
    let options = {
      json: true,
      method: method,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        // Non-API requests (like /users/sign_in) sometimes require this.
        'X-Requested-With': 'XMLHttpRequest'
      }
    };

    if (method === 'GET') {
      options.url = new QueryString(values).makeUrl(url);
    } else {
      options.url = url;
      if (values && Object.values(values).find(v => v instanceof File)) {
        let data = new FormData();
        objUtils.forEach(values, (key, val) => data.append(key, val));
        options.data = data;
        // options.json affects both incoming and outgoing data, which breaks multipart requests.
        // Only allow the response to be json in this case.
        options.json = false;
        options.responseType = 'json';
      } else {
        options.data = values;
      }
    }

    if (this.m_options.addCustomHeaders) {
      options.headers = {
        ...options.headers,
        ...this.m_options.addCustomHeaders(options.headers, values)
      };
    }

    return options;
  }

  /**
   * Create a function to get the resource id.
   *
   * @param {String|Function} id - The id, or a function that maps resource slice to id.
   * @returns {function(object):string} - Takes the resource slice and returns the id.
   * @private
   */
  _makeGetId(id) {
    if (typeof id === 'function') {
      return resourceSlice => id(resourceSlice);
    }
    else {
      return () => id;
    }
  }
}


/**
 * Given Redux status, return a representative string.
 *
 * @param {ResourceStatus} status
 * @returns {String}
 */
const getStatusString = status => {
  if (status.succeeded) {
    return 'succeeded';
  } else if (status.pending) {
    return 'pending';
  } else if (status.failed) {
    return 'failed';
  } else {
    return 'idle';
  }
};

/**
 * Given Redux status, return unique number that matches the status. Use this instead of
 * getStatusString when the actual value doesn't matter, because it's more performant.
 *
 * @param {ResourceStatus} status
 * @returns {int}
 */
const getStatusId = status =>
  status.succeeded << 0 | status.failed << 1 | status.pending << 2 | status.idle << 3;

/**
 * Return true if two statuses are equal, ignoring errors.
 *
 * @param {ResourceStatus} s1
 * @param {ResourceStatus} s2
 * @returns {Boolean}
 */
export const statusEqual = (s1, s2) => getStatusId(s1) === getStatusId(s2);
