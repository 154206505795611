import { getRailsEnv } from 'app_utils';
import { storage } from 'utils/storage';

export const EARN_THIS_BADGE_TEST = "earn_this_badge";

/**
 * Simple cookie-based A/B testing framework, using Google Analytics.
 * See helpers/a_b_tests_helper.rb for the Rails side.
 *
 * This is currently a very limited framework, due to the use of an environment variable to
 * determine tests. If we find ourselves doing more A/B testing, it would be worth creating more
 * sophisticated conditions such as test start+end date,
 *
 * Since the user's slice is randomized every Wednesday, data from Wedesday will be unreliable
 * (already true, due to the release cycle), and slices may change in the middle of a multiple-
 * week test. This can be improved somewhat by expiring the cookie based on HEROKU_SLUG_COMMIT,
 * rather than always expiring at 3:30pm on Wednesday.
 *
 * To add a test, modify the AB_TESTS environment variable, which is of the form:
 * AB_TESTS="test1:1-50;test2:51-100"
 *
 * To check whether a test is active on the current slice:
 * ABTest.enabled('test1');
 *
 * Analyzing A/B test results:
 * In your Google Analytics results, create a custom segment:
 *   New Segment ->
 *   Advanced -> Conditions
 *   Filter by: [Slice] [contains] [test name]
 * Compare this against the control segment: [Slice] [does not match regex] [slice name|error]
 */
export class ABTest {
  static initialize() {
    const tests = window.initialAppValues.abTests;
    // Add tests overrides, set by devtools.jsx.
    (storage.getItem('ab_force_tests') || '').split(/;/).forEach(t => tests[t] = 1);

    if (!window.ga && getRailsEnv() === 'development') {
      console.log('Active A/B tests:', Object.keys(tests).join(';'));
    }
  }

  /**
   * Is this A/B test enabled?
   *
   * @param {String} name - The name of an A/B test
   * @returns {Boolean}
   */
  static enabled(name) {
    return !!window.initialAppValues.abTests[name];
  }
}
