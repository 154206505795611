import { lazyWithRetry } from 'app_utils/lazy_load';

export const UserLayout = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-user" */ './user_layout')
    .then(module => ({default: module.UserLayout}))
);

export const TranscriptDownloadLayout = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-user" */ './transcript_download_layout')
    .then(module => ({default: module.TranscriptDownloadLayout}))
);

TranscriptDownloadLayout.matches = ['/users/:user_vanity_slug/transcripts/:id'];
