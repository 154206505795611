/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CookieLinks } from './cookie_links';


export const FooterLinks = ({ children, appLinks }) => {
  return (
    <div className="grid">
      <section className="l1-0 r1-0 footer-margin">
        <div className="links">
          {children}
        </div>
        <CookieLinks />
        {appLinks}
      </section>
    </div>
  );
};

FooterLinks.propTypes = {
  appLinks: PropTypes.node
};
