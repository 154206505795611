import React from 'react';
import PropTypes from 'prop-types';
import { PaginationMetadata } from 'utils/prop_types';

import './pagination.sass';

const defaultPropTypes = {
  ...PaginationMetadata,
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['compact'])
};

/**
 * Renders pagination navigation links when applicable
 *
 * @param {object} props
 *   @param {PaginationMetadata} props.data - pagination metadata
 *   @param {function} [props.className] - CSS class name for outer element
 * @returns {JSX.Element}
 */
export const PaginationProgressIndicator = (props) => {
  const data = props.data;
  if (!data || !data.current_page || data.total_count === 0) return null;

  const pageStart = data.current_page * data.per - (data.per - 1);
  const pageEnd = pageStart + data.count - 1;
  const pageTotal = data.total_count;

  const renderText = () => {
    if (props.appearance === 'compact') {
      return `${pageStart}-${pageEnd} of ${pageTotal}`;
    } else {
      return `Showing ${pageStart} to ${pageEnd} of ${pageTotal}`;
    }
  };

  return (
    <div className={props.className}>
      {renderText()}
    </div>
  );
};

PaginationProgressIndicator.propTypes = defaultPropTypes;
