import React from "react";
import PropTypes from "prop-types";
import { titleFromSnakeCase } from "utils/string";

export const JobDetailsLocationWorkplacePreferencesAndComp = (props) => {
  return (
    <>
      {[props.city, props.state_or_province].filter(Boolean).join(', ')}
      {(props.city || props.state_or_province) ? <span>&nbsp;&bull;&nbsp;</span> : null}
      {props.workplace_preferences.map((wp) => titleFromSnakeCase(wp)).join(', ')}
      &#65372;
      {titleFromSnakeCase(props.compensation_type)}
    </>
  );
};

JobDetailsLocationWorkplacePreferencesAndComp.propTypes = {
  city: PropTypes.string,
  state_or_province: PropTypes.string,
  workplace_preferences: PropTypes.arrayOf(PropTypes.string),
  compensation_type: PropTypes.string
};
