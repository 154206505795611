import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { Heading } from 'controls/heading';
import './settings_components.sass';

export let Settings = {};

/**
 * Heading and subheading for the settings pages.
 */
Settings.Heading = props =>
  <div className="settings-heading-group">
    <Heading appearance="settings-head">{props.heading}</Heading>
    {props.children && <div>{props.children}</div>}
  </div>;

Settings.Heading.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.any
};

/**
 * Top-level section for the settings pages.
 */
Settings.Section = props => (
  <div {...props} className={makeClassName('settings-section', props.className)}/>
);

Settings.Section.propTypes = {
  className: PropTypes.string
};

/**
 * Page containing multiple sections.
 */
Settings.Page = props => (
  <div {...props} className={makeClassName('settings-page', props.className)}/>
);

Settings.Page.propTypes = {
  className: PropTypes.string
};

/**
 * A set of form fields.
 */
Settings.FieldSet = props =>
  <div {...props} className={makeClassName('settings-field-set', props.className)}/>;

Settings.Heading.displayName = 'Settings.Heading';
Settings.Section.displayName = 'Settings.Section';
Settings.Page.displayName = 'Settings.Page';
Settings.FieldSet.displayName = 'Settings.FieldSet';
