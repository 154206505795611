import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import SkipLinkTarget from 'controls/skip_link_target';
import { LoadingSpinner } from 'controls/loading_spinner';
import { NewHeadingLevel } from 'controls/heading';
import './earner_layout.sass';

export const EarnerLayout = props => {
  return (
    <Fragment>
      <SkipLinkTarget name="earner"/>
      <div className={`earner-layout ${props.container && 'container'}`}>
        <Suspense fallback={<LoadingSpinner position="below" delay={500}/>}>
          <NewHeadingLevel>
            {props.children}
          </NewHeadingLevel>
        </Suspense>
      </div>
    </Fragment>
  );
};

EarnerLayout.propTypes = {
  path: PropTypes.string,
  children: PropTypes.any,
  container: PropTypes.bool
};

EarnerLayout.defaultProps = {
  container: true
};
