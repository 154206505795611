/* eslint-disable quote-props */
/* eslint-disable camelcase */

export const translations = {
  fr: () => import(/* webpackChunkName: "i18n-fr" */ "translations/fr.json").then(m => m.default),
  en: () => import(/* webpackChunkName: "i18n-en" */ "translations/en.json").then(m => m.default),
  ko: () => import(/* webpackChunkName: "i18n-ko" */ "translations/ko.json").then(m => m.default),
  ja: () => import(/* webpackChunkName: "i18n-ja" */ "translations/ja.json").then(m => m.default),
  'fr-CA': () =>
    import(/* webpackChunkName: "i18n-fr_CA" */ "translations/fr_CA.json").then(m => m.default),
  de: () => import(/* webpackChunkName: "i18n-de" */ "translations/de.json").then(m => m.default),
  'es-ES': () =>
    import(/* webpackChunkName: "i18n-es_ES" */ "translations/es_ES.json").then(m => m.default),
  'es-US': () =>
    import(/* webpackChunkName: "i18n-es_US" */ "translations/es_US.json").then(m => m.default),
  pt: () => import(/* webpackChunkName: "i18n-pt" */ "translations/pt.json").then(m => m.default),
  'pt-BR': () =>
    import(/* webpackChunkName: "i18n-pt_BR" */ "translations/pt_BR.json").then(m => m.default),
  'zh-CN': () =>
    import(/* webpackChunkName: "i18n-zh_CN" */ "translations/zh_CN.json").then(m => m.default)
};

/**
* Finds the badge template on a badge object. This is because
* depending on context, the badge_template may be attached differently
* @param badge - badge object
* @returns {Function}
*/
function findBadgeTemplate(badge) {
  if (badge.badge_template == null) {
    return badge.get('badge_template');
  } else {
    return badge.badge_template;
  }
}

/**
 * Determines the locale for the badge given org and template settings
 * @param badge - badge object
 * @returns {Function}
 */
export function badgeLocale(badge) {
  const primaryIssuer = badge.issuer.entities.find(
    entity => entity.primary === true
  );
  let result = 'en';
  if ((primaryIssuer != null) && (primaryIssuer.entity.internationalize_badge_templates) &&
    (findBadgeTemplate(badge).translatable)) {
    if (badge.locale) {
      result = badge.locale;
    }
  }

  return result;
}

/**
 * Converts a value and scope to a key.
 * Example: intlKeyFromValue('foo bar', 'some.scope'
 * Returns: some.scope.foo_bar
 * @param value - value to translate
 * @param scope - scope of the key to search in
 * @returns {Function}
 */
export function intlKeyFromValue(value, scope) {
  if (value) {
    return scope.toLowerCase() + "." + value.replace(/\s+/g, '_').toLowerCase();
  } else {
    return scope.toLowerCase() + ".na";
  }
}

/**
 * Obtains a translation for a value by converting it to a key.
 * Example: intlMappedValue('foo bar', 'some.scope', intl, 'Default')
 * Returns: international value for key: some.scope.foo_bar
 * @param value - value to translate
 * @param scope - scope of the key to search in
 * @param intl - ReactIntl instance
 * @param defaultValue - A default value (if any)
 * @returns {Function}
 */
export function intlMappedValue(value, scope, intl, defaultValue) {
  if (!value || value.length === 0) {
    return defaultValue;
  } else {
    return intl.get(intlKeyFromValue(value, scope));
  }
}
