import React, { useCallback } from 'react';
import { jobTitleActions } from 'reference';
import { Form } from 'form';

export const JobTitleField = (props) => {
  const [jobTitleSearchState, jobTitleSearchPerform] = jobTitleActions.useAction('list');

  const jobTitleSearchAction = useCallback((query) => {
    return jobTitleSearchPerform({ query });
  }, [jobTitleSearchPerform]);

  return (
    <Form.TypeaheadTextField
      searchAction={jobTitleSearchAction}
      searchActionState={jobTitleSearchState}
      iconStyle="none"
      {...props}
    />
  );
};
