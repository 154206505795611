import { reset } from 'redux-resource-plugins';
import {Action} from 'utils/action';

const badgeApiSortMapping = {
  issued_at: 'pending_first|-issued_at',
  updated_at: 'pending_first|-updated_at',
  issuer_name: 'pending_first|issuer_name|created_at',
  name: 'pending_first|badge_templates[name]|created_at'
};

export const EARNED_BADGES_SORT_BY = Object.keys(badgeApiSortMapping);

export const fetchEarnedBadges = new Action({
  resourceType: 'earnedBadges',
  requestKey: 'fetchEarnedBadges',
  url: '/api/v1/users/self/badges',
  list: 'dashboard',
  defaultParams: { page_size: 48, state: 'accepted,pending' },
  effect: 'read',
  method: 'GET'
});

// Wrap the generated action to translate the sort parameter into a value that the API will
// understand
const fetchEarnedBadgesInnerAction = fetchEarnedBadges.action;

fetchEarnedBadges.action = ({ sort: friendlySort, ...restParams }) => {
  const sort = badgeApiSortMapping[friendlySort];
  return fetchEarnedBadgesInnerAction({ sort, ...restParams });
};

// Wrap the generated getResources selector in one that will always select the appropriate list
const fetchEarnedBadgesInnerGetResources = fetchEarnedBadges.getResources;

fetchEarnedBadges.getResources = (state) => {
  return fetchEarnedBadgesInnerGetResources(state, 'dashboard');
};

export const resetEarnedBadges = () => dispatch => {
  return dispatch(reset.resetResource('earnedBadges', { list: 'dashboard' }))
};
