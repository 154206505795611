import React from 'react';
import { useShallowEquals } from 'utils/react_utils';

/**
 * Observer for a set of data. The class keeps track of the set and reference counters thereto,
 * and notifies subscribers whenever an item is added or removed.
 */
export class DataSetObserver {
  constructor() {
    this.m_data = new Map();
    this.m_observers = new Set();
  }

  /**
   * Add a new item to the set.
   *
   * @param {*} item
   */
  add(item) {
    this.m_data.set(item, (this.m_data.get(item) || 0) + 1);
    if (this.m_data.get(item) === 1) {
      this._notify();
    }
  }

  /**
   * Remove an item from the set.
   *
   * @param {*} item
   */
  remove(item) {
    if (this.has(item)) {
      const count = this.m_data.get(item);
      if (count === 1) {
        this.m_data.delete(item);
        this._notify();
      } else {
        this.m_data.set(item, count - 1);
      }
    }
  }

  /**
   * Is the given item being observed?
   *
   * @param {*} item
   */
  has(item) {
    return this.m_data.has(item);
  }

  /**
   * Subscribe to all changes in the set.
   *
   * @param {function(Iterator)} fn - Called with the set of data, whenever the set changes.
   */
  subscribe(fn) {
    this.m_observers.add(fn);
  }

  /**
   * Unsubscribe to all changes in the set.
   *
   * @param {function(Iterator)} fn - The same function pointer passed to subscribe().
   */
  unsubscribe(fn) {
    this.m_observers.delete(fn);
  }

  /**
   * React state hook, which returns a memoized set of observed data. The return value can
   * reliably be used in hook dependencies to determine whether something changed.
   *
   * @returns {Array}
   */
  use() {
    const [data, setData] = React.useState([...this.m_data.keys()]);
    React.useEffect(() => {
      const setter = iterator => setData([...iterator]);
      this.subscribe(setter);
      return () => this.unsubscribe(setter);
    }, []);
    return useShallowEquals(data);
  }

  /**
   * Completely reset this observer. Use with care.
   */
  reset() {
    this.m_data = new Map();
    this.m_observers = new Set();
  }

  /**
   * Notify all subscribers of a change.
   *
   * @private
   */
  _notify() {
    const items = this.m_data.keys();
    this.m_observers.forEach(o => o(items));
  }
}
