import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';
import { actionTypes } from 'redux-resource';
import { verifyBlockcert } from './blockcert_verifier';

export const blockcertActions = new ActionManager('publicBlockcerts')
  .add({
    requestKey: 'get',
    url: '/badges/[id]/blockcerts/[blockcert_id]/export',
    urlSubs: new stringUtils.BracketSubs({
      blockcert_id: { param: 'blockcert_id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    alwaysRefresh: true,
    transform: (data) => {
      // pretend that the blockert has the badge ID as its ID, and wrap the original blockcert
      // data in another object, so that we can get at the unadulterated data for verification
      const blockcert = data[0];
      const id = blockcert.id.replace(/^.*badge_assertions\/([0-9a-f-]+)$/, '$1');
      return [{ blockcert, id, verification: {} }];
    }
  }, true);

export const blockcertActionsVerify = (blockcert) => (dispatch) => {
  const updateBlockcert = (verification) => {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: { publicBlockcerts: [{ ...blockcert, verification }] }
    });
  };

  // Initialize verification to empty
  let verification = { steps: {}, finalStatus: {} };
  updateBlockcert(verification);

  const progressFn = ({ code, status, errorMessage }) => {
    verification = {
      finalStatus: verification.finalStatus,
      steps: { ...verification.steps, [code]: { status, errorMessage } }
    };
    updateBlockcert(verification);
  };

  const doneFn = ({ code, status, errorMessage }) => {
    verification = { ...verification, finalStatus: { code, status, errorMessage } };
    updateBlockcert(verification);
  };

  const failFn = (e) => {
    verification = {
      ...verification,
      finalStatus: { code: 'final', status: 'failure', errorMessage: e.toString() }
    };
    updateBlockcert(verification);
  };

  verifyBlockcert(blockcert.blockcert, progressFn, doneFn, failFn);
};
