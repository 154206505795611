import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { uuidPropType } from 'prop_types';
import { isEmpty } from 'utils';
import { QueryString } from 'utils/query_string';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { LoadingSpinner } from 'controls/loading_spinner';
import { savedDirectorySearchActionManager } from 'pages/public/organization/earner_directory/saved_directory_searches/actions';

/**
 * Layout component that handles multiple behaviors when navigating to
 * viewing a saved directory search including:
 *  - Fetching data for the search
 *  - Pinging the api for updating when the search was last viewed
 *  - Populating the query string with the saved search filters
 * @param {Object} props
 *   @param {String} props.id - The id of the saved directory search to view.
 */
export const SavedDirectorySearchResourceLoader = props => {
  const [savedDirectorySearchState] = savedDirectorySearchActionManager.get(
    'get', props.id
  ).useOnMount();
  useRaiseOnFailedRequest(savedDirectorySearchState.status, props.id);
  const [_s, performUpdateSavedDirectorySearch] = savedDirectorySearchActionManager.get(
    'update_last_viewed', props.id
  ).useAction();
  useEffect(() => {
    performUpdateSavedDirectorySearch();
  }, [props.id]);
  const history = useHistory();
  useEffect(() => {
    if (!isEmpty(savedDirectorySearchState.resources)) {
      const savedDirectorySearch = savedDirectorySearchState.resources;
      const qs = new QueryString(savedDirectorySearch.search_params);
      history.replace({ search: qs.toString() });
    }
  }, [savedDirectorySearchState.resources]);
  if (!savedDirectorySearchState.resources || savedDirectorySearchState.status.pending) {
    return <LoadingSpinner position="center" delay={300}/>;
  } else {
    return props.render(savedDirectorySearchState);
  }
};

SavedDirectorySearchResourceLoader.propTypes = {
  id: uuidPropType
};
