import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TrackStatPropType } from 'utils/prop_types';
import { TrackLink, TrackOnClick } from 'app_utils/tracking';
import * as objUtils from 'utils/object';

const DISPATCH_PREFIX = 'local:dispatch/';

/**
 * Allows callers to render links that perform different types of actions, without having to be
 * aware of which action is required.  This is useful in cases where the actions are being produced
 * by an API or received as props from a parent.
 *
 * @property {string} action - indicates the action to be performed when the link is activated; if
 * an absolute HTTP/S URL is provided, it will be a standard HTML link, if a relative URL is
 * provided, it will be a React Router Link (push-state), and if it has the form:
 * local:dispatch/<action_type> it will be dispatched as an action to the Redux store
 * @property {string} [className] - optional class to give to the element
 * @property {Function} [onClick] - if present, will be called when the element is clicked; if the
 *   SmartLink's action is an absolute URL, the default behavior is neither prevented nor delayed,
 *   so this should be a brief, synchronous function
 * @property {object} trackingParams - optional hash of data to sent to acclaim_stats_app
 * @property {*} children - content for the link
 */
class SmartLink extends PureComponent {
  isAbsoluteUrl () {
    return this.props.action.match(/^(https?|mailto):/);
  }

  dispatchAction = (evt) => {
    this.onClick();
    evt.preventDefault();
    const actionType = this.props.action.substring(DISPATCH_PREFIX.length);
    return this.props.dispatch({ type: actionType });
  };

  isDispatch () {
    return this.props.action.indexOf(DISPATCH_PREFIX) === 0;
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render () {
    const children = this.props.children;
    const track = this.props.trackingParams;
    const commonProps = {
      className: this.props.className,
      id: this.props.id,
      tabIndex: this.props.tabIndex
    };
    if (this.props.title) {
      commonProps.title = this.props.title;
    }
    if (this.props.target && this.isAbsoluteUrl()) {
      commonProps.target = this.props.target;
      commonProps.rel = 'noopener,noreferrer';
    }
    let smartLinkJSX = null;

    // deal with <a> tag cases
    if (this.isDispatch()) {
      smartLinkJSX = (
        <a
          {...commonProps}
          ref={this.props.innerRef}
          href="#"
          onClick={this.dispatchAction}
        >
          {children}
        </a>
      );
    } else if (this.isAbsoluteUrl()) {
      smartLinkJSX = (
        <a
          {...commonProps}
          ref={this.props.innerRef}
          href={this.props.action}
          onClick={this.onClick}
        >
          {children}
        </a>
      );
    }

    // deal with tracking cases
    if (track) {
      if (smartLinkJSX) {
        return (<TrackOnClick track={track}>{smartLinkJSX}</TrackOnClick>);
      } else {
        return (
          <TrackLink
            {...commonProps}
            innerRef={this.props.innerRef}
            track={track}
            to={this.props.action}
            onClick={this.onClick}
          >
            {children}
          </TrackLink>
        );
      }
    }

    // fallback if neither <a> tag nor tracked link
    if (!smartLinkJSX) {
      let linkInnerRef;
      if (this.props.innerRef) {
        linkInnerRef = (node) => { this.props.innerRef.current = node; };
      }
      smartLinkJSX = (
        <Link
          {...commonProps}
          innerRef={linkInnerRef}
          to={this.props.action}
          onClick={this.onClick}
        >
          {children}
        </Link>
      );
    }

    return smartLinkJSX;
  }
}

SmartLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  trackingParams: TrackStatPropType,
  dispatch: PropTypes.func,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  tabIndex: PropTypes.number
};

SmartLink.defaultProps = {
  tabIndex: 0
};

const ConnectedSmartLink = connect()(SmartLink);
const ConnectedSmartLinkWithForwardRef = React.forwardRef((props, ref) => {
  return <ConnectedSmartLink {...props} innerRef={ref}/>;
});

ConnectedSmartLinkWithForwardRef.displayName = 'SmartLink';

ConnectedSmartLinkWithForwardRef.propTypes = {
  ...objUtils.except(SmartLink.propTypes, ['innerRef'])
};

export { ConnectedSmartLinkWithForwardRef as SmartLink };

export const testing = { SmartLink };
