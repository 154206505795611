import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { makeClassName, Routes } from 'utils';
import { GridItem } from 'controls/grid_item';
import { ExpiredBadgeLabel, Medium as MediumBadge, isExpired } from 'badges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { defineMessages } from '@formatjs/intl';
import { injectIntl } from 'react-intl';
import { buildBadgeAltText } from 'badges/badge_utils';

import './earned_badge_grid_item.sass';

export class EarnedBadgeGridItem extends PureComponent {
  className() {
    return makeClassName([
      this.props.className,
      'cr-earned-badge-grid-item',
      this.isPending() && 'cr-earned-badge-grid-item--pending'
    ]);
  }

  isPending() {
    return this.props.state === 'pending';
  }

  isPrivate() {
    return !this.props.public;
  }

  render() {
    const props = this.props;
    let title = props.name;
    const intl = this.props.intl;
    let iconToDisplay;
    let iconClassName;
    let shouldShowExpired = false;
    const badgeAltText = buildBadgeAltText(
      intl,
      { name: props.name, type_category: props.type_category, level: props.level },
      { name: props.issuer_name }
    );

    const messages = defineMessages({
      pending: {
        id: 'dashboard.pending',
        defaultMessage: 'Pending: '
      },
      private: {
        id: 'dashboard.private',
        defaultMessage: 'Private: '
      },
      expired: {
        id: 'dashboard.expired',
        defaultMessage: 'Expired: '
      }
    });
    if (this.isPending()) {
      iconToDisplay = faBell;
      iconClassName = "fa-bell";
      title = intl.formatMessage(messages.pending) + props.name;
    } else if (this.isPrivate()) {
      iconToDisplay = faEyeSlash;
      iconClassName = "fa-eye-slash";
      title = intl.formatMessage(messages.private) + props.name;
    } else if (isExpired(props.expires_at)) {
      shouldShowExpired = true;
      title = intl.formatMessage(messages.expired) + props.name;
    }
    return (
      <GridItem
        title={title}
        action={Routes.earnedBadgeUrl(props.id)}
        className={this.className()}
      >
        {iconToDisplay && (<FontAwesomeIcon className={iconClassName} icon={iconToDisplay} />)}
        {shouldShowExpired && (
          <ExpiredBadgeLabel
            expiresAt={props.expires_at}
            className="cr-earned-badge-grid-item__expired-label"
          />
        )}
        <div className={makeClassName(
          'cr-earned-badge-grid-item__badge-container',
          shouldShowExpired && 'cr-earned-badge-grid-item__expired'
        )}
        >
          <MediumBadge
            alt={badgeAltText}
            image_url={props.image_url}
            issuer_name={props.issuer_name}
            name={props.name}
          />
        </div>
      </GridItem>
    );
  }
}

EarnedBadgeGridItem.propTypes = {
  ...MediumBadge.propTypes,
  id: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};
// eslint-disable-next-line no-class-assign
EarnedBadgeGridItem = injectIntl(EarnedBadgeGridItem);
