import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "controls/dialog";
import { Form, useFormProps } from "form";
import { EmployerField } from 'form/complex_fields/employer_field';
import { JobTitleField } from 'form/complex_fields/job_title_field';
import { updateProfileAction } from "profile";
import { JobPreferencesForm } from "pages/earner/settings/preferences/job_preferences_form";

import "./review_profile_dialog.sass";

export const ReviewProfileDialog = props => {
  const [step, setStep] = useState("profile_info");

  let CurrentStep;
  if (step === "profile_info") {
    CurrentStep = ProfileInfoStep;
  } else if (step === "job_preferences") {
    CurrentStep = JobPreferencesStep;
  }

  return (
    <CurrentStep
      setStep={setStep}
      shouldClose={props.shouldClose}
      size={props.size}
      onSuccess={props.onSuccess}
    />
  );
};

ReviewProfileDialog.propTypes = {
  size: PropTypes.string,
  shouldClose: PropTypes.func,
  onSuccess: PropTypes.func
};

const ProfileInfoStep = props => {
  const [formProps] = useFormProps(updateProfileAction);

  const interceptSubmit = vals => {
    const formVals = { ...vals };
    formVals.career_growth = {
      looking_within_company: vals["career_growth[looking_within_company]"],
      looking_for_new_jobs: vals["career_growth[looking_for_new_jobs]"],
      looking_for_new_skills: vals["career_growth[looking_for_new_skills]"]
    };
    delete formVals["career_growth[looking_within_company]"];
    delete formVals["career_growth[looking_for_new_jobs]"];
    delete formVals["career_growth[looking_for_new_skills]"];
    formProps.submit(formVals);
  };

  const onSubmitSuccess = (vals) => {
    const shouldGoToJobPreferences = vals["career_growth[looking_for_new_jobs]"];
    if (shouldGoToJobPreferences) {
      props.setStep("job_preferences");
    } else {
      props.onSuccess("cta-completed-without-job-preferences");
    }
  };

  const editProfileSuccessAlert = {
    type: "notice",
    text: "Your profile was edited successfully."
  };

  const editProfileFailureAlert = {
    type: "error",
    head: "We're sorry.",
    text: "There was an error editing your profile."
  };

  return (
    <Dialog
      title="Profile Info"
      show
      shouldClose={props.shouldClose}
      size={props.size || "half"}
    >
      <Dialog.Content>
        <Form
          className="review-profile-dialog__profile-form"
          {...formProps}
          submitSuccessAlert={editProfileSuccessAlert}
          submitFailureAlert={editProfileFailureAlert}
          onSubmitSuccess={onSubmitSuccess}
          submit={interceptSubmit}
        >
          {({ propsForFieldGroup, isSubmitting, submit }) => (
            <Fragment>
              <p>
                Want to see the most relevant credentials and opportunities?
                Tell us more about where you're at in your career.
              </p>
              <div className="review-profile-dialog__profile-form__half-fields">
                <Form.TextField
                  className="review-profile-dialog__profile-form-field review-profile-dialog__profile-form__half-field"
                  enclosed
                  required
                  label="First Name"
                  maxLength="255"
                  {...propsForFieldGroup("first_name")}
                />
                <Form.TextField
                  className="review-profile-dialog__profile-form-field review-profile-dialog__profile-form__half-field"
                  enclosed
                  required
                  label="Last Name"
                  maxLength="255"
                  {...propsForFieldGroup("last_name")}
                />
              </div>
              <EmployerField
                className="review-profile-dialog__profile-form-field"
                enclosed
                label="Employer"
                maxLength="255"
                {...propsForFieldGroup("current_organization_name")}
              />
              <JobTitleField
                className="review-profile-dialog__profile-form-field"
                enclosed
                label="Job Title"
                maxLength="255"
                {...propsForFieldGroup("current_position_name")}
              />
              <p className="review-profile-dialog__profile-form-header">
                Career Growth
              </p>
              <Form.Checkbox
                className="review-profile-dialog__profile-form-field"
                {...propsForFieldGroup('career_growth[looking_within_company]')}
                label="I am looking for a new role at my organization."
              />
              <Form.Checkbox
                className="review-profile-dialog__profile-form-field"
                {...propsForFieldGroup('career_growth[looking_for_new_jobs]')}
                label="I am interested in being contacted about job opportunities."
              />
              <Form.Checkbox
                className="review-profile-dialog__profile-form-field"
                {...propsForFieldGroup('career_growth[looking_for_new_skills]')}
                label="I am looking for new skills and credentials in general."
              />

              <Dialog.Footer>
                <Dialog.Action
                  noClose
                  action={submit}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Update Profile
                </Dialog.Action>
              </Dialog.Footer>
            </Fragment>
          )}
        </Form>
      </Dialog.Content>
    </Dialog>
  );
};

ProfileInfoStep.propTypes = {
  size: PropTypes.string,
  shouldClose: PropTypes.func,
  onSuccess: PropTypes.func,
  setStep: PropTypes.func
};

const JobPreferencesStep = props => {
  const renderSubmit = (onSubmit, isSubmitting, isDisabled) => {
    return (
      <Dialog.Footer>
        <Dialog.Action
          noClose
          action={onSubmit}
          loading={isSubmitting}
          disabled={isDisabled}
        >
          Save Job Preferences
        </Dialog.Action>
      </Dialog.Footer>
    );
  };

  const onSubmitSuccess = () => {
    props.onSuccess("cta-completed-with-job-preferences");
  };

  const submitSuccessAlert = {
    type: 'notice',
    text: 'Nice work! Your preferences have been saved.'
  };

  return (
    <Dialog
      title="Job Preferences"
      show
      shouldClose={props.shouldClose}
      size={props.size || "half"}
    >
      <Dialog.Content>
        <p>
          Credly works with many of the world's leading recruiters and employers.
          Fill out this information to tell them a little bit more about what you
          are looking for and they may contact you.
        </p>
        <JobPreferencesForm
          showResumeSection={false}
          submitSuccessAlert={submitSuccessAlert}
          onSubmitSuccess={onSubmitSuccess}
          renderSubmit={renderSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
};

JobPreferencesStep.propTypes = {
  size: PropTypes.string,
  shouldClose: PropTypes.func,
  onSuccess: PropTypes.func
};
