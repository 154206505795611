import { ActionManager } from 'utils/action_manager';

export const canonicalSkillsActions = new ActionManager('userSkills')
  .add({
    requestKey: 'fetch',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/skills/canonical_skills',
    list: 'list',
    alwaysRefresh: true
  }, false);
