import React from 'react';
import * as objUtils from 'utils/object';

/**
 * React hook to create <Form> properties that don't depend on Redux. This is private to the
 * form component directory. Externally, useFormProps or <LocalForm> should be used.
 *
 * @param {Object} formProps - Properties to pass to <Form>.
 *   @param {function(values: object)} formProps.submit - Called when the form is submitted, with
 *     the form fields as key/value pairs. If this returns false, submit is assumed to have failed.
 * @returns {Object} Properties that <Form> understands.
 */
export const useLocalForm = formProps => {
  const [status, setStatus] = React.useState(makeStatus({idle: true}));
  const [errors, setErrors] = React.useState({});

  /**
   * Submit the form. Unlike with `Form`, this will only work if props.submit is explicitly set.
   *
   * @param {object<string, *>} values
   */
  const submit = React.useCallback(values => {
    let success;
    try {
      if (formProps.submit) {
        const submitResult = formProps.submit(values);
        if (submitResult === undefined || submitResult !== false) {
          success = true;
        }
      } else {
        // This is a developer error. A form should not be submitted without a submit function.
        success = false;
      }
    } catch (error) {
      success = false;
    }

    if (success === true) {
      setStatus(makeStatus({succeeded: true}));
      setErrors({});
    } else if (success === false) {
      setStatus(makeStatus({failed: true}));
      setErrors({base: ['An error occurred']});
    }
  }, []);

  /**
   * Add errors to the state.
   *
   * @param {object<string, array>} [newErrors]
   */
  const addClientErrors = React.useCallback(newErrors => {
    setStatus(makeStatus({failed: true}));
    setErrors({...errors, ...newErrors});
  }, [errors]);

  /**
   * Clear some or all errors.
   *
   * @param {Array} [fieldNames] - Field names to clear, or omit to clear all errors.
   */
  const clearClientErrors = React.useCallback((fieldNames = null) => {
    setErrors(fieldNames ? objUtils.except(errors, fieldNames) : {});
  }, [errors]);

  return {
    ...formProps,
    submit,
    addClientErrors,
    clearClientErrors,
    submitStatus: status,
    errors: errors
  };
};


/**
 * Create redux-compatible status.
 *
 * @param {object} statusOverride - An object containing a single redux status param to override.
 * @returns {ResourceStatus}
 * @private
 */
const makeStatus = (statusOverride) => ({
  idle: false,
  pending: false,
  succeeded: false,
  failed: false,
  ...statusOverride
});
