import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { makeClassName } from 'utils';

import './homepage_stat_tile.sass';

export const HomepageStatTile = props => {
  return (
    <div className={makeClassName('homepage-stat-tile', props.className)}>
      <Heading appearance="custom" className="homepage-stat-tile__block">
        {props.header}
      </Heading>
      <div className="homepage-stat-tile__body-content-row">
        {props.body}
      </div>
    </div>
  );
};

HomepageStatTile.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired
};
