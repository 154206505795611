import { LocalAction } from 'utils/local_action';

export const permissionsRequests = new LocalAction({
  resourceType: 'permissionsRequests',
  requestKey: 'all',
  method: 'GET',
  effect: 'read',
  list: 'all'
});

export const updatePermissions = new LocalAction({
  resourceType: 'permissionsRequests',
  requestKey: 'all',
  method: 'UPDATE',
  effect: 'update',
  list: 'all'
});

export const deletePermissions = new LocalAction({
  resourceType: 'permissionsRequests',
  requestKey: 'all',
  method: 'DELETE',
  effect: 'delete',
  list: 'all'
});
