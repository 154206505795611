import { useMutation } from '@tanstack/react-query';
import {
  createJobPostBookmark,
  deleteJobPostBookmark
} from "../api/job_post_bookmarks";

export const useSaveJobPostBookmarkMutation = (jobId, mutationCallbacks = {}) => {
  return useMutation(() => createJobPostBookmark(jobId), mutationCallbacks);
};

export const useDeleteJobPostBookmarkMutation = (jobId, mutationCallbacks = {}) => {
  return useMutation(() => deleteJobPostBookmark(jobId), mutationCallbacks);
};
