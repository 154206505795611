import React from 'react';

export const CookieLinks = () => {
  const cookiePolicyURL = 'https://info.credly.com/cookie-policy';
  const cookiesOnClick = (e) => {
    e.preventDefault();
    const oneTrustPresent = typeof window !== 'undefined' &&
      window.OneTrust &&
      window.OneTrust.ToggleInfoDisplay;

    // In cases where OneTrust is present, we want to show their modal
    // otherwise we'll default to visiting the cookie policy page.
    if (oneTrustPresent) {
      window.OneTrust.ToggleInfoDisplay();
    } else {
      window.open(cookiePolicyURL, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="cookie-links">
      <div className="cookie-link-wrapper">
        <a className="cookie-link" href="#" onClick={cookiesOnClick}>
          Cookies
        </a>
      </div>
      <div className="cookie-link-wrapper">
        <a className="cookie-link" href={cookiePolicyURL} target="_blank" rel="noopener noreferrer">
          Do Not Sell My Personal Information
        </a>
      </div>
    </div>
  );
};

CookieLinks.propTypes = {};
