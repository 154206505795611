import React from 'react';
import PropTypes from 'prop-types';
import { SelectorMenu } from 'controls/selector_menu';
import { FormattedMessage } from "react-intl";

/**
 * Called when an item is selected from SelectorMenu
 *
 * @callback SortSelectorOnSort
 * @param {string} sortValue
 */

/**
 * Thin shell around SelectorMenu. This only exists to avoid too much refactoring. It should be
 * removed at some point, by replacing all references to SortSelector with SelectorMenu.
 */
export const SortSelector = props =>
  <SelectorMenu
    label={
      <FormattedMessage
        id="dashboard.view.sort_by"
        defaultMessage="Sort by:"
      />
    }
    onSelect={props.onSort}
    selectedValue={props.selectedSortValue}
    options={props.sortValues.map(opt => ({ value: opt.sortValue, text: opt.displayValue }))}
  />;

export default SortSelector;

SortSelector.propTypes = {
  onSort: PropTypes.func.isRequired,
  selectedSortValue: PropTypes.string.isRequired,
  sortValues: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.node.isRequired,
      sortValue: PropTypes.string.isRequired
    })
  )
};
