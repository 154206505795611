// Force some common files to be in the application roll-up (as opposed to duplicated in every
// chunk that needs it), because webpack sometimes makes poor life choices.
import 'controls/heading';
import 'utils/action';
import 'utils/action_manager';
import 'utils/string';
import 'utils/url';
import 'utils/query_string';
import 'utils/modern_polyfills';
import 'utils/tracking';
import 'controls/button';
import 'controls/dialog';
import 'controls/collapsible_section';
