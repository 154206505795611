import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { PaginationMetadata } from 'utils/prop_types';
import { makeClassName } from 'utils';
import Icon from 'controls/icon';
import { PaginationPages } from './page_numbers';
import { usePaginatedUrl } from './use_paginated_url';

import './pagination.sass';

/**
 * Renders pagination navigation links when applicable
 *
 * @param {Object} props
 *   @param {PaginationMetadata} props.data - pagination metadata
 *   @param {String} [props.className] - a CSS class to apply to the top-level element
 *   @param {Boolean} [props.pageNumbers] - if true, renders page number links
 *   @param {Boolean} [props.centered] - if components should be centered or floated to edges of the container
 *   @param {Boolean} [props.compact] - removes the next and previous text
 * @returns {JSX.Element}
 */
export const PaginationNavControls = (props) => {
  const className = makeClassName(
    props.className,
    props.centered && `${props.className}--centered`
  );
  return (
    <nav className={className} role="navigation" aria-label="Pagination">
      <PaginationNavControlBack data={props.data} compact={props.compact} />
      {props.pageNumbers && <PaginationPages data={props.data} />}
      <PaginationNavControlForward data={props.data} compact={props.compact} />
    </nav>
  );
};

PaginationNavControls.propTypes = {
  centered: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.shape(PaginationMetadata),
  pageNumbers: PropTypes.bool,
  compact: PropTypes.bool
};

PaginationNavControls.defaultProps = {
  centered: false,
  className: "pagination-nav-controls",
  pageNumbers: false,
  compact: false
};

/**
 * @param {Object} props
 *   @param {PaginationMetadata} props.data - pagination metadata
 * @returns {JSX.Element}
 */
export const PaginationNavControlBack = (props) => {
  const { data } = props;
  const paginatedUrl = usePaginatedUrl();
  return (
    data.current_page <= 1 ? <div /> : (
      <Link
        to={paginatedUrl(data.current_page - 1)}
        className="pagination__prev"
        aria-label="previous page"
      >
        <Icon
          imageClassName="pagination__prev__left-arrow"
          image={faArrowLeft}
        />
        {!props.compact &&
          <FormattedMessage
            id="button.previous.short"
            defaultMessage="Prev"
          />
        }
      </Link>
    )
  );
};

PaginationNavControlBack.propTypes = {
  data: PropTypes.shape(PaginationMetadata),
  compact: PropTypes.bool
};

/**
 * @param {Object} props
 *   @param {PaginationMetadata} props.data - pagination metadata
 * @returns {JSX.Element}
 */
export const PaginationNavControlForward = (props) => {
  const { data } = props;
  const paginatedUrl = usePaginatedUrl();
  return (
    data.current_page >= data.total_pages ? <div /> : (
      <Link
        to={paginatedUrl(data.current_page + 1)}
        className="pagination__next"
        aria-label="next page"
      >
        {!props.compact &&
          <FormattedMessage
            id="button.next.short"
            defaultMessage="Next"
          />
        }
        <Icon
          imageClassName="pagination__next__right-arrow"
          image={faArrowRight}
        />
      </Link>
    )
  );
};

PaginationNavControlForward.propTypes = {
  data: PropTypes.shape(PaginationMetadata),
  compact: PropTypes.bool
};
