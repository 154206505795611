import React from "react";
import PropTypes from "prop-types";
import { Button } from "controls/button";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';

import "./bookmark_job.sass";

export const BookmarkJob = (props) => {
  return (
    <Button
      className="save-job"
      type="secondary"
      onClick={() => props.submitBookmark()}
    >
      {props.bookmarkedJob ? (
        <>
          <FormattedMessage
            id="talent_match.job_board.saved_job"
            defaultMessage="Saved Job"
          />
          <FontAwesomeIcon className="bookmarked-job-post" icon={faBookmark} />
        </>
      ) : (
        <FormattedMessage
          id="talent_match.job_board.save_job"
          defaultMessage="Save Job"
        />
      )}
    </Button>
  );
};

BookmarkJob.propTypes = {
  bookmarkedJob: PropTypes.bool,
  submitBookmark: PropTypes.func.isRequired
};
