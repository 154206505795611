import { Action } from 'utils/action';
import { getCsrfToken } from 'utils';

const buildUr = (url, params) => {
  const baseUrl = url;
  const searchParams = new URLSearchParams();
  searchParams.set('q', params.q);
  Object.entries(params).forEach(([key, value]) => {
    if (key !== 'q' && value) {
      if (Array.isArray(value)) {
        value.forEach(item => {
          searchParams.append(`${key}[]`, item);
        });
      } else {
        searchParams.set(key, value);
      }
    }
  });
  return `${baseUrl}?${searchParams.toString()}`;
};

class FilteredBadgeSearchAction extends Action {
  /**
   * Override Action._makeXhrOptions to make a param url structure for arrays
   * example: ?q=software&type_category[]=Validation&type_category[]=Learning
   *
   * @returns {Function}
   */
  _makeXhrOptions(url, values, method = 'GET') {
    const options = {
      json: true,
      method: method,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        // Non-API requests (like /users/sign_in) sometimes require this.
        'X-Requested-With': 'XMLHttpRequest'
      }
    };

    options.url = buildUr(url, values);

    if (this.m_options.addCustomHeaders) {
      options.headers = {
        ...options.headers,
        ...this.m_options.addCustomHeaders(options.headers, values)
      };
    }

    return options;
  }
}

export { FilteredBadgeSearchAction };
