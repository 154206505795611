/* eslint-disable react/prop-types */
import React from 'react';
import { FooterLink } from './footer_link';
import { FooterLinks } from './footer_links';

import './default_site_footer.sass';

export const DefaultSiteFooter = () => {
  const appLinks = (
    <div className="app-store-links">
      <a
        className="app-store-link app-store-link-google"
        href="https://play.google.com/store/apps/details?id=com.credly.android"
      />
      <a
        className="app-store-link app-store-link-apple"
        href="https://apps.apple.com/us/app/credly/id1630234370"
      />
    </div>
  );

  return (
    <FooterLinks appLinks={appLinks}>
      <FooterLink
        linkTarget="https://resources.credly.com/schedule-a-demo"
        linkId="default_footer.request_demo"
        linkDefaultMessage="Request Demo"
      />
      <FooterLink
        linkTarget="https://info.credly.com/about-us"
        linkId="default_footer.about_credly"
        linkDefaultMessage="About Credly"
      />
      <FooterLink
        linkTarget="https://info.credly.com/legal"
        linkId="default_footer.terms"
        linkDefaultMessage="Terms"
      />
      <FooterLink
        linkTarget="https://info.credly.com/privacy-policy"
        linkId="default_footer.privacy"
        linkDefaultMessage="Privacy"
      />
      <FooterLink
        linkTarget="/docs"
        linkId="default_footer.developers"
        linkDefaultMessage="Developers"
      />
      <FooterLink
        linkTarget="/support"
        linkId="default_footer.support"
        linkDefaultMessage="Support"
      />
    </FooterLinks>
  );
};

DefaultSiteFooter.propTypes = {
};
