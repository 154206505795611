/* eslint-disable camelcase */
import { uniqueId } from 'utils';
import debounce from 'lodash.debounce';

const BREAKPOINTS = [
  { name: 'xs', min: null, max: 575.98 },
  { name: 'sm', min: 576, max: 767.98 },
  { name: 'md', min: 768, max: 991.98 },
  { name: 'lg', min: 992, max: 1199.98 },
  { name: 'xl', min: 1200, max: null }
];

const BREAKPOINT_NAMES = BREAKPOINTS.map(b => b.name);

const MIN_RESIZE_DELAY = 100;
const MAX_RESIZE_DELAY = 300;
let MATCH_BREAKPOINTS;
let _resizeHandler;
init();

const m_effects = {};
const m_lastView = {};

/**
 * Window width watchers.
 */
const watcher = {
  /**
   * Add a handler.
   *
   * @param {function(view: string)} cb - Call this when the width breakpoint changes. view is one
   *   of {mobile, tablet, desktop}.
   * @returns {*}
   */
  add: cb => {
    const id = uniqueId();
    m_effects[id] = cb;
    m_lastView[id] = null;

    if (Object.keys(m_effects).length === 1) {
      if (watcher.usingMatchMedia()) {
        for (const query of Object.values(MATCH_BREAKPOINTS)) {
          query.addListener(_resizeHandler);
        }
      } else {
        window.addEventListener('resize', _resizeHandler);
      }
    }
    _resizeHandlerInner(null, id);

    return id;
  },


  /**
   * Remove a handler.
   *
   * @param {String} id
   */
  remove: id => {
    delete m_effects[id];
    delete m_lastView[id];
    if (Object.keys(m_effects).length === 0) {
      if (watcher.usingMatchMedia()) {
        for (const query of Object.values(MATCH_BREAKPOINTS)) {
          query.removeListener(_resizeHandler);
        }
      } else {
        window.removeEventListener('resize', _resizeHandler);
      }
    }
  },


  /**
   * Return the current view.
   *
   * @returns {String} One of {xs, sm, md, lg, xl}
   */
  currentView: () => {
    // Use matchMedia, because innerWidth can cause a reflow, which is expensive on resize.
    if (watcher.usingMatchMedia()) {
      for (const breakpointName of Object.keys(MATCH_BREAKPOINTS)) {
        const query = MATCH_BREAKPOINTS[breakpointName];
        if (query.matches) {
          return breakpointName;
        }
      }
    }

    // Older browsers
    const w = window.innerWidth;
    for (const breakpoint of BREAKPOINTS) {
      if ((!breakpoint.min || w >= breakpoint.min) && (!breakpoint.max || w <= breakpoint.max)) {
        return breakpoint.name;
      }
    }
  },


  /**
   * Count the number of watchers.
   *
   * @returns {int}
   */
  length: () => Object.keys(m_effects).length,


  /**
   * Are we using the matchMedia API?
   *
   * @returns {boolean}
   */
  usingMatchMedia: () => !!MATCH_BREAKPOINTS
};



/**
 * Notify watchers on width breakpoints.
 *
 * @type {Function}
 * @param {Event|MediaQueryList} e - The event's data.
 * @param {String=null} id - If specified, only check this one id.
 * @private
 * @function
 */
function _resizeHandlerInner(e, id) {
  const newView = watcher.currentView();
  const ids = id ? [id] : Object.keys(m_effects);
  ids.filter(id => m_lastView[id] !== newView).forEach(id => {
    m_lastView[id] = newView;
    m_effects[id] && m_effects[id](newView);
  });
}


export default watcher;

// For testing.
export {
  BREAKPOINTS,
  BREAKPOINT_NAMES,
  MIN_RESIZE_DELAY
};

function init() {
  const matchMediaSupported = window.matchMedia && matchMedia('(all)').addListener;
  if (matchMediaSupported) {
    MATCH_BREAKPOINTS = {};
    for (const breakpoint of BREAKPOINTS) {
      const match = [];
      if (breakpoint.min) {
        match.push(`(min-width: ${breakpoint.min}px)`);
      }
      if (breakpoint.max) {
        match.push(`(max-width: ${breakpoint.max}px)`);
      }
      MATCH_BREAKPOINTS[breakpoint.name] = matchMedia(match.join(' and '));
    }
    _resizeHandler = _resizeHandlerInner;
  } else {
    MATCH_BREAKPOINTS = null;
    _resizeHandler = debounce((e, id = null) => {
      requestAnimationFrame(() => _resizeHandlerInner(e, id));
    }, MIN_RESIZE_DELAY, { maxWait: MAX_RESIZE_DELAY });
  }
}

// Should only be called by testing code.
export const testReinit = init;
