import React from 'react';
import PropTypes from 'prop-types';
import { combineLoadersIgnoreIdle } from 'utils/combine_loaders';
import { useEventCallback, useCallOnCondition } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { faBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faLightbulb as faLightBulbSolid } from '@fortawesome/pro-solid-svg-icons/faLightbulb';
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faBolt as faBoltSolid } from '@fortawesome/free-solid-svg-icons/faBolt';
import { employments } from 'employments/action_creators';
import { PermissionsRequest } from './permissions_request';
import { PERMISSIONS_STATES } from './permissions_utils';
import { FormattedMessage, useIntl } from 'react-intl';


const permissions = organization => ([
  {
    sectionHeader: {
      id: "accept_employment.organization.view.header",
      defaultMessage: "What will {organization} be able to view?",
      values: { organization }
    },
    sectionIconOpen: faUserSolid,
    sectionIconClosed: faUser,
    sectionOverview: {
      id: "accept_employment.organization.view.overview",
      defaultMessage: "Profile Data, Badge Data, Skill Interests"
    },
    sectionItems: [
      {
        id: "accept_employment.organization.view.section1",
        defaultMessage: "Your profile information."
      },
      {
        id: "accept_employment.organization.view.section2",
        defaultMessage: "Your public badges."
      },
      {
        id: "accept_employment.organization.view.section3",
        defaultMessage: "Your skill interests."
      }]
  },
  {
    sectionHeader: {
      id: "accept_employment.organization.do.header",
      defaultMessage: "What will {organization} be able to do?",
      values: { organization }
    },
    sectionIconOpen: faBoltSolid,
    sectionIconClosed: faBolt,
    sectionOverview: {
      id: "accept_employment.organization.do.overview",
      defaultMessage: "Personalize and promote your experience"
    },
    sectionItems: [
      {
        id: "accept_employment.organization.do.section1",
        defaultMessage: "Make recommendations to you."
      },
      {
        id: "accept_employment.organization.do.section2",
        defaultMessage: "Send notifications to you."
      },
      {
        id: "accept_employment.organization.do.section3",
        defaultMessage: "Showcase your achievements to other connected peers."
      },
      {
        id: "accept_employment.organization.do.section4",
        defaultMessage: "Add details to your profile visible only to you."
      }]
  },
  {
    sectionHeader: {
      id: "accept_employment.user.do.header",
      defaultMessage: "What will I be able to do?"
    },
    sectionIconOpen: faLightBulbSolid,
    sectionIconClosed: faLightbulb,
    sectionOverview: {
      id: "accept_employment.user.do.overview",
      defaultMessage: "Discover, engage, and grow with your organization's learning ecosystem"
    },
    sectionItems: [
      {
        id: "accept_employment.user.do.section1",
        defaultMessage: "Receive personalized skill and badge recommendations."
      },
      {
        id: "accept_employment.user.do.section2",
        defaultMessage: "Dive into the learning activities within your organization."
      },
      {
        id: "accept_employment.user.do.section3",
        defaultMessage: "View learning milestones and profiles of other connected peers."
      },
      {
        id: "accept_employment.user.do.section4",
        defaultMessage: "Celebrate achievements of other connected peers."
      }]
  }
]);

/**
 * Permissions UI for accepting an employment request.
 *
 * @param {Object} props
 *   @param {String} props.id - The request id.
 *   @param {String} props.requesterName - The name of the requester.
 *   @param {String} props.requesterImageUrl - The image of the requester needed for the header.
 *   @param {function(id: string)} props.onComplete - Called when accept or reject is complete.
 */
export const AcceptEmployment = ({ id, requesterName, requesterImageUrl, onComplete }) => {
  const intl = useIntl();
  const alerts = useAlerts();
  const [acceptAction, accept] = employments.useAction('accept', id);
  const acceptRejectStatus = combineLoadersIgnoreIdle(acceptAction.status);

  const onClickAccept = useEventCallback(accept, false, [accept]);

  useCallOnCondition(() => {
    alerts.add({
      type: 'notice',
      head: 'Request succeeded!',
      text: `You have successfully connected to ${requesterName}.`
    });
  }, () => acceptAction.status.succeeded);

  useCallOnCondition(() => {
    alerts.add({
      type: 'error',
      head: 'Request failed',
      text:
        'An unexpected error caused the connection to fail. This page will be hidden until the ' +
        'next time you sign in.'
    });
  }, () => acceptRejectStatus.failed);

  useCallOnCondition(() => {
    onComplete(PERMISSIONS_STATES.accepted);
  }, () => acceptRejectStatus.succeeded || acceptRejectStatus.failed);

  return (
    <>
      <PermissionsRequest.Header
        requesterImage={requesterImageUrl}
        requesterName={requesterName}
        heading={
          intl.formatMessage({
            id: "accept_employment.header",
            defaultMessage: "{organization} would like to connect with you"
          }, { organization: requesterName })
        }
        subHeading={
          intl.formatMessage({
            id: "accept_employment.subheading",
            defaultMessage: "Empower your professional journey by connecting, engaging, and unlocking insights within your organization."
          })
        }
      />
      <PermissionsRequest.Items requesterName={requesterName}>
        <PermissionsRequest.Items.AccordionGroup className="accept-employment--accordion-group" permissions={permissions(requesterName)} />
      </PermissionsRequest.Items>
      <PermissionsRequest.Actions
        acceptRejectStatus={acceptAction.status}
        onSkip={() => onComplete(PERMISSIONS_STATES.skipped)}
        onClickAccept={onClickAccept}
        acceptAction={acceptAction}
        text={
          intl.formatMessage({
            id: "accept_employment.footer",
            defaultMessage: "By clicking 'Connect', you're choosing to make the above data visible to everyone at {organization}, fostering a culture of shared learning and growth. This connection is under your control and can be modified at any time via your settings. You can read more about how your data is managed in the {credly_privacy_policy_link}."
          }, {
            organization: requesterName,
            credly_privacy_policy_link: (
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                <FormattedMessage
                  id="accept_employment.credly_privacy_policy_link"
                  defaultMessage="Credly privacy policy"
                />
              </a>)
          })
        }
      />
    </>);
};

AcceptEmployment.propTypes = {
  id: PropTypes.string.isRequired,
  requesterName: PropTypes.string.isRequired,
  requesterImageUrl: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
};
