import React, { useMemo } from 'react';
import { AlertImplementationProvider } from "./alert_implementation_context";
import { useDispatch } from "react-redux";
import AlertsActions from "./alerts_actions";
import PropTypes from "prop-types";

export const AlertDefaultImplementationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const defaultActions = useMemo(() =>
    AlertsActions.bind(dispatch),
  [dispatch]);

  return (
    <AlertImplementationProvider value={defaultActions}>
      {children}
    </AlertImplementationProvider>
  );
};

AlertDefaultImplementationProvider.propTypes = {
  children: PropTypes.node
};
