import PropTypes from 'prop-types';

export const BadgeIssuerPropType = PropTypes.shape({
  summary: PropTypes.string,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      primary: PropTypes.bool,
      entity: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        vanity_url: PropTypes.string
      })
    })
  )
});

export const ResourcesPropType = PropTypes.oneOfType(
  [PropTypes.object, PropTypes.arrayOf(PropTypes.object)]
);

/**
 * @typedef ResourceStatus
 * @property {boolean} failed - true if request is failed
 * @property {boolean} idle - true if request is idle
 * @property {boolean} pending - true if request is pending
 * @property {boolean} succeeded - true if request is succeeded
 */
let ResourceStatusDocOnly;

export const ResourceStatusPropType = PropTypes.shape({
  failed: PropTypes.bool,
  idle: PropTypes.bool,
  pending: PropTypes.bool,
  succeeded: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
});

export const ActionStatePropType = PropTypes.shape({
  resources: ResourcesPropType,
  status: ResourceStatusPropType.isRequired,
  requestDetails: PropTypes.object,
  metadata: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      count: PropTypes.number,
      current_page: PropTypes.number,
      next_page_url: PropTypes.string,
      per: PropTypes.number,
      previous_page_url: PropTypes.string,
      total_count: PropTypes.number,
      total_pages: PropTypes.number
    })
  ])
});

export const RouterPropTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string,
    state: PropTypes.object
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })
};

/**
 * @typedef RouterLocation
 * @property {string} search - query string of URL
 * @property {string} pathname - path portion of URL
 * @property {string} hash - hash portion of URL
 */
let RouterLocationDocOnly;

/**
 * @typedef PaginationMetadata
 * @param {number} props.current_page - current page user is on
 * @param {number} props.count - number of items on current page
 * @param {number} props.total_count - total number of items
 * @param {number} props.total_pages - total number of pages
 * @param {number} props.per - number of items per page
 * @param {string} props.previous_page_url - link to API endpoint for previous page
 * @param {string} props.previous_page_url - link to API endpoint for next page
 */
export const PaginationMetadata = {
  count: PropTypes.number,
  current_page: PropTypes.number,
  total_count: PropTypes.number,
  total_pages: PropTypes.number,
  per: PropTypes.number,
  previous_page_url: PropTypes.string,
  next_page_url: PropTypes.string
};

export const ReactRef = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) })
]);

/**
 * @typedef TrackingParams
 * @property {string} type - the type of stat that will be recorded (e.g. "badge.view")
 * @property {string} object_id - the identifier of the stat object (UUID)
 * @property {string} object_type - the type of the stat object (e.g. "Badge")
 */
let TrackingParamsDocOnly;

/**
 * PropTypes shape for (part of) the data associated with a tracking request.
 */
export const TrackStatPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  object_id: PropTypes.string.isRequired,
  object_type: PropTypes.string.isRequired
});

/**
 * PropType for a multidimensional array.
 *
 * Examples:
 *
 * Array of strings:
 * PropTypesExt.MultidimensionalArrayOf(PropTypes.string)
 *
 * Array of strings and numbers:
 * PropTypesExt.MultidimensionalArrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
 *
 * Array of shapes:
 * PropTypesExt.MultidimensionalArrayOf(PropTypes.shape({p1: PropTypes.string}))
 *
 * @param {function} type - Any PropTypes.* or PropTypesExt.* member.
 */
const MultidimensionalArrayOf = type =>
  PropTypes.oneOfType([type, PropTypes.arrayOf(function() {
    return MultidimensionalArrayOf(type).apply(this, arguments);
  })]);

/**
 * PropType to require that either this prop is present or another one. If this prop is present, the
 * underlyingPropType validation is applied to it.
 *
 * @param {function} underlyingPropType - a PropType function that can be used to validate the
 *   type of the prop, provided it is found to be present.
 * @param {string} otherPropName - the name of the other prop that could be present instead of this
 *   one
 * @returns {function}
 */
const requiredUnlessPresent = (underlyingPropType, otherPropName) => {
  return (props, propName, componentName, ...rest) => {
    if (props[propName] !== undefined && props[propName] !== null) {
      return underlyingPropType(props, propName, componentName, ...rest);
    } else if (props[otherPropName] === undefined || props[otherPropName] === null) {
      return new Error(
        `Either ${propName} or ${otherPropName} must be provided for ${componentName}`
      );
    }
  };
};

/**
 * Extended PropTypes. These can be used in the same way as types from the 'prop-types' npm.
 */
export const PropTypesExt = {
  BadgeIssuer: BadgeIssuerPropType,
  Router: RouterPropTypes,
  ResourceStatus: ResourceStatusPropType,
  MultidimensionalArrayOf: MultidimensionalArrayOf,
  ReactRef,
  requiredUnlessPresent
};
