import { ajax, waitFor } from './index';
import { trackPendoEvent } from 'utils/pendo';

/**
 * Download a url.
 *
 * @param {String} url - The url to download
 * @param {String=} filename - The name of the downloaded file. This will be ignored if the file is
 *   not same-origin.
 * @param {Element=} domContext - Sometimes, the global anchor this creates is not clickable due
 *   to other click handlers (for example, focusTrap). Use this to put the anchor in a known
 *   clickable element.
 */
export const downloadUrl = (url, filename, domContext, eventType = null) => {
  try {
    domContext = domContext || document.body;
    let elt = document.createElement('a');
    elt.href = url;
    filename && (elt.download = filename);
    elt.style.display = 'none';
    domContext.appendChild(elt);
    elt.click();
    domContext.removeChild(elt);
    if (eventType) {
      trackPendoEvent(eventType);
    };
  } catch (e) {
    console.error('downloadUrl failed:', url, filename, domContext);
    console.error(e);
  }
};


/**
 * Make a (mostly) unique download filename, including date and a random number.
 *
 * @param {String} name - The beginning of the filename.
 * @param {String} ext - The file extension.
 * @returns {String} The filename.
 */
export const makeDownloadFilename = (name, ext) => {
  return name + ' ' +
    (new Date()).toISOString().replace(/T.*/, '') + ' ' +
    Math.floor(Math.random() * 1000000) +
    '.' + ext;
};


/**
 * Start the request process.
 *
 * @param {String} uri - The uri to hit
 * @param {object} body - The body of the request
 * @returns {Promise.<String>} The request ID, if the request was successful.
 */
export const requestDownloadable = async (uri, body) => {
  return ajax({
    method: 'POST',
    uri: uri,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: body
  });
};


/**
 * Get the status from a request result.
 *
 * @param {Object} result - The result of an ajax request.
 * @returns {String} The current status.
 * @private
 */
export const getStatus = (result) => {
  if (result.statusCode === 401) {
    return 'unauthorized';
  }

  const isError =
    !result.success ||
    !result.body ||
    result.body.data.error ||
    // Allow success (2xx) and redirect/not modified etc (3xx)
    Math.floor(result.statusCode / 100) > 3;

  if (isError) {
    return 'failed';
  } else if (result.body.data.ready) {
    return 'succeeded';
  }
  return 'pending';
};


/**
 * Wait for a request to complete.
 *
 * @param {String} url - The url to poll.
 * @param {int=100} pollInterval - Delay between tests, in miliseconds.
 * @param {int=1000} pollMaxAttempts - If more than this many attempts are made, return false.
 * @param {function:boolean} abortWhen - Abort the poll when this condition is true
 * @returns {Promise.<String>} The current status.
 * @private
 */
export const poll = async (url, pollInterval, pollMaxAttempts, abortWhen = () => false) => {
  let status = 'pending';
  await waitFor(async () => {
    if (abortWhen()) {
      status = 'aborted';
      return true;
    }

    const response = await ajax({
      uri: url,
      headers: {
        'Accept': 'application/json'
      },
      responseType: 'json'
    });
    status = getStatus(response);

    return status !== 'pending';
  }, pollInterval, pollMaxAttempts);

  // Time out failure.
  if (status === 'pending' || status === 'aborted') {
    status = 'failed';
  }
  return status;
};
