import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { uniqueId, makeClassName } from "utils";
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from "translations";
import "./field_error_handler.sass";
import { sentenceCase } from "utils/string";

/**
 * This class adds error-handling to a render-prop component in two ways:
 *
 * [1] the render method passes the presence of an error and two error-handling
 * functions into the RP component
 *
 * [2] the constructor and CWUM methods add/remove the RP component from the
 * 'claimedFields' property of the parent <Form>'s state (currently used solely for
 * filtering and displaying 'unclaimed' errors, but will be expanded for parsing
 * which values of a resource to actually send in updates.)
 */

export default class FieldErrorHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.uniqueIntForId = uniqueId();
  }

  componentDidMount() {
    if (this.props.claimField) {
      this.props.claimField(this.props.name, this.props.validate);
    }
  }

  componentWillUnmount() {
    if (this.props.releaseField) {
      this.props.releaseField(this.props.name);
    }
  }

  /**
   * This function is passed into a child component, which (if errors are
   * present) will call it in order to add error attributes
   */
  createErrorAttributes = (ariaDescribedBy) => {
    const errorAttributes = {};
    if (this.props.errors) {
      errorAttributes["aria-invalid"] = true;
      errorAttributes["aria-describedby"] =
        `${ariaDescribedBy ? `${ariaDescribedBy} ` : ''}${this.getUniqueId()}`;
    }
    return errorAttributes;
  };

  getUniqueId = () => {
    return `${this.props.name}__errors__${this.uniqueIntForId}`;
  };

  labelText = () => {
    if (!this.props.renderLabel) {
      return null;
    } else if (this.props.errorLabel) {
      return this.props.errorLabel;
    } else if (typeof this.props.label === 'string') {
      return this.props.label;
    } else if (this.props.label?.props?.id && this.props.label?.props?.defaultMessage) {
      return (
        <FormattedMessage
          defaultMessage={this.props.label.props.defaultMessage}
          id={this.props.label.props.id}
        />
      );
    } else {
      return null;
    }
  };

  /**
   * This function is passed into a child component, which (if errors are
   * present) will call it in order to render its errors as a list
   *
   * @returns {React.element}
   */
  renderErrorText = () => {
    return (
      <ul
        className={makeClassName(
          'c-field-group__errors',
          this.props.noPadding && 'c-field-group__errors-no-padding'
        )}
        data-testid="field-error-list"
        id={this.getUniqueId()}
      >
        {this.props.errors && this.props.errors.map((error, index) => {
          return (
            <li
              data-testid="field-error"
              key={index}
            >
              {this.labelText()}
              {
                <FormattedMessage
                  id={intlKeyFromValue(error, "field_error")}
                  defaultMessage={
                    this.props.renderLabel ? error.charAt(0) === ' ' ? error : (' ' + error)
                      : sentenceCase(error)
                  }
                />
              }
              {this.props.link}
            </li>);
        })}
      </ul>
    );
  };

  render() {
    return this.props.render(
      !!this.props.errors,
      this.createErrorAttributes,
      this.renderErrorText
    );
  }
}

FieldErrorHandler.propTypes = {
  claimField: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Used when label is not just a string (like if it has links)
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  renderLabel: PropTypes.bool,
  noPadding: PropTypes.bool,
  releaseField: PropTypes.func,
  render: PropTypes.func,
  validate: PropTypes.func
};

FieldErrorHandler.defaultProps = {
  renderLabel: true
};
