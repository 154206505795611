import { ActionManager } from 'utils/action_manager';

export const badgeTemplatesSearchActions = new ActionManager('searchableBadgeTemplates')
  .add({
    requestKey: 'fetch',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/badge_templates/search',
    list: 'list',
    alwaysRefresh: true
  }, false);
