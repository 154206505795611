import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * React.Context for overriding the default site header with a custom one for certain contexts.
 * @type {React.Context<{customHeader: null, setCustomHeader: (function(): null)}>}
 */
export const SiteHeaderContext = React.createContext({
  customHeader: null, setCustomHeader: () => null
});

/**
 * Wrapper for SiteHeaderContext.Provider that supplies an appropriate object to that component,
 * which includes both the configured custom header (if any) and the setter function to change the
 * custom header.
 *
 * @props {*} children - the React sub-tree to which the custom header context will be provided;
 *   must include the <SiteHeader/> component that will render the header, as well as any content
 *   that may wish to provide a custom header, i.e. nearly all of the application
 */
export class SiteHeaderContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { customHeader: null, setCustomHeader: this.setCustomHeader };
  }

  /**
   * Function to update the customHeader value and trigger re-render of SiteHeader with either
   * the default (if header is null) or custom (if header is a React element) header.
   * @param {null|React.element} header - the header to use (null for default, React element for
   *   custom)
   */
  setCustomHeader = (header) => {
    this.setState(state => {
      if (header && state.customHeader) {
        console.warn('Cannot set custom header when one is already in use');
      }
      return { customHeader: header };
    });
  };

  /**
   * Render the component.
   * @returns {React.element}
   */
  render() {
    return (
      <SiteHeaderContext.Provider value={this.state}>
        {this.props.children}
      </SiteHeaderContext.Provider>
    );
  }
}

SiteHeaderContextProvider.propTypes = {
  children: PropTypes.node
};
