import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeClassName } from 'utils';

import './icon_with_notification.sass';

/**
 * Basic presentational component that renders an icon with a counter inside of a notification
 * bubble overlaying that icon
 *
 * @param {int} number - the number displayed
 * @param {boolean} [showZero=false] - if true, includes the notification circle with a zero
 *  where it would normally be omitted entirely
 */

export const IconWithNotification = (props) => {
  return (
    <div
      className={makeClassName("c-icon-with-notification__container", props.className)}
    >
      <FontAwesomeIcon className="c-icon-with-notification__icon" icon={props.icon}/>
      {(props.number > 0 || props.showZero) && (
        <div className="c-icon-with-notification__icon-number">
          <span className="c-icon-with-notification__icon-number-text">{props.number}</span>
        </div>
      )}
    </div>
  );
};

IconWithNotification.propTypes = {
  number: PropTypes.number,
  icon: PropTypes.object,
  showZero: PropTypes.bool,
  className: PropTypes.string
};

IconWithNotification.defaultProps = {
  number: 0,
  showZero: false
};
