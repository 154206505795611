import { Action } from 'utils/action';
import * as actionTypes from './action_types';
import { FilteredBadgeSearchAction } from './badge_filters/utils';

export const search = new Action({
  resourceType: 'searchResults',
  requestKey: 'search',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/global_search',
  list: 'lastSearch',
  alwaysRefresh: true
});

export const filteredBadgeSearch = new FilteredBadgeSearchAction({
  resourceType: 'filteredBadgeSearchResults',
  requestKey: 'filteredBadgeSearch',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/global_search/badge_template',
  list: 'lastFilteredBadgeSearch',
  alwaysRefresh: true
});

export const closeGlobalSearchUI = () => dispatch => {
  dispatch({ type: actionTypes.CLOSE_GLOBAL_SEARCH });
};

export const openGlobalSearchUI = () => dispatch => {
  dispatch({ type: actionTypes.OPEN_GLOBAL_SEARCH });
};

export const toggleGlobalSearchUI = () => dispatch => {
  dispatch({ type: actionTypes.TOGGLE_GLOBAL_SEARCH });
};
