/* eslint-disable camelcase */
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet-async';
import { SigninRequiredError } from 'utils/signin_required_error';
import { LoadingSpinner } from 'controls/loading_spinner';
import { injectIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';

/**
 * How dare I use subclassing in React?!
 *
 * The more Reacty approach of rendering <Route> in a render() method doesn't work. The Router
 * component doesn't like that, and pages no longer change.
 */
export default class CredlyRoute extends Route {
  UNSAFE_componentWillMount() {
    this.updateTitle(this.props.title);
  }

  /**
   * Why componentWillUpdate? componentDidUpdate is called in the wrong order -- high level routes
   * after low level.
   *
   * @param {Object} nextProps
   * @param {Object} nextState
   */
  UNSAFE_componentWillUpdate(nextProps) {
    this.updateTitle(nextProps.title);
  }


  /**
   * Update the title if necessary.
   *
   * @param {String|Function} title
   */
  updateTitle(title) {
    if (typeof title === 'function') {
      setTitle(
        title({
          ...this.props,
          match: this.props.match || this.props.computedMatch
        }),
        this.props.intl
      );
    } else {
      setTitle(title, this.props.intl);
    }
  }

  /**
   * Sets the canonical link for the page to the current Route's URL
   * if the canonicalize prop is true.
   *
   * @param {Object} routeProps
   */
  renderCanonicalLink(routeProps) {
    if (this.props.canonicalize) {
      return (
        <Helmet>
          <link rel="canonical" href={window.location.origin + routeProps.match.url} />
        </Helmet>
      );
    }
  }

  /**
   * Call the Route's render function.
   *
   * @param {Object} routeProps
   */
  renderWrap = routeProps => {
    return (
      // The route-level Suspense below prevents some of the flicker that comes from the
      // higher-level Suspense on some pages.
      <Suspense fallback={<LoadingSpinner position="window-center" delay={500} />}>
        {this.props.render(routeProps)}
        {this.renderCanonicalLink(routeProps)}
      </Suspense>
    );
  };


  /**
   * Render the component. If the user isn't logged in, render the signin page.
   *
   * @returns {*}
   */
  render() {
    const globals = window.initialAppValues;
    const user = globals.currentUser;
    if (user.anonymous && !this.props.anonymous) {
      // Cause the signin page to render, replacing all content, even if it's higher up in the DOM.
      SigninRequiredError.raise();
      return '';
    } else {
      const innerProps = { ...this.props };
      if (this.props.render) {
        innerProps.render = this.renderWrap;
      }
      return <Route {...innerProps} />;
    }
  }
}


// remove-proptypes is needed for babel-plugin-transform-react-remove-prop-types, which doesn't
// handle component inheritance.
CredlyRoute.propTypes /* remove-proptypes */ = {
  ...Route.propTypes,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  anonymous: PropTypes.bool,
  canonicalize: PropTypes.bool
};

CredlyRoute.MIN_DEBOUNCE_WAIT = 100;
CredlyRoute.MAX_DEBOUNCE_WAIT = 300;

/**
 * Set the title. We have a multi-level Route structure, which results in multiple Routes updating
 * on page change. To avoid performance and accessibility issues caused by multiple title changes,
 * debounce.
 *
 * @type {Function}
 */
const setTitle = debounce((title, intl) => {
  const credly = 'Credly';
  if (title) {
    if (/.* - Badges$/.test(title)) { // '{name} - Badges' title of public badge view.
      const ownerName = title.split(" - ")[0];
      const ending = `- ${intlMessage(intl, 'Badges')} - ${credly}`;
      title = createSEOPageTitle(ownerName, `${ending}`);
    } else {
      const intlTitle = intlMessage(intl, title);
      const ending = `- ${credly}`;
      title = createSEOPageTitle(intlTitle, ending);
    }
  } else {
    title = credly;
  }

  if (document.title !== title) {
    document.title = title;
  }
}, CredlyRoute.MIN_DEBOUNCE_WAIT, { maxWait: CredlyRoute.MAX_DEBOUNCE_WAIT });


/**
 * Create SEO-friendly page title.
 *
 * This strictly limits page titles to 60 characters, including the ellipsis and the site name.
 *
 * @param {String} pageName
 * @param {String} brand
 * @returns {String}
 */
export function createSEOPageTitle(pageName, brand) {
  let seoTitle = `${pageName} ${brand}`;

  if (seoTitle.length > 60) {
    const ellipsis = '... '; // with space for the space between the ellipsis & brand
    const maxTitleLength = 60 - brand.length - ellipsis.length;
    seoTitle = `${pageName.substring(0, maxTitleLength)}${ellipsis}${brand}`;
  }

  return seoTitle;
}

/**
 * i18n for page titles
 *
 * @type {Function}
 */
function intlMessage(intl, title) {
  return intl.formatMessage({
    id: intlKeyFromValue(title, "title"),
    defaultMessage: title
  });
}

// eslint-disable-next-line no-class-assign
CredlyRoute = injectIntl(CredlyRoute);
