import PropTypes from 'prop-types';

export const uuidPropType = function(props, propName, componentName) {
  const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  if (!uuidRegex.test(props[propName])) {
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. ` +
      `Expected a valid uuid. Received ${props[propName]}.`
    );
  }
  return null;
};

/**
 * @typedef MessageDefinition
 * @property {String} id - message ID
 * @property {String} defaultMessage - default English-language message in case ID cannot be
 *   resolved
 */
let _DocOnlyMessageDefinition;

export const messageDefinitionPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired
});
