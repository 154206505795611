import { lazyWithRetry } from 'app_utils/lazy_load';
export { useCustomHeader, withCustomHeader } from './with_custom_header';

export const SiteHeader = lazyWithRetry(() => import(/* webpackChunkName: "header" */ './site_header')
  .then(module => ({default: module.SiteHeader})));

export const PortaledHeader = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './portaled_header')
    .then(module => ({default: module.PortaledHeader}))
);

export const DefaultSiteHeader = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './default_site_header')
    .then(module => ({ default: module.DefaultSiteHeader }))
);

export const HeaderMenuNav = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './header_menu_nav')
    .then(module => ({default: module.HeaderMenuNav}))
);

export const HeaderMenuNavToggle = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './header_menu_nav_toggle')
    .then(module => ({ default: module.HeaderMenuNavToggle }))
);

export const ContextHeader = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './context_header')
    .then(module => ({default: module.ContextHeader}))
);

// Don't lazy-load this one. It's a light-weight component used by <AppStructure>.
export { SiteHeaderContextProvider } from './site_header_context';

export { HidesHeader } from './hides_header';
