import { QueryString } from 'utils/query_string';

/**
 * URL functions for URLs with simple query strings. Use QueryString for more complex URLs.
 *
 * @type {Object}
 */
const urlUtils = {
  /**
   * Add parameters to a URL.
   *
   * @param {string} url - The base URL
   * @param {object<string,string>} data - Parameters
   * @returns {string}
   */
  makeUrl: (url, data) => {
    const params = urlUtils.makeQueryString(data);

    if (params) {
      url += url.indexOf('?') >= 0 ? '&' : '?';
      url += params;
    }

    return url;
  },



  /**
   * Make a query string from parameters. Will recursively construct
   * query params for nested objects.
   * @param {object<string,*>} data - Parameters
   * @returns {string}
   */
  makeQueryString: (data) => {
    if (!data) {
      return '';
    }
    return (new QueryString(data)).toString();
  },


  /**
   * Extract all parameters from a URL.
   *
   * @param {String=} url - A url or query string. Default to the current document's location.
   * @returns {URLSearchParams}
   */
  params: url => {
    url = url || document.location.search;
    if (!/[=?]/.test(url)) {
      return new URLSearchParams();
    }
    return new URLSearchParams(url.replace(/.*\?/, ''));
  },


  /**
   * Append a parameter to a location search string, or replace an existing parameter.
   *
   * @param {String} url - A url or query string.
   * @param {String} name - The parameter name.
   * @param {String|number} val - The parameter value.
   */
  appendParam: (url, name, val = '') => {
    const params = urlUtils.params(url);
    params.set(name, val);
    return urlUtils.makeUrl(url.replace(/\?.*$/, ''), params);
  },

  /**
   * Append a parameter to a location search string, or replace an existing parameter.
   *
   * @param {String} url - A url or query string.
   * @param {object<string,string>} data - The parameters to append
   */
  appendParams: (url, data) => {
    const params = urlUtils.params(url);
    for (const [key, value] of Object.entries(data)) {
      params.set(key, value);
    }
    return urlUtils.makeUrl(url.replace(/\?.*$/, ''), params);
  },


  /**
   * Remove a parameter from a location search string.
   *
   * @param {String} url - A url or query string.
   * @param {String} name - The parameter name.
   */
  removeParam: (url, name) => {
    return url.replace(new RegExp(name + '=[^&]*&?', 'g'), '').replace(/[?&]$/, '');
  },

  /**
   * If the given path ends with a GUID, return it. Otherwise, return an empty string.
   *
   * @param {Object} path - The current URL path.
   * @returns {String} The GUID, if there is one.
   */
  extractTrailingGuid: (path = document.location.pathname) =>
    path.replace(/^.*\/([0-9\-a-fA-F]{10,})(\?.*)?$|.*/, '$1'),

  /**
   * Convert a URLSearchParams instance into a simple object.
   *
   * @param {URLSearchParams} urlSearchParams - the URLSearchParams that you want to convert to
   *   an object
   * @returns {object}
   */
  urlSearchParamsToObject: (urlSearchParams) => {
    const result = {};
    urlSearchParams.forEach((value, key) => {
      if (Object.prototype.hasOwnProperty.call(result, key)) {
        if (Array.isArray(result[key])) {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });
    return result;
  }
};

/**
 * Add filter parameters to a url (for example, a user profile url).
 *
 * @param {Object} filters - directory filters.
 * @param {String} url - The base url.
 * @param {String} source - The source of the filter.
 * @returns {String} - The modified url.
 */
export const makeFilteredUrl = (filters, url, source = 'earner_directory') => {
  return new QueryString().set('filter', filters).set('source', source).makeUrl(url);
};
export default urlUtils;
