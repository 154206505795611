import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils/index';
import { RoleButton } from 'aria/role_button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { SmartLink } from 'controls/smart_link';
import './alert.sass';

const ALERT_TYPE_STYLES = {
  notice: { icon: faThumbsUp, timeout: 10, className: 'ac-alert-notice' },
  info: { icon: null, timeout: 10, className: 'ac-alert-info' },
  error: { icon: faFrown, timeout: null, className: 'ac-alert-error' }
};

const ALERT_WITH_ACTION = {
  notice: { icon: faThumbsUp, timeout: null, className: 'ac-alert-notice' },
  info: { icon: faComment, timeout: null, className: 'ac-alert-info' },
  error: { icon: faExclamationTriangle, timeout: null, className: 'ac-alert-error' }
};

/**
 * A pop-up alert. Usually used with AlertStack.
 *
 * @property {Function} [onClose] - Called when the alert is dismissed; if omitted, it indicates
 *   that the alert should not be dismissable
 * @property {String} text - The text content
 * @property {String} className - A class name to apply to the component
 * @property {String} type - Determines the visual of the alert
 * as well as its duration displayed on the screen.
 * @property {Object} [actionLink] - Called when the alert must perform an action. It must be an object
 *   with the properties description (name of the action) and action (function or action to perform)
 */
export default class Alert extends Component {
  constructor(props) {
    super(props);
    this.m_ref = React.createRef();
    this.m_timeout = this.timeoutFromType();
    this.m_closingTimeout = null;
    this.m_mounted = false;
  }


  /**
   * The component has been mounted to the React virtual DOM.
   */
  componentDidMount() {
    this.m_mounted = true;
    this.delayedClose();
  }


  /**
   * The component is about to be unmounted.
   */
  componentWillUnmount() {
    this.m_mounted = false;
  }

  /**
   * Check whether the alert can be closed or not.
   *
   * @returns {boolean}
   */
  canClose() {
    return !!this.props.onClose;
  }


  /**
   * Close the alert. This sends an event to the container, rather than closing anything itself.
   *
   * @param {Event} e
   */
  handleClose = e => {
    if (this.m_mounted && this.canClose()) {
      e && e.preventDefault();
      this.m_closingTimeout = 0;
      this.props.onClose();
    }
  };


  /**
   * Close the alert (this.m_timeout) seconds after the last mouseover event.
   */
  delayedClose = () => {
    if (this.m_timeout && this.canClose()) {
      if (this.m_closingTimeout) {
        clearTimeout(this.m_closingTimeout);
      }
      this.m_closingTimeout = setTimeout(() => {
        if (this.m_mounted) {
          this.m_ref.current.classList.add('ac-alert__fade');
          setTimeout(this.handleClose, 600);
          this.m_closingTimeout = 0;
        }
      }, this.m_timeout * 1000);
    }
  };

  /**
   * Calculates the timeout from the type of alert given (notice, info, error)
   * Returns an integer if the alert should disappear after a given time or null
   * if the alert should persist
   * @returns {number|null}
   *
   */
  timeoutFromType = () => ALERT_TYPE_STYLES[this.props.type].timeout;

  /**
   * Calculates the React element output from the type of alert given (notice, info, error)
   * @returns {React.element|null}
   *
   */
  iconFromType = () => {
    if (this.props.actionLink) {
      const icon = ALERT_WITH_ACTION[this.props.type].icon;
      return (
        <div className="ac-alert__action-icon-container">
          <FontAwesomeIcon
            className="ac-alert__action-icon-container__icon"
            icon={icon}
          />
        </div>
      );
    } else {
      const icon = ALERT_TYPE_STYLES[this.props.type].icon;
      if (icon) {
        return (
          <div className="ac-alert__type-icon-container">
            <FontAwesomeIcon
              className="ac-alert__type-icon-container__icon"
              icon={icon}
            />
          </div>
        );
      } else {
        return null;
      }
    }
  }


  /**
   * Render the component.
   *
   * @returns {*}
   */
  render() {
    const classFromType = ALERT_TYPE_STYLES[this.props.type].className;
    return (
      <div
        className={makeClassName('ac-alert', classFromType, this.props.className)}
        ref={this.m_ref}
        onMouseMove={this.delayedClose}
      >
        <div className="ac-alert__content">
          {this.iconFromType()}
          <div className="ac-alert__text" role="alert">{this.props.text}</div>
          {this.props.actionLink &&
            <SmartLink
              className="ac-alert__action"
              action={this.props.actionLink.action ? this.props.actionLink.action : null}
              mobileSize="auto"
            >
              {this.props.actionLink.description ? this.props.actionLink.description : null}
            </SmartLink>
          }
          {
            this.canClose() && (
              <RoleButton
                onClick={this.handleClose}
                tagName="div"
                className="ac-alert__dismiss-icon-container"
              >
                <FontAwesomeIcon
                  className="ac-alert__dismiss-icon-container__icon"
                  icon={faTimesCircle}
                />
              </RoleButton>
            )
          }
        </div>
      </div>
    );
  }
}


Alert.propTypes = {
  onClose: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'notice']).isRequired,
  actionLink: PropTypes.object
};

Alert.defaultProps = {
  type: 'info'
};

