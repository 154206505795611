import { ActionManager } from "utils/action_manager";
import * as stringUtils from "utils/string";
import { actionTypes } from 'redux-resource';
import { profileSelfId } from 'profile';
import { invalidateCurrentUser } from '../profile/action_creators';
import { invalidateAllBadges } from "./hooks";

export * from './blockchain_actions';
export * from './download_badge_actions';
export * from './obi_badge_actions';
export * from './verify_blockcert_actions';
export * from './hooks';



/**
 * Used by badge actions that operate on earnedBadges to sync updates to state and public properties
 * to publicBadges, which is used by the badge views to display the metadata about badges.
 *
 * @param {Function} dispatch - Redux dispatch function
 * @param {Function} getState - function to get the Redux store state
 * @param {Object} action - the Redux resource action that succeeded
 */
const syncEarnedBadgesAndPublicBadgesStatus = (dispatch, getState, action) => {
  const badge = action.resources[0];
  const badgeId = badge.id;
  const getPublicBadges = publicBadgeActions.get('fetch', badgeId);
  const publicBadge = getPublicBadges.getResources(getState());
  invalidateAllBadges();
  if (publicBadge) {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        publicBadges: {
          [badgeId]: { state: badge.state, public: badge.public }
        }
      }
    });
  }
};

/**
 * After accepting a badge, update the badge store, and the pending badge count.
 *
 * @param {Function} dispatch - Redux dispatch function
 * @param {Function} getState - Function to get all redux state
 * @param {Object} action - the Redux resource action that succeeded
 */
const updateStateOnBadgeAccept = (dispatch, getState, action) => {
  syncEarnedBadgesAndPublicBadgesStatus(dispatch, getState, action);
  invalidateCurrentUser();
  // Decrement the badge count when you accept a badge, so the number in the profile menu is updated
  const profile = getState().profile;
  invalidateAllBadges();
  dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      profile: {
        [profileSelfId(profile)]: {
          pending_badge_count: action.res.body.metadata.pending_count
        }
      }
    }
  });
};

export const updatePublicBadgeAttributes = (dispatch, getState, action, attributes) => {
  const resource = action.resources[0];
  const badgeId = resource.badge_id;
  const getPublicBadges = publicBadgeActions.get('fetch', badgeId);
  const publicBadge = getPublicBadges.getResources(getState());
  if (publicBadge) {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        publicBadges: {
          [badgeId]: {
            ...attributes
          }
        }
      }
    });
  }
};

export const updateViewableBadgeAttributes = (dispatch, getState, action, attributes) => {
  const resource = action.resources[0];
  const badgeId = resource.badge_id;
  const getViewableBadges = viewableBadgeActions.get('fetch', badgeId);
  const viewableBadge = getViewableBadges.getResources(getState());
  if (viewableBadge) {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        viewableBadges: {
          [badgeId]: {
            ...attributes
          }
        }
      }
    });
  }
};

// Actions for pages that deal with just one badge. Stores multiple badges so they can be reused.
export const badgeActions = new ActionManager('earnedBadges')
  .add({
    requestKey: 'get',
    url: '/api/v1/users/self/badges/[id]',
    effect: 'read',
    method: 'GET'
  }, true)
  .add({
    requestKey: 'email',
    url: '/api/v1/users/self/badges/[id]/email_shares',
    effect: 'update',
    method: 'POST',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'badge_id' }
    })
  })
  .add({
    requestKey: 'accept',
    url: '/api/v1/users/self/badges/[id]/accept',
    effect: 'update',
    method: 'PUT',
    // This endpoint doesn't return the exact required badge data which causes skills to disappear.
    // For now, just refresh the badge data
    onSuccess: updateStateOnBadgeAccept
  }, true)
  .add({
    requestKey: 'reject',
    url: '/api/v1/users/self/badges/[id]/reject',
    effect: 'update',
    method: 'PUT',
    // Similar to accept, this endpoint operates on earnedBadges, while the view shows information
    // from publicBadges, so we use this onSuccess handler to sync the two stores
    onSuccess: syncEarnedBadgesAndPublicBadgesStatus
  }, true)
  .add({
    requestKey: 'update',
    url: '/api/v1/users/self/badges/[id]',
    effect: 'update',
    method: 'PUT',
    // Like accept/reject, this endpoint operates on earnedBadges, while the view shows information
    // from publicBadges, so we use this onSuccess handler to sync the two stores
    onSuccess: syncEarnedBadgesAndPublicBadgesStatus
  }, true);

export const publicBadgeActions = new ActionManager('publicBadges')
  .add({
    requestKey: 'fetch',
    url: '/api/v1/public_badges/[id]?fields=recommendations:verbose,related_badges:basic',
    effect: 'read',
    method: 'GET'
  }, true);

export const preservedBadgeActions = new ActionManager('preservedBadges')
  .add({
    requestKey: 'fetch',
    url: '/api/v1/preserved_badges/[id]',
    effect: 'read',
    method: 'GET'
  }, true);

export const viewableBadgeActions = new ActionManager('viewableBadges')
  .add({
    requestKey: 'fetch',
    alwaysRefresh: true,
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    url: '/api/v1/viewable_badges/[organization_id]/[id]'
  }, true);

export const socialActions = new ActionManager('temp')
  .add({
    requestKey: 'share',
    url: '/api/v1/users/self/badges/[id]/social_shares',
    effect: 'update',
    method: 'POST',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'badge_id' }
    })
  }, true);
