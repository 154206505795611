import axios from 'axios';

export const getOtherPublicBadges = async (vanitySlug, url) => {
  url = url || `/api/v1/users/${vanitySlug}/external_badges/open_badges/public?page=1&page_size=48`;
  const { data } = await axios.get(url, {
    headers: {
      Accept: 'application/json'
    }
  });
  return data;
};
