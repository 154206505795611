
/**
 * Run Blockert's cert-verifier-js on a blockcert and report on the status.
 *
 * @param {object} certificate - the blockcert data to verify
 * @param {Function} progressFn - a function to monitor the progress of verification; will receive
 *   an object containing code, status, and errorMessage keys; see cert-verifier-js API docs
 *   for details: https://github.com/blockchain-certificates/cert-verifier-js#verifystepcallback
 * @param {Function} doneFn - a function to be called when verification is complete; will
 *   receive an object containing code, status, and errorMessage keys; see the same documentation
 *   as for progressFn for additional info
 * @param {Function} failFn - a function to be called when cert-verifier-js raises an error; this
 *   can happen, for example, if the blockcert has an invalid structure; receives the exception that
 *   was raised
 */
export const verifyBlockcert = (certificate, progressFn, doneFn, failFn) => {
  import('@blockcerts/cert-verifier-js').then(({ Certificate }) => {
    try {
      const certVerify = new Certificate(certificate);
      certVerify.init()
        .then(() => certVerify.verify(progressFn), failFn)
        .then(doneFn, failFn)
        .catch(failFn);
    } catch (e) {
      failFn(e);
    }
  });
};
