import React from 'react';
import PropTypes from 'prop-types';
import flattenChildren from 'react-keyed-flatten-children';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'pages/error_views';

export const CredlySwitch = (props) => {
  const flattenedChildren = flattenChildren(props.children);

  return (
    <Switch>
      {flattenedChildren}
      <Route render={() => <NotFound suppressContainer={props.suppressContainer}/>}/>
    </Switch>
  );
};

CredlySwitch.propTypes = {
  children: PropTypes.node,
  suppressContainer: PropTypes.bool
};
