import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RouterPropTypes } from 'utils/prop_types';
import { QueryString } from 'utils/query_string';

const SEARCH_PARAMS_WHITELIST = ['page'];

/**
 * Route changes inside this component cause the page to scroll to the top.
 */
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // Scroll to the top of the page whenever the route changes (but not the query string).
    let doScroll = this.props.location.pathname !== prevProps.location.pathname;

    // ...except for certain whitelisted query params, which do cause scroll.
    // This isn't very Reacty (since it requires this component knowing about other components),
    // but there's no other easy way to do it in the current architecture, and this has no side
    // effects other than scroll.
    if (!doScroll) {
      const prevSearch = new QueryString(prevProps.location.search);
      const curSearch = new QueryString(this.props.location.search);
      doScroll = SEARCH_PARAMS_WHITELIST.find(s => prevSearch.get(s) !== curSearch.get(s));
    }

    doScroll && window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: RouterPropTypes.location
};

const Connected = withRouter(ScrollToTop);
export { Connected as ScrollToTop };
