import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './homepage_review_tile.sass';

export const HomepageReviewTile = props => {
  return (
    <div className={makeClassName('homepage-review-tile', props.className)}>
      <p className="homepage-review-tile__large-quote-text">{props.header}</p>
      <div className="row">
        <div className="col-md-3 offset-md-0 col-3 offset-1">
          <img src={props.imageUrl} alt={props.alt} className="homepage-review-tile__customer-logo"/>
        </div>
        <div className="col-md-9 col-8 homepage-review-tile__text-wrapper">
          <div className="homepage-review-tile__bold-text">{props.body}</div>
          <div>{props.labelText}</div>
        </div>
      </div>
    </div>
  );
};

HomepageReviewTile.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  labelText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string
};
