import { lazyWithRetry } from 'app_utils/lazy_load';

export const BadgeTemplateLmiRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "badge_templates-lmi" */ './badge_template_lmi_route'
  ).then(module => ({default: module.BadgeTemplateLmiRoute}))
);

export const BadgeTemplateLmiRedirectBarChart = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "badge_templates-lmi" */ './badge_template_lmi_redirect_bar_chart'
  ).then(module => ({default: module.BadgeTemplateLmiRedirectBarChart}))
);
