import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import FieldErrorHandler from './field_error_handler';
import { makeClassName, uniqueId } from 'utils';
import { PropTypesExt } from 'utils/prop_types';
import InputLabel from './input_label';

import './field_group.sass';

const INPUT_PASSTHROUGH_PROPS = [
  'maxLength', 'role', 'aria-describedby', 'aria-invalid', 'aria-label', 'aria-autocomplete',
  'aria-controls', 'aria-activedescendant', 'accept', 'alt', 'autoComplete', 'autoFocus', 'disabled',
  'max', 'min', 'pattern', 'placeholder', 'readOnly', 'step', 'onKeyDown', 'onKeyUp', 'onBlurCapture'
];

class FieldGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { focused: false };
    this.uniqueIntForId = uniqueId();
  }

  getUniqueId = () => {
    return `${this.props.name}_${this.uniqueIntForId}`;
  };

  handleBlur = (evt) => {
    !this.props.noFocusStyle && this.setState({ focused: false });
    this.props.handleBlur && this.props.handleBlur(evt);
  };

  handleChangeFromEvent = (evt) => {
    evt.preventDefault();
    const el = evt.target;
    this.props.handleChange(el.name, el.value);
  };

  handleFocus = (evt) => {
    !this.props.noFocusStyle && this.setState({ focused: true });
    this.props.handleFocus && this.props.handleFocus(evt);
  };

  handleEnter = (event) => {
    if (event.key === 'Enter' && this.props.handleEnter) {
      this.props.handleEnter(event);
    }
  }

  validate = (name, val) => {
    const errs = [];
    if (this.props.readOnly) {
      return errs;
    }

    if (!this.props.ignoreDefaultValidations) {
      // Enforce "required."
      if (this.props.required && /^\s*$/.test(val === undefined || val === null ? '' : val)) {
        errs.push('is required');
      }
      // Enforce "maxLength" in case the browser doesn't.
      if (this.props.maxLength && (val || '').length > this.props.maxLength) {
        errs.push(`is too long (maximum is ${this.props.maxLength} characters)`);
      }
    }

    // Check other validators.
    return this.props.validate
      ? [...errs, ...this.props.validate(name, val)]
      : errs;
  };

  inputProps(hasErrors, createErrorAttributes, labelId) {
    const result = {
      name: this.props.name,
      id: this.getUniqueId(),
      value:
        this.props.value === undefined || this.props.value === null
          ? ''
          : this.props.value,
      onChange: this.handleChangeFromEvent,
      onKeyPress: this.handleEnter,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      required: !this.props.readOnly && this.props.required,
      'aria-labelledby': labelId
    };

    INPUT_PASSTHROUGH_PROPS.forEach(
      (name) => name in this.props && (result[name] = this.props[name])
    );

    return hasErrors ? { ...result, ...createErrorAttributes(result['aria-describedby']) } : result;
  }

  label(inputProps) {
    const requiredClassName = inputProps && inputProps.required && !this.props.requiredNoStar
      ? { className: 'c-field-group__label-name--required' }
      : {};
    return (
      <Fragment>
        <span {...requiredClassName}>{this.props.label}</span>
        <span>{this.props.labelSupplement}</span>
      </Fragment>
    );
  }

  render() {
    return (
      <FieldErrorHandler
        name={this.props.name}
        errors={this.props.errors}
        label={this.props.label}
        errorLabel={this.props.errorLabel}
        link={this.props.link}
        claimField={this.props.claimField}
        validate={this.validate}
        releaseField={this.props.releaseField}
        render={(hasErrors, createErrorAttributes, renderErrorText) => {
          const labelId = `label_${this.getUniqueId()}`;
          const inputProps = this.inputProps(hasErrors, createErrorAttributes, labelId);
          const label = this.label(inputProps);
          return (
            <div
              className={makeClassName([
                'c-field-group',
                this.state.focused && 'c-field-group--focused',
                this.props.label && 'c-field-group__has-label',
                (this.props.hasErrors || hasErrors) && 'c-field-group--errors',
                this.props.className && this.props.className
              ])}
              title={this.props.title}
              ref={this.props.fieldGroupRef}
            >
              <div
                className={makeClassName([
                  'c-field-group__input-group',
                  this.props.enclosed && 'c-field-group__input-group--enclosed',
                  this.props.hidden && 'c-field-group__input-group--hidden',
                  this.props.disabled && 'c-field-group__input-group--disabled',
                  this.props.tall && 'c-field-group__input-group--tall',
                  this.props.fullHeight &&
                  'c-field-group__input-group--no-height',
                  this.props.inputGroupClassName
                ])}
              >
                <InputLabel
                  className={makeClassName(["c-field-group__label", this.props.labelClassName])}
                  htmlFor={this.getUniqueId()}
                  id={labelId}
                >
                  {label}
                </InputLabel>

                {this.props.render(inputProps)}
              </div>
              {hasErrors && renderErrorText()}
            </div>
          );
        }}
      />
    );
  }
}

FieldGroup.propTypes = {
  ...FieldErrorHandler.propTypes,
  'aria-label': PropTypesExt.requiredUnlessPresent(PropTypes.string, 'label'),
  enclosed: PropTypes.bool,
  hasErrors: PropTypes.bool,
  fieldGroupRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ignoreDefaultValidations: PropTypes.bool,
  inputGroupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  validate: PropTypes.func,
  label: PropTypesExt.requiredUnlessPresent(PropTypes.node, 'aria-label'),
  labelSupplement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  requiredNoStar: PropTypes.bool,
  style: PropTypes.object,
  tall: PropTypes.bool,
  fullHeight: PropTypes.bool,
  title: PropTypes.string,
  noFocusStyle: PropTypes.bool,
  // These properties are provided by Form.propsForFieldGroup
  errors: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleEnter: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(File)
  ])
};

FieldGroup.defaultProps = {
  labelSupplement: '',
  required: false,
  tall: false,
  value: ''
};

export default FieldGroup;
