import { onScrollManager } from './window_event_manager';

/**
 * Utilities namespace for HTMLElement objects.
 */
const element = {
  /**
   * Get the window-relative position of an element.
   * Warning: This will cause a reflow. If you call it in a frequent event like onscrollwheel,
   * onmousemove or onscroll, make sure to throttle.
   *
   * @param {HTMLElement|EventTarget} elt - The element to measure.
   * @return {{x: int, y: int}}
   */
  getPosition: elt => {
    let top = 0;
    let left = 0;
    for (; elt && elt !== document.body; elt = elt.offsetParent) {
      left += elt.offsetLeft;
      top += elt.offsetTop;
    }
    return { x: left, y: top };
  },

  /**
   * Is the element visible on screen?
   * Warning: This will cause a reflow. If you call it in a frequent event like onscrollwheel,
   * onmousemove or onscroll, make sure to throttle.
   *
   * @param {Element|EventTarget|string} elt - The element to measure, or a unique CSS selector.
   * @return {Boolean}
   */
  isVisible: elt => {
    if (typeof elt === 'string') {
      elt = document.querySelector(elt);
    }

    if (!elt) {
      return false;
    }

    const rect = elt.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    // If the rect has a height and if the bottom is lower than the top of the viewport, and the top is higher than the bottom
    // of the viewport, then some part of the element is on-screen.
    return rect.height > 0 && rect.bottom >= 0 && rect.top < viewHeight;
  },

  /**
   * Is the element FULLY visible in the parent? This is useful when an element is inside of a
   * scroll view
   *
   * Warning: This will cause a reflow. If you call it in a frequent event like onscrollwheel,
   * onmousemove or onscroll, make sure to throttle.
   *
   * @param {Element|EventTarget|string} elt - The element to measure, or a unique CSS selector.
   * @return {Boolean}
   */
  isElementFullyVisibleInParent: elt => {
    if (typeof elt === 'string') {
      elt = document.querySelector(elt);
    }

    if (!elt) {
      return false;
    }

    const parent = elt.parentElement;
    if (!parent) {
      return false;
    }

    const eltPos = elt.getBoundingClientRect();
    const parentPos = parent.getBoundingClientRect();
    const viewHeight = Math.min(document.documentElement.clientHeight, window.innerHeight);

    // Keeping in mind that (0,0) is the upper left, and (100,100) is down and to the right,
    // The child is FULLY visible within the parent if all 3 of the following criteria are met:
    // 1. The child top position is equal to or greater than the parent top
    // 2. The child bottom position is equal to or less than the parent bottom
    // 3. The childs bottom position is equal to or less than the window viewHeight

    const isChildsTopVisible = (eltPos.top >= parentPos.top);
    const isChildsBottomVisible = (
      (eltPos.bottom <= parentPos.bottom) && (eltPos.bottom <= viewHeight)
    );

    return (isChildsTopVisible && isChildsBottomVisible);
  },

  /**
   * Cross-browser window scroll position.
   *
   * @returns {number}
   */
  windowScrollY: () => {
    return window.scrollY || window.pageYOffset || document.body.scrollTop;
  },

  /**
   * Call a callback the first time an element is visible on screen.
   *
   * @param {Element|string} elt - an Element, or a unique CSS selector.
   * @param {Function} callback
   * @returns {Function} a function to remove the callback.
   */
  whenFirstVisible: (elt, callback) => {
    if (element.isVisible(elt)) {
      // Delay the callback so it's always asynchronous.
      setTimeout(callback, 0);
      return () => null;
    } else {
      const callbackWithVisibilityTest = () => {
        if (element.isVisible(elt)) {
          callback();
          // Only call callback() once.
          return false;
        }
        return true;
      };
      onScrollManager.add(callbackWithVisibilityTest);

      // Return a function to remove the callback.
      return () => onScrollManager.remove(callbackWithVisibilityTest);
    }
  },

  ClosestParentWithStyleAbortError: class extends Error {
  },

  /**
   * Get the closest parent with a specified style, or window if none is found.
   *
   * @param {HTMLElement|EventTarget} elt - The element.
   *   @param {HTMLElement} elt.parentElement - Documented to make RubyMine happy.
   * @param {function(object):boolean} test - This takes in the computed styles of the current
   *   element in the traversal. If it returns truthy, the desired element has been found. If it
   *   returns falsy, traversal will continue. If it returns closestParentWithStyle.STOP_TRAVERSAL,
   *   traversal is halted and the window object is returned.
   * @returns {HTMLElement}
   */
  closestParentWithStyle: (elt, test) => {
    for (elt = elt.parentElement; elt && elt !== document.body; elt = elt.parentElement) {
      const result = test(window.getComputedStyle(elt));
      if (result === element.closestParentWithStyle.STOP_TRAVERSAL) {
        return window;
      } else if (result) {
        return elt;
      }
    }
    return window;
  },

  /**
   * Get the closest parent with a specified style, or window if none is found.
   *
   * @param {HTMLElement|EventTarget} elt - The element.
   *   @param {HTMLElement} elt.parentElement - Documented to make RubyMine happy.
   * @param {String} propertyName - Look for this property in the style.
   * @param {function(value:string):boolean} valueCheck - This takes in the property value, and
   *   returns true if the desired style has been found.
   * @returns {HTMLElement}
   */
  closestParentWithStyleAttr: (elt, propertyName, valueCheck) => {
    return element.closestParentWithStyle(elt, (styles) => valueCheck(styles[propertyName]));
  },

  /**
   * Get the closest parent that hides overflow.
   *
   * @param {HTMLElement|EventTarget} elt - The element.
   *   @param {HTMLElement} elt.parentElement - Documented to make RubyMine happy.
   * @returns {HTMLElement}
   */
  closestCroppingParent: elt =>
    element.closestParentWithStyleAttr(elt, 'overflow', val => val && val !== 'visible'),

  /**
   * Get the closest parent with a (potential) vertical scrollbar.
   *
   * @param {HTMLElement|EventTarget} elt - The element.
   *   @param {HTMLElement} elt.parentElement - Documented to make RubyMine happy.
   * @returns {HTMLElement}
   */
  closestScrollingParent: elt => element.closestParentWithStyleAttr(
    elt,
    'overflowY',
    val => val && val !== 'hidden' && val !== 'visible'
  ),

  /**
   * Get the closest parent that hides vertical overflow.
   *
   * @param {HTMLElement|EventTarget} elt - The element.
   *   @param {HTMLElement} elt.parentElement - Documented to make RubyMine happy.
   * @returns {HTMLElement}
   */
  closestParentWithHiddenYOverflow: (elt) => element.closestParentWithStyle(elt, (styles) => {
    if (styles.overflowY === 'hidden') {
      return true;
    } else if (styles.position === 'fixed') {
      return element.closestParentWithStyle.STOP_TRAVERSAL;
    }
    return false;
  }),

  /**
   * Calculate the style adjustment required to place `contained` fully inside `container`.
   * `contained` must use non-static positioning.
   *
   * Warning: This function can cause a reflow. Don't call it in frequently-triggered events like
   * resize or scroll without throttling.
   *
   * @param {HTMLElement} contained - The element to be contained.
   * @param {HTMLElement|Node} container - The container element.
   * @returns {{marginLeft: number, marginTop: number}|{}} The required style adjustment.
   */
  moveWithin: (contained, container) => {
    const computedStyle = window.getComputedStyle(contained);
    if (!computedStyle.position || computedStyle.position === 'static') {
      return {};
    }

    const containedPos = contained.getBoundingClientRect();
    const containerPos = container === window
      ? { top: 0, left: 0, right: window.innerWidth, bottom: window.innerHeight }
      : container.getBoundingClientRect();

    let topAdjustment = 0;
    if (containedPos.bottom > containerPos.bottom) {
      // If bottom is below the visible area, move it up
      topAdjustment -= containedPos.bottom - containerPos.bottom;
    } else if (containedPos.top < containerPos.top) {
      // If top is above the visible area, move it down
      topAdjustment += containerPos.top - containedPos.top;
    }

    let leftAdjustment = 0;
    if (containedPos.right > containerPos.right) {
      // If right is right of the visible area, move it left
      leftAdjustment -= containedPos.right - containerPos.right;
    } else if (containedPos.left < containerPos.left) {
      // If left is left of the visible area, move it right
      leftAdjustment += containerPos.left - containedPos.left;
    }

    // Use margins instead of positioning, because it's much easier when using both left and right,
    // or top and bottom.
    const style = {};
    if (leftAdjustment) {
      style.marginLeft = (parseInt(computedStyle.marginLeft || 0) + leftAdjustment) + 'px';
    } else if (contained.style.marginLeft) {
      // Preserve existing inline styles.
      style.marginLeft = contained.style.marginLeft;
    }

    if (topAdjustment) {
      style.marginTop = (parseInt(computedStyle.marginTop || 0) + topAdjustment) + 'px';
    } else if (contained.style.marginTop) {
      // Preserve existing inline styles.
      style.marginTop = contained.style.marginTop;
    }

    return style;
  },

  /**
   * onKeyDown handler for lists, to enable keyboard navigation between the list's children.
   * This assumes the children do not have elements in between, and that all children are tabable,
   * either by being inherently tabable objects (inputs, anchors etc) or through the use of
   * tabindex (preferably tabindex=0).
   *
   * @param {SyntheticEvent} evt - The onKeyDown event.
   * @param {Object} options
   *   @param {*} [options.data] - Data to pass to the event options when triggered.
   *   @param {function(*, SyntheticEvent)} [options.onSelect] - Triggered when an item is selected.
   *   @param {function(*, SyntheticEvent)} [options.onEscape] - Triggered when the list is escaped,
   *     by pressing the escape key, or by tabbing out of the list.
   */
  listKeyboardNavHandler: (evt, options) => {
    const target = evt.currentTarget;

    if (evt.key === 'Tab') {
      // The browser handles tabbing via tabindex. This is for the "tabbed out of the list" case.
      if (options.onEscape) {
        let next;
        if (evt.shiftKey) {
          next = target.previousElementSibling || target.previousSibling;
        } else {
          next = target.nextElementSibling || target.nextSibling;
        }

        if (!next) {
          options.onEscape(options.data);
        }
      }
    } else if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
      options.onSelect && options.onSelect(options.data, evt);
    } else if (evt.key === 'ArrowDown' || evt.key === 'ArrowLeft') {
      // Currently, left/right act just like up/down. In the future, an option to navigate in
      // display order (for example, move up, down, left and right through a table) might be a
      // worthwhile option to add.
      evt.preventDefault();
      const next = target.nextElementSibling || target.nextSibling;
      next && next.focus();
    } else if (evt.key === 'ArrowUp' || evt.key === 'ArrowRight') {
      evt.preventDefault();
      const prev = target.previousElementSibling || target.previousSibling;
      prev && prev.focus();
    } else if (evt.key === 'Escape') {
      evt.preventDefault();
      target.blur();
      options.onEscape && options.onEscape(options.data, evt);
    }
  },

  /**
   * Get the outer width (including margins) of an element.
   *
   * @param {Element} elt
   * @param {Object} style - CSS styles from getComputedStyle. You can pass this in to optimize
   *   multiple calls that use getComputedStyle
   * @returns {number}
   */
  outerWidth: (elt, style = null) => {
    style = style || getComputedStyle(elt);
    return elt.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight);
  },

  /**
   * Get the left position of an element (including its margin), relative to its offsetParent.
   *
   * @param {Element} elt
   * @param {Object} style - CSS styles from getComputedStyle. You can pass this in to optimize
   *   multiple calls that use getComputedStyle
   * @returns {number}
   */
  outerLeft: (elt, style = null) => {
    style = style || getComputedStyle(elt);
    return elt.offsetLeft - parseInt(style.marginLeft);
  },

  /**
   * Get the inner width of an element, excluding margins and padding. This is the space that
   * can contain other elements.
   *
   * @param {Element} elt
   * @param {Object} style - CSS styles from getComputedStyle. You can pass this in to optimize
   *   multiple calls that use getComputedStyle
   * @returns {number}
   */
  innerWidth: (elt, style = null) => {
    style = style || getComputedStyle(elt);
    return elt.offsetWidth -
      parseInt(style.borderLeftWidth) - parseInt(style.borderRightWidth) -
      parseInt(style.paddingLeft) - parseInt(style.paddingRight);
  },

  /**
   * Find the index of the last child matching the given selector that hasn't overflowed out of the container.
   *
   * @param {Element} container - The container.
   * @param {String} childSelector - CSS selector for children this function should consider.
   * @param {int} spaceRequired - If speciried, pretend an element this wide is also in the list,
   *   just before the point where the first overflow occurs. This may result in the second-to-last
   *   element being returned.
   * @returns {Element} The last matching child.
   */
  lastChildInViewIdx: (container, childSelector, spaceRequired = 0) => {
    const elts = container.querySelectorAll(childSelector);
    if (elts.length === 1) {
      if (elts[0].offsetHeight && elts[0].offsetTop <= container.offsetHeight) {
        return -1;
      }
    } else if (elts.length) {
      let lastWrappedIdx = -1;
      const containerHeight = container.offsetHeight;
      // Find the last child which is has overflowed out of the container.
      for (
        let x = elts.length - 1;
        x >= 0 && (!elts[x].offsetHeight || elts[x].offsetTop > containerHeight);
        x--
      ) {
        lastWrappedIdx = x;
      }

      let lastVisibleIdx = Math.max(-1, lastWrappedIdx - 1);

      // The caller wants some free space. Make sure there's enough, and if not, move the found
      // index back one spot in the list.
      if (spaceRequired && lastVisibleIdx >= 0) {
        const containerWidth = element.innerWidth(container);

        while (lastVisibleIdx >= 0) {
          const elt = elts[lastVisibleIdx];
          const style = getComputedStyle(elt);
          const eltWidth = element.outerWidth(elt, style);
          const eltLeft = element.outerLeft(elt, style);

          const remainingSpace = containerWidth - eltLeft - eltWidth;
          if (remainingSpace < spaceRequired) {
            lastVisibleIdx--;

            // Edge case: If The element + spaceRequired is wider than the available space, only move
            // back once spot (pushing the current element out). Otherwise, we might never find a
            // good place to fit spaceRequired.
            const allSpaceTaken = containerWidth - eltWidth < spaceRequired;
            if (allSpaceTaken) {
              break;
            }
          } else {
            break;
          }
        }
      }

      return lastVisibleIdx;
    }
  },

  /**
   * Find the last child matching the given selector that hasn't overflowed out of the container.
   *
   * @param {Element} container - The container.
   * @param {String} childSelector - CSS selector for children this function should consider.
   * @param {int} spaceRequired - If speciried, pretend an element this wide is also in the list,
   *   just before the point where the first overflow occurs. This may result in the second-to-last
   *   element being returned.
   * @returns {Element} The last matching child.
   */
  lastChildInView: (container, childSelector, spaceRequired = 0) => {
    const elts = container.querySelectorAll(childSelector);
    if (elts.length === 1) {
      // This is legacy behavior that is probably broken but other code might depend on
      return elts[0].offsetHeight && elts[0].offsetTop > container.offsetHeight ? null : elts[0];
    } else {
      const idx = element.lastChildInViewIdx(container, childSelector, spaceRequired);
      return elts[idx];
    }
  },

  /*
   * Return true if neither the DOM node nor any of its parents has display:none.
   *
   * @param {HTMLElement} elt
   * @returns {Boolean}
   */
  isDisplayed: elt => {
    if (!elt) {
      return false;
    }

    // Optimization: offsetParent is rougly twice as fast as getComputedStyle in Chrome, so cover
    // the most common case before looping.
    if (elt.offsetParent) {
      return true;
    }

    // If offsetParent is null, the element is hidden with display:none, or has a display:none
    // parent. The exception is fixed nodes, which always have offsetParent=null, so we have to loop
    // until we find a non-fixed node.
    for (; elt && elt !== document; elt = elt.parentNode) {
      const style = getComputedStyle(elt);
      if (style.display === 'none') {
        return false;
      }
      if (style.position !== 'fixed') {
        return !!elt.offsetParent;
      }
    }

    return true;
  }
};

element.closestParentWithStyle.STOP_TRAVERSAL = {};

export default element;
