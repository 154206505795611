import axios from "axios";
import { QueryString } from 'utils/query_string';

export const getJobPosts = async ({ page, statusFilter, jobDetailsFilter }) => {
  const queryParams = {};
  if (statusFilter) {
    queryParams.status_filter = statusFilter;
  }

  if (page) {
    queryParams.page = page;
  }

  if (jobDetailsFilter) {
    queryParams.job_details_filter = jobDetailsFilter;
  }

  const filterQueryString = new QueryString(queryParams).toString();

  const { data } = await axios.get(`/api/v1/job_posts${
    filterQueryString ? `?${filterQueryString}` : ""
  }`);

  return data;
};
