import { actionTypes } from 'redux-resource';
import * as objUtils from 'utils/object';

export const METADATA_DEFAULT_KEY = '_default';
export const OBJECT_LEVEL_METADATA_KEY = '_object_level_meta';

/**
 * List of actions that signal the end of a request cycle, which we means we should update metadata.
 *
 * @type {Array<string>}
 */
const requestEndActions = [
  actionTypes.CREATE_RESOURCES_SUCCEEDED,
  actionTypes.DELETE_RESOURCES_SUCCEEDED,
  actionTypes.READ_RESOURCES_SUCCEEDED,
  actionTypes.UPDATE_RESOURCES_SUCCEEDED
];

/**
 * Actions that delete resources. These should be handled a bit differently, because we want to
 * remove metadata related to these resources altogether.  Also, the resources list is just a list
 * of IDs (rather than objects containing ID).
 * @type {Array<string>}
 */
const deleteActions = [
  actionTypes.DELETE_RESOURCES,
  actionTypes.DELETE_RESOURCES_SUCCEEDED
];

/**
 * Determine whether the action should update the general section of metadata.  We do this on
 * request end.
 *
 * @param {object} action - the action to test
 * @returns {boolean}
 */
const shouldUpdateGeneralMetadata = (action) => {
  return requestEndActions.indexOf(action.type) !== -1;
};

/**
 * Determine whether the general section of metadata should be updated based on the provided action,
 * and if so, return a new copy of state with the updates.
 *
 * @param {object} state - current resource slice state
 * @param {object} action - the action from which to update, if appropriate
 * @returns {object}
 */
const updateGeneralMetadata = (state, action) => {
  if (shouldUpdateGeneralMetadata(action)) {
    state = {
      ...state,
      metadata: {
        ...state.metadata,
        [METADATA_DEFAULT_KEY]: objUtils.dig(action, ['res', 'body', 'metadata'])
      }
    };
  }
  return state;
};

/**
 * Determine whether the list section of metadata should be updated.  We update list metadata on
 * read request end, because list metadata is used mainly for pagination.
 *
 * @param {object} action - the action to test
 * @returns {boolean}
 */
const shouldUpdateListMetadata = (action) => {
  return !!(action.type === actionTypes.READ_RESOURCES_SUCCEEDED && action.list);
};

/**
 * Determine whether the list section of metadata should be updated based on the provided action,
 * and if so, return a new copy of state with the updates.
 *
 * @param {object} state - current resource slice state
 * @param {object} action - the action from which to update, if appropriate
 * @returns {object}
 */
const updateListMetadata = (state, action) => {
  if (shouldUpdateListMetadata(action)) {
    state = {
      ...state,
      metadata: {
        ...state.metadata,
        [action.list]: objUtils.dig(action, ['res', 'body', 'metadata'])
      }
    };
  }
  return state;
};

/**
 * Given an action, extracts the IDs of the resources being deleted, or if the action is not a
 * deletion, returns an empty list.
 *
 * @param {object} action - action from which to extract IDs
 * @returns {Array}
 */
const extractDeleteResourceIds = (action, resourceType) => {
  if (action.type === actionTypes.DELETE_RESOURCES_SUCCEEDED) {
    return [...action.resources];
  } else if (action.type === actionTypes.DELETE_RESOURCES) {
    return [...action.resources[resourceType]];
  }
  return [];
};

/**
 * Returns true if the provided action should delete object-level metadata.
 *
 * @param {object} action - action to test
 * @returns {boolean}
 */
const shouldDeleteResourceMetadata = (action) => {
  return deleteActions.indexOf(action.type) !== -1;
};

/**
 * Returns true if the provided action should update object-level metadata.
 *
 * @param {object} action - action to test
 * @returns {boolean}
 */
const shouldUpdateResourceMetadata = (action) => {
  return requestEndActions.indexOf(action.type) !== -1;
};

/**
 * Given an action that should update object-level metadata, identify and return a new copy of
 * state with the object-level metadata updated for the affected resources.
 *
 * @param {object} state - state prior to object-level metadata updates
 * @param {object} action - action from which to update metadata
 * @returns {object}
 */
const mergeResourceMetadata = (state, action) => {
  const newMeta = {
    ...state.metadata, [OBJECT_LEVEL_METADATA_KEY]: { ...state.metadata[OBJECT_LEVEL_METADATA_KEY] }
  };
  const objectLevelMeta = newMeta[OBJECT_LEVEL_METADATA_KEY];

  const responseData = objUtils.dig(action, ['res', 'body', 'data']);
  const responseMetadata = objUtils.dig(action, ['res', 'body', 'metadata']);

  if (responseData) {
    const resourceIds = (action.resources || []).map(r => r.id);
    if (Array.isArray(responseData)) {
      // we'll assume this is a list API and look for metadata in the response corresponding to
      // individual resources
      if (responseMetadata && responseMetadata.items) {
        resourceIds.forEach(id => {
          objectLevelMeta[id] = responseMetadata.items[id];
        });
      }
    } else if (resourceIds.length === 1 && resourceIds[0] === responseData.id) {
      objectLevelMeta[resourceIds[0]] = responseMetadata;
    }
  }
  return { ...state, metadata: newMeta };
};

/**
 * Determine whether the object-level metadata should be updated based on the provided action,
 * and if so, return a new copy of state with the updates.
 *
 * @param {object} state - state prior to object-level metadata updates (will not be mutated)
 * @param {object} action - action from which to update metadata
 * @param {string} resourceType - the name of the resource slice for which to apply updates
 * @returns {{metadata: *}}
 */
const updateResourceMetadata = (state, action, resourceType) => {
  if (shouldDeleteResourceMetadata(action)) {
    const newMeta = {
      ...state.metadata,
      [OBJECT_LEVEL_METADATA_KEY]: { ...state.metadata[OBJECT_LEVEL_METADATA_KEY] }
    };
    extractDeleteResourceIds(action, resourceType).forEach(id => {
      delete newMeta[OBJECT_LEVEL_METADATA_KEY][id];
    });
    state = { ...state, metadata: newMeta };
  } else if (shouldUpdateResourceMetadata(action)) {
    state = mergeResourceMetadata(state, action);
  }
  return state;
};

/**
 * Returns true if the provided action deletes the given resource type.
 *
 * @param {object} action - action to test
 * @param {string} resourceType - the type of resource to test action against
 * @returns {boolean}
 */
const isDeleteActionFor = (action, resourceType) => {
  return (
    action.type === actionTypes.DELETE_RESOURCES &&
    Object.prototype.hasOwnProperty.call(action.resources, resourceType)
  );
};

/**
 * Adds a new key to the resource slice to hold metadata from API responses. By default, this is
 * stored in .metadata._default. If the 'list' option was passed into the action, it's stored in
 * .metadata.[listname].  Also supports object-level metadata via .metadata._object_level_meta
 *
 * @param resourceType
 * @returns {Function}
 */
export function reduxResourceMetadataPlugin(resourceType) {
  return function(state, action) {
    const typeToCheck = action.resourceType || action.resourceName;
    if (typeToCheck === resourceType || isDeleteActionFor(action, resourceType)) {
      state = updateResourceMetadata(state, action, resourceType);
      state = updateListMetadata(state, action);
      state = updateGeneralMetadata(state, action);
    }
    return state;
  };
}


export function reduxResourceMetadataPluginInitialState() {
  return {
    metadata: {
      [OBJECT_LEVEL_METADATA_KEY]: {}
    }
  };
}

/**
 * Consistent pointer to return when the requested metadata is unavailable.
 *
 * @type {{}}
 */
const EMPTY_OBJECT = {};

/**
 * Retrieves the requested metadata from the specified resource slice.
 *
 * @param {object} resourceSlice - a redux-resource slice that has been augmented with the
 *   reduxResourceMetadataPlugin
 * @param {{id:string, list:string}} options - identifies what metadata to retrieve. Available
 *   options are id, list, or nothing (in which case the default, slice-level metadata will be
 *   returned)
 * @param {string} options.id - the ID of the resource for which to get metadata
 * @param {string} options.list - the name of a redux-resource list in the indicated resourceSlice
 *   for which to get metadata
 * @returns {object}
 */
export function getMetadata(resourceSlice, options = {}) {
  if (!resourceSlice.metadata) {
    throw new Error('Attempted to call getMetadata() on an action not created with apiReducer()');
  }
  if (options.id) {
    if (options.list) {
      throw new Error(
        'Both list and id were provided to getMetadata(), only one should be specified'
      );
    }
    return resourceSlice.metadata[OBJECT_LEVEL_METADATA_KEY][options.id] || EMPTY_OBJECT;
  } else if (options.list) {
    return resourceSlice.metadata[options.list] || EMPTY_OBJECT;
  } else {
    return resourceSlice.metadata[METADATA_DEFAULT_KEY] || EMPTY_OBJECT;
  }
}
