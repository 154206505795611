import { matchPath } from 'react-router';
import pathToRegexp from 'path-to-regexp';
import * as objUtils from './object';
import urlUtils from './url';

/**
 * Object representing a single route. Used as part of a route set produced by {@link makeRoutes}.
 */
export class SingleRoute {
  /**
   * Construct a new SingleRoute.  Not intended for consumption outside of this module (use
   * {@link makeRoutes}, instead).
   *
   * @param {SingleRoute} parentRoute - if not-null, a SingleRoute instance under which this route
   *   is nested
   * @param {string} subRoute - a string defining the portion of the path specific to this route
   * @param {string} [baseUrl] - if provided, an actual path matching the route defined by
   *   parentRoute which this route is being used
   */
  constructor(parentRoute, subRoute, baseUrl = null, env = 'production') {
    this._parentRoute = parentRoute || { route: () => '' };
    this._subRoute = subRoute;
    this._env = env;
    if (baseUrl) {
      const parameterizedBase = matchPath(baseUrl, { path: this._parentRoute.route() });
      if ((this._env === 'development' || this._env === 'test') && !parameterizedBase) {
        throw new Error(`Expected ${baseUrl} to match ${this._parentRoute.route()}`);
      }
      this._fullRoute = `${parameterizedBase.url}${this._subRoute}`;
    } else {
      this._fullRoute = `${this._parentRoute.route()}${this._subRoute}`;
    }
    this._parameterizedGenerator = pathToRegexp.compile(this._fullRoute);
  }

  /**
   * Spawns a copy of this route with the provided baseUrl.
   *
   * @param {string} baseUrl - the concrete base URL at which this route is being used
   * @returns {SingleRoute}
   */
  forBaseUrl(baseUrl) {
    return new SingleRoute(this._parentRoute, this._subRoute, baseUrl, this._env);
  }

  /**
   * Generate a concrete path from the route, given values for the parameterized portions of the
   * route.
   *
   * @param {object} [data] - an object containing keys corresponding to the parameterized portions
   *   of the route, e.g. if the route is /foo/:id, then data should be { id: 'some-id' }
   * @param {object} [queryParams] - an object containing additional parameters to include in the
   *   query string for the generated URL
   * @returns {string}
   */
  parameterize(data = {}, queryParams = null) {
    return urlUtils.makeUrl(this._parameterizedGenerator(data), queryParams);
  }

  /**
   * Returns the route (including, possibly, path parameters, etc.)
   *
   * @returns {string}
   */
  route() {
    return this._fullRoute;
  }
}

/**
 * A set of routes nested under a root route.
 */
export class RouteSet {
  /**
   * Construct a new RouteSet.  Not intended for consumption outside of this module (use
   * {@link makeRoutes}, instead).
   *
   * @param {string} [currentUrl] - if provided, the provided URL should match the root route, and
   *   all routes in the resulting RouteSet will use that as the already-parameterized base
   */
  constructor(currentUrl = null, env = 'production') {
    this._routes = {};
    this._currentUrl = currentUrl;
    this._env = env;
  }

  /**
   * Register a new route with the RouteSet using the provided name and route generator.
   *
   * @param {string} routeName - the name of the new route; the route will be accessible on
   *   instances of RouteSet as a property with this name
   * @param {string} subRoute - the portion of the path specific to this route (i.e. the portion
   *   after the root)
   */
  static add(routeName, subRoute) {
    // defineProperty with an accessor method (get) allows us to create what appears to be a simple
    // property of the object, but is backed by a method, rather than being a simple variable.
    //
    // Route accessors are defined using defineProperty so that when a RouteSet is customized for a
    // given base URL, it doesn't have to eagerly instantiate every SingleRoute that makes up the
    // Route Set.  If the RouteSet has a lot of routes and only a small fraction of them will
    // actually be used (e.g. the RouteSet has all "Developers" section routes, but only a single
    // authorizationTokens route will be accessed), this can save some time and memory.  (Granted,
    // it's unlikely to matter in the real world.)
    Object.defineProperty(this.prototype, routeName, {
      get: function() {
        if (!this._routes.hasOwnProperty(routeName)) {
          this._routes[routeName] = new SingleRoute(this.root, subRoute, this._currentUrl, this._env);
        }
        return this._routes[routeName];
      }
    });
  }

  /**
   * Create a sub-class of RouteSet (i.e. a new route hierarchy) using the provided root route.
   *
   * @param {SingleRoute} root - the root route that will be prepended to all other routes defined
   *   in this RouteSet; can be null if this RouteSet will be the base of a route hierarchy
   * @returns {RouteSet}
   */
  static extend(root) {
    return class extends RouteSet {
      /**
       * Lazy accessor for root route property, so that we can instaniate a new, specialized
       * SingleRoute for it if this instance has been spawned with a base URL. {@link RouteSet#add}
       * has more information abut the rationale for this.
       *
       * @returns {SingleRoute}
       */
      get root() {
        if (!this._materializedRoot) {
          if (this._currentUrl) {
            this._materializedRoot = new SingleRoute(root, '', null, this._env).forBaseUrl(this._currentUrl);
          } else {
            this._materializedRoot = root;
          }
        }
        return this._materializedRoot;
      }
    };
  }

  /**
   * Spawns a new copy of this RouteSet with the root parameterized as in the provided path.
   *
   * For example, if the root route is /mgmt/organizations/:organization_id, and the provided path
   * is /mgmt/organizations/org-1/foo/bar/baz, then the parameterized route set will have the
   * effective root of /mgmt/organizations/org-1.  That is, the :organization_id will be fixed for
   * the routes, and usages of the resulting routes will only match that specific organization ID,
   * similarly, calls to parameterize need not supply organization_id, because it is already fixed.
   *
   * @param {string} path - a path that matches the root route of this RouteSet; if it does not,
   *   then an exception will be raised (development and test mode only)
   * @returns {RouteSet}
   */
  forUrl(path) {
    return new this.constructor(path);
  }
}

/**
 * Create a RouteSet using the provided base route and an object containing sub-routes to add.
 *
 * @param {SingleRoute|string} root - the root route for this route hierarchy; can be either a
 *   SingleRoute instance obtained from an existing RouteSet or a simple string, if this will be
 *   the root of a new route hierarchy
 * @param {Object<string, string>} routes - a map of route names to their corresponding sub-route
 *   patterns, which will be used to generate the full routes of the hierarchy
 * @param {string} [env] - the Rails environment; if not provided, defaults to 'production'
 * @returns {ConcreteRouteSet}
 */
export const defineRoutes = (root, routes, env = 'production') => {
  let rootAsRoute = root;
  if (typeof rootAsRoute === 'string') {
    rootAsRoute = new SingleRoute(null, root, null, env);
  }

  const ExtendedRouteSet = RouteSet.extend(rootAsRoute);
  objUtils.forEach(routes, (routeName, subRoute) => {
    ExtendedRouteSet.add(routeName, subRoute);
  });
  return new ExtendedRouteSet(null, env);
};

const defineRoutesTest = (root, routes) => defineRoutes(root, routes, 'test');
const SingleRouteTest = (parentRoute, subRoute, baseUrl = null) => new SingleRoute(parentRoute, subRoute, baseUrl, 'test');

export const testing = {
  SingleRoute: SingleRouteTest,
  RouteSet,
  defineRoutes: defineRoutesTest
};
