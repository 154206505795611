import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import SkipLinkTarget from 'controls/skip_link_target';
import { LoadingSpinner } from 'controls/loading_spinner';
import { NewHeadingLevel } from 'controls/heading';
import { withCustomBackground } from 'controls/custom_background';
import './dashboard_layout.sass';

const DashboardLayout = props => {
  return (
    <Fragment>
      <SkipLinkTarget name="earner"/>
      <div className="dashboard-layout">
        <Suspense fallback={<LoadingSpinner position="below" delay={500}/>}>
          <NewHeadingLevel>
            {props.children}
          </NewHeadingLevel>
        </Suspense>
      </div>
    </Fragment>
  );
};

DashboardLayout.propTypes = {
  path: PropTypes.string,
  children: PropTypes.any
};

const LayoutWithCustomBackground = withCustomBackground('gray')(DashboardLayout);
export { LayoutWithCustomBackground as DashboardLayout };
