import { useEffect } from 'react';
import PropTypes from 'prop-types';
import noScroll from 'no-scroll';

/**
 * Tracks number of mounted components that want no-scroll.  When it goes to zero, scrolling can be
 * restored.
 *
 * @type {number}
 */
let counter = 0;

/**
 * Wrapper for the no-scroll library which allows components to disable scrolling without being
 * aware of whether some other component has already done so by tracking the number of active
 * NoScroll instances.
 *
 * @param {boolean} active - whether to disable scrolling or not
 */
export const useNoScroll = (active) => {
  useEffect(() => {
    if (active) {
      counter += 1;
      if (counter === 1) {
        noScroll.on();
      }
      return () => {
        counter -= 1;
        if (counter === 0) {
          noScroll.off();
        }
      };
    }
  }, [active]);
};

/**
 * Wrapper for the no-scroll library which allows components to disable scrolling without being
 * aware of whether some other component has already done so by tracking the number of active
 * NoScroll instances.
 *
 * @param {object} props
 *   @param {boolean} props.active - whether scrolling is currently disabled
 * @returns {React.element}
 * @constructor
 */
export const NoScroll = (props) => {
  useNoScroll(props.active);
  return null;
};

NoScroll.propTypes = {
  active: PropTypes.bool
};
