import { useQuery } from '@tanstack/react-query';
import { getJobPosts } from '../api/job_posts';

export const useJobPosts = ({ page, statusFilter, jobDetailsFilter }) => {
  return useQuery({
    queryKey: ["jobPosts", { page, statusFilter }, jobDetailsFilter],
    queryFn: () => getJobPosts({ page, statusFilter, jobDetailsFilter }),
    staleTime: 1000 * 60 * 5 // 5 minutes
  });
};
