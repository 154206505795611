import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "form";
import { canonicalSkillsActions } from "reference";
import * as objectUtils from "utils/object";
import { makeClassName } from "utils/index";
import { tracking } from "app_utils/tracking";
import { TAG_UIS } from "controls/tags";

import "./skills_select.sass";

export const SkillsSelect = (props) => {
  const isFirstRender = useRef(true);
  const [canonicalSkillsSearchState, canonicalSkillsSearchPerform] =
    canonicalSkillsActions.useAction("fetch");

  const canonicalSkillsAction = useCallback((query) => {
    if (!objectUtils.isEmpty(props.trackData)) {
      tracking.track({
        ...props.trackData,
        type: `${props.trackData.type}.searched`,
        snapshot_json: {
          user_skills: query
        }
      });
    };
    canonicalSkillsSearchPerform({ query });
  }, [canonicalSkillsSearchPerform]);

  const typeaheadMultiSelectProps = objectUtils.except(props,
    [
      "heading",
      "subheading",
      "tagUi",
      "trackData",
      "maxSelectCount",
      "warnSelectCount"
    ]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // Now that we know we are not in the first render pass,
    // track `skills.selected if props.value changed`
    if (!objectUtils.isEmpty(props.trackData)) {
      tracking.track({
        ...props.trackData,
        type: `${props.trackData.type}.selected`,
        snapshot_json: {
          user_skills: typeaheadMultiSelectProps.value
        }
      });
    };
  }, [typeaheadMultiSelectProps.value]);

  const numOfAddedSkills = typeaheadMultiSelectProps.value?.length || 0;
  const approachingSkillLimit = () => numOfAddedSkills < props.warnSelectCount;
  const excessiveSkills = () => numOfAddedSkills > props.maxSelectCount;

  const typeaheadSkillCountClassName = makeClassName([
    "skills__typeahead-counter",
    approachingSkillLimit() && "skills__typeahead-counter--hidden",
    excessiveSkills() && "skills__typeahead-counter--invalid"
  ]);

  return (
    <div className="skills__container">
      {props.heading && props.heading}
      {props.subheading && props.subheading}
      <div className="skills__typeahead-input">
        <p className={typeaheadSkillCountClassName}>
          {numOfAddedSkills} / {props.maxSelectCount}
        </p>
        <Form.TypeaheadMultiSelect
          searchAction={canonicalSkillsAction}
          searchActionState={canonicalSkillsSearchState}
          enclosed
          label={props.label}
          tagUi={props.tagUi}
          errorLabel=" "
          disabled={numOfAddedSkills >= props.maxSelectCount}
          required={props.required}
          {...typeaheadMultiSelectProps}
        />
      </div>
    </div>
  );
};

SkillsSelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  tagUi: PropTypes.oneOf(TAG_UIS),
  heading: PropTypes.node,
  subheading: PropTypes.node,
  maxSelectCount: PropTypes.number,
  warnSelectCount: PropTypes.number,
  trackData: PropTypes.object
};


SkillsSelect.defaultProps = {
  label: "Skills",
  tagUi: "skill",
  maxSelectCount: 30,
  warnSelectCount: 27
};
