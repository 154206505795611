import { useEffect, useState } from 'react';

/**
 * Create a storage manager for localStorage, cookies etc, with cache and subscribers. This also
 * provides a React state hook, whose state is shared between all users of the hook.
 *
 * @param {object} storeFuncs - Storage functions
 *   @param {function(key: string):string} storeFuncs.getItem
 *   @param {function(key: string, val:string)} storeFuncs.setItem
 *   @param {function(key: string)} storeFuncs.removeItem
 */
export const createStorage = storeFuncs => {
  let m_subscribers = {};
  let m_subscriberKeys = {};
  let m_cache = {};
  let m_lastId = 0;

  /**
   * Get a value from the cache, or localStorage on a cache miss.
   *
   * @param {String} key
   * @returns {String}
   */
  const getItem = key => {
    if (m_cache[key]) {
      return m_cache[key];
    }

    try {
      return storeFuncs.getItem(key);
    } catch (e) {
      return null;
    }
  };

  /**
   * Set a value in localStorage, and notify all subscribers.
   *
   * @param {String} key
   * @param {String} val
   * @returns {Boolean} false if localStorage threw an exception.
   */
  const setItem = (key, val) => {
    let success = true;
    try {
      storeFuncs.setItem(key, val);
      // Only update the cache on success.
      m_cache[key] = val;
    } catch (e) {
      success = false;
    }

    // Notify subscribers of the change.
    if (success) {
      _notifySubscribers(key, val);
    }

    return success;
  };

  /**
   * Remove a value from localStorage, and notify all subscribers.
   *
   * @param {String} key
   * @returns {Boolean} false if localStorage threw an exception.
   */
  const removeItem = key => {
    let success = true;
    try {
      storeFuncs.removeItem(key);
      // Only update the cache on success.
      delete m_cache[key];
    } catch (e) {
      success = false;
    }

    // Notify subscribers of the change.
    if (success) {
      _notifySubscribers(key, undefined);
    }

    return success;
  };

  /**
   * Subscribe to changes in a storage key.
   *
   * @param {String} key - The storage key.
   * @param {function(value:String)} callback - Called on storage changes.
   * @returns {Function} call this to unsubscribe.
   */
  const subscribe = (key, callback) => {
    if (!key || !callback) {
      throw new Error('Invalid parameters to storage.subscribe()');
    }

    m_lastId++;
    const id = m_lastId;
    if (!m_subscribers[key]) {
      m_subscribers[key] = {};
    }
    m_subscribers[key][id] = callback;
    m_subscriberKeys[id] = key;

    return () => {
      delete m_subscribers[m_subscriberKeys[id]][id];
      delete m_subscriberKeys[id];
    };
  };

  /**
   * React state hook for local storage, which shares state between all components that use it.
   *
   * @param {String|function:String} initialValue - The initial val, or a function that returns it
   * @param {String} key - The storage key.
   * @returns {[string, function(string)]} Getter and setter for React state hook array.
   */
  const useStateString = (initialValue, key) => {
    if (!key) {
      throw new Error('A key must be passed into storage.useState');
    }

    const [val, setVal] = useState(getItem(key) || _valOrFunc(initialValue));

    // On mount, subscribe to storage changes. On unmount, unsubscribe.
    // This will cause the component to update whenever a change occurs.
    useEffect(() => subscribe(key, setVal), []);

    return [
      val,
      newVal => {
        // Set in storage.
        setItem(key, _valOrFunc(newVal, val));
        // Set locally cached value, so storage doesn't have to be accessed every time.
        setVal(newVal);
      }
    ];
  };

  /**
   * React state hook for local storage, which automatically converts the value to and from JSON.
   *
   * @param {*} initialValue
   * @param {String} key - The storage key
   * @returns {Array} includes [getter: *, setter: function(*)]
   */
  const useStateJSON = (initialValue, key) => {
    let [storedValue, setStoredValue] =
      useStateString(() => initialValue ? JSON.stringify(initialValue) : undefined, key);

    if (storedValue) {
      // Wrap storedValue in a JSON.parse
      try {
        storedValue = JSON.parse(storedValue);
      } catch (e) {
        console.error('Failed to parse JSON from storage, key=' + key);
        storedValue = initialValue;
      }
    }

    // Wrap setStoredValue in a JSON.stringify
    const newSetStoredValue = value =>
      setStoredValue(JSON.stringify(_valOrFunc(value, storedValue)));

    return [storedValue, newSetStoredValue];
  };

  /**
   * Reset function for unit tests.
   */
  const testReset = () => {
    m_subscribers = {};
    m_subscriberKeys = {};
    m_cache = {};
    m_lastId = 0;
  };

  /**
   * Notify subscribers of a change to storage.
   *
   * @private
   * @param {String} key
   * @param {String} val
   */
  const _notifySubscribers = (key, val) => {
    // Notify subscribers of the change.
    if (m_subscribers[key]) {
      Object.keys(m_subscribers[key]).forEach(id => {
        // Swallow exceptions, so one subscriber doesn't break others.
        try {
          m_subscribers[key][id](val);
        } catch (e) {
          console.error(e);
        }
      });
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    subscribe,
    useState: useStateString,
    useStateJSON,
    testReset
  };
};

export const storage = createStorage({
  getItem: key => window.localStorage.getItem(key),
  setItem: (key, val) => window.localStorage.setItem(key, val),
  removeItem: key => window.localStorage.removeItem(key)
});

export const cookie = createStorage({
  // Find a specific value in a document.cookie, which is in the form: "a=b; c=d; e=f"
  getItem: key => {
    const b = document.cookie.match(
      new RegExp(`(^|[^;]+)\\s*${encodeURIComponent(key)}\\s*=\\s*([^;]+)`)
    );
    return b ? decodeURIComponent(b.pop()) : '';
  },
  setItem: (key, val) => document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
  removeItem: (key) => document.cookie = `${encodeURIComponent(key)}=`
});

/**
 * Return the return value of the passed-in function, or the value itself if it's not a function.
 *
 * @private
 * @param {function(*):*|*} val - A function, or a non-function.
 * @param {*} param - A parameter which is passed to the function.
 * @returns {*} - val, or val(param).
 */
const _valOrFunc = (val, param = undefined) => val instanceof Function ? val(param) : val;
