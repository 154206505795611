import axios from "axios";
import { getCsrfToken } from "utils";

export const createJobPostBookmark = (jobId) => jobPostBookmarkRequest(jobId, "POST");

export const deleteJobPostBookmark = (jobId) =>
  jobPostBookmarkRequest(jobId, "DELETE");

const jobPostBookmarkRequest = async (jobId, method) => {
  try {
    const { data } = await axios({
      url: "/api/v1/job_post_bookmarks",
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken()
      },
      data: { job_id: jobId }
    });
    return data;
  } catch (error) {
    throw new Error(error.response.data.errors);
  }
};
