import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormProps, useCheckAllFieldsPresent } from 'form';
import { LoadingSpinner } from 'controls/loading_spinner';
import { Settings } from '../settings_components';
import { getProfileAction } from 'profile';
import { jobPreferencesActionManager } from '../action_creators';
import { UserSkills } from './user_skills';
import './job_preferences_form.sass';

const jobSearchStatuses = [
  {
    selectionValue: 'open_to_new_opportunities',
    displayValue: 'Interested in learning about a new role'
  },
  {
    selectionValue: 'casually_looking',
    displayValue: 'Casually looking for my next role'
  },
  {
    selectionValue: 'actively_looking',
    displayValue: 'Actively looking for my next role'
  }
];

const currentLevels = [
  {
    selectionValue: 'intern_trainee',
    displayValue: 'Intern/Trainee'
  },
  {
    selectionValue: 'entry_level',
    displayValue: 'Junior/Entry Level'
  },
  {
    selectionValue: 'mid_level',
    displayValue: 'Mid Level'
  },
  {
    selectionValue: 'lead_manager',
    displayValue: 'Lead/Manager'
  },
  {
    selectionValue: 'senior_manager_director',
    displayValue: 'Senior Manager/Director'
  },
  {
    selectionValue: 'executive',
    displayValue: 'Executive'
  }
];

const workplacePreferences = [
  {
    selectionValue: 'in_person',
    displayValue: 'In Person'
  },
  {
    selectionValue: 'remote',
    displayValue: 'Remote'
  },
  {
    selectionValue: 'hybrid',
    displayValue: 'Hybrid'
  }
];

const employmentTypes = [
  {
    selectionValue: 'full_time',
    displayValue: 'Full time'
  },
  {
    selectionValue: 'part_time',
    displayValue: 'Part time'
  },
  {
    selectionValue: 'contract',
    displayValue: 'Contract'
  },
  {
    selectionValue: 'freelance',
    displayValue: 'Freelance'
  }
];

const yesNoOptions = [
  {
    selectionValue: true,
    displayValue: 'Yes'
  },
  {
    selectionValue: false,
    displayValue: 'No'
  }
];

const submitSuccessAlert = {
  type: 'notice',
  text: 'Job preferences have been saved.'
};

export const JobPreferencesForm = props => {
  const [userState] = getProfileAction.useAction();
  const currentUser = userState.resources;

  const [jobPreferencesData] = jobPreferencesActionManager.useOnMount(
    'fetch',
    currentUser.id
  );

  if (jobPreferencesData.status.pending) {
    return <LoadingSpinner delay={200} position="below"/>;
  } else {
    return (
      <JobPreferencesFormContent
        {...props}
        currentUserId={currentUser.id}
        jobPreferences={jobPreferencesData.resources || {}}
      />
    );
  }
};

JobPreferencesForm.propTypes = {
  submitSuccessAlert: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  renderSubmit: PropTypes.func
};

const JobPreferencesFormContent = props => {
  if (props.jobPreferences.current_level === 'not_applicable') {
    // This will trigger the validation error for the current_level field
    // can remove once all users who have a current_level value of 'not_applicable' have been
    // migrated to a new 'current_level' value
    props.jobPreferences.current_level = null;
  }
  const [formProps] = useFormProps(
    jobPreferencesActionManager.get('update', props.currentUserId),
    {
      // checking for job_search_status, because an empty response is still coming back from the
      // store with an id property, but it won't have the other attributes
      initialValues: (('job_search_status' in props.jobPreferences) && props.jobPreferences) || {
        job_search_status: 'open_to_new_opportunities',
        current_level: 'mid_level',
        workplace_preferences: [],
        employment_types: [],
        willing_to_relocate: false,
        requires_sponsorship: false,
        user_skills: [],
        completed: true
      },
      onSubmitSuccess: props.onSubmitSuccess,
      submitSuccessAlert: props.submitSuccessAlert || submitSuccessAlert
    }
  );

  const allFieldsPresent = useCheckAllFieldsPresent(
    'job_search_status',
    'current_level',
    'employment_types',
    'workplace_preferences',
    'willing_to_relocate',
    'requires_sponsorship'
  );

  const renderSubmit = (onSubmit, isSubmitting, isDisabled) => {
    if (props.renderSubmit) {
      return props.renderSubmit(onSubmit, isSubmitting, isDisabled);
    }

    return (
      <div className="job-preferences-form__actions">
        <Form.Submit loading={isSubmitting} disabled={isDisabled}>
          Save Updates
        </Form.Submit>
      </div>
    );
  };

  return (
    <Form
      {...formProps}
      className="job-preferences-form"
    >
      {({
        propsForFieldGroup,
        isSubmitting,
        submit
      }) => (
        <Fragment>
          <Settings.FieldSet className="job-preferences-form__fieldset">
            <Form.RadioGroup
              required
              label="What is the status of your job search?"
              options={jobSearchStatuses}
              {...propsForFieldGroup('job_search_status')}
            />
            <Form.RadioGroup
              required
              label="What is your current level?"
              options={currentLevels}
              {...propsForFieldGroup('current_level')}
            />
            <Form.RadioGroup
              required
              label="Are you willing to relocate?"
              options={yesNoOptions}
              {...propsForFieldGroup('willing_to_relocate')}
            />
            <Form.CheckboxGroup
              enclosed
              required
              label="Workplace preferences"
              options={workplacePreferences}
              {...propsForFieldGroup('workplace_preferences')}
            />
            <Form.CheckboxGroup
              enclosed
              required
              label="Employment type preferences"
              options={employmentTypes}
              {...propsForFieldGroup('employment_types')}
            />
            <Form.RadioGroup
              required
              label="Will you now or in the future require visa sponsorship?"
              options={yesNoOptions}
              {...propsForFieldGroup('requires_sponsorship')}
            />
          </Settings.FieldSet>
          <UserSkills
            showResumeSection={props.showResumeSection}
            currentUserId={props.currentUserId}
            {...propsForFieldGroup('user_skills')}
          />

          {renderSubmit(submit, isSubmitting, !allFieldsPresent(propsForFieldGroup))}
        </Fragment>
      )}
    </Form>
  );
};

JobPreferencesFormContent.propTypes = {
  submitSuccessAlert: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  renderSubmit: PropTypes.func,
  currentUserId: PropTypes.string,
  jobPreferences: PropTypes.object,
  showResumeSection: PropTypes.bool
};
