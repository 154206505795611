import { useInfiniteQuery } from '@tanstack/react-query';
import { getOtherPublicBadges } from '../badge_actions_api/public_other_badges';

export const useOtherPublicBadges = (vanitySlug) => {
  const queryInfo = useInfiniteQuery(
    ['otherPublicBadges', 'list', vanitySlug],
    ({ pageParam }) => getOtherPublicBadges(vanitySlug, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.metadata?.['next_page_url'] || undefined,
      staleTime: 300000
    }
  );

  return { ...queryInfo };
};
