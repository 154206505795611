import React from 'react';
import PropTypes from 'prop-types';
import { ErrorView } from './error_view';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";

const INTERNAL_ERROR_MSG = 'We have encountered an error and are unable to process your request ' +
  'at this time.  Our teams have been notified and are working on it.';

const InternalError = props => {
  return (
    <ErrorView
      title="Error"
      subTitle="We're sorry"
      message={INTERNAL_ERROR_MSG}
      error={props.error}
      icon={
        <FontAwesomeIcon
          icon={faExclamationTriangle}
        />
      }
      suppressContainer={props.suppressContainer}
      homePath={props.homePath}
    />
  );
};

InternalError.propTypes = {
  homePath: PropTypes.string,
  suppressContainer: PropTypes.bool,
  error: PropTypes.object
};

export { InternalError };
