import React from 'react';
import PropTypes from 'prop-types';
import { ErrorView } from './error_view';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";

const NOT_FOUND_MESSAGE = "We’re sorry, but the page you are looking for has either moved, " +
  "or is no longer available.";

const NotFound = props => {
  return (
    <ErrorView
      title="Error"
      subTitle="Page not found"
      message={NOT_FOUND_MESSAGE}
      icon={
        <FontAwesomeIcon
          icon={faQuestionCircle}
        />
      }
      suppressContainer={props.suppressContainer}
      homePath={props.homePath}
    />
  );
}

NotFound.propTypes = {
  homePath: PropTypes.string,
  suppressContainer: PropTypes.bool
};

export { NotFound };
