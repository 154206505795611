import {
  reduxResourceMetadataPlugin,
  reduxResourceMetadataPluginInitialState
} from './redux_resource_metadata_plugin';
import { resourceReducer } from 'redux-resource';
import { reset } from 'redux-resource-plugins';

export const API_REDUCER_OPTS = () => {
  return {
    plugins: [reduxResourceMetadataPlugin, reset],
    initialState: { ...reduxResourceMetadataPluginInitialState() }
  };
};

/**
 * Create a reducer for Credly API requests.
 *
 * @param {String} key - The name of the resource in the redux store.
 * @param {Object=} options - See resourceReducer().
 * @returns {*}
 */
export const apiReducer = (key, options = {}) => {
  const apiOpts = API_REDUCER_OPTS();
  return resourceReducer(key, {
    ...options,
    plugins: [...(options.plugins || []), ...apiOpts.plugins],
    initialState: { ...options.initialState, ...apiOpts.initialState }
  });
};
