import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './input_label.sass';

/**
 * Input label component.
 *
 * @property {String} htmlFor - The html-for attribute, for labeled inputs.
 * @property {String} className - CSS classes
 */
const InputLabel = memo(({ id, htmlFor, className, children }) => {
  return (
    <label
      htmlFor={htmlFor}
      id={id}
      className={makeClassName('c-input-label', className)}
    >
      {children}
    </label>);
});

InputLabel.propTypes = {
  id: PropTypes.string,
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

InputLabel.displayName = 'InputLabel';
export default InputLabel;
