import * as objUtils from './object';

/**
 * Support for a URL query string, including Rails/PHP-style multidimensional query strings.
 */
export class QueryString {
  constructor(params = null) {
    // These default values allow methods to reference the local object. If you call .dig(), you
    // can get QueryString instances that reference sub-objects instead.
    this.m_parent = this;
    this.m_key = 'm_data';
    this.m_data = {};

    if (params) {
      if (params instanceof QueryString) {
        // Copy constructor.
        this.m_data = objUtils.clone(params.m_data);
      } else if (params instanceof URLSearchParams) {
        this.parse(params.toString().replace(/\+/g, ' '));
      } else if (typeof params === 'object') {
        // From existing data
        this.m_data = objUtils.clone(params);
      } else if (params && params.trim()) {
        // From URL query string.
        this.parse(params);
      }
    }
  }

  /**
   * Return a copy of this object.
   */
  clone() {
    return new QueryString(this);
  }

  /**
   * Create a new QueryString object based on a named parameter. Manipulating this new object
   * manipulates the original. To preserve the original, call clone().
   *
   * @param {String} key
   * @return {QueryString}
   */
  dig(key) {
    let q = new QueryString();
    q.m_parent = this.m_data;
    q.m_key = key;
    if (this.m_data) {
      q.m_data = this.m_data[key];
    }
    return q;
  }

  /**
   * Get the value of one key, or all data.
   *
   * @param {String} key - Get the value of this key, or get all data if it's undefined.
   * @returns {*}
   */
  get(key = undefined) {
    if (this.m_data) {
      return key !== undefined ? this.m_data[key] : this.m_data;
    }
  }

  /**
   * Copy the value of one key, or all data.
   *
   * @param {String} key - Copy the value of this key, or get all data if it's undefined.
   * @returns {*}
   */
  copy(key = undefined) {
    if (this.m_data) {
      return objUtils.clone(key ? this.m_data[key] : this.m_data);
    }
  }

  /**
   * Set (and overwrite) a key/value pair.
   *
   * @param {String} key - The key to set.
   * @param {String|number|QueryString|Object|Array} value - Any value which can be processed by
   *   this class. If value is undefined, delete the key.
   *   If value is an Object, Array or QueryString, it will be copied by reference to optimize
   *   performance. If the value should not be mutable, clone it before calling this function.
   * @returns {QueryString}
   */
  set(key, value) {
    if (!this.m_data) {
      this._initialize();
      this.set(key, value);
    } else {
      if (value instanceof QueryString) {
        value = value.get();
      }

      if (value === undefined) {
        this.removeKey(key);
      } else {
        this.m_data[key] = value;
      }
    }

    return this;
  }

  /**
   * Treat the container as a potential array. If it doesn't exist, add the value as a scalar.
   * If it does exist, push the value, creating an array if it is not one already. This method can
   * be called in one of two ways:
   * - push(value) - Push to this object.
   * - push(key, value) - Push to object[key], creating the hash if needed.
   *
   * @param {*} key
   * @param {*} value
   */
  pushUnique(key, value = undefined) {
    if (value === undefined) {
      value = key;
      key = undefined;
    }

    if (key) {
      if (!this.m_data) {
        this._initialize();
      }
      this.dig(key).pushUnique(value);
    } else {
      if (value instanceof QueryString) {
        value = value.get();
      }

      if (!this.m_data) {
        this.m_parent[this.m_key] = value;
      } else if (Array.isArray(this.m_data)) {
        if (this.m_data.indexOf(value) < 0) {
          this.m_data.push(value);
        }
      } else if (this.m_parent[this.m_key] !== value) {
        this.m_parent[this.m_key] = [this.m_parent[this.m_key], value];
      }
    }

    return this;
  }

  /**
   * Reverse of add(). Remove a key.
   *
   * @param {String} key
   * @returns {QueryString}
   */
  removeKey(key) {
    if (this.m_data && key in this.m_data) {
      delete this.m_data[key];
    }
    return this;
  }

  /**
   * Remove an array element by value. Can be used to reverse pushUnique().
   *
   * @param {String} value
   * @returns {QueryString}
   */
  removeFromArray(value) {
    if (this.m_data) {
      if (Array.isArray(this.m_data)) {
        const idx = this.m_data.indexOf(value);
        if (idx >= 0) {
          if (this.m_data.length === 1) {
            delete this.m_parent[this.m_key];
          } else {
            this.m_data.splice(idx, 1);
            if (this.m_data.length === 1) {
              this.m_parent[this.m_key] = this.m_data[0];
            }
          }
        }
      } else if (value === this.m_data) {
        // Scalars can behave like arrays in this class, so remove them too.
        delete this.m_parent[this.m_key];
        this.m_data = undefined;
      }
    }
    return this;
  }

  /**
   * @param {String} value
   * @returns {Boolean} - true if the value is contained in the array
   */
  isPresent(value) {
    if (Array.isArray(this.m_data)) {
      return this.m_data.indexOf(value) >= 0;
    } else {
      return value === this.m_data;
    }
  }

  /**
   * Return a set of array values, provided that the object's data is an array or scalar.
   *
   * @param {String} key - If provided, get the array from this child key. Otherwise, get the array
   *   from the current object.
   * @returns {object<string, boolean>} The set of values.
   */
  arrayValueSet(key = undefined) {
    const data = this.get(key);

    let rval = {};
    if (Array.isArray(data)) {
      for (let v of data) {
        rval[v] = true;
      }
    } else if (data && typeof data !== 'object') {
      rval[data] = true;
    }
    return rval;
  }

  /**
   * Consume the string that would be passed to Rails' FilterParameters.
   * The string is a pipe-delimited list of key/value pairs separated with a double-colon.
   * For example:
   * first_name::robert|city::new york
   *
   * @param {String|URLSearchParams} paramStr - A string of parameters, usually from
   *   document.location.search or a URLSearchParams instance representing the same.
   * @returns {QueryString}
   */
  parse(paramStr) {
    this._initialize();
    this._eachSearchParam(paramStr, (key, value) => {
      const keyParts = key.replace(/]/g, '').split(/\[/);
      this._unserializeParam(this.m_data, keyParts, value);
    });

    return this;
  }

  /**
   * Transform the parameters into a string that can be consumed by Rails' FilterParameters.
   *
   * @param {String} rootName - If specified, serialize {[rootName]: data}
   */
  toString(rootName = undefined) {
    if (this.m_data) {
      return this._serialize(rootName ? {[rootName]: this.m_data} : this.m_data);
    } else {
      return '';
    }
  }

  /**
   * Return true of the passed-in query string is equal to this object's query string.
   * This is a strict, ordered comparison to optimize performance.
   *
   * @param {QueryString|String} q - The query string to compare against.
   * @param {Boolean} nonOrdered - Ignore parameter order when making the comparison.
   */
  isEqual(q, nonOrdered = false) {
    if (nonOrdered) {
      throw new Error('non-ordered isEqual has not been implemented');
    } else {
      if (this === q) {
        return true;
      } else if (q instanceof QueryString) {
        return q.toString() === this.toString();
      } else {
        return q === this.toString();
      }
    }
  }

  /**
   * Return true if the query is empty.
   */
  isEmpty() {
    if (this.m_data) {
      if (Array.isArray(this.m_data)) {
        return this.m_data.length === 0;
      }
      return Object.keys(this.m_data).length === 0;
    }

    return true;
  }

  /**
   * Perform an action for each key/value pair.
   *
   * @param {function(string, *)} cb
   */
  forEach(cb) {
    if (!Array.isArray(this.m_data)) {
      objUtils.forEach(this.m_data, (key, a) => cb(key, [...a]));
    }
  }

  /**
   * Perform an action for each key/value pair, digging one level deep if an array is encountered.
   *
   * @param {function(string, *)} cb
   */
  forEachFlat(cb) {
    if (!Array.isArray(this.m_data)) {
      objUtils.forEach(this.m_data, (key, value) => {
        if (Array.isArray(value)) {
          value.forEach(elementValue => cb(key, elementValue));
        } else {
          cb(key, value);
        }
      });
    }
  }

  /**
   * Like forEachFlat, but behaves like objUtils.map().
   *
   * @param {function(string, string)} cb
   */
  mapFlat(cb) {
    if (!Array.isArray(this.m_data)) {
      const rval = [];
      this.forEachFlat((key, value) => rval.push(cb(key, value)));
      return rval;
    }
  }

  /**
   * Add parameters to a URL.
   *
   * @param {string} base - The base URL
   * @returns {string}
   */
  makeUrl(base) {
    const params = this.toString();

    if (params) {
      base += base.indexOf('?') >= 0 ? '&' : '?';
      base += params;
    }

    return base;
  }

  /**
   * Internal helper used by parse to iterate over key-value pairs in a parameter string or instance
   * of URLSearchParams.
   *
   * @param {String|URLSearchParams} params - a query string or instance of URLSearchParams over
   *   which to iterate
   * @param {function(string, string)} cb - a callback to invoked with each key-value pair from the
   *   query string
   * @private
   */
  _eachSearchParam(params, cb) {
    if (params instanceof URLSearchParams) {
      params.forEach((value, key) => cb(key, value));
    } else {
      params.replace(/^\?/, '').split('&').forEach(param => {
        const [key, value] = param.split('=', 2).map(decodeURIComponent);
        cb(key, value);
      });
    }
  }

  /**
   * Recursive function to turn data into a query string.
   *
   * @param {*} dataPtr - The current data object in the stack (starts as this.m_data).
   * @param {Array<String>} params - The query param accumulator.
   * @param {String} keyPrefix - The current key prefix, including key name and array references.
   * @private
   */
  _serialize(dataPtr, params = [], keyPrefix = '') {
    if (Array.isArray(dataPtr)) {
      dataPtr.forEach(val => this._serialize(val, params, `${keyPrefix}[]`));
    } else if (typeof dataPtr === 'object') {
      objUtils.forEach(dataPtr, (key, val) =>
        this._serialize(val, params, keyPrefix ? `${keyPrefix}[${key}]` : key)
      );
    } else {
      params.push(`${encodeURIComponent(keyPrefix)}=${encodeURIComponent(dataPtr)}`);
    }

    return params.join('&');
  }

  /**
   * Unserialize a multidimensional query string parameter.
   *
   * @param {Object} dataPtr - The current data object in the stack (starts as this.m_data).
   * @param {Array<String>} keyParts - A list of array references. For example, the parameter
   *   q[a][][b] would have the keyParts ['a', '', 'b']
   * @param {String} value - The value of the parameter.
   * @private
   */
  _unserializeParam(dataPtr, keyParts, value) {
    let newDataPtr;
    if (keyParts.length > 1) {
      // Recurse over the keyParts array. If keyParts[1], this is a hash table. Otherwise, it's an
      // array.
      newDataPtr = dataPtr[keyParts[0]] || (keyParts[1] ? {} : []);
      this._unserializeParam(newDataPtr, keyParts.slice(1), value);
    } else {
      // Terminal node in the recursion. Prepare to set the value.
      newDataPtr = value;
    }

    try {
      if (keyParts[0]) {
        dataPtr[keyParts[0]] = newDataPtr;
      } else {
        dataPtr.push(newDataPtr);
      }
    } catch (e) {
      // Drop it, so it doesn't cause problems in production, but log an error.
      console.log('QueryString._unserializeParam failed', dataPtr, keyParts, value);
    }
  }

  /**
   * Create initial data for this object.
   *
   * @param {*} value - The initial value.
   * @private
   */
  _initialize(value = {}) {
    this.m_parent[this.m_key] = value;
    this.m_data = this.m_parent[this.m_key];
  }
}
