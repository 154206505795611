import { loadFacebook as utilsLoadFacebook } from "utils";
export * from 'app_utils/lazy_load';
export * from 'app_utils/route_builder';

export const loadFacebook = () => utilsLoadFacebook(initialAppValues.share.facebookClientId);

/**
 * Is a feature enabled?
 *
 * @param {String} id - The feature id, from feature.rb. This is the lowercase string value, not
 *   the Ruby key.
 * @returns {Boolean}
 */
export const isFeatureEnabled = featureId => {
  return (
    window.initialAppValues.features &&
    window.initialAppValues.features.some(f => f.id === featureId)
  );
};

/**
 * Get the Rails environment.
 *
 * @returns {String} development, staging, test or production.
 */
export const getRailsEnv = () => window.initialAppValues.env;

