import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';
import { TrackStatPropType } from 'utils/prop_types';
import { onResizeManager } from 'utils/window_event_manager';
import element from 'utils/element';
import { matchPath, useLocation } from 'react-router';
import { makeClassName } from 'utils';
import { SmartLink } from 'controls/smart_link';
import { HandleBlurFromExternalEvent } from 'controls/handle_blur_from_external_event';
import { RoleButton } from 'aria/role_button';
import urlUtils from 'utils/url';
import { deepEquals } from 'utils/object';

import './section_navigation.sass';

/**
 * A navigation link within the standard section navigation bar.
 *
 * @param {object} props
 *   @param {string} props.action - the URL that the nav link routes to when clicked, and is used
 *     for determining whether this link is "active"
 *   @param {object} props.activeQueryParams - an object representing the active query parameters
 *     in order to match against a link's query parameters
 *   @param {Node} props.children - the content (usually just a string) of the nav link
 *   @param {boolean} [props.exact=false] - whether to consider the link active only if the path
 *     matches exactly (exact=true), or if matching a prefix of the location is sufficient
 *     (exact=false)
 *   @param {TrackingParams} [props.trackingParams] - optional object indicating that a stat should
 *     be tracked when the nav item is clicked
 *
 * @returns {React.element}
 * @constructor
 */
export const SectionNavigationLink = (props) => {
  const linkIsActive = (location, action, exact, matchByQuery) => {
    if (matchByQuery) {
      const actionParams = Object.fromEntries(urlUtils.params(action).entries());

      return matchPath(location.pathname, { path: action.split("?")[0], exact: exact }) &&
        deepEquals(actionParams, matchByQuery);
    } else {
      return matchPath(location.pathname, { path: action, exact: exact });
    };
  };

  // Compare the current location against the link's props to determine whether this is the active
  // link in the nav, and if so, add an appropriate CSS class
  const location = useLocation();
  const active = linkIsActive(location, props.action, props.exact, props.activeQueryParams);
  const linkClassName = makeClassName(
    'cr-section-navigation__link',
    active && 'cr-section-navigation__link--active'
  );

  return (
    <li className="cr-section-navigation__item" onClick={props.onClick}>
      <SmartLink
        className={linkClassName}
        action={props.action}
        // hard-coded target prop is because SmartLink will ignore the property if the URL is not
        // absolute, anyway, and absolute URLs we'd like to open in a new tab
        target="_blank"
        trackingParams={props.trackingParams}
      >
        <div className="cr-section-navigation__link-content">
          <div>{props.children}</div>
          {props.notificationCount > 0 &&
            <i
              aria-hidden
              className="cr-org-mgmt-organizations-nav-link__notification-icon cr-generic-section-header__heading-icon"
            >
              {props.notificationCount}
            </i>
          }
          {props.action.match(/^https?:/) && <FontAwesomeIcon icon={faExternalLinkSquare}/>}
        </div>
      </SmartLink>
    </li>
  );
};

SectionNavigationLink.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.node,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  activeQueryParams: PropTypes.object,
  trackingParams: TrackStatPropType,
  notificationCount: PropTypes.number
};

/**
 * The width of the "More ..." link that exposes additional navigation items, when they can't all
 * fit in the available horizontal space.
 *
 * DRY Violation: this value is highly dependent on the CSS for this component, if you change that,
 * also change this, and vice versa
 *
 * @type {number}
 */
const SHOW_MORE_WIDTH = 90;

/**
 * Standard section-level navigation bar, containing one or more SectionNavigation.Link components
 * as children.
 *
 * @param {object} props - React props for the component
 *   @param {Node} props.children - a list of SectionNavigation.Link components that comprise this
 *     nav bar
 * @returns {React.element}
 * @constructor
 */
function SectionNavigation(props) {
  const itemsList = useRef();
  const overflowList = useRef();
  const [firstOverflowed, setFirstOverflowed] = useState(-1);
  const [moreLeft, setMoreLeft] = useState(0);
  const overflowing = firstOverflowed >= 0;
  useLayoutEffect(() => {
    // monitor for overflowing items in the nav and if they appear or the index at which the items
    // start to overflow changes show/hide the "More"
    const onResize = () => {
      if (!itemsList.current) {
        return;
      }
      const lastInView = element.lastChildInView(
        itemsList.current,
        '.cr-section-navigation__item',
        SHOW_MORE_WIDTH
      );

      let newFirstOverflowed = -1;
      for (let i = 0; i < itemsList.current.children.length - 1; ++i) {
        if (itemsList.current.children[i] === lastInView) {
          newFirstOverflowed = i + 1;
        }
      }
      // if there is overflow, determine where the right edge of the last visible item is, and use
      // that as the left position for the "more" menu
      if (newFirstOverflowed >= 0) {
        setMoreLeft(lastInView.offsetLeft + lastInView.offsetWidth);
      }
      setFirstOverflowed(newFirstOverflowed);
    };
    onResize();
    onResizeManager.add(onResize);
    return () => onResizeManager.remove(onResize);
  }, [props.children, overflowing]);

  const [showingMore, setShowingMore] = useState(false);

  return (
    <nav
      className={makeClassName(
        'cr-section-navigation',
        overflowing && `cr-section-navigation--overflow-start-${firstOverflowed}`
      )}
    >
      <ul className="cr-section-navigation__items" ref={itemsList}>
        {props.children}
      </ul>
      {
        overflowing && (
          <div
            className="cr-section-navigation__overflow"
            ref={overflowList}
            style={typeof moreLeft === 'number' ? { left: moreLeft } : {}}
          >
            <RoleButton
              tagName="div"
              className={makeClassName(
                'cr-section-navigation__show-more',
                showingMore && 'cr-section-navigation__show-more--active'
              )}
              onClick={() => setShowingMore(state => !state)}
            >
              More
              <FontAwesomeIcon icon={faAngleDown}/>
            </RoleButton>
            <ul
              className={makeClassName(
                'cr-section-navigation__overflow-items',
                showingMore && 'cr-section-navigation__overflow-items--visible'
              )}
              onClick={() => setShowingMore(false)}
            >
              {props.children}
            </ul>
            <HandleBlurFromExternalEvent
              container={overflowList}
              onBlur={() => setShowingMore(false)}
            />
          </div>
        )
      }
    </nav>
  );
}

SectionNavigation.propTypes = {
  children: PropTypes.node
};

SectionNavigation.Link = SectionNavigationLink;

export { SectionNavigation };
