import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeClassName } from 'utils';
import { ROOT_PATH } from 'utils/routes';
import { SignIn } from 'pages/signin';
import { isGoogleSSOEnabled } from 'pages/signin/signin_utils';
import { HidesHeader } from 'header';
import { HomepageAdditionalSection } from "./homepage_additional_section";
import { HomepageReviewTile } from "./homepage_review_tile";
import { HomepageStatTile } from "./homepage_stat_tile";
import { SigninBadgeRow } from "../signin/signin_badge_row";
import LogoWhite from 'images/credly-logo-white.svg';
import Autodesk from 'images/autodesk.png';
import Esri from 'images/esri.png';
import Snowflake from 'images/snowflake.png';

import './homepage.sass';

/**
 * An array of action items to be displayed below the bodyText.
 * These items are intended to be expanded for FormattedMessage props.
 * @type Array<{ id: String, defaultMessage: String }>
 */
const actionItems = [
  {
    id: "homepage.action.item.grow",
    defaultMessage: "Grow your professional skills"
  },
  {
    id: "homepage.action.item.broadcast",
    defaultMessage: "Broadcast your skills to managers at your company"
  },
  {
    id: "homepage.action.item.earn",
    defaultMessage: "Earn credentials that translate to college credit"
  },
  {
    id: "homepage.action.item.discover",
    defaultMessage: "Discover new professional opportunities"
  }
];

const homepageStats = [
  {
    headerText: "0:00:01",
    headerTextId: "homepage.stats_section_header_one",
    bodyText: "Every second, a digital badge is earned, shared, verified, or used on Credly",
    bodyTextId: "homepage.stats_section_body_one"
  },
  {
    headerText: "50M+",
    headerTextId: "homepage.stats_section_header_two",
    bodyText: "Badges earned and managed on Credly",
    bodyTextId: "homepage.stats_section_body_two"
  },
  {
    headerText: "650K+",
    headerTextId: "homepage.stats_section_header_three",
    bodyText: "Badges shared from Credly every month",
    bodyTextId: "homepage.stats_section_body_three"
  }
];

const homepageReviews = [
  {
    headerText: "“Clients feel more confident when I show them my digital credentials.”",
    headerTextId: "homepage.reviews_section_header_one",
    bodyText: "Autodesk badge holder",
    bodyTextId: "homepage.reviews_section_body_one",
    labelText: "Mexico",
    labelTextId: "homepage.reviews_section_label_one",
    imageUrl: Autodesk
  },
  {
    headerText: "“I’m now considered the expert throughout all of my company.”",
    headerTextId: "homepage.reviews_section_header_two",
    bodyText: "Snowflake badge holder",
    bodyTextId: "homepage.reviews_section_body_two",
    labelText: "United States",
    labelTextId: "homepage.reviews_section_label_two",
    imageUrl: Snowflake
  },
  {
    headerText: "“I added my digital badges to my resume and received 3 job offers.”",
    headerTextId: "homepage.reviews_section_header_three",
    bodyText: "Esri badge holder",
    bodyTextId: "homepage.reviews_section_body_three",
    labelText: "India",
    labelTextId: "homepage.reviews_section_label_three",
    imageUrl: Esri
  }
];

/**
 * The homepage is a wrapper component that displays the homepage screen and accepts various
 * dynamic prop values to plug in visual content at various places on the homepage screen.
 *
 * This must be kept in sync with the SEO version - /views/dashboard/_show.html.haml
 *
 * @param {Object} props
 *   @param {string|ReactElement} props.bodyContent - Body content underneath a header at the
 *   top left section of the homepage, defaults to "Join millions of professionals, recruiters,
 *   and employers... "
 *   @param {string} props.headerText - Header Text that headlines the body text at the top left
 *   section of the homepage. Defaults to "Respected Brands. Resume-worth Achievements."
 *   @param {boolean} props.isSignUpPendingBadge - A property to determine if the homepage
 *   is rendering content related to signing up for a pending badge.
 *   @param {string|ReactElement} props.supplementalBottomContent - Render content to be displayed
 *   at the bottom of the homepage. If none is provided defaults to default supplemental content.
 *   @param {string} props.supplementalBottomContentContainerClassName - A classname that can be
 *   provided to style the bottom content container for custom content. Useful to customize the
 *   spacing from absolute content that is positioned above.
 * @returns {ReactElement} A homepage element
 */
export const Homepage = (props) => {
  const signinSignupPendingBadge = props.isSignUpPendingBadge || props.isSignInPendingBadge;
  return (
    <HidesHeader>
      <div className={signinSignupPendingBadge && 'homepage__is-pending-badge'}>
        <div className="homepage__top-content">
          <div className="container homepage__top-content--container">
            <div className="row homepage__top-content--container_row">
              <div className="col-md-5 homepage__landing-text" ref={props.canvasFocusRef}>
                <Link to={ROOT_PATH}>
                  <img
                    src={LogoWhite}
                    alt="Credly logo"
                    className="homepage__logo"
                  />
                </Link>
                <h1 className="homepage__header">
                  {props.headerText ? (
                    props.headerText
                  ) : (
                    <FormattedMessage
                      id="homepage.header_text.level_up_career"
                      defaultMessage="Level up your career."
                    />
                  )}
                </h1>
                {(signinSignupPendingBadge && props.badge) &&
                  <SigninBadgeRow
                    appearance="accept-badge-landing"
                    className="create-account-choose__form-badge-info d-none d-md-flex"
                    badge={props.badge}
                  />
                }
                <div className="homepage__top-text-container">
                  {props.bodyContent ? (
                    props.bodyContent
                  ) : (
                    <>
                      <p className="homepage__top-text">
                        <FormattedMessage
                          id="homepage.body_text.join_millions"
                          defaultMessage="Join millions of professionals, recruiters, and employers on Credly, where you can:"
                        />
                      </p>
                      <ul>
                        {actionItems.map((item, index) => (
                          <li key={`action-item-${index}`}>
                            <div className="homepage__action-item-container">
                              <span className="homepage__icon">
                                <FontAwesomeIcon icon={faCheckCircle}/>
                              </span>
                              <FormattedMessage {...item} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6 offset-md-1 homepage__children">
                <div className={makeClassName(
                  'homepage__signin-wrapper',
                  props.isSignInPendingBadge ? 'homepage__signin-wrapper--signin-accept' : '',
                  props.isSignUpPendingBadge ? 'homepage__signin-wrapper--signup-accept' : '',
                  (
                    isGoogleSSOEnabled() && signinSignupPendingBadge
                      ? 'homepage__signin-wrapper--sso'
                      : ''
                  )
                )}
                >
                  {props.children ? props.children : <SignIn/>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={makeClassName(
            'homepage__bottom-content',
            props.supplementalBottomContentContainerClassName,
            signinSignupPendingBadge ? 'homepage__bottom-content--badge-pending' : ''
          )}
        >
          <div className="container">{props.supplementalBottomContent}</div>
          {signinSignupPendingBadge &&
            <Fragment>
              <HomepageAdditionalSection
                type="stats"
              >
                {homepageStats.map((stat) => (
                  <HomepageStatTile
                    key={stat}
                    header={
                      <FormattedMessage
                        id={stat.headerTextId}
                        defaultMessage={stat.headerText}
                      />}
                    body={
                      <FormattedMessage
                        id={stat.bodyTextId}
                        defaultMessage={stat.bodyText}
                      />
                    }
                  />
                ))}
              </HomepageAdditionalSection>
              <HomepageAdditionalSection
                type="reviews"
              >
                {homepageReviews.map((review) => (
                  <HomepageReviewTile
                    key={review}
                    header={
                      <FormattedMessage
                        id={review.headerTextId}
                        defaultMessage={review.headerText}
                      />}
                    body={
                      <FormattedMessage
                        id={review.bodyTextId}
                        defaultMessage={review.bodyText}
                      />
                    }
                    {...review}
                  />
                ))}
              </HomepageAdditionalSection>
            </Fragment>
          }
        </div>
      </div>
    </HidesHeader>
  );
};

Homepage.propTypes = {
  badge: PropTypes.object,
  bodyContent: PropTypes.node,
  canvasFocusRef: PropTypes.object,
  children: PropTypes.any,
  headerText: PropTypes.node,
  isSignUpPendingBadge: PropTypes.bool,
  isSignInPendingBadge: PropTypes.bool,
  supplementalBottomContent: PropTypes.node,
  supplementalBottomContentContainerClassName: PropTypes.string,
  supplementalTopContent: PropTypes.node
};
