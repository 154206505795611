import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as AriaMenu from 'react-aria-menubutton';
import { makeClassName, uniqueId } from 'utils';
import { useDeepEquals } from 'utils/react_utils';
import CaretDownSVG from 'svg/caret-down-solid.svg';
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from "translations";

import './selector_menu.sass';

/**
 * A simple, accesssible menu. Use this when <Select> is overkill (or when you don't want to import
 * the form.js chunk).
 *
 * @property {String} label - The text label to show before the select menu.
 * @property {String} ariaLabel - A label for accessibility purposes. Useful when the `label` prop
 *   is omitted.
 * @property {function(string)} onSelect - Called with the selected value when selection changes.
 * @property {array<{value: string, text: string, to: string}>} options - Menu options.
 *   - value: The value. Can match props.selectedValue, and is passed back in props.onSelected.
 *   - text: The text to display.
 *   - to: Optional. Go to this url when clicked. If all options have this property, onSelect is
 *     not required.
 * @property {Boolean} iconOnly - Only show the icon. Don't show the currently selected item.
 */
export const SelectorMenu = props => {
  const history = useHistory();
  const labelId = React.useRef(`selector_menu_${uniqueId()}`);

  const selectedOption =
    props.options.find(item => item.value === props.selectedValue) || props.options[0];

  const onSelect = React.useCallback(value => {
    props.onSelect && props.onSelect(value);

    const opt = props.options.find(item => item.value === value);
    if (opt.to) {
      // Something about react-aria-menubutton prevents history changes in event context, so a
      // timeout must be used.
      setTimeout(() => history.push(opt.to), 0);
    }
  }, [props.onSelect, useDeepEquals(props.options)]);

  // Prevent clicks on the menu button from propagating to parent components. This should happen in
  // onSelect using its second parameter (an Event), but react-aria-menubutton must be handling that
  // event asynchronously, because blocking it has no effect.
  const blockButtonPropagation = React.useCallback(e => e.preventDefault(), []);

  const buttonProps = {
    className: makeClassName(
      'c-selector-menu__current-selection',
      props.iconOnly && 'c-selector-menu__icon-only-display'
    )
  };
  if (props.label) {
    buttonProps['aria-labelledby'] = labelId.current;
  }
  if (props.ariaLabel) {
    buttonProps['aria-label'] = props.ariaLabel;
  }

  return (
    <div className={makeClassName('c-selector-menu', props.className)}>
      {
        props.label && (
          <span className="c-selector-menu__label" id={labelId.current}>
            {props.label}&nbsp;
          </span>
        )
      }
      <AriaMenu.Wrapper className="c-selector-menu__menu_wrapper" onSelection={onSelect}>
        <span onClick={blockButtonPropagation}>
          <AriaMenu.Button {...buttonProps}>
            {!props.iconOnly && (
              <span>
                <FormattedMessage
                  id={intlKeyFromValue(selectedOption.text, "selector.items")}
                  defaultMessage={selectedOption.text}
                />
              </span>
            )}
            <CaretDownSVG />
          </AriaMenu.Button>
        </span>
        <AriaMenu.Menu className="c-selector-menu__options" tag="ul">
          {props.options.map(item =>
            <AriaMenu.MenuItem
              className={makeClassName(
                'c-selector-menu__option',
                item.value === selectedOption.value && 'c-selector-menu__option--selected'
              )}
              tag="li"
              key={item.value}
              value={item.value}
            >
              <FormattedMessage
                id={intlKeyFromValue(item.text, "selector.items")}
                defaultMessage={item.text}
              />
            </AriaMenu.MenuItem>
          )}
        </AriaMenu.Menu>
      </AriaMenu.Wrapper>
    </div>
  );
};

SelectorMenu.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ariaLabel: PropTypes.string,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    to: PropTypes.string
  })),
  iconOnly: PropTypes.bool
};
