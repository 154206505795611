import React, { useCallback, useState } from 'react';
import PropTypes from "prop-types";

/**
 * React.Context for overriding the default site footer with a custom one for certain contexts.
 * @type {React.Context<{customFooter: null, setCustomFooter: (function(): null)}>}
 */
export const SiteFooterContext = React.createContext({
  customFooter: null, setCustomFooter: () => null
});

export const SiteFooterContextProvider = ({ children }) => {
  const [footer, setFooter] = useState(null);

  const setCustomFooter = useCallback((newFooter) => {
    setFooter((oldFooter) => {
      if (!!oldFooter && !!newFooter) {
        // eslint-disable-next-line no-console
        console.warn('Cannot set custom footer when one is already in use');
      }

      return newFooter;
    });
  }, []);

  return (
    <SiteFooterContext.Provider value={{ customFooter: footer, setCustomFooter }}>
      {children}
    </SiteFooterContext.Provider>
  );
};

SiteFooterContextProvider.propTypes = {
  children: PropTypes.any
};
