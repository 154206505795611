import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { FormattedMessage } from "react-intl";
import { makeClassName } from 'utils';

import './homepage_additional_section.sass';

/**
 * Component for displaying additional content on the homepage.
 * Shows a header on top, with 3-column row of generic components underneath.
 *
 * @property {string} className - identifying class name for the section
 * @property {string} headerText - a string containing the header text for the section
 * @property {string} headerTextId - the localization key for the headerText
 * @property {node} children - the components to be rendered in the columns
 */
export const HomepageAdditionalSection = props => {
  let className;
  let headerText;
  let headerTextId;
  if (props.type === 'stats') {
    className = "homepage__stats-wrapper";
    headerText = "The world's most trusted digital credential network";
    headerTextId = "homepage.header_text.stats_section";
  } else if (props.type === 'reviews') {
    className = 'homepage__reviews-wrapper';
    headerText = "New skills = Endless opportunities";
    headerTextId = "homepage.header_text.reviews_section";
  }

  return (
    <div className={makeClassName(
      'homepage__additional-section-wrapper',
      className
    )}
    >
      <div className="container">
        <Heading
          appearance="section-title"
          className={makeClassName(
            'homepage__additional-section--heading',
            (className + '--header')
          )}
        >
          <FormattedMessage
            id={headerTextId}
            defaultMessage={headerText}
          />
        </Heading>
        <div className="row homepage__additional-section__row">
          {props.children.map((child, index) => {
            return (
              <div key={index} className="col-12 col-md-4">
                {child}
              </div>);
          })}
        </div>
      </div>
    </div>
  );
};

HomepageAdditionalSection.propTypes = {
  type: PropTypes.oneOf(['stats', 'reviews']).isRequired,
  children: PropTypes.node.isRequired
};
