import React, { useCallback } from 'react';
import { employerActions } from 'reference';
import { Form } from 'form';

export const EmployerField = (props) => {
  const [employerSearchState, employerSearchActionPerform] = employerActions.useAction('list');

  const employerSearchAction = useCallback((query) => {
    return employerSearchActionPerform({ query });
  }, [employerSearchActionPerform]);

  return (
    <Form.TypeaheadTextField
      searchAction={employerSearchAction}
      searchActionState={employerSearchState}
      iconStyle="none"
      {...props}
    />
  );
};
