export function badgeToBadgeMedium(badge){
  return {
    image_url: badge.image_url,
    issuer_name: badge.issuer.entities.find((e) => e.primary).entity.name,
    name: badge.badge_template.name,
    id: badge.id,
    expires_at: badge.expires_at
  };
}

/**
 * Given a standard badge issuer payload, returns the primary issuer entity.
 *
 * @param issuer - standard badge issuer payload, containing summary and entities keys
 * @returns {object}
 */
export const findPrimaryIssuer = (issuer) => issuer.entities.find((e) => e.primary).entity;


/**
 * Returns true if the badge is expired.
 *
 * @returns {boolean}
 */
export const isExpired = date => date && Date.now() > new Date(date).getTime();

/**
 * Given an endorsement, returns true if the endorsement is displayable.
 *
 * @param {object} endorsement - an object containing endorsement data
 * @returns {boolean} - whether the endorsement is displayable
 */
export const isDisplayableEndorsement = (endorsement) => !!endorsement.description;
