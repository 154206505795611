import React from 'react';
import { DefaultSiteFooter } from './default_site_footer';
import { SiteFooterContext } from './site_footer_context';

/**
 * Render the site footer - either default or custom - based on the current SiteFooterContext value.
 * @param {Object} _props - ignored; React props for component
 * @returns {React.element}
 * @constructor
 */
export const SiteFooter = (_props) => (
  <SiteFooterContext.Consumer>
    {value => value.customFooter || <DefaultSiteFooter/>}
  </SiteFooterContext.Consumer>
);
