import React from "react";
import PropTypes from "prop-types";
import { useAlerts } from "alert";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import { JobDetailsLocationWorkplacePreferencesAndComp } from './job_details_location_workplace_preferences_and_comp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { LoadingSpinner } from "controls/loading_spinner";
import { TagList, Tag } from 'controls/tags';
import { ApplyToJob } from "./apply_to_job";
import { BookmarkJob } from "./bookmark_job";
import orgPlaceholderImage from 'images/job_board_chiclet_org_logo_default.png';
import "./job_details.sass";
import { useQueryClient } from '@tanstack/react-query';
import { useSaveJobPostBookmarkMutation, useDeleteJobPostBookmarkMutation } from './hooks/use_job_post_bookmark';
import { tracking } from '../../app_utils/tracking';

export const JobDetails = (props) => {
  const alerts = useAlerts();
  const job = props.job;
  const savedJobsRoute = '/job_board?statusFilter=saved';
  const queryClient = useQueryClient();

  const saveBookmarkMutation = useSaveJobPostBookmarkMutation(job.id, {
    onSuccess: () => {
      queryClient.invalidateQueries('jobPosts');
      saveAlert();
    },
    onError: () => {
      oopsSomethingWentWrong();
    }
  });

  const deleteBookmarkMutation = useDeleteJobPostBookmarkMutation(job.id, {
    onSuccess: () => {
      queryClient.invalidateQueries('jobPosts');
      deleteAlert();
    },
    onError: () => {
      oopsSomethingWentWrong();
    }
  });

  const oopsSomethingWentWrong = () => {
    alerts.add({
      type: 'error',
      text: (
        <FormattedMessage
          id="talent_match.job_board.save_error"
          defaultMessage="Oops something went wrong! Please try again."
        />
      )
    });
  };

  const saveAlert = () => {
    alerts.add({
      type: 'notice',
      text: (
        <FormattedMessage
          id="talent_match.job_board.save_success"
          defaultMessage="Your job has been saved. Go to {link} jobs."
          values={{
            link: (
              <a href={savedJobsRoute}>
                <FormattedMessage
                  id="talent_match.job_board.saved_posts_link"
                  defaultMessage="Saved"
                />
              </a>)
          }}
        />
      )
    });
  };

  const deleteAlert = () => {
    alerts.add({
      type: 'notice',
      text: (
        <FormattedMessage
          id="talent_match.job_board.bookmark_removed"
          defaultMessage="This job has been removed from your saved jobs."
        />
      )
    });
  };
  const trackJobView = () => {
    if (props.readOnly) return;
    tracking.track({
      type: 'job.viewed',
      object_id: job.job_requisition_id,
      object_type: 'JobRequisition',
      snapshot_json: {
        candidate_id: props.currentUserId,
        autoload: props.autoload
      }
    });
  };

  trackJobView();

  const submitBookMark = () => {
    if (props.bookmarked) {
      deleteBookmarkMutation.mutate();
    } else {
      saveBookmarkMutation.mutate();
    };
  };

  // TODO: pull this into it's own file to share with/DRY up:
  // app/javascript/src/pages/credly_recruit/job_requisition_details.jsx
  const formatAcceptedLevelsText = (ac) => {
    switch (ac) {
      case "intern_trainee":
        return "Intern/Trainee";
      case "entry_level":
        return "Junior/Entry Level";
      case "mid_level":
        return "Mid Level";
      case "lead_manager":
        return "Lead/Manager";
      case "senior_manager_director":
        return "Senior Manager/Director";
      case "executive":
        return "Executive";
    }
  };

  // TODO: pull this into it's own file to share with/DRY up:
  // app/javascript/src/pages/occupations/occupation_top_skills.jsx
  const SkillTag = ({
    name,
    id
  }) => {
    return (
      <Link
        to={`/skills/${id}`}
        className="details-skill-link"
        title={name}
      >
        <Tag
          clickable
          ui="skill"
        >
          {name}
        </Tag>
      </Link>
    );
  };

  SkillTag.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  };

  return (
    <>
      {!job ? (
        <LoadingSpinner delay={200} position="below" />
      ) : (
        <div>
          <div>
            <div className="org-logo-details-bookmark-wrapper">
              <img className="details-org-logo" src={job.company_logo?.image || orgPlaceholderImage} />
              {!props.readOnly &&
                <button
                  className="bookmark-button"
                  onClick={() => submitBookMark()}
                  disabled={saveBookmarkMutation.isLoading || deleteBookmarkMutation.isLoading}
                >
                  {props.bookmarked ? (
                    <FontAwesomeIcon className="bookmark-button-icon-unsave" icon={faBookmark} />
                  ) : (
                    <FontAwesomeIcon className="bookmark-button-icon-save" icon={faBookmark} />
                  )}
                </button>
              }
            </div>
            <div className="details-title">
              {job.title}
            </div>
            <div className="details-org">
              {job.company_name}
            </div>
            <div className="details-accepted-levels">
              {job.accepted_levels.map((level) => formatAcceptedLevelsText(level)).join(', ')}
            </div>
            <div className="details-location-type-salary">
              <JobDetailsLocationWorkplacePreferencesAndComp
                city={job.city}
                state_or_province={job.state_or_province}
                workplace_preferences={job.workplace_preferences}
                compensation_type={job.compensation_type}
              />
            </div>
            <div className="details-salary">
              <FormattedNumber
                value={job.compensation_minimum}
                style="currency"
                currency="USD"
              />
              &nbsp;&ndash;&nbsp;
              <FormattedNumber
                value={job.compensation_maximum}
                style="currency"
                currency="USD"
              />
            </div>
            <div className="details-visa-info">
              {job.sponsorship_available ? "Visa Sponsorship Available" : "Visa Sponsorship Not Available"}
            </div>
          </div>
          <hr className="details-break" />
          {(job.skills.length > 0) &&
            <>
              <div>
                <div className="details-skill-reqs">Skill Requirements</div>
                <TagList ui="skill" >
                  {
                    job.skills.map(skill => (
                      <SkillTag
                        key={`skill-${skill.id}`}
                        name={skill.name}
                        id={skill.id}
                      />
                    ))
                  }
                </TagList>
              </div>
              <hr className="details-break" />
            </>
          }
          <div>
            <div className="details-about-the-job">About the job</div>
            <div className="details-about-the-job-content">
              <p>{job.description}</p>
            </div>
          </div>
          {!props.readOnly &&
            <div className="details-actions-wrapper">
              <BookmarkJob
                submitBookmark={() => submitBookMark()}
                bookmarkedJob={props.bookmarked}
              />
              <ApplyToJob
                jobId={job.id}
                appliedToJob={job.applied_to_job}
              />
            </div>
          }
        </div>
      )}
    </>
  );
};

JobDetails.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    applied_to_job: PropTypes.bool,
    bookmarked_job: PropTypes.bool,
    number_of_days_since_posting: PropTypes.number,
    title: PropTypes.string,
    city: PropTypes.string,
    state_or_province: PropTypes.string,
    accepted_levels: PropTypes.arrayOf(PropTypes.string),
    workplace_preferences: PropTypes.arrayOf(PropTypes.string),
    compensation_type: PropTypes.string,
    compensation_minimum: PropTypes.string,
    compensation_maximum: PropTypes.string,
    job_requisition_id: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })),
    sponsorship_available: PropTypes.bool,
    description: PropTypes.string,
    company_name: PropTypes.string,
    company_logo: PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string
    })
  }),
  bookmarked: PropTypes.bool,
  readOnly: PropTypes.bool,
  currentUserId: PropTypes.string,
  autoload: PropTypes.bool
};
