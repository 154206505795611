import { createSelector } from 'reselect';

/**
 * Get the primary id for the profile resource.
 *
 * @param {Object} profileResources
 * @returns {Object}
 */
export const profileSelfId = profileResources => {
  if (profileResources.lists.self) {
    return profileResources.lists.self[0];
  } else {
    return null;
  }
};

/**
 * Get the user's profile from state.
 *
 * @param {Object} state - The redux store
 * @returns {Object}
 */
export const profileResource = state => state.profile.resources[profileSelfId(state.profile)];

/**
 * Create a memoizing selector that gets the user's profile from state.
 *
 * @param {Object} state - The redux store
 * @returns {Object}
 */
export const makeProfileSelector = () => createSelector(
  state => state.profile.resources,
  state => profileSelfId(state.profile),
  (profileResources, selfId) => profileResources[selfId]
);

