import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { FocusTrap } from 'controls/focus_trap';
import { useNoScroll } from 'controls/no_scroll';

import './modal.sass';

/**
 * Renders content in a modal that obscures the rest of the page content and traps focus.
 *
 * @param {object} props
 *   @param {("light"|"dark")} [props.backdropTheme="dark"] - determines whether the modal backdrop
 *     is light or dark (i.e. how much the context of the modal should be visible vs. obscured);
 *     default is dark
 *   @param {Function():React.element} props.children - a function to render the content of the
 *     modal; receives an object containing a ref openFocusRecipientRef, which can be used to define
 *     an element that should be focused when the modal first opens
 *   @param {boolean} [props.disableBackdropClose=false] - whether to prevent the modal from closing
 *     when the backdrop is clicked
 *   @param {Function} props.onClose - a function that will be called when one of the events that
 *     Modal listens for to determine that is should close occurs (i.e. Escape key is pressed or
 *     backdrop is clicked); calling this function should cause Modal to be removed from a
 *     subsequent render (unless something should prevent that, such as a validation error)
 * @returns {React.element}
 * @constructor
 */
export const Modal = (props) => {
  // Ref to provide to render prop so that the modal's content can be focused when it mounts
  const openFocusRecipientRef = useRef();

  // On mount: disable scrolling and focus the modal content
  // On unmount: re-enable scrolling
  useEffect(() => {
    openFocusRecipientRef.current && openFocusRecipientRef.current.focus();
  }, []);

  // disable scrolling
  useNoScroll(true);

  const handleModalKeyDown = useCallback((evt) => {
    if (evt.key === "Escape") {
      evt.stopPropagation();
      props.onClose();
    }
  }, [props.onClose]);

  const handleBackdropClick = useCallback(() => {
    !props.disableBackdropClose && props.onClose();
  }, [props.onClose, props.disableBackdropClose]);

  return createPortal(
    (
      <FocusTrap>
        <div
          className="cr-modal__container"
          onKeyDown={handleModalKeyDown}
        >
          <div
            className={makeClassName(
              'cr-modal__backdrop',
              props.backdropTheme === 'light' && 'cr-modal__backdrop--light'
            )}
            onClick={handleBackdropClick}
          />
          {props.children({ openFocusRecipientRef })}
        </div>
      </FocusTrap>
    ), document.body
  );
};

Modal.propTypes = {
  backdropTheme: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.func.isRequired,
  disableBackdropClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

Modal.defaultProps = {
  backdropTheme: 'dark',
  disableBackdropClose: false
};
