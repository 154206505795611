import 'regenerator-runtime'; // Needed for Webpack boilerplate code.
import { config } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleWrapper } from 'translations/locale_wrapper';
import { LoadingSpinner } from 'controls/loading_spinner';
import { Routes } from 'routes';
import { ApplicationStructure, prerender } from 'pages/app_structure';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'queries/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from "react-helmet-async";
import { defaultFallbackInView } from 'react-intersection-observer';

// This is a global setting for IntersectionObserver API.
// if a user is using our site with a browser that doesn't support IntersectionObserver API,
// it will fail gracefully rather than crashing react. By setting it to false, we are telling
// InView NOT to trigger thereby not sending a view event to the acclaim stats api.
// Also, DON'T use 'react-intersection-observer' for any critical path user experience!
// It will fail on old browsers.
defaultFallbackInView(false);

document.addEventListener('DOMContentLoaded', initApplication);

// squash FontAwesome's desire to inject additional CSS into our page
config.autoAddCss = false;

function initApplication() {
  const rootNode = document.querySelector('#root');
  if (rootNode.getAttribute('data-suppress-application-init')) {
    return;
  }

  prerender();

  const userLocale = defineUserLocale();
  // Note: The upcoming createRoot feature of ReactDOM makes the page load much faster.
  ReactDOM.render(
    <QueryClientProvider client={queryClient} >
      <HelmetProvider>
        <LocaleWrapper
          userLocale={userLocale}
          loadingPlaceholder={<LoadingSpinner position="center" delay={500}/>}
        >
          <ApplicationStructure hasSiteHeader hasSiteFooter>{() =>
            <Routes />
          }
          </ApplicationStructure>
        </LocaleWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </HelmetProvider>
    </QueryClientProvider>,
    rootNode
  );
}

export function defineUserLocale() {
  const english = 'en';
  const globals = window.initialAppValues ? window.initialAppValues : english;
  const userLocale = globals === english ? english : (
    globals.currentUser.locale ? globals.currentUser.locale : english
  );
  return userLocale;
}
