import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import './heading.sass';

// Start at level 1
const HeadingLevel = React.createContext(1);

/**
 * Heading component to replace <h1>, <h2> etc. This manages the current heading level using
 * <NewHeadingLevel> containers. For example:
 *
 * <Heading>This is an h1</Heading>
 * <NewHeadingLevel>
 *   <Heading>This is an h2</Heading>
 *   <Heading level={1}>Override the current level, and render an h1</Heading>
 * </NewHeadingLevel
 *
 * @param {Object} props
 *   @param {int} props.level - Ignore the current heading level, and force a specific level.
 *   @param {String} props.appearance - indicates what visual style the heading should have
 * @constructor
 */
export const Heading = props => {
  if (props.appearance === 'custom' && !props.className) {
    throw new Error(
      'Heading: You specified the "custom" appearance but did not specify a className'
    );
  }
  const className = makeClassName('ac-heading', `ac-heading--${props.appearance}`, props.className);
  const children = props.children;
  const passThroughProps = { ...props };
  delete passThroughProps.appearance;

  const renderHeader = (level, localClassName) => {
    const H = 'h' + Math.min(level || 1, 6);
    return (
      <H
        {...passThroughProps}
        className={localClassName}
      >{children}
      </H>);
  };

  const render = level => {
    // Special handler for gray-box, since it's full width with a responsive inner container.
    if (props.appearance === 'gray-box') {
      return (
        <div className={className}>
          {renderHeader(level, 'container')}
        </div>
      );
    }

    return renderHeader(level, className);
  };

  if (props.level) {
    return render(props.level);
  } else {
    return <HeadingLevel.Consumer>{render}</HeadingLevel.Consumer>;
  }
};

Heading.propTypes = {
  appearance: PropTypes.oneOf([
    'action-page-head',
    'badge-name-hero',
    'primary',
    'bold-primary',
    'serif-primary',
    'serif-primary-large',
    'serif-large',
    'button-nav-label',
    'section-subheader',
    'subpage-head',
    // has no styles, callers should style the heading as needed using className
    'custom',
    'db-badge-title',
    'lmi-insights-header',
    'gray-box',
    'section-title',
    'settings-head',
    'subhead',
    'small-caps',
    'plain-text',
    'org-mgmt-subhead',
    'org-dashboard-subhead',
    'workforce-subhead',
    'job-requisition-form--main',
    'job-requisition-form--subheader'
  ]).isRequired,
  level: PropTypes.number
};

export const useCurrentHeadingLevel = () => {
  return React.useContext(HeadingLevel);
};

export const useCurrentHeadingLevelAsHn = () => {
  const headingLevel = useCurrentHeadingLevel();
  return `h${headingLevel}`;
};

/**
 * Headings inside this container have a level one higher.
 *
 * @param {Object} props - Supports all valid properties for a <h1> tag.
 * @returns {*}
 * @constructor
 */
export const NewHeadingLevel = props =>
  <HeadingLevel.Consumer>{level =>
    <HeadingLevel.Provider value={level + 1}>
      {props.children}
    </HeadingLevel.Provider>
  }
  </HeadingLevel.Consumer>;

NewHeadingLevel.propTypes = {
  children: PropTypes.node.isRequired
};
