import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PaginationMetadata } from 'utils/prop_types';
import { usePaginatedUrl } from './use_paginated_url';

/**
 * Renders list of page numbers with links
 *
 * @param {Object} props
 *   @param {PaginationMetadata} props.data - pagination metadata
 * @returns {JSX.Element}
 */
export const PaginationPages = (props) => {
  const data = props.data;

  const start = Math.max(Math.min(data.current_page - 2, data.total_pages - 4), 1);
  const end = Math.min(start + 4, data.total_pages);
  const pagesToShow = [];
  for (let x = start; x <= end; x++) {
    pagesToShow.push(x);
  }
  const paginatedUrl = usePaginatedUrl();

  return (
    pagesToShow.length < 3 ? <div /> : (
      <div className="pagination__pages">
        {pagesToShow.map(p => {
          if (data.current_page === p) {
            return (
              <span
                key={'page' + p}
                className="pagination__page--current pagination__page"
                aria-current="page"
                tabIndex="0"
              >
                {p}
              </span>
            );
          } else {
            return (
              <Link
                key={'page' + p}
                to={paginatedUrl(p)}
                className="pagination__page"
                aria-label={'Go to page ' + p}
              >
                {p}
              </Link>
            );
          }
        })}
      </div>
    )
  );
};

PaginationPages.propTypes = {
  data: PropTypes.shape(PaginationMetadata)
};
