/**
 * Return the substring which is between the strings "start" and "end."
 * For example, substrBetween('hi there', ' ', 're') = 'the'
 *
 * @param {String} str - Search this string.
 * @param {String} start - Start with this substring.
 * @param {String} end - End with this substring.
 * @returns {String|null} The found string.
 */
export const substrBetween = (str, start, end) => {
  let sIdx = str.indexOf(start);
  if (sIdx >= 0) {
    sIdx += start.length;

    const eIdx = str.indexOf(end, sIdx);
    if (eIdx >= 0) {
      return str.substring(sIdx, eIdx);
    }
    return str.join(' ');
  }
  return null;
};

/**
 * Substitute keys in square brackets with values in an object.
 *
 * @param {String} str - A string with square-bracketted substitution strings.
 * @param {Object} subst - Keys to look up in the string, and replacements.
 * @returns {*}
 */
export const substituteBracketKeys = (str, subst) => {
  for (const k of Object.keys(subst)) {
    str = str.replace(new RegExp('\\[' + k + '\\]', 'g'), subst[k]);
  }
  return str;
};

/**
 * "Normalizes" input by trimming spaces and converting to lower-case.
 *
 * @param {String} val - the value to normalize
 * @returns {string}
 */
export const normalizeCaseInsensitive = (val) => {
  return (val || '').trim().toLowerCase();
};

/**
 * returns title case from param's snake case
 *
 * @param str
 * @returns {string}
 */
export const titleFromSnakeCase = (str) => {
  str = str.toString().split('_');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].substring(1);
  }
  return str.join(' ');
};

/**
 * returns sentence case from string param
 *
 * @param str
 * @returns {string}
 */
export const sentenceCase = (str) => {
  if (typeof str === "string" || str instanceof String) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};


/**
* A more sophisticated wrapper around substituteBracketKeys. Examples:
*
* let params = {special_food: 'pie'};
* const subs = new BracketSubs({food: {food: 'special_food'}});
* const str = subs.substitute('I like [food]', params);
* // str = "I like pie". params is unchanged.
*
* let params = {special_food: 'pie'};
* const subs = new BracketSubs({food: {food: 'special_food', remove: true}});
* const str = subs.substitute('I like [food]', params);
* // str = "I like pie". params = {}
*
* @type {stringUtils.BracketSubs}
*/
export class BracketSubs {
  /**
   * @param {object<string, {param:string, remove:boolean}>} subs - Substitution strings. The key
   *   is the string to substitute. The value can include 'param,' which is the name of a parameter
   *   used by .substitute(), and 'remove,' which causes .substitute() to remove the variable from
   *   the params list.
   */
  constructor(subs) {
    this.m_subs = subs || {};
  }


  /**
   * Substitute strings in square brackets, using the class data.
   *
   * @param {String} str - A string with square-bracketted substitution strings.
   * @param {Object} params - Each key in this object is looked up in the keys passed to the
   *   constructor. If it exists, its value is extracted from "params," and used to replace
   *   [key] in the URL. If the 'remove' flag is set for the key, it is deleted from 'params.'
   * @returns {*}
   */
  substitute(str, params) {
    const map = {};
    for (const k of Object.keys(this.m_subs)) {
      const sub = this.m_subs[k];
      map[k] = params[sub.param];
      if (sub.remove) {
        delete params[sub.param];
      }
    }
    return substituteBracketKeys(str, map);
  }
}
