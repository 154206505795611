import React from 'react';
import { lazyLoadWithSuspense, lazyWithRetry } from 'app_utils/lazy_load';
import { MiniformTextFieldPlaceholder } from './miniform_text_field_placeholder';
import { MiniformCheckboxPlaceholder } from './miniform_checkbox_placeholder';
export { connectForm } from './connect_form';
export { useFormProps } from './use_form_props';
export { useQueryForm } from './use_query_form';
export { useCheckAllFieldsPresent } from './use_check_all_fields_present';
export * from './utils';

// lazyLoadWithSuspense prevents page flicker for low-level components.
export const Form = lazyLoadWithSuspense('Form', () =>
  import(/* webpackChunkName: "form" */ './form').then(module => ({default: module.Form}))
);
export const LocalForm = lazyLoadWithSuspense('LocalForm', () =>
  import(/* webpackChunkName: "form" */ './form').then(module => ({default: module.LocalForm}))
);
export const FormFieldWrapper = lazyLoadWithSuspense('FormFieldWrapper', () =>
  import(/* webpackChunkName: "form" */ './form_field_wrapper').then(module => ({
    default: module.FormFieldWrapper
  }))
);

Form.Textarea = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './textarea').then(module => ({default: module.Textarea}))
);
Form.LimitTextarea = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './limit_textarea')
    .then(module => ({default: module.LimitTextarea}))
);
Form.TextField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './text_field')
);
Form.TypeaheadTextField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './typeahead_text_field')
    .then(module => ({default: module.TypeaheadTextField}))
);
Form.TypeaheadMultiSelect = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './typeahead_multi_select')
    .then(module => ({ default: module.TypeaheadMultiSelect }))
);
Form.FileUpload = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './file_upload')
    .then(module => ({default: module.FileUpload}))
);
Form.Select = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './select').then(module => ({default: module.Select}))
);
Form.MultiSelectDropdown = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './multi_select_dropdown')
    .then(module => ({ default: module.MultiSelectDropdown }))
);
Form.Checkbox = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './checkbox')
);
Form.CheckboxSingle = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './checkbox_single')
    .then(module => ({ default: module.CheckboxSingle }))
);
Form.CheckboxGroup = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './checkbox_group')
    .then(module => ({ default: module.CheckboxGroup }))
);
Form.RadioSet = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './radio_set').then(module => ({default: module.RadioSet}))
);
Form.Radio = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './radio_set').then(module => ({default: module.Radio}))
);
Form.RadioGroup = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './radio_group')
    .then(module => ({ default: module.RadioGroup }))
);
Form.Submit = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './submit')
);
Form.PasswordField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './password_field')
);
Form.LanguageDropdown = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './language_dropdown')
    .then(module => ({ default: module.LanguageDropdown }))
);
Form.CountryTypeaheadSelectOrDropdown = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './country_typeahead_select_or_dropdown')
    .then(module => ({ default: module.CountryTypeaheadSelectOrDropdown }))
);
Form.StateOrProvinceTypeaheadSelectOrDropdown = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './state_or_province_typeahead_select_or_dropdown')
    .then(module => ({ default: module.StateOrProvinceTypeaheadSelectOrDropdown }))
);
Form.BirthYearDropdown = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './birth_year_dropdown')
    .then(module => ({ default: module.BirthYearDropdown }))
);
Form.InputLabel = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './input_label')
);
Form.DateField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './date_field')
    .then(module => ({ default: module.DateField }))
);
Form.CompoundDateField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './compound_date_field')
    .then(module => ({default: module.CompoundDateField}))
);
Form.HiddenField = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './hidden_field')
    .then(module => ({ default: module.HiddenField }))
);
Form.RangeGroup = lazyWithRetry(() =>
  import(/* webpackChunkName: "form" */ './range_group')
    .then(module => ({ default: module.RangeGroup }))
);
// The <div> fallbacks for these two are not ideal, but they prevent page flicker.
export const MiniformTextField = lazyLoadWithSuspense('MiniformTextField', () =>
  import(/* webpackChunkName: "form" */ './miniform_text_field')
    .then(module => ({default: module.MiniformTextField})),
  () => <MiniformTextFieldPlaceholder/>
);
export const MiniformCheckbox = lazyLoadWithSuspense('MiniformCheckbox', () =>
  import(/* webpackChunkName: "form" */ './miniform_checkbox')
    .then(module => ({default: module.MiniformCheckbox})),
  () => <MiniformCheckboxPlaceholder/>
);
