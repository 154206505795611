import React from 'react';
import { getRailsEnv } from 'app_utils';
import {
  Tracking,
  TrackOnClick as UtilTrackOnClick,
  TrackLink as UtilTrackLink,
  useComponentTracking as UtilUseComponentTracking,
  withComponentTracking as UtilWithComponentTracking,
  tryGetAndClearLinkSource,
  trySetLinkSource
} from 'utils/tracking';
export { tryGetAndClearLinkSource, trySetLinkSource } from 'utils/tracking';

export const tracking = new Tracking({}, window.initialAppValues.tracking, getRailsEnv());

tracking._GLOBAL_VERSION = true;

export const TrackOnClick = props => <UtilTrackOnClick {...props} tracking={tracking} />;

export const TrackLink = props => <UtilTrackLink {...props} tracking={tracking} />;

export const useComponentTracking = (defaultSnapshot = {}) => (
  UtilUseComponentTracking(defaultSnapshot, tracking)
);

export const withComponentTracking = (WrappedComponent) => (
  UtilWithComponentTracking(WrappedComponent, tracking)
);
