import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AlertsActions } from 'alert';

/**
 * Connect a <Form> to a component using redux. Use the useFormProps hook for functional
 * components.
 *
 * @param {Object} options
 *   @param {Action|function} options.resourceHandler - An Action or factory that, given ownProps,
 *     will return an Action. See action.js
 *   @param {Boolean|String} [options.valuesSource='auto'] - Where to find default values to submit
 *     with the form. One of:
 *     'resource': Get defaults from the redux resource.
 *     'props': Get defaults from props.initialValues.
 *     'auto': Get defaults from props if props.initialValues exists, and the resource otherwise.
 */
export const connectForm = ({ resourceHandler, valuesSource = 'auto' }) => {
  const mapStateToProps = (state, ownProps) => {
    // create a local alias, so that we don't modify the value of resourceHandler for other
    // instances of this component
    let resolvedResourceHandler = resourceHandler;
    if (!('getResources' in resolvedResourceHandler)) {
      resolvedResourceHandler = resolvedResourceHandler(ownProps);
    }

    const result = {
      submitStatus: resolvedResourceHandler.getStatus(state),
      errors: resolvedResourceHandler.getErrors(state)
    };

    if (valuesSource !== 'props') {
      if (valuesSource === 'resource' || !('initialValues' in ownProps)) {
        result.initialValues = resolvedResourceHandler.getResources(state);
      }
    }
    return result;
  };

  const mapDispatchToProps = () => {
    let xhr;
    return (dispatch, ownProps) => {
      let resolvedResourceHandler = resourceHandler;
      if (!('getResources' in resolvedResourceHandler)) {
        resolvedResourceHandler = resolvedResourceHandler(ownProps);
      }
      return bindActionCreators(
        {
          submit: opts => {
            if (xhr) {
              xhr.abort();
            }

            const action = resolvedResourceHandler.action(opts);
            const handler = (dispatch, getState) =>
              (xhr = action(dispatch, getState));
            return handler;
          },
          addAlert: data => {
            const action = new AlertsActions();
            return action.add(data);
          },
          addClientErrors: resolvedResourceHandler.addErrors,
          clearClientErrors: resolvedResourceHandler.clearErrors
        },
        dispatch
      );
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  );
};
