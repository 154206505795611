/**
 * Utility functions for arrays.
 */

/**
 * Compare two arrays, down to a depth of one.
 *
 * @param {Array} a1
 * @param {Array} a2
 * @returns {Boolean}
 */
export const shallowEquals = (a1, a2) => {
  if (a1 === a2) {
    return true;
  }

  if (!Array.isArray(a1) || !Array.isArray(a2)) {
    return false;
  }

  if (a1.length !== a2.length) {
    return false;
  }

  for (let x = 0; x < a1.length; x++) {
    if (a1[x] !== a2[x]) {
      return false;
    }
  }

  return true;
};

/**
 * Recursively flatten an array.
 *
 * @param {Array} list - An array, possibly containing other arrays.
 * @returns {Array}
 */
export const flatten = list => list.reduce(
  (result, item) => result.concat(Array.isArray(item) ? flatten(item) : item), []
);

/**
 * Force a scalar into an array. If an array is passed in, leave it alone.
 *
 * @param {*|Array} a
 * @returns {Array}
 */
export const wrapArray = a => Array.isArray(a) ? a : [a];

/**
 * Given a key and one or more iteratables over objects, returns an array containing only one object
 * for each value of the provided key.  For example, given several arrays of objects with an "id"
 * property, returns an array containing an object with each ID only once.
 *
 * @param {string} key - the name of the property by which unique objects will be selected
 * @param {object[]} arrays - the iterables from which to select unique objects
 * @returns {object[]}
 */
export const uniqueByKey = (key, ...arrays) => {
  const result = [];
  const keys = new Set();
  for (const arr of arrays) {
    for (const v of arr) {
      if (!keys.has(v[key])) {
        keys.add(v[key]);
        result.push(v);
      }
    }
  }
  return result;
};

/**
 * Promote certain items in an array to the front, and return a new array.
 *
 * @param {Array} list - The array.
 * @param {Object|Set} promote - values that should be promoted to the front of the list.
 * @param {String} key - If specified, `list` is assumed to be a list of objects, and promotion
 *   happens based on this key.
 */
export const promotedSort = (list, promote, key = null) => {
  let first = [];
  let last = [];
  const has = promote instanceof Set ? (key => promote.has(key)) : (key => key in promote);
  if (key) {
    list.forEach(item => (has(item[key]) ? first : last).push(item));
  } else {
    list.forEach(item => (has(item) ? first : last).push(item));
  }
  return [...first, ...last];
};

/**
 * Compact an array, removing falsey values.
 *
 * @param {Array} a
 * @return {Array}
 */
export const compact = a => a.filter(v => v);

/**
 * Returns a new array that has an element added if not present in the original array or removed
 * otherwise
 * @param {*[]} array
 * @param {*} element
 * @returns {*[]}
 */
export const toggleElement = (array, element) => {
  if (array.includes(element)) {
    return array.filter(val => val !== element);
  } else {
    return [...array, element];
  }
};

/**
 * Compact an array, then join it.
 *
 * @param {Array} a
 * @param {String} joinOn - Join on this string.
 * @returns {String}
 */
export const joinCompact = (a, joinOn = '') => compact(a).join(joinOn);

export const array = {
  shallowEquals,
  flatten,
  wrapArray,
  uniqueByKey,
  promotedSort,
  compact,
  joinCompact,
  toggleElement
};
