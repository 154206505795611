import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';

import './accordion_heading.sass';

/**
 * Simple wrapper used in conjunction with <Accordion> to give default styles and handle logic of
 * displaying the icon in either expanded or contracted state.
 *
 * @property {*} children - the content to be displayed
 * @property {boolean} expanded - the current expansion state of the accordion
 */
export const AccordionHeading = (props) => {
  return (
    <div className="cr-accordion-heading__container">
      <div className="cr-accordion-heading__content">
        {props.children}
      </div>
      <FontAwesomeIcon
        icon={props.expanded ? faAngleUp : faAngleDown}
        className="cr-according-heading__expand-arrow"
      />
    </div>
  );
};

AccordionHeading.propTypes = {
  expanded: PropTypes.bool,
  children: PropTypes.element
};
