import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { SizedImage } from 'controls/sized_image';
import { makeClassName } from 'utils';
import { BadgeIssuerPropType } from 'utils/prop_types';
import { BadgeIssuer } from 'badges';
import './signin_badge_row.sass';

/**
 * A badge row suitable for the signin and create account pages. Other than the Heading appearance,
 * this is identical to the header for Share. The two should probably be combined.
 *
 * @param {Object} props
 * @returns {React.element}
 * @constructor
 */
export const SigninBadgeRow = props => {
  const badge = props.badge;

  return (
    <div className={makeClassName('signin-badge-row', props.className, props.appearance && ('signin-badge-row--' + props.appearance))}>
      <SizedImage
        className="signin-badge-row__image"
        src={badge.image_url}
        alt={badge.badge_template.name}
        width={110}
      />
      <div className="signin-badge-row__details">
        <Heading appearance="db-badge-title">{badge.badge_template.name}</Heading>
        <div className="signin-badge-row__issuer">
          <BadgeIssuer issuer={badge.issuer} {...props}/>
        </div>
      </div>
    </div>
  );
};

SigninBadgeRow.propTypes = {
  appearance: PropTypes.oneOf([
    'accept-badge-landing',
    'min'
  ]),
  className: PropTypes.string,
  badge: PropTypes.shape({
    image_url: PropTypes.string.isRequired,
    badge_template: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    issuer: BadgeIssuerPropType.isRequired
  }).isRequired
};
