import { lazyWithRetry } from 'app_utils/lazy_load';

export const SignInRoutes = lazyWithRetry(() =>
  import(/* webpackChunkName: "signin" */ './signin_routes')
    .then(module => ({default: module.SignInRoutes}))
);
export const SignIn = lazyWithRetry(() =>
  import(/* webpackChunkName: "signin" */ './signin')
    .then(module => ({default: module.SignIn}))
);

// Since these pages reuire the transcript chunk anyway, put them there and make the signin chunk
// a tiny bit smaller.
export const CreateAccountAcceptWithSSORedirect = lazyWithRetry(() =>
  import(/* webpackChunkName: "transcript" */ './create_account_accept')
    .then(module => ({default: module.CreateAccountAcceptWithSSORedirect}))
);
export const CreateAccountForTranscriptDownload = lazyWithRetry(() =>
  import(/* webpackChunkName: "transcript" */ './create_account_for_transcript_download')
    .then(module => ({default: module.CreateAccountForTranscriptDownload}))
);
