import {
  lazyLoadWithSuspense as utilsLazyLoadWithSuspense,
  lazyWithRetry as utilsLazyWithRetry,
  WithLazyLoadFallbackContent
} from 'utils/lazy_load';
import { getRailsEnv } from 'app_utils';

/**
 * Whether to print errors related to lazy-loading JS chunks to the console or not.
 *
 * @returns {boolean|*}
 */
export const debugReactLazyErrors = () => {
  return getRailsEnv() !== 'production' || window.initialAppValues.frontendDebug;
};

export const lazyLoadWithSuspense = (name, componentImport, fallback) => (
  utilsLazyLoadWithSuspense(name, componentImport, fallback, debugReactLazyErrors())
);

export const lazyWithRetry = (fn) => utilsLazyWithRetry(
  fn, debugReactLazyErrors()
);

export { WithLazyLoadFallbackContent };
