import React, { useEffect } from 'react';
import './custom_background.sass';

/**
 * HOC that takes care of setting a custom background when the wrapped component is mounted,
 * and removing it when the wrapped component is unmounted.
 *
 * @param {String} backgroundVariant - the variant to use for the background.
 *   The currently support variants include: gray
 * @returns {Function} - function that takes a component and returns a new component that will
 *   automatically set/clear the custom background when the component is mounted/unmounted.
 */
export const withCustomBackground = (backgroundVariant) => {
  return (WrappedComponent) => {
    const rootClass = `custom-background-root__${backgroundVariant}`;
    const footerClass = `custom-background-footer__${backgroundVariant}`;

    return (props) => {
      useEffect(() => {
        document.getElementById('root').classList.add(rootClass);
        document.getElementById('footer').classList.add(footerClass);

        return () => {
          document.getElementById('root').classList.remove(rootClass);
          document.getElementById('footer').classList.remove(footerClass);
        };
      }, []);

      return <WrappedComponent {...props} />;
    };
  };
};
