import React from 'react';
import PropTypes from 'prop-types';
import { useOnOff } from 'utils/react_utils';
import { SimpleButton } from "controls/button";
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH';
import { FilterSection } from "management/controls/filter_section";
import { IconWithNotification } from "controls/icon_with_notification";
import { MultiSelectList } from "controls/multi_select_list";
import { useBrowserQueryString } from 'controls/with_query_string';
import { Form, FormFieldWrapper } from 'form';
import Dialog from "controls/dialog";

import './job_board_filters.sass';

export const JobBoardFilters = ({ onSubmit }) => {
  const queryString = useBrowserQueryString();
  const [experienceFilter, setExperienceFilter] = React.useState(queryString.get('experienceFilter')?.split(',') || []);
  const [workplaceFilter, setWorkPlaceFilter] = React.useState(queryString.get('workplaceFilter')?.split(',') || []);
  const [visaFilter, setVisaFilter] = React.useState(queryString.get('visaFilter')?.split(',') || []);
  const [statusFilterOpen, openStatusDialog, closeStatusDialog] = useOnOff(false);
  const iconCount = (experienceFilter.length + workplaceFilter.length + visaFilter.length);

  const handleExperienceToggle = (experienceSelection) => {
    setExperienceFilter(experienceSelection);
  };

  const handleWorkplaceToggle = (workplaceSelection) => {
    setWorkPlaceFilter(workplaceSelection);
  };

  const handleVisaToggle = (visaSelection) => {
    setVisaFilter(visaSelection);
  };

  const handleSubmit = () => {
    const values = {
      workplace_preferences: workplaceFilter,
      accepted_levels: experienceFilter,
      sponsorship_available: visaFilter
    };

    onSubmit(values);
  };

  const handleReset = () => {
    setExperienceFilter([]);
    setWorkPlaceFilter([]);
    setVisaFilter([]);
    onSubmit();
  };

  return (
    <>
      <Form
        initialValues={{}}
        submit={handleSubmit}
      >
        {(formProps) =>
          <Dialog
            show={statusFilterOpen}
            shouldClose={closeStatusDialog}
            title="Filter"
            size="half"
          >
            <Dialog.Content>
              <FormFieldWrapper>
                <FilterSection className="filter-section" heading="Experience Level">
                  <MultiSelectList
                    items={[
                      { label: 'Intern/Trainee', value: 'intern_trainee' },
                      { label: 'Junior/Entry Level', value: 'entry_level' },
                      { label: 'Mid Level', value: 'mid_level' },
                      { label: 'Lead/Manager', value: 'lead_manager' },
                      { label: 'Senior Manager/Director', value: 'senior_manager_director' },
                      { label: 'Executive', value: 'executive' }
                    ]}
                    value={experienceFilter}
                    onChange={handleExperienceToggle}
                    allowDeselectAll
                    skipEmptySelection
                  />
                </FilterSection>
                <FilterSection className="filter-section" heading="Workplace">
                  <MultiSelectList
                    items={[
                      { label: 'In Person', value: 'in_person' },
                      { label: 'Remote', value: 'remote' },
                      { label: 'Hybrid', value: 'hybrid' }
                    ]}
                    value={workplaceFilter}
                    onChange={handleWorkplaceToggle}
                    allowDeselectAll
                    skipEmptySelection
                  />
                </FilterSection>
                <FilterSection className="filter-section" heading="Visa Sponsorship">
                  <MultiSelectList
                    items={[
                      { label: 'I need a visa sponsorship', value: 'true' }
                    ]}
                    value={visaFilter}
                    onChange={handleVisaToggle}
                    allowDeselectAll
                    skipEmptySelection
                  />
                </FilterSection>
              </FormFieldWrapper>
            </Dialog.Content>
            <Dialog.Footer>
              <Dialog.Action action={handleReset} type="secondary">Reset</Dialog.Action>
              <Dialog.Action action={formProps.submit}>Apply</Dialog.Action>
            </Dialog.Footer>
          </Dialog>
        }
      </Form>
      <SimpleButton
        onClick={openStatusDialog}
        type="tertiary"
        mobileSize="auto"
        className=""
      >
        <div className="filter-wrapper">
          Filter
          <IconWithNotification
            number={iconCount}
            icon={faSlidersH}
            className=""
          />
        </div>
      </SimpleButton>
    </>
  );
};

JobBoardFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
