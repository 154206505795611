import React from 'react';
import * as objUtils from 'utils/object';
import { Action } from 'utils/action';

const CLEANUP_TIMEOUT = 60000;

/**
 * Actions created through this class are managed, and can be fetched as a group. Actions are not
 * created until they're needed, and they're destroyed when no longer needed.
 *
 * Example usage:
 *
 * const actionsCollection = (new ActionManager('keyToStore'))
 *   .add({
 *     requestKey: 'action1',
 *     url: '/endpoint1',
 *     effect: 'read',
 *     method: 'GET'
 *   })
 *   .add({
 *     requestKey: 'action2',
 *     url: '/endpoint2/[id]',
 *     effect: 'read',
 *     method: 'GET'
 *   }, true) // This parameter means the request will be tracked seperately for each id.
 *
 * // Dispatch a normal action.
 * dispatch(actionsCollection.get('action1').action());
 *
 * // Dispatch an action keyed off of an id. The status for this action will be trackable
 * // based on the id.
 * dispatch(actionsCollection.get('action2', '12345').action();
 *
 * // Get a list of all active status objects based on ids, which looks like this:
 * // {'12345': {'action2': {pending: true, ...}}}
 * actionsCollection.getAllStatus(state)
 * // For non-id actions, get status directly:
 * actionsCollection.get('action1').getStatus()
 */
export class ActionManager {
  /**
   * @param {String} resourceType - The name of a key in the redux store.
   * @param {function} actionFactory - function use to generate Action instance.
   * @param {String} env - The Rails environment. This is used to determine whether to warn on abort.
   */
  constructor(resourceType, actionFactory = null, env = 'production') {
    this.m_actions = {};
    this.m_idActions = {};
    this.m_savedOptions = {};
    this.m_deleteTimeouts = {};
    this.m_requiresId = {};
    this.m_resourceType = resourceType;
    this.m_actionFactory = actionFactory;

    if (!this.m_actionFactory) {
      this.m_actionFactory = (options) => {
        return new Action(options, env);
      };
    }
  }


  /**
   * Bind all actions to a class. This can be passed into Redux's mapDispatchToProps().
   * Non-id-based actions are bound as: [requestKey](params)
   * id-based actions are bound as:     [requestKey](id, params)
   *
   * @param {function} dispatch
   * @returns {object<string, function>}
   */
  bind(dispatch) {
    let bound = {};
    for (let key of Object.keys(this.m_savedOptions)) {
      bound[key] = key in this.m_requiresId ?
        (id, ...args) => dispatch(this.get(key, id).action(...args)) :
        (...args) => dispatch(this.get(key).action(...args));
    }
    return bound;
  }


  /**
   * Return all state for a given id, appropriate for passing into redux's mapStateToProps().
   *
   * @param {Object} state - The redux state.
   * @param {String} requestKey - The redux-resource requestKey.
   * @param {String=} id - The resource id, if there is one.
   * @returns {{resources:function, status:function, metadata:function, requestDetails:function}}
   */
  allStateFor(state, requestKey, id) {
    return this.get(requestKey, id).allState(state);
  }


  /**
   * Create an action.
   * See the top of the class for full documentation. Parameter names copied here for auto-complete.
   *
   * @param {{method: string, requestKey: string, effect: string, urlSubs, url: string}} options
   *   @param {String} options.resourceType
   *   @param {String} options.effect
   *   @param {String} options.method='GET'
   *   @param {String} options.url
   *   @param {String} options.requestKey
   *   @param {String=} options.id
   *   @param {String=} options.list
   *   @param {Object=} options.defaultParams
   *   @param {function(function, object, object, object)=} options.onSuccess
   *   @param {function} options.onFailure
   * @param {Boolean=false} requiresId - An id must be passed into get() to use this action.
   * @returns {ActionManager}
   */
  add(options, requiresId=false) {
    this.m_savedOptions[options.requestKey] = {...options, resourceType: this.m_resourceType};
    this.m_requiresId[options.requestKey] = requiresId;
    return this;
  }


  /**
   * Does the given key/id combination exist?
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, check for an action that's specific to this id.
   * @returns {boolean}
   */
  has(requestKey, id) {
    return (requestKey in this.m_savedOptions) && (id || !this.m_requiresId[requestKey]);
  }


  /**
   * Get an action created by .add().
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, get an action that's specific to an id. This allows the
   *   request state to be monitored. This action will be removed from the manager 60 seconds after
   *   the request is finished.
   * @returns {Action}
   */
  get(requestKey, id='') {
    // Create the object just-in-time.
    return this._createAction(requestKey, id, true);
  }


  /**
   * Get all active actions for a given id.
   *
   * @param {String} id
   * @returns {Object}
   */
  getAll(id) {
    return this.m_idActions[id] || {};
  }


  /**
   * Get all id-based requests.
   *
   * @param {Object} state - Redux state
   * @param {String} id - Limit requests to a specific id
   * @returns {Object} If id was passed in, return a map of requestKey=>details for that id.
   *   Otherwise, return data for all ids as requestKey=>details.
   */
  getAllRequestDetails(state, id=null) {
    return this._getAllPredicate(state, (action, state) => action.getRequestDetails(state), id);
  }


  /**
   * Get all id-based status objects.
   *
   * @param {Object} state - Redux state
   * @param {String} id - Limit requests to a specific id
   * @returns {Object} If id was passed in, return a map of requestKey=>details for that id.
   *   Otherwise, return data for all ids as requestKey=>details.
   */
  getAllStatus(state, id=null) {
    return this._getAllPredicate(state, (action, state) => action.getStatus(state), id);
  }


  /**
   * Stop managing an action.
   *
   * @param {String} requestKey
   * @param {String} id
   */
  destroy(requestKey, id = '') {
    let key = makeFullRequestKey(requestKey, id);

    delete this.m_actions[key];

    if (id in this.m_idActions) {
      delete this.m_idActions[id][requestKey];
      if (Object.keys(this.m_idActions[id]).length === 0) {
        delete this.m_idActions[id];
      }
    }

    if (key in this.m_deleteTimeouts) {
      clearTimeout(this.m_deleteTimeouts[key]);
      delete this.m_deleteTimeouts[key];
    }
  }


  /**
   * React hook for use with this class. In functional components, this negates the need for
   * connect(). Unlike ActionManager.get(), this will not clean up actions after a timeout. A
   * React unmount function will do that automatically instead.
   *
   * In ActionManager actions, manager.get().useAction() will call this method instead.
   *
   * Usage:
   * const MyComponent = props => {
   *   const [request, doIt] = manager.useAction('key', 'id');
   *   doIt();
   *   return request.resources.map(r => <div key={r.id}>{r.id}</div>);
   * }
   *
   * In some cases, you only want to fetch data if it has not already been loaded. You can
   * accomplish this by passing a true value into useOnMount.
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, get an action that's specific to an id.
   * @returns {[ActionState, PerformAction, function]}
   *   The return value consists of:
   *   - An object containing all available state.
   *   - A function to invoke the action, which takes all action() parameters. It returns an abort
   *     function, which allows it to be used as the return value in a useEffect() hook.
   *     See PerformAction documentation above.
   *   - A reset function.
   */
  useAction(requestKey, id = '') {
    const action = this._createAction(requestKey, id, false);

    // Destroy the previous action when action changes, or on unmount.
    React.useEffect(() => {
      return () => this.destroy(requestKey, id);
    }, [requestKey, id]);

    return action._useActionOrig();
  }


  /**
   * Alternate version of useAction, useful for fetch operations. This performs the action on mount,
   * and, if there is an id, whenever the id changes. It only fetches new data if data has not
   * already been fetched.
   *
   * In ActionManager actions, manager.get().useOnMount() will call this method instead.
   *
   * Usage:
   * const MyComponent = props => {
   *   const [request, action] = manager.useOnMount('key', 'id');
   *   if (request.status.idle || request.status.pending) {
   *     return <LoadingSpinner/>;
   *   } else {
   *     return request.resources.map(d => <div key={d.id}>{d.name}</div>);
   *   }
   * }
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, get an action that's specific to an id.
   * @param {Object} values - Query parameters to pass to the request.
   * @param {Array} dependencies - Also fetch when any of these dependencies change.
   * @returns {[ActionState, function]}
   *   The return value consists of:
   *   - An object containing all available state.
   *   - A reset function.
   */
  useOnMount(requestKey, id = '', values = {}, dependencies = []) {
    const action = this._createAction(requestKey, id, false);
    // Use requestKey and id in the dependencies list, rather than the action itself, because a
    // non-hook usage could cause the action to be deleted and recreated, resulting in a fetch
    // when it's not expected.
    return action._useOnMountOrig(values, [...dependencies, requestKey, id]);
  }


  /**
   * React hook for the errors of the current request. This data is only meaningful when
   * `status.failed`.
   *
   * Usage:
   * const [errors, addErrors, clearErrors] = myAction.useErrors()
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, get an action that's specific to an id.
   * @returns {[Object, function(object<string, array> errors], function(string fieldNames)}
   *   Error data, an addErrors function and a clearErrors function.
   */
  useErrors(requestKey, id = '') {
    return this._createAction(requestKey, id, false)._useErrorsOrig();
  }


  /**
   * Get data from an action based on id.
   *
   * @param {Object} state - Redux state
   * @param {function(action:object,state:object):*} predicate - This function should transform
   *   the action and the global store into the data it cares about.
   * @param {String} id - Limit requests to a specific id
   * @returns {Object} If id was passed in, return a map of requestKey=>details for that id.
   *   Otherwise, return data for all ids as requestKey=>details.
   * @private
   */
  _getAllPredicate(state, predicate, id = null) {
    if (id) {
      return objUtils.map(this.m_idActions[id], (key, action) => predicate(action, state));
    }
    return objUtils.map(this.m_idActions, id => this._getAllPredicate(state, predicate, id));
  }


  /**
   * Create a new action.
   *
   * @param {String} requestKey
   * @param {String=} id - If specified, get an action that's specific to an id.
   * @param {Boolean} autoCleanupId - Automatically remove id-based actions after a 60 second
   *   timeout.
   * @returns {Action}
   */
  _createAction(requestKey, id, autoCleanupId) {
    if (!(requestKey in this.m_savedOptions)) {
      throw new Error('ActionManager: Attempted to create action for invalid key: ' + requestKey);
    }
    if (!id && this.m_requiresId[requestKey]) {
      throw new Error(
        'ActionManager: Action ' + this.m_resourceType + '.' + requestKey + ' requires an id'
      );
    }

    const key = makeFullRequestKey(requestKey, id);
    if (!(key in this.m_actions)) {
      const options = this.m_savedOptions[requestKey];
      if (!id) {
        this.m_actions[key] = this.m_actionFactory(options);
      } else {
        let factoryOptions = {
          ...options,
          requestKey: key,
          id: id,
          transform: data => {
            if (options.transform) {
              data = options.transform(data);
            }

            // If this is an id-based action, make sure the returned id is the same as the sent id.
            const idExists = data.find(d => d.id === id);
            if (!idExists) {
              data[0]._id_from_server = data[0].id;
              data[0].id = id;
            }
            return data;
          }
        };

        // Extend onSuccess/onFailure to do clean-up.
        if (autoCleanupId) {
          const onDone = () => {
            if (key in this.m_deleteTimeouts) {
              clearTimeout(this.m_deleteTimeouts[key]);
            }
            this.m_deleteTimeouts[key] =
              setTimeout(() => this.destroy(requestKey, id), CLEANUP_TIMEOUT);
          };

          factoryOptions.onSuccess = (dispatch, action, res, body) => {
            options.onSuccess && options.onSuccess(dispatch, action, res, body);
            onDone();
          };

          factoryOptions.onFailure = (...args) => {
            options.onFailure && options.onFailure(...args);
            onDone();
          };
        }

        const action = this.m_actionFactory(factoryOptions);

        // Sort by id
        if (!(id in this.m_idActions)) {
          this.m_idActions[id] = {};
        }
        this.m_actions[key] = this.m_idActions[id][requestKey] = action;
      }

      // Wrap React hooks, so that calls to action.[hook]() will call the manager's hook instead.
      const action = this.m_actions[key];
      action._useActionOrig = action.useAction;
      action._useOnMountOrig = action.useOnMount;
      action._useErrorsOrig = action.useErrors;
      action.useAction = (...args) => this.useAction(requestKey, id, ...args);
      action.useErrors = (...args) => this.useErrors(requestKey, id, ...args);
      action.useOnMount = (...args) => this.useOnMount(requestKey, id, ...args);
    }

    return this.m_actions[key];
  }
}

// @todo: this is a janky workaround to prevent both
//   [1] redux-resource and [2] our getStatus from exploding when an id includes a full-stop
//   (n.b. we don't know of any square-bracket cases -- that's preventative.)
const REQUEST_KEY_SUBS = {
  '.': '__dot__',
  '[': '__open_bracket__',
  ']': '__close_bracket__'
};

/**
 * Creates request key, sanitizing ID if it contains a full-stop or square-brackets
 * @param {String} requestKey
 * @param {String} id
 *
 * @returns {String}
 */
const makeFullRequestKey = (requestKey, id) => {
  if (id) {
    id = id.toString().replace(/[.\[\]]/, (char) => REQUEST_KEY_SUBS[char]);
    return requestKey + id;
  }else {
    return requestKey;
  }
};

/**
 * Shortcuts for dealing with Redux status changes.
 */
export class ReduxManagerStatus {
  /**
   * @param {Object} status - The status object returned by ActionManager::getAllStatus()
   * @param {Object} prevStatus - Same as status, but from the previous redux state.
   */
  constructor(status, prevStatus) {
    this.m_status = status;
    this.m_prevStatus = prevStatus;
  }

  exists(requests, id, key) {
    return !!(id in requests && key in requests[id]);
  }

  succeeded(requests, id, key) {
    return !!(this.exists(requests, id, key) && requests[id][key].succeeded);
  }

  justSucceeded(id, key) {
    return !this.succeeded(this.m_prevStatus, id, key) && this.succeeded(this.m_status, id, key);
  }
}
