import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'utils';
import { RoleButton } from 'aria/role_button';
import CollapsibleSection from 'controls/collapsible_section';
import { AccordionHeading } from './accordion_heading';

import './accordion.sass';


/**
 * Simple accordion component that includes a header that is displayed by default and expandable
 * details passed through as ordered children. It's basically just a styled wrapper that handles
 * the logic for expansion/contraction and includes the corresponding arrow icon. Whichever
 * needed heading component is passed as the first child followed by any desired component(s)
 * for the expansion area.
 *
 * Example:
 * <Accordion>
 *   <SomeHeaderComponent>
 *   <div>
 *     i'm only visible if expanded
 *   </div>
 * </Accordion>
 *
 * @property {*} children - the content to be displayed. The first child will be in the
 *   always-visible header secion and any subsequent children will be displayed in the expansion
 *   area
 */
export const Accordion = (props) => {
  const [expanded, setExpanded] = useState(props.expanded);
  const [heading, ...children] = props.children;
  const handleExpand = () => setExpanded(!expanded);
  const accordionId = useMemo(() => uniqueId().toString(), []);
  const expandedId = useMemo(() => uniqueId().toString(), []);

  return (
    <div className="cr-accordion__container" id={props.id} tabIndex={0}>
      <RoleButton
        onClick={handleExpand}
        tagName="div"
        id={accordionId}
        aria-controls={expandedId}
      >
        <AccordionHeading expanded={expanded}>
          {heading}
        </AccordionHeading>
      </RoleButton>
      <CollapsibleSection
        id={expandedId}
        tagName="div"
        role="region"
        collapsed={!expanded}
        aria-expanded={!expanded}
        aria-labelledby={accordionId}
        allowOverflow={expanded}
      >
        {children}
      </CollapsibleSection>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  expanded: PropTypes.bool,
  id: PropTypes.string
};

Accordion.defaultProps = {
  expanded: false
};
