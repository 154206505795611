import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { GLOBAL_SEARCH_TERM_KEY } from 'global_search';

/**
 * Wrap a component in this to hide the page header.
 */
export const HidesHeader = props => {
  const history = useHistory();

  React.useEffect(() => {
    // Show or hide signin and create account buttons.
    const toggleHeader = on => {
      const header = document.querySelector('#website-header');
      if (header) {
        header.style.display = on ? '' : 'none';
      }

      /*
      On mount, check if the url cointains a global search term and replace it with an empty string
      If the user is not logged in, they should be redirected to a login page
      */
      const hashParams = new URLSearchParams(history.location.hash.replace(/^#?/, ''));
      if (!on && hashParams.has(GLOBAL_SEARCH_TERM_KEY)) {
        hashParams.delete(GLOBAL_SEARCH_TERM_KEY);
        history.replace({ ...history.location, hash: hashParams.toString() });
      }
    };

    // Hide header on mount.
    toggleHeader(false);
    // Show header on unmount.
    return () => toggleHeader(true);
  }, []);

  return props.children || '';
};


HidesHeader.propTypes = {
  children: PropTypes.any
};
