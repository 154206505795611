import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { defineRoutes } from 'app_utils';
import { compact, flatten } from 'utils/array';

export const SavedDirectorySearchRouteHelpers = defineRoutes(
  '',
  {
    savedDirectorySearches: '/recruit',
    showSavedDirectorySearch: '/recruit/saved_directory_searches/:id'
  }
);

export const savedDirectorySearchSearchParams = (filters, facets) => (
  { filter: filters, facets: facets }
);

export const processSearchParamsToFilterTags = (searchParams) => {
  const facetsArray = compact([
    searchParams.facets['0'],
    searchParams.facets['1'],
    searchParams.facets['2'],
    searchParams.facets['3']
  ]);
  const filterTags = compact(flatten(facetsArray.map((facet) => {
    const filterKey = `${facet}_name`;
    return searchParams.filter[filterKey] || searchParams.filter[`not_${filterKey}`];
  })));
  return filterTags;
};

export const SavedDirectorySearchPropType = PropTypes.shape({
  id: uuidPropType,
  name: PropTypes.string.isRequired,
  displayable_matches: PropTypes.bool.isRequired,
  search_params: PropTypes.object.isRequired,
  new_candidates: PropTypes.number.isRequired
});
