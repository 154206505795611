import { useLocation } from 'react-router-dom';
import { useQueryString } from 'controls/with_query_string';
import urlUtils from 'utils/url';

export const usePaginatedUrl = () => {
  const location = useLocation();
  const queryString = useQueryString(location);

  return (pageNumber) => {
    return urlUtils.makeUrl(location.pathname, queryString.clone().set('page', pageNumber));
  };
};
