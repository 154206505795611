import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * A react hook that returns the parsed location data from the current query parameters.
 * Intended to be used alongside {@link location_picker.jsx}
 *
 * @returns {{country: (string|*|string), state: string}} The country/state data from the query params, or the user's
 *   selected country (in iso_alpha2 format). Defaults to 'US'
 */
export const useLocationData = (fallbackCountry = 'US') => {
  const { search } = useLocation();

  return useMemo(() => {
    const urlParams = new URLSearchParams(search);

    let state = urlParams.get('state');
    const country = urlParams.get('country') || fallbackCountry;
    if (!country) {
      state = null;
    }

    return { country: country, state: state };
  }, [search, fallbackCountry]);
};
