import {Action} from 'utils/action';
import {actionTypes} from 'redux-resource';
import {ajax, waitFor} from 'utils';
import {badgeActions} from 'badges';
import {ActionManager} from 'utils/action_manager';

const SHORT_POLL_INTERVAL = 1000; // Once a second, for up to 3 minutes.
const LONG_POLL_INTERVAL = 60 * 1000; // Once a minute, for up to 3 hours.
const POLL_MAX_DURATION = 3 * 60 * 60 * 1000;

/**
 * Blockchain publish and poll. This doesn't use the API and has no ids, so redux-resource doesn't
 * like it.
 */
class BlockchainActions extends Action {
  constructor(options) {
    super({
      ...options,
      url: 'placeholder',
      effect: 'placeholder',
      method: 'placeholder'
    });

    this.m_pollingIds = {};
  }


  /**
   * Override Action.action to make a multi-part request.
   *
   * @override
   * @param {Object} params
   *   @param {String} params.id - The badge id.
   *   @param {String=} params.certId - The certificate id. If this is passed in, the badge has
   *     already been published. Just monitor the state.
   * @returns {Function}
   */
  action = params => {
    return async dispatch => {
      if (!this.m_pollingIds[params.id]) {
        const options = this.getOptions();

        const setStatus = action => dispatch({
          type: action,
          requestKey: options.requestKey,
          resourceType: options.resourceType,
          resources: [{id: 'blockcert' + params.id}]
        });

        setStatus(actionTypes.UPDATE_RESOURCES_PENDING);

        let certId = params.certId;
        if (!certId) {
          const result = await this._publish(params.id);
          certId = result.success && result.body.id;
        }

        let published = false;
        if (certId) {
          const pollUrl = `/badges/${params.id}/blockcerts/${certId}/blockcert_state`;

          // Do a short, fast poll, waiting for the badge to be scheduled for publication.
          const result = await this._poll(pollUrl, params.id, SHORT_POLL_INTERVAL, true);

          if (result != 'failed') {
            // Mark the request as succeeded, but we still have to wait for publish.
            setStatus(actionTypes.UPDATE_RESOURCES_SUCCEEDED);

            // Do a long, slow poll, waiting for the badge to be published.
            const result = await this._poll(pollUrl, params.id, LONG_POLL_INTERVAL);
            if (result != 'failed') {
              published = true;
              // Refresh the badge to get cert data.
              dispatch(badgeActions.get('get', params.id).action());
            }
          }
        }

        !published && setStatus(actionTypes.UPDATE_RESOURCES_FAILED);
        delete this.m_pollingIds[params.id];
      }
    }
  };


  /**
   * Stop polling a particular id.
   *
   * @param {String} id - The badge id.
   * @returns {boolean}
   */
  stopPolling = id => delete this.m_pollingIds[id];


  /**
   * Start the process.
   *
   * @param {String} id - The badge id.
   * @returns {Promise.<String>} The request ID, if the request was successful.
   * @private
   */
  async _publish(id) {
    return ajax({
      method: 'POST',
      uri: `/badges/${id}/blockcerts`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }


  /**
   * Wait for success or failure.
   *
   * @param {String} url - The url to poll.
   * @param {String} id - The badge id.
   * @param {int} interval - Polling interval
   * @param {Boolean=false} endIfScheduled - Scheduled states break out of the loop.
   * @returns {Promise.<String>} state.
   * @private
   */
  async _poll(url, id, interval, endIfScheduled = false) {
    this.m_pollingIds[id] = 1;

    return waitFor(async () => {
      const result = await ajax({
        uri: url,
        headers: {'Accept': 'application/json'},
        responseType: 'json'
      });

      if (!result.success || !result.body || result.body.state == 'failed') {
        return 'failed';
      }
      else if (result.body.state == 'successful') {
        return 'succeeded';
      }

      if (!this.m_pollingIds[id]) {
        return 'canceled';
      }

      if (endIfScheduled && result.body.state == 'ready') {
        return 'scheduled';
      }

      return null;
    }, interval, POLL_MAX_DURATION / interval);
  }
}

export const blockchainPublish =
  new BlockchainActions({resourceType: 'temp', requestKey: 'publish'});

export const blockchainActions = new ActionManager('temp', BlockchainActions)
  .add({requestKey: 'publish'}, true);
