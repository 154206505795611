import throttle from 'lodash.throttle';

const EVENT_THROTTLE_DURATION = 300;

/**
 * Manage throttled window events, so there never has to be more than one handler on a page. This
 * speeds up event handlers, and makes it easy to diagnose performance issues, since this is the
 * only file that has to be profiled.
 */
const createEventManager = name => {
  let attached = false;
  let callbacks = [];
  let eventHandler = null;

  const remove = idx => {
    if (idx >= 0) {
      callbacks.splice(idx, 1);

      if (callbacks.length === 0) {
        window.removeEventListener(name, eventHandler);
        attached = false;
      }
    }
  };

  // Initialize it here, so that setTimeout won't be referenced (in throttle()) until
  // Jest's fake timers have been set up (for the unit test).
  const createEventHandler = () => {
    if (!eventHandler) {
      eventHandler = throttle(() => {
        let removeThese = [];
        callbacks.forEach((cb, idx) => {
          // Return false to remove it.
          if (cb() === false) {
            removeThese.push(idx);
          }
        });
        removeThese.reverse().forEach(remove);
      }, EVENT_THROTTLE_DURATION);
    }
  };

  const funcs = {
    /**
     * Attach a callback to the event handler.
     *
     * @param {function():boolean} cb - The callback. Return false (not falsy) when called to
     *   remove it. Do not call .remove() from this callback.
     */
    add: cb => {
      createEventHandler();

      callbacks.push(cb);
      if (!attached) {
        attached = true;
        window.addEventListener(name, eventHandler);
      }
    },

    /**
     * Detach a callback from the event handler.
     *
     * @param {function():boolean} cb - The callback. Return false when called to remove it.
     */
    remove: cb => remove(callbacks.indexOf(cb)),

    /**
     * For testing only. Do not use this in regular code!
     */
    _testing_reset: () => {
      callbacks.forEach(funcs.remove);
      eventHandler = null;
    },
    _testing_length: () => callbacks.length
  };

  return funcs;
};


/**
 * Manage onScroll events, so there never has to be more than one handler on a page.
 *
 * @type {{add: (function(function(): boolean)), remove: (function(function(): boolean))}}
 */
export const onScrollManager = createEventManager('scroll');

/**
 * Manage onMouseMove events, so there never has to be more than one handler on a page.
 *
 * @type {{add: (function(function(): boolean)), remove: (function(function(): boolean))}}
 */
export const onMouseMoveManager = createEventManager('mousemove');

/**
 * Manage onMouseWheel events, so there never has to be more than one handler on a page.
 *
 * @type {{add: (function(function(): boolean)), remove: (function(function(): boolean))}}
 */
export const onMouseWheelManager = createEventManager('mousewheel');

/**
 * Manage onResize events, so there never has to be more than one handler on a page.
 *
 * @type {{add: (function(function(): boolean)), remove: (function(function(): boolean))}}
 */
export const onResizeManager = createEventManager('resize');
