import {
  defineRoutes as utilsDefineRoutes,
  SingleRoute as UtilsSingleRoute
} from 'utils/route_builder';
import { getRailsEnv } from 'app_utils';

export const defineRoutes = (root, routes) => (
  utilsDefineRoutes(root, routes, getRailsEnv())
);

export const SingleRoute = (parentRoute, subRoute, baseUrl = null) => (
  new UtilsSingleRoute(parentRoute, subRoute, baseUrl, getRailsEnv())
);
