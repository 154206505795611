import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import element from 'utils/element';

const NAME = 'skip-target';



/**
 * Create a target for the "skip link," which skips navigation elements for accessibility.
 * "skip link" is created in _header.html.haml, and _global.sass.
 *
 * Only the lowest SkipLinkTarget in the ReactDOM tree renders its link.
 *
 * @property {String} name - An arbitrary string used to identify this link. Useful for debugging.
 */
export default class SkipLinkTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {isCurrent: undefined};

    /**
     * Activate or deactivate the link.
     *
     * This is defined in constructor to be instance-specific for equality comparisons.
     *
     * @param {Boolean} isCurrent - Is this the uniquely active link?
     */
    this.update = isCurrent => this.setState({isCurrent: isCurrent});
  }


  /**
   * Add this component to the SkipLinkMananger.
   */
  componentDidMount() {
    manager.register(this.update);
  }


  /**
   * Clean-up.
   */
  componentWillUnmount() {
    manager.unregister(this.update);
  }


  /**
   * Render the link, if this is the lowest SkipLinkTarget in the ReactDOM tree.
   *
   * @returns {React.element}
   */
  render() {
    if (this.state.isCurrent === true) {
      return <span id={NAME} data-debugname={this.props.name}/>;
    }
    return <Fragment/>;
  }
}

SkipLinkTarget.propTypes = {
  name: PropTypes.string.isRequired
};


/**
 * Manage "skip link" targets, ensuring that only the most recently added is active.
 */
class SkipLinkManager {
  constructor() {
    // Callback stack
    this.m_callbacks = [];
    // Global skip-link
    this.m_skipLink = null;
  }


  /**
   * Register a callback.
   *
   * @param {function(boolean)} callback - Called to enable or disable a link.
   * @returns {*}
   */
  register(callback) {
    this._initSkipLink();

    // Disable all other links.
    this.m_callbacks.forEach(cb => cb(false));

    // Add and enable the new link.
    this.m_callbacks.push(callback);

    callback(true);
  }


  /**
   * Remove a callback, and activate the most recently added link.
   *
   * @param {function(boolean)} callback
   */
  unregister(callback) {
    callback(false);

    let lastIdx = this.m_callbacks.length - 1;
    if (lastIdx >= 0) {
      // Most of the time, the last-added link will be the first to be removed.
      let found;
      if (this.m_callbacks[lastIdx] == callback) {
        found = true;
        this.m_callbacks.pop();
      }
      // In the unlikely event that components are unmounted out of order, do a search and splice.
      else {
        const idx = this.m_callbacks.indexOf(callback);
        if (idx >= 0) {
          found = true;
          this.m_callbacks.splice(idx, 1);
        }
      }

      if (found) {
        lastIdx--;

        // Enable the most recent link.
        if (lastIdx >= 0) {
          this.m_callbacks[lastIdx](true);
        }
      }
    }
  }


  /**
   * Initialize the global skip-link generated by application/_header.html.haml.
   *
   * @private
   */
  _initSkipLink() {
    // Add a click listener, or update it if it's changed.
    const skipLink = document.getElementById('skip-to-content');
    if (this.m_skipLink != skipLink) {
      if (this.m_skipLink) {
        this.m_skipLink.removeEventListener('click', this._skipLinkOnClick);
      }

      this.m_skipLink = skipLink;
      skipLink.addEventListener('click', this._skipLinkOnClick);
    }
  }


  /**
   * The HAML-generated skip-link was clicked. Reduce the amount scrolled so that sticky and fixed
   * position elements. won't cover up the content.
   *
   * @param {Event} e - A real event (not React SyntheticEvent).
   * @private
   */
   _skipLinkOnClick(e) {
    try {
      const linkTarget = document.getElementById(NAME);
      if (linkTarget) {
        let fixedEltPos = [];
        // Anything below the half-way point is assumed to be stuck to the bottom.
        const maxTop = window.innerHeight / 2;

        // This selector/copy combination looks inefficient, but it only takes about 1/8 of a milisecond
        // on Chrome, and 1/50 on FireFox on a fast machine.
        [...document.body.getElementsByTagName('*')].forEach(elt => {
          const cStyle = getComputedStyle(elt, null);
          const position = cStyle.getPropertyValue('position');
          // Fixed position elements can be stacked on top of each other. Find all of them, so we
          // can pick the lowest one on the page.
          if (position == 'fixed') {
            const style = {top: parseInt(cStyle.top), bottom: elt.getBoundingClientRect().bottom};
            if (parseInt(style.top) < maxTop) {
              fixedEltPos.push(style);
            }
          }
          // Assume that sticky elements have no top offset. Otherwise, this calculation would be
          // impossible, because neither 'style' nor 'getComputedStyle' would give the correct result.
          else if (position == 'sticky') {
            const rect = elt.getBoundingClientRect();
            if (rect.top != rect.bottom) {
              fixedEltPos.push({top: 0, bottom: rect.bottom - rect.top});
            }
          }
        });

        // Find the lowest fixed/sticky element on the page.
        const bottom = fixedEltPos.reduce((accum, style) => {
          const top = parseInt(style.top);
          const bottom = parseInt(style.bottom);
          return isNaN(top) || isNaN(bottom) || bottom > accum ? bottom : accum;
        }, -Number.MAX_SAFE_INTEGER);

        // Only intercept the default anchor behavior if we found something.
        if (bottom != -Number.MAX_SAFE_INTEGER) {
          e.preventDefault();
          const scrollTop = element.getPosition(linkTarget).y - bottom;
          scrollTo(0, scrollTop);
          linkTarget.focus();
        }
      }
    } catch(e) {
      // Ignore the error, to ensure that skip-link always works.
      console.error(e);
    }
  }
}

SkipLinkTarget.NAME = NAME; // Useful for testing.

const manager = new SkipLinkManager();
