import { ActionManager } from 'utils/action_manager';
import { actionTypes } from 'redux-resource';
import * as stringUtils from 'utils/string';

export const jobRequisitionActions = new ActionManager('jobRequisitions')
  .add({
    requestKey: 'unpost',
    method: 'PUT',
    effect: 'update',
    url: '/api/v1/job_requisitions/[id]/unpost',
    list: 'list'
  }, false);

export const jobRequisitionMatchesActions = new ActionManager('jobRequisitionMatches')
  .add({
    requestKey: 'contact',
    method: 'POST',
    effect: 'update',
    url: '/api/v1/job_requisitions/[jobRequisitionId]/matches/[userId]/contact',
    urlSubs: new stringUtils.BracketSubs({
      userId: { param: 'userId', remove: true },
      jobRequisitionId: { param: 'jobRequisitionId', remove: true }
    })
  }, true)
  .add({
    requestKey: 'preview_contact',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/job_requisitions/[jobRequisitionId]/matches/[userId]/preview_contact',
    alwaysRefresh: 'true',
    urlSubs: new stringUtils.BracketSubs({
      userId: { param: 'userId', remove: true },
      jobRequisitionId: { param: 'jobRequisitionId', remove: true }
    })
  }, false)
  .add({
    requestKey: 'dismiss_email',
    method: 'POST',
    effect: 'update',
    url: '/api/v1/job_requisitions/[jobRequisitionId]/matches/[userId]/dismiss_email',
    urlSubs: new stringUtils.BracketSubs({
      userId: { param: 'userId', remove: true },
      jobRequisitionId: { param: 'jobRequisitionId', remove: true }
    })
  }, true);

/**
 * After making a request to change bookmarks or applications, updates
 * the relevant job post to reflect the new state without redoing the
 * entire index request.
 */
const updateJobPostState = (dispatch, getState, action, response, { data }) => {
  const jobPostId = data.job_post_id;
  const attrs = {};

  if (action.resourceType === 'jobPostBookmarks') {
    attrs.bookmarked_job = (action.requestKey === 'create');
  } else if (action.resourceType === 'jobPostApplications') {
    attrs.applied_to_job = (action.requestKey === 'create');
  }

  dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      jobBoardPublicJobs: {
        [jobPostId]: attrs
      }
    }
  });
};

export const jobPostApplicationsActions = new ActionManager('jobPostApplications')
  .add({
    requestKey: 'create',
    method: 'POST',
    effect: 'update',
    onSuccess: updateJobPostState,
    url: '/api/v1/job_post_applications'
  }, false);
