import React, { useContext, useEffect } from 'react';
import { SiteFooterContext } from './site_footer_context';

export const useCustomFooter = (FooterComponent) => {
  const footerContext = useContext(SiteFooterContext);
  useEffect(() => {
    footerContext.setCustomFooter(FooterComponent ? <FooterComponent/> : null);
    return () => footerContext.setCustomFooter(null);
  }, [FooterComponent]);
};
