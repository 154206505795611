import { lazyWithRetry } from 'app_utils/lazy_load';

export const ManagementLayout = lazyWithRetry(() =>
  import(/* webpackChunkName: "management" */ './management_layout')
    .then(module => ({ default: module.ManagementLayout }))
);

export const ManagementLayoutRecentlyViewed = lazyWithRetry(() =>
  import(/* webpackChunkName: "management" */ './management_layout_recently_viewed')
    .then(module => ({ default: module.ManagementLayoutRecentlyViewed }))
);
