import React, { Fragment } from 'react';
import { FormattedMessage } from "react-intl";
import { makeClassName } from "utils";
import { getProfileAction, updateUserTosAction, isActiveOrgMember } from 'profile';
import { Form, useFormProps } from "form";
import { Heading } from "controls/heading";

import './earner_tos_agreement.sass';

export const EarnerTosAgreement = (props) => {
  const tosUpdatedAt = window.initialAppValues.tosUpdatedAt;
  const [formProps] = useFormProps(updateUserTosAction);

  const [userState] = getProfileAction.useAction();
  const user = userState.resources;
  let showBanner = false;

  if (user) {
    const userAcceptedPrivacyPolicyAt = user.privacy_policy_accepted_at;
    const userAcceptedTermsAt = user.terms_and_conditions_accepted_at;
    const userHasAcceptedTerms = (userAcceptedTermsAt > tosUpdatedAt) && (userAcceptedPrivacyPolicyAt > tosUpdatedAt);
    showBanner = !user.anonymous && !userHasAcceptedTerms;
  }

  const isSignedInActiveOrgMember = () => {
    return user && !user.anonymous && isActiveOrgMember(user);
  };

  const termsLink = isSignedInActiveOrgMember()
    ? 'https://info.credly.com/credential-management-agreement'
    : 'https://info.credly.com/earner-terms-of-service';

  return (
    showBanner &&
      <div className="earner-reaccept-tos">
        <div className="earner-reaccept-tos__innerWrapper">
          <Heading appearance="plain-text">
            <FormattedMessage
              id="reaccept.tos.header"
              defaultMessage="Welcome back! We’ve updated our {termsOfService} and {privacyPolicy}. Please read the updated terms. By continuing to use our website, you agree to these updates."
              values={{
                termsOfService: (
                  <a href={termsLink} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="create_account_form.terms_of_service"
                      defaultMessage="Terms of Service"
                    />
                  </a>),
                privacyPolicy: (
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      id="create_account_form.privacy_policy"
                      defaultMessage="Privacy Policy"
                    />
                  </a>)
              }}
            />
          </Heading>
          <Form
            className={makeClassName('reaccept-tos-form')}
            {...formProps}
            {...props}
          >
            {({
              isSubmitting
            }) => (
              <Fragment>
                <Form.HiddenField
                  name="accept-terms"
                  value
                  className="cr-checkbox__wrapper--reaccept-tos-form"
                />
                <Form.Submit
                  loading={isSubmitting}
                >
                  <FormattedMessage
                    id="button.accept_terms"
                    defaultMessage="Dismiss"
                  />
                </Form.Submit>
              </Fragment>
            )}
          </Form>
        </div>
      </div>
  );
};
