import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { PropTypesExt } from 'utils/prop_types';

import './filter_section.sass';

/**
 * Renders a styled section within a filter dialog.
 *
 * @param {object} props
 *   @param {string|JSX.Element} props.children - content to render within the section (typically, a
 *     React element, but could also just be a string)
 *   @param {string} [props.heading] - the name of the section; required unless noHeading is
 *     specified
 *   @param {string} [props.className] - optional extra class name to apply to the root element
 *   @param {boolean} [props.noHeading] - if true, indicates that the section does not have a
 *     heading
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterSection = (props) => {
  return (
    <div className={makeClassName('cr-org-mgmt-filter-section', props.className)}>
      {/*
        This should most likely be a Heading, but currently the Dialog title isn't a heading, so for
        now not making this one, either
      */}
      {
        props.noHeading || (
          <div className="cr-org-mgmt-filter-section__heading">{props.heading}</div>
        )
      }
      {props.children}
    </div>
  );
};

FilterSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // assume callers won't specify noHeading=false, so this is close enough
  heading: PropTypesExt.requiredUnlessPresent(PropTypes.string, 'noHeading'),
  noHeading: PropTypes.oneOf([true])
};

export const FilterSectionGroup = (props) => {
  return(
      <div className={makeClassName('cr-org-mgmt-filter-section-group', props.className)}>
        {props.children}
      </div>
  );
}

FilterSectionGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
