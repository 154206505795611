import { lazyWithRetry } from 'app_utils/lazy_load';
import * as actionTypes from './action_types';
export { search, toggleGlobalSearchUI } from './action_creators';
export { actionTypes };
export { GLOBAL_SEARCH_TERM_KEY } from './constants';

export const GlobalSearch = lazyWithRetry(() =>
  import(/* webpackChunkName: "header" */ './global_search')
    .then(module => ({ default: module.GlobalSearch }))
);
