import React from 'react';
import PropTypes from 'prop-types';
import { TrackStatPropType } from 'utils/prop_types';
import { makeClassName } from 'utils';
import { SmartLink } from './smart_link';

import './grid_item.sass';

/**
 * Component that can be used to wrap content for an item that appears in a DataTable using the
 * grid layout.
 *
 * @property {string} action - a string suitable for consumption by SmartLink that indicates the
 *   action that should be performed when the grid item is clicked
 * @property {string} [className] - an optional, additional class to add to the top-level element
 */
export const GridItem = props =>
  <SmartLink
    className={makeClassName(props.className, 'c-grid-item')}
    action={props.action}
    title={props.title}
    aria-label={props.title}
    trackingParams={props.trackingParams}
    target={props.target}
  >
    {props.children}
  </SmartLink>;

GridItem.propTypes = {
  ...SmartLink.propTypes,
  trackingParams: TrackStatPropType
};

GridItem.defaultProps = {
  stackBreakpoint: 'sm'
};
