import React from 'react';
import Badges from './badges';
import { DashboardBanner } from './dashboard_banner';
import './dashboard.sass';
import { useEnabledFeatures } from 'utils/enabled_features';

export const Dashboard = () => {
  const isFeatureEnabled = useEnabledFeatures();
  return (
    <div className="c-earner-earned-badges-dashboard">
      {isFeatureEnabled('earner-dashboard-banner') && <DashboardBanner/>}
      <Badges/>
    </div>
  );
};
