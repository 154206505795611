////
// A set of polyfills that are:
// - For browsers that don't load the polyfills rollup (BrowserVersions.browser_needs_polyfills?).
// - Are not injected by Webpack/Bower config.
//
// Every block in this file should include the specific browser versions for which it was created.
// When BrowserVersions.browser_needs_polyfills? is updated, blocks in this file might be removed.
////


// Add Canvas.toBlob to Edge 12-78. File type params are not supported.
if (!HTMLCanvasElement.prototype.toBlob && HTMLCanvasElement.prototype.msToBlob) {
  HTMLCanvasElement.prototype.toBlob = function toBlob(callback) {
    // Use setTimeout to ensure that it's asynchronous, like toBlob.
    setTimeout(() => {
      callback(this.msToBlob());
    }, 1);
  };
}
