import React, { useState } from 'react';
import { Routes } from "utils";
import { Heading, NewHeadingLevel } from 'controls/heading';
import { SectionNavigation } from "controls/section_navigation";
import { JobChiclet } from './job_chiclet';
import { JobDetails } from './job_details';
import { FormattedMessage } from "react-intl";
import { EmptyJobBoard } from './empty_job_board';
import { LoadingSpinner } from "controls/loading_spinner";
import { PaginationNavControls, PaginationProgressIndicator } from 'controls/pagination';
import { useBrowserQueryString } from 'controls/with_query_string';
import { useJobPosts } from './hooks/use_job_posts';
import { JobBoardFilters } from './job_board_filters';
import { getProfileAction } from 'profile';
import './job_board.sass';

export const JobBoard = () => {
  const queryString = useBrowserQueryString();
  const [statusFilter, setStatusFilter] = useState(queryString.get('statusFilter'));
  const page = parseInt(queryString.get('page')) || 1;
  const [jobDetailsFilter, setJobDetailsFilter] = useState(queryString.get('jobDetailsFilter') | []);
  const handleFiltering = (values) => {
    setJobDetailsFilter(values);
  };

  const { data: response, isLoading } = useJobPosts({ page, statusFilter, jobDetailsFilter });
  const { data, metadata } = response || { data: null, metadata: null };
  const [jobIndex, setJobIndex] = useState(0);
  const [prevJobIndex, setPrevJobIndex] = useState(0);
  const [{ resources: currentUser }] = getProfileAction.useAction();
  const currentUserId = currentUser?.id;

  const previouslySeen = () => {
    return prevJobIndex !== 0 && jobIndex === 0;
  };

  const isAutoloaded = () => {
    if (previouslySeen()) return false;
    return data[jobIndex] === data[0];
  };
  const renderJobChiclets = () => {
    return (
      isLoading ? (
        <LoadingSpinner delay={200} position="center" />
      ) : (
        data.map((job, index) =>
          <JobChiclet
            job={job}
            key={index}
            tabIndex={index}
            onClick={() => {
              setPrevJobIndex(jobIndex);
              setJobIndex(index);
            }}
            active={index === jobIndex}
          />
        ))
    );
  };

  const renderPaginationDetails = () => {
    return isLoading ? (
      <LoadingSpinner delay={200} position="center" />
    ) : (
      data.length === 0 ? (
        <Heading appearance="custom" className="job-board-header-text-counter">
          <FormattedMessage
            id="talent_match.job_board.empty_board.pagination_copy"
            defaultMessage="Showing 0 jobs"
          />
        </Heading>
      ) : (
        <Heading appearance="custom" className="job-board-header-text-counter">
          <PaginationProgressIndicator data={metadata} />
        </Heading>
      )
    );
  };

  return (
    <>
      <div className="job-board-layout">
        <div className="container job-board-layout__container">
          <div className="job-board-header-filter-tabs">
            <NewHeadingLevel>
              <div className="">
                <SectionNavigation>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter()}
                    action={Routes.jobBoardUrl()}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    All Jobs
                  </SectionNavigation.Link>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter('applied')}
                    action={Routes.jobBoardByStatusUrl('applied')}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    Applied
                  </SectionNavigation.Link>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter('saved')}
                    action={Routes.jobBoardByStatusUrl('saved')}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    Saved
                  </SectionNavigation.Link>
                </SectionNavigation>
              </div>
            </NewHeadingLevel>
            <div className="job-board-filters">
              <JobBoardFilters onSubmit={handleFiltering} />
            </div>
          </div>
          <div className="job-board-header">
            <Heading appearance="custom" className="job-board-header-text">All Jobs</Heading>
            {renderPaginationDetails()}
          </div>
          {!isLoading && data?.length === 0 ? (
            <EmptyJobBoard filter={statusFilter} />
          ) : (
            <div className="row no-gutters">
              <div className="col-12 col-lg-5">
                <div className="job-board-layout__sidebar">
                  {isLoading ? (
                    <LoadingSpinner delay={200} position="center" />
                  ) : (
                    <>
                      {renderJobChiclets()}
                      <div className="job-board-pagination">
                        <PaginationNavControls
                          data={metadata}
                          pageNumbers
                          centered
                          compact
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="job-board-layout__main">
                  {isLoading ? (
                    <LoadingSpinner delay={200} position="center" />
                  ) : (
                    <div className="job-board-details">
                      <div className="job-board-details-content">
                        <JobDetails
                          job={data[jobIndex] || data[0]}
                          autoload={isAutoloaded()}
                          currentUserId={currentUserId}
                          /* eslint-disable camelcase */
                          bookmarked={data[jobIndex]?.bookmarked_job}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
