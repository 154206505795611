/**
 * Custom error class to trigger rendering of the signin page in ErrorBoundary.
 */
export class SigninRequiredError extends Error {
  constructor() {
    super('Signin Required. Rendering Signin page');
    this.errorType = SigninRequiredError;
    window.scrollTo(0, 0);
  }
}

SigninRequiredError.errorIs = error => error && error.errorType === SigninRequiredError;

SigninRequiredError.raise = () => {
  throw new SigninRequiredError();
};
