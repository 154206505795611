import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { actionTypes, resourceReducer } from 'redux-resource';
import thunk from 'redux-thunk';
import { apiReducer } from 'utils/reduxUtils';
import { globalSearchUIReducer, searchResultsReducer } from 'global_search/reducers';
// intentionally importing this directly, rather than via the manifest file, because the rest of the
// modules are lazy loaded, but this one we need immediately
import {
  managementOrganizationsReducers,
  orgMgmtReducer
} from 'management/organizations/reducers';
import { rulesIntegrationReducers } from 'rules_integration';

/**
 * Create the global redux store, with links to all sub-reducers.
 *
 * To add a simple reducer, you can use apiReducer() directly. This will set up data compatible
 * with Action and ActionManager, with a redux-reducer structure, plus a metadata component
 * corresponding to API metadata.
 *
 * Reducers that don't use redux-resource, or reducers with plugins to do custom processing, should
 * be imported.
 *
 * @type {Reducer<any>}
 */
const reducer = combineReducers({
  // Store for temporary things that don't need actual data (or don't need it for long).
  // If you use this, make sure your request key is unique.
  temp: apiReducer('temp'),
  // Employees Learning Activity for Workforce dashboard
  employeesLearningActivity: orgMgmtReducer('employeesLearningActivity'),
  // Earned badges, including lists, and specific badges loaded for details pages.
  earnedBadges: apiReducer('earnedBadges'),
  // Prior Learning Network credits quantity for the user.
  plnCredits: apiReducer('plnCredits'),
  // Congratulate a user's badge - create/delete
  congratulations: apiReducer('congratulations'),
  // Lists congratulations for a badge
  fetchCongratsByBadgeId: apiReducer('fetchCongratsByBadgeId'),
  // Mark congratulations as seen for a badge
  markCongratsAsSeen: apiReducer('markCongratsAsSeen'),
  // Lists skills from emerging skills endpoint
  emergingSkills: apiReducer('emergingSkills'),
  // Lists badge suggestions from explore endpoint
  exploreBadges: apiReducer('exploreBadges'),
  // Lists popular program suggestions
  explorePopularPrograms: apiReducer('explorePopularPrograms'),
  // Lists popular badges within an employer organization for consumer/employee
  popularOrgBadgesConsumer: apiReducer('popularOrgBadgesConsumer'),
  // Lists top organizations
  exploreTopOrganizations: apiReducer('exploreTopOrganizations'),
  // Faethm occupation matcher
  faethmOccupationMatcher: apiReducer('faethmOccupationMatcher'),
  faethmOccupationSearch: apiReducer('faethmOccupationSearch'),
  // Faethm skills search
  faethmSkillSearch: apiReducer('faethmSkillSearch'),
  // The user profile. This store is initialized on page load, as initialAppValues.currentUser.
  profile: apiReducer('profile'),
  // Badge issuers the user has marked for auto-accept.
  autoAccepts: apiReducer('autoAccepts'),
  // Candidates for auto-accept.
  candidateOrganizations: apiReducer('candidateOrganizations'),
  claimCredlyMigratedAccount: apiReducer('claimCredlyMigratedAccount'),
  credlyMigratedUser: apiReducer('credlyMigratedUser'),
  occupationDetailsAndStats: apiReducer('occupationDetailsAndStats'),
  // Account settings: Secondary email list.
  secondaryEmails: apiReducer('secondaryEmails'),
  // Account settings: Language preference.
  language: apiReducer('language'),
  // Account settings: Merge accounts flow.
  mergeAccounts: apiReducer('mergeAccounts'),
  // Account settings: Job preferences
  jobPreferences: apiReducer('jobPreferences'),
  // Account settings: User Experiences
  userExperiences: apiReducer('userExperiences'),
  // Account settings: User Experience Integrations
  userExperienceSocialProfiles: apiReducer('userExperienceSocialProfiles'),
  // Pop-up messages for the Alert and AlertStack components.
  alerts: resourceReducer('alerts'),
  // Jobs UI
  jobs: apiReducer('jobs'),
  googlePayPassJWT: apiReducer('googlePayPassJWT'),
  // Create a new account
  createAccount: apiReducer('createAccount'),
  // Global search
  globalSearchUI: globalSearchUIReducer,
  // Skills UI
  lmiData: apiReducer('lmiData'),
  // Interested Skills Dashboard UI
  userSkillInterests: apiReducer('userSkillInterests'),
  // Interested Skills Dashboard Search
  skillInterests: apiReducer('skillInterests'),
  // Faethm API Skill Details
  skillEnhancedDetail: apiReducer('skillEnhancedDetail'),
  // Faethm Skill Trend
  skillTrend: apiReducer('skillTrend'),
  // Badge Template LMI Page
  badgeTemplateLmiStats: apiReducer('badgeTemplateLmiStats'),
  // Related badges for skill
  relatedBadges: apiReducer('relatedBadges'),
  // Faethm API Related Badges
  faethmRelatedBadges: apiReducer('faethmRelatedBadges'),
  // Manager Recommended Badges
  managerRecommendedBadges: apiReducer('managerRecommendedBadges'),
  // Global search results
  searchResults: searchResultsReducer,
  // Global autocomplete results
  searchAutocompleteResults: apiReducer('searchAutocompleteResults'),
  // Global filtered badge search
  filteredBadgeSearchResults: apiReducer('filteredBadgeSearchResults'),
  // Directory: Search results, earner cards and custom filter data
  directorySearch: apiReducer('directorySearch'),
  directoryCards: apiReducer('directoryCards'),
  directoryFilter: apiReducer('directoryFilter'),
  // Directory: Search results, earner cards for EOD employers tab
  employerDirectorySearch: apiReducer('employerDirectorySearch'),
  employerDirectoryCards: apiReducer('employerDirectoryCards'),
  // Account settings: Applications
  applicationProviders: apiReducer('applicationProviders'),
  applicationProviderConnections: apiReducer('applicationProviderConnections'),
  nonceTokens: apiReducer('nonceTokens'),
  oauthApplications: apiReducer('oauthApplications'),
  // Permissions: For user on orgs
  permissionOrganizations: apiReducer('permissionOrganizations'),
  // Pending permission notifications
  permissionsRequests: apiReducer('permissionsRequests'),
  // For public badge template displays (e.g. on organization profile or badge template details)
  publicBadgeTemplates: apiReducer('publicBadgeTemplates'),
  // for public issuer collections
  publicIssuerCollections: apiReducer('publicIssuerCollections'),
  // For public org badge displays (e.g. on organization profile or B2B badge details)
  publicOrganizationEarnedBadges: apiReducer('publicOrganizationEarnedBadges'),
  // Public badges
  publicBadges: apiReducer('publicBadges'),
  // Preserved badges
  preservedBadges: apiReducer('preservedBadges'),
  // Viewable badges
  viewableBadges: apiReducer('viewableBadges'),
  // Public organizations (i.e. public org profile)
  publicOrganizations: apiReducer('publicOrganizations'),
  // obiBadges
  obiBadges: apiReducer('obiBadges'),
  // Public user information (for user profiles)
  publicUsers: apiReducer('publicUsers'),
  // Publicly-viewable badges for a given user
  publicUserBadges: apiReducer('publicUserBadges'),
  // Publicly-available blockcerts data for badges published to blockchain
  publicBlockcerts: apiReducer('publicBlockcerts'),
  savedDirectorySearches: apiReducer('savedDirectorySearches'),
  // Data for creating an account via SSO
  sso: apiReducer('sso'),
  ssoTfa: apiReducer('ssoTfa'),
  // Rules Integration UI
  ...rulesIntegrationReducers,
  // Badge templates listed under an issuer collection
  publicICBadgeTemplates: apiReducer('publicICBadgeTemplates'),
  // Stores information about user requests to distribute academic transcripts
  transcriptDistributions: apiReducer('transcriptDistributions'),
  // Stores information about distributed academic transcript recipients
  transcriptDistributionRecipients: apiReducer('transcriptDistributionRecipients'),
  // Employee uploader.
  uploadEmployees: orgMgmtReducer('uploadEmployees'),
  employments: apiReducer('employments'),
  acceptedEmployments: apiReducer('acceptedEmployments'),
  employmentHistories: apiReducer('employmentHistories'),
  resendEmailConfirmation: apiReducer('resendEmailConfirmation'),
  // For calling autocomplete job titles name
  jobTitles: apiReducer('jobTitles'),
  // For calling autocomplete employer name
  employers: apiReducer('employers'),
  // Enabled Features
  enabledFeatures: apiReducer('enabledFeatures'),
  // Badge Templates
  searchableBadgeTemplates: apiReducer('searchableBadgeTemplates'),
  // User Skills
  userSkills: apiReducer('userSkills'),
  // User Group Tag
  userGroupTags: apiReducer('userGroupTags'),
  // For extracting skills from a resume
  userSkillExtractions: apiReducer('userSkillExtractions'),
  // For extracting skills from a badge template
  badgeTemplateSkillExtractions: apiReducer('badgeTemplateSkillExtractions'),
  // For the talent match screen (info on user and the job postings)
  jobRequisitions: apiReducer('jobRequisitions'),
  jobRequisitionMatches: apiReducer('jobRequisitionMatches'),
  jobRequisitionMatchesDetails: apiReducer('jobRequisitionMatchesDetails'),
  oauthUiOptions: apiReducer('oauthUiOptions'),
  // For the earner job board
  jobBoardPublicJobs: apiReducer('jobBoardPublicJobs'),
  jobPostApplications: apiReducer('jobPostApplications'),
  jobPostBookmarks: apiReducer('jobPostBookmarks'),
  // For earner dashboard
  dashboardActivitySnapshotSignatures: apiReducer('dashboardActivitySnapshotSignatures'),
  dashboardActivitySnapshot: apiReducer('dashboardActivitySnapshot'),
  similarEmployees: apiReducer('similarEmployees'),
  trendingEmergingSkills: apiReducer('trendingEmergingSkills'),
  topSkillsByOccupation: apiReducer('topSkillsByOccupation'),
  // Employer Stats
  popularSkills: apiReducer('popularSkills'),
  popularOrgBadgesOrg: apiReducer('popularOrgBadgesOrg'),
  industryTrendingEmergingSkills: apiReducer('industryTrendingEmergingSkills'),
  workforceEmployerStats: orgMgmtReducer('workforceEmployerStats'),
  workforceEmployerStatsHistory: orgMgmtReducer('workforceEmployerStatsHistory'),
  workforceOccupationPageFilter: orgMgmtReducer('workforceOccupationPageFilter'),
  workforceLocationPageFilter: orgMgmtReducer('workforceLocationPageFilter'),
  workforceJobFamilyPageFilter: apiReducer('workforceJobFamilyPageFilter'),
  workforceOrganizationPageFilter: orgMgmtReducer('workforceOrganizationPageFilter'),
  workforceOccupationCategoryPageFilter: orgMgmtReducer('workforceOccupationCategoryPageFilter'),
  workforceSkillsDistribution: orgMgmtReducer('workforceSkillsDistribution'),
  workforceSkillsGained: orgMgmtReducer('workforceSkillsGained'),
  workforceFutureProofSkills: orgMgmtReducer('workforceFutureProofSkills'),
  signinViaMicrosoft: apiReducer('signinViaMicrosoft'),
  signinViaApple: apiReducer('signinViaApple'),
  learningPlans: apiReducer('learningPlans'),
  industry: apiReducer('industry'),
  organizationSize: apiReducer('organizationSize'),
  // File picker
  filePicker: apiReducer('filePicker'),
  quickCreateOrganizations: orgMgmtReducer('quickCreateOrganizations'),
  ...managementOrganizationsReducers // or members of the organizations)
});

// Support redux devtools in Chrome.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

/*
 * Maps resource paths to their corresponding slices within the Redux store.  Used by
 * initPrerenderData to populate the Redux store from data stashed in the initial page load.
 */
const PRERENDER_DATA_MAPPING = [
  {
    test: p => /\/v1\/public_badges\/[^/]+$/.test(p),
    resourceSlice: 'publicBadges'
  },
  {
    test: p => /\/v1\/organizations\/[^/]+\/employer_stats\/current$/.test(p),
    resourceSlice: 'workforceEmployerStats'
  },
  {
    test: p => /\/v1\/organizations\/[^/]+\/employer_stats\/last_month$/.test(p),
    resourceSlice: 'workforceEmployerStatsHistory'
  },
  {
    test: p => /\/org\/[^/]+\/badge\/[^/]+$/.test(p),
    resourceSlice: 'publicBadgeTemplates'
  },
  {
    test: p => /\/organizations\/[^/]+\/badges/.test(p),
    resourceSlice: 'publicBadgeTemplates'
  },
  {
    test: p => /\/organizations\/[^/]+\/earned_organization_badges/.test(p),
    resourceSlice: 'publicOrganizationEarnedBadges'
  },
  {
    test: p => /\/organizations\/[^/]+\/collections(\/[^/]+)?\/?$/.test(p),
    resourceSlice: 'publicIssuerCollections'
  },
  {
    test: p => /\/organizations\/[^/]+\/collections\/[^/]+\/badge_templates/.test(p),
    resourceSlice: 'publicICBadgeTemplates'
  },
  {
    test: p => /\/organizations\/[^/]+$/.test(p),
    resourceSlice: 'publicOrganizations'
  },
  {
    test: p => /\/users\/[^/]+$/.test(p),
    resourceSlice: 'publicUsers'
  },
  {
    test: p => /\/users\/[^/]+\/badges/.test(p),
    resourceSlice: 'publicUserBadges'
  },
  {
    test: p => /\/users\/registrations\/sso/.test(p),
    resourceSlice: 'sso'
  },
  {
    test: p => /\/users\/sessions\/two_factor_authentication_sso/.test(p),
    resourceSlice: 'ssoTfa'
  },
  {
    test: p => /\/v1\/oauth\/v2\/applications\/[^/]+\/ui_options/.test(p),
    resourceSlice: 'oauthUiOptions'
  }
];

/**
 * @property {Array<object>} data - array of resources to put in the resource slice
 * @property {string} [list] - if data should be assigned to a list, the name of that list
 * @property {object} [metadata] - metadata to associate with the above list, just as pagination
 *   information and data key
 */
let PrerenderDataElementDocOnly;

/**
 * Populates the Redux store with data from the provided map.  Expected usage is to use data stashed
 * in the initial page load by the Rails application to initialize the store with data corresponding
 * to any HTML that was rendered by the Rails side, so that the React app doesn't have to make any
 * requests to render the initial view.
 *
 * @param {Object<string, PrerenderDataElementDocOnly>} data - the data to initialize the store
 *   from
 */
export const initPrerenderData = (data) => {
  const resources = {};
  const lists = {};
  const metadata = {};
  const mergeResources = {};
  for (const path of Object.keys(data)) {
    const mapping = PRERENDER_DATA_MAPPING.find(m => m.test(path));
    if (mapping) {
      const key = mapping.resourceSlice;
      const resourceData = data[path];
      if (resourceData.data) {
        const dataArray = Array.isArray(resourceData.data) ? resourceData.data : [resourceData.data];
        resources[key] = dataArray;
        mergeResources[key] = false;

        if (resourceData.list) {
          lists[key] = { [resourceData.list]: dataArray.map(r => r.id) };

          if (resourceData.metadata) {
            metadata[key] = { [resourceData.list]: resourceData.metadata };
          }
        }
      }
    }
  }

  store.dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    resources,
    lists,
    metadata,
    mergeResources
  });
};

export const testingStore = () => createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
