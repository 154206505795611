import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { makeClassName } from 'utils/index';
import './loading_spinner.sass';


/**
 * Accessible loading spinner.
 *
 * @param {Object} props
 *   @param {String} props.position - One of {default, below, center}
 *     default: Render the spinner in place, with no extra styling.
 *     below: Render the spinner horizontally centered, and below the container.
 *     center: Render horizontally and vertically centered within the closest positioned container.
 *     window-center: Center in the window, regardless of parent nodes.
 *   @param {String} props.size - One of {fill, small, medium, large}; fill can only be used with
 *     "default" position option, since fills the size of its container
 *   @param {String} props.className - A class name.
 *   @param {String} props.ariaLabel - Text for the screen reader.
 *   @param {Boolean} props.quiet - Don't announce this spinner to screen readers.
 *   @param {Boolean} props.inheritColor - Instead of choosing a color, inherit the parent color.
 *   @param {int=500} props.delay - Only show the spinner when this component has been active for
 *     this many milliseconds.
 * @returns {*}
 * @constructor
 */
export class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: !props.delay };
    this.m_delayTimeout = 0;
  }

  /**
   * The component rendered for the first time.
   */
  componentDidMount() {
    if (this.props.delay) {
      this.m_delayTimeout = setTimeout(this.show, this.props.delay);
    }
  }

  /**
   * The component is about to unmount.
   */
  componentWillUnmount() {
    this.m_delayTimeout && clearTimeout(this.m_delayTimeout);
  }

  /**
   * Show the loading spinner, if it was previously hidden.
   */
  show = () => {
    this.m_delayTimeout = 0;
    this.setState({ visible: true });
  };

  /**
   * Render the component;
   *
   * @returns {React.element}
   */
  render() {
    if (!this.state.visible) {
      return <Fragment/>;
    }

    const props = this.props;

    const ariaProps = props.quiet
      ? {}
      : {
        role: 'alert',
        'aria-live': 'assertive',
        'aria-atomic': true
      };

    return <div
      className={makeClassName([
        'loading-spinner',
        'loading-spinner__' + (props.position || 'default'),
        'loading-spinner__' + (props.size || 'medium'),
        props.inheritColor && 'loading-spinner__inherit-color',
        props.className
      ])}
    >
      <FontAwesomeIcon icon={faSpinner}/>
      <span className="loading-spinner__hidden-text" {...ariaProps}>
        {props.ariaLabel || 'Loading'}
      </span>
    </div>;
  }
}

LoadingSpinner.propTypes = {
  position: PropTypes.oneOf(['default', 'below', 'center', 'window-center']),
  size: PropTypes.oneOf(['fill', 'small', 'medium', 'large']),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  quiet: PropTypes.bool,
  inheritColor: PropTypes.bool,
  delay: PropTypes.number
};

LoadingSpinner.defaultProps = {
  delay: 0
};
