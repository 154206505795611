import { createSelector } from 'reselect';
import { badgeToBadgeMedium } from 'badges';
import { EARNED_BADGES_SORT_BY } from './action_creators';

export const makeEarnedBadgesSortBySelector = () => (
  createSelector(
    (props) => props.location.search,
    (search) => {
      let sortBy = search && new URLSearchParams(search).get('sortBy');
      if (EARNED_BADGES_SORT_BY.indexOf(sortBy) === -1){
        sortBy = 'issued_at';
      }
      return sortBy;
    }
  )
);

const badgeAPIToGridItemAdapter = (badge) => ({ public: badge.public, state: badge.state, ...badgeToBadgeMedium(badge) });

export const makeEarnedBadgesSelector = () => {
  return createSelector(
    badges => badges,
    (badges) => badges.map(badgeAPIToGridItemAdapter)
  );
};
