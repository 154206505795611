import { flatten } from 'utils/array';

/**
 * Combine redux status from multiple loaders.
 *
 * @param {Object[]} loaders The loaders to combine
 *   @param {bool} loaders[].idle
 *   @param {bool} loaders[].pending
 *   @param {bool} loaders[].suceeded
 *   @param {bool} loaders[].failed
 * @returns {object} The combined loader
 */
export const combineLoaders = (...loaders) => {
  loaders = flatten(loaders);

  if (loaders.length === 0) {
    return {idle: true, succeeded: false, pending: false, failed: false};
  }

  // Pending has highest priority.
  const pending = loaders.some(l => l.pending);
  // Then failed.
  const failed = loaders.some(l => l.failed) && !pending;
  // Succeeded is only true if every loader succeeded.
  const succeeded = loaders.every(l => l.succeeded);
  // Idle is the fallback.
  const idle = !succeeded && !pending && !failed;

  return {idle: idle, succeeded: succeeded, pending: pending, failed: failed};
};

/**
 * Combine redux status from multiple loaders. Don't give any weight to idle states.
 *
 * @param {Object[]} loaders The loaders to combine
 *   @param {bool} loaders[].idle
 *   @param {bool} loaders[].pending
 *   @param {bool} loaders[].suceeded
 *   @param {bool} loaders[].failed
 * @returns {object} The combined loader
 */
export const combineLoadersIgnoreIdle =
  (...loaders) => combineLoaders(...loaders.filter(l => !l.idle));
