import React, { Component, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { TrackStatPropType } from 'utils/prop_types';
import { withRouter } from 'react-router';
import { ajax, makeClassName } from 'utils';
import { Link } from 'react-router-dom';
import * as objUtils from 'utils/object';
import { useShallowEquals, useMemoStrict } from 'utils/react_utils';

const REFRESH_SIGNATURE_INTERVAL = 8 * 60 * 1000;
const TRACKING_TIMEOUT = 400;


/**
 * Fire tracking events to the server. Events are blocked for platform managers.
 * In general, the `tracking` singleton should be used, rather than the `Tracking` constructor.
 *
 * Fire a tracking event:
 *   tracking.track({type: string, object_id: string, object_type: string});
 *
 * Fire a tracking event, augmenting data before it's sent:
 *   tracking.track(trackParams, [data => {data.something_extra = 'stuff'}]);
 *
 * Create a tracking link, which only tracks once. If the link will cause the page to refresh,
 * the tracking has 400ms to complete.
 *   <TrackLink track={trackData} augment={augmentArray} once to="url">track me!</TrackLink>
 *
 * Track the first click on a node:
 *   <TrackOnClick track={trackData} augment={augment} once>
 *     <div>Track me!</div>
 *   </TrackOnClick>
 */
export class Tracking {
  /**
   * @param {Object=} options
   *   @param {int=TRACKING_TIMEOUT} options.timeout - Execution flow will be allowed to continue
   *     after this many miliseconds. If the window is closed or the page is refreshed after this
   *     time, but before tracking is complete, the tracking request will be cancelled.
   *   @param {int=REFRESH_SIGNATURE_INTERVAL} options.refreshInterval - The tracking params will
   *     be refreshed at intervals of this many miliseconds, starting when the class is constructed.
   * @param {Object=} trackParams - The tracking params to use for all requests.
   * @param {Object=} env - The environment object.
   */
  constructor(options = {}, trackParams = {}, env) {
    this.m_augmentations = [];
    this.m_globalData = {};
    this.m_url = null;
    this.m_refreshUrl = null;
    this.m_timeout = options.timeout || TRACKING_TIMEOUT;
    this.m_refreshInterval = options.refreshInterval || REFRESH_SIGNATURE_INTERVAL;
    this.m_stop = false;
    this.m_env = env;

    if (Object.keys(trackParams).length > 0) {
      const tr = trackParams;
      this.m_url = tr.url;
      this.m_refreshUrl = tr.refresh_url;
      this.m_globalData = tr.request_data || {};
      this.m_timeout = tr.overrideTimeout || this.m_timeout;

      // Platform managers don't get tracking. Disable.
      this.m_isPlatformManager = Object.keys(this.m_globalData).length === 0 && !tr.test_mode;
    }

    this._refreshSignature();
  }


  /**
   * Stop refreshing the signature.
   */
  stop() {
    if (this._GLOBAL_VERSION) {
      throw (new Error(
        'Tracking::stop: Called stop() on the global track object. Use only for local instances.'
      ));
    }
    this.m_stop = true;
  }


  /**
   * Add an augmentation, which intercepts data before submit.
   *
   * @param {function(object):object} augmentation
   */
  addAugmentation(augmentation) {
    this.m_augmentations.push(augmentation);
  }


  /**
   * Send a tracking request.
   *
   * @param {Object} data - Tracking data. Can include any options, but requires the following:
   *   @param {String} data.type
   *   @param {String} data.object_id
   *   @param {String} data.object_type
   * @param {Array<function(object):object>=} augment - A set of augment functions.
   * @returns {Promise<Boolean>}
   */
  async track(data, augment = []) {
    let modData = { ...this.m_globalData, snapshot_json: {} };

    objUtils.forEach(data, (key, val) => {
      if (key === 'snapshot_json') {
        modData.snapshot_json = { ...val, ...modData.snapshot_json };
      } else if (key in { type: 1, object_id: 1, object_type: 1 }) {
        modData['stat_' + key] = val;
      } else {
        modData.snapshot_json[key] = val;
      }
    });

    if (Object.keys(modData.snapshot_json).length === 0) {
      delete modData.snapshot_json;
    }

    const url = this.m_url || data.url;

    try {
      this.m_augmentations.forEach(aug => modData = aug(modData));
      (augment || []).forEach(aug => modData = aug(modData));
    } catch (e) {
      console.error('Tracking::track: Bad augment data');
      console.error(e);
    }

    if (!modData.stat_type || !modData.stat_object_id || !modData.stat_object_type || !url) {
      // Don't throw an exception; tracking should never break execution.
      console.error('Tracking request missing data.', modData, url);
      return false;
    } else {
      if (this.m_isPlatformManager) {
        // Block tracking for platform managers.
        if (this.m_env === 'development') {
          console.log('Tracking is disabled for platform managers.', modData);
        }
      } else {
        await new Promise(resolve => {
          ajax({
            uri: url,
            method: 'POST',
            body: modData,
            headers: { 'Content-Type': 'application/json' }
          }).then(resolve).catch(resolve);

          // If the timeout passes, return control to the caller, but don't cancel the XHR request.
          setTimeout(resolve, this.m_timeout);
        });
      }
    }
    return true;
  }


  /**
   * Refresh the signature data sent in each tracking request at a regular interval.
   *
   * @private
   * @param {int} delay - Wait this many miliseconds before making the request.
   */
  _refreshSignature = (delay = this.m_refreshInterval) => {
    if (this.m_stop || this.m_isPlatformManager || !this.m_refreshUrl) {
      return;
    }

    setTimeout(async () => {
      if (this.m_stop) {
        return;
      }

      const handleError = () => {
        if (this.m_env === 'development') {
          console.log('Error refreshing tracking signature');
        }
      };

      const start = Date.now();
      let result;
      try {
        result = await ajax({
          uri: this.m_refreshUrl,
          headers: { Accept: 'application/json' },
          type: 'GET',
          responseType: 'json'
        }).catch(handleError);
      } catch (e) {
        // ajax().catch() doesn't always work.
        handleError();
      }

      if (result && result.success && result.body && result.body.data) {
        this.m_globalData = result.body.data.request_data;
        this.m_url = result.body.data.url;
      }

      const duration = Date.now() - start;

      // The signature lasts for a maximum of ten minutes. Subtract duration, to avoid sleeping
      // for too long.
      this._refreshSignature(this.m_refreshInterval - duration);
    }, Math.max(0, delay));
  };
}

class TrackingTest extends Tracking {
  constructor(options = {}) {
    super(
      options,
      {
        url: 'TRACKING_URL',
        refresh_url: 'REFRESH_URL',
        test_mode: true
      },
      'test'
    );
  }
}

let trackingTest;
const testInitializeTracking = () => {
  trackingTest = new TrackingTest();

  return () => {
    trackingTest = null;
  };
};

/**
 * Track clicks on a single child node. Be careful not to call stopPropagation() in any child
 * onClick events, or tracking will not happen.
 *
 * <TrackOnClick track={trackData} augment={augment} once>
 *   <div>Track me!</div>
 * </TrackOnClick>
 *
 * @property {{type: string, object_id: string, object_type: string}} track - tracking data.
 *   Arbitrary options are allowed as well.
 * @property {Boolean} once - Only the first click is tracked.
 * @property {Array<function(object):object>} augment - Augment functions
 */
export class TrackOnClick extends Component {
  constructor(props) {
    super(props);
    this.m_count = 0;
    this.m_tracking = props.tracking;
  }


  /**
   * The node has been clicked.
   *
   * @param {Event} e
   */
  onClick = e => {
    if (!this.props.once || this.m_count === 0) {
      this.m_count++;
      this.m_tracking.track(this.props.track, this.props.augment);
    }
  };


  /**
   * Render the compoent.
   *
   * @returns {*}
   */
  render() {
    if (!this.props.children) {
      return '';
    }

    if (!React.Children.only(this.props.children)) {
      throw (new Error('TrackOnClick only supports a single child node'));
    }

    const oldClick = this.props.children.props.onClick;
    const child = React.cloneElement(this.props.children, {
      onClick: e => {
        oldClick && oldClick(e);
        this.onClick(e);
      }
    });

    return <Fragment>{child}</Fragment>;
  }
}
TrackOnClick.propTypes = {
  track: TrackStatPropType.isRequired,
  once: PropTypes.bool,
  augment: PropTypes.arrayOf(PropTypes.func),
  tracking: PropTypes.instanceOf(Tracking)
};

const TrackOnClickTest = props => (
  <TrackOnClick
    {...props}
    tracking={trackingTest}
  />
);

TrackOnClickTest.propTypes = { ...TrackOnClick.propTypes };


/**
 * Track clicks on a link. This behaves in most ways like a react-router-dom <Link> node.
 *
 * <TrackLink track={trackData} augment={augmentArray} once to="url">track me!</TrackLink>
 *
 * Supports all properties of <Link>, as well as:
 *
 * @property {String} to - Unlike with <Link>, this property is not required. Avoid setting it to
 *   no-op strings like '#' or 'javascript:void(0)'
 * @property {Boolean} localRoutesToSameWindow - If false, links are opened according to the
 *   'target' parameter. If true, remote links open in a new tab, and local links open in the
 *   current tab.
 * @property {{type: string, object_id: string, object_type: string}} track - tracking data.
 *   Arbitrary options are allowed as well.
 * @property {Boolean} once - Only the first click is tracked.
 * @property {Array<function(object):object>} augment - Augment functions
 */
class TrackLink extends Component {
  constructor(props) {
    super(props);
    this.m_count = 0;
    this.m_isRouterLink = true;
    this.m_tracking = props.tracking;
  }


  /**
   * The link has been clicked.
   *
   * @param {Event} e
   */
  onClick = async e => {
    e.preventDefault();
    const props = this.props;

    // Get these from the link, rather than props, because their correct values have already been
    // determined in render().
    const clicked = e.currentTarget;
    const target = clicked.target;
    const to = clicked.getAttribute('href');
    const linkGoesSomewhere = to && to !== '#';

    // if we are opening an actual link (indicated by to !== '#'), rather than just being a click
    // target for an onClick behavior, and should be opened in a new tab/window, do that before
    // trying to perform any tracking, because browsers only allow us to do such things in response
    // to a user action, and this page will stay open to finish the tracking, anyway
    if (linkGoesSomewhere && target) {
      window.open(to, target, 'noopener,noreferrer');
    }

    if (!props.once || this.m_count === 0) {
      this.m_count++;
      await this.m_tracking.track(props.track, props.augment);
    }

    props.onClick && props.onClick(e);

    // if we are opening an actual link (indicated by to !== '#'), but it should be in this same
    // window/tab as a push-state, we do so now (after starting the tracking request)
    if (linkGoesSomewhere && !target) {
      if (this.m_isRouterLink) {
        props.history.push(this.stripOrigin(to));
      } else {
        document.location.href = this.stripOrigin(to);
      }
    }
  };


  /**
   * Strip the origin from a URL.
   *
   * @param {String} url - The url
   * @returns {String}
   */
  stripOrigin(url) {
    if (url && url.indexOf(document.location.origin) === 0) {
      return url.substr(document.location.origin.length);
    }
    return url;
  }


  /**
   * Is the "to" location local and routable?
   *
   * @returns {boolean}
   */
  isLocalRoute() {
    if (!this.props.to) {
      return true;
    }

    const to = this.stripOrigin(this.props.to);

    if (to.charAt(0) === '/') {
      const exclusions = [/\/sign_(in|up)/];
      const localMatches = [
        /^\/(badges|jobs|skills|earner\/)/,
        /^\/mgmt\//,
        /^\/org\/[^/]+(\/badge\/[^/]+)?$/,
        /^\/users\/[^/]+($|\/badges)/
      ];

      return !exclusions.find(ex => ex.test(to)) && !!localMatches.find(ex => ex.test(to));
    }
  }


  /**
   * Render the compoent.
   *
   * @returns {*}
   */
  render() {
    const ref = this.props.innerRef;
    const linkProps = { ...this.props };
    delete linkProps.track;
    delete linkProps.once;
    delete linkProps.localRoutesToSameWindow;
    // Remove properties from withRouter
    delete linkProps.staticContext;
    delete linkProps.history;
    delete linkProps.match;
    delete linkProps.location;
    // Remove properties from React.forwardRef
    delete linkProps.innerRef;

    if ((!this.props.target || this.props.localRoutesToSameWindow) && this.isLocalRoute()) {
      this.m_isRouterLink = true;
      if (this.props.localRoutesToSameWindow) {
        delete linkProps.target;
      }

      let innerRef;
      if (ref) {
        innerRef = (node) => { ref.current = node; };
      }
      return <Link
        {...linkProps}
        className={makeClassName('track-link', linkProps.className)}
        onClick={this.onClick}
        to={this.props.to ? this.stripOrigin(this.props.to) : '#'}
        innerRef={innerRef}
             />;
    } else {
      this.m_isRouterLink = false;
      delete linkProps.to;
      // Makes the click handler simpler
      const target =
        linkProps.target || (this.props.localRoutesToSameWindow ? undefined : '_blank');
      return <a
        {...linkProps}
        className={makeClassName('track-link', linkProps.className)}
        target={target}
        onClick={this.onClick}
        href={this.props.to}
        ref={ref}
             />;
    }
  }
}
TrackLink.propTypes = {
  ...Link.propTypes,
  to: PropTypes.string, // Redefine this to remove isRequired from Link.propTypes.
  localRoutesToSameWindow: PropTypes.bool,
  target: PropTypes.string,
  track: TrackStatPropType.isRequired,
  once: PropTypes.bool,
  augment: PropTypes.arrayOf(PropTypes.object),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  tracking: PropTypes.instanceOf(Tracking)
};

TrackLink.defaultProps = {
  to: '#'
};

const TrackLinkTest = props => (
  <TrackLink
    {...props}
    tracking={trackingTest}
  />
);

TrackLinkTest.propTypes = { ...TrackLink.propTypes };
TrackLinkTest.defaultProps = { ...TrackLink.defaultProps };

const TrackLinkWithRouter = withRouter(TrackLink);

const TrackLinkWithRouterAndForwardRef = React.forwardRef((props, ref) => (
  <TrackLinkWithRouter {...props} innerRef={ref} />
));

TrackLinkWithRouterAndForwardRef.propTypes = {
  ...objUtils.except(TrackLink.propTypes, ['innerRef'])
};

TrackLinkWithRouterAndForwardRef.displayName = 'TrackLink';

export { TrackLinkWithRouter as TrackLink };

/**
 * Attempts to get (and then clear) the linkSource attribute from session storage. Catches and
 * swallows errors if they arise, since tracking link source is not critical and should not disrupt
 * normal functions.
 *
 * @returns {string} - the link source, if it could be retrieved or null
 */
export const tryGetAndClearLinkSource = linkSource => {
  let result = null;
  try {
    result = sessionStorage.getItem('linkSource');
    sessionStorage.removeItem('linkSource');
  } catch (e) {
    // not much to be done: the user's browser and/or privacy settings aren't having any of it
    console.info("sessionStorage is not available");
    console.info(e);
  }
  return result;
};

/**
 * Attempts to set the linkSource attribute in session storage.  Catches and swallows errors if they
 * arise, since tracking link source is not critical and should not disrupt normal functions.
 *
 * @param {string} linkSource - the link source to use
 */
export const trySetLinkSource = linkSource => {
  try {
    sessionStorage.setItem('linkSource', linkSource);
  } catch (e) {
    // not much to be done: the user's browser and/or privacy settings aren't having any of it
    console.info("sessionStorage is not available");
    console.info(e);
  }
};

/**
 * Tracks stats for UI components.
 *
 * @param {Object} defaultSnapshot - default snapshot
 * @param {Tracking} tracking - tracking object
 * @returns {{track: function(string, Object)}}
 */

export const useComponentTracking = (defaultSnapshot = {}, tracking) => {
  const uuid = useMemoStrict(() => {
    return uuidv4();
  }, []);
  const defaultSnapshotMemoized = useShallowEquals(defaultSnapshot);

  const track = useCallback((type, snapshot) => {
    tracking.track({
      type: type,
      object_id: uuid,
      object_type: 'UIComponentInstance',
      snapshot_json: { ...defaultSnapshotMemoized, ...snapshot }
    });
  }, [defaultSnapshotMemoized]);

  return { track };
};

const useComponentTrackingTest = (defaultSnapshot = {}) => (
  useComponentTracking(defaultSnapshot, trackingTest)
);

/**
* HoC to supply Class component with useComponentTracking
*
* @param {React.Component} WrappedComponent - the component to wrap
* @param {Object} tracking - tracking object
* @returns {React.Component}
*/

export const withComponentTracking = (WrappedComponent, tracking) => {
  return (props) => {
    const componentTracking = useComponentTracking({}, tracking);
    return <WrappedComponent componentTracking={componentTracking?.track} {...props} />;
  };
};

const withComponentTrackingTest = (WrappedComponent) => (
  withComponentTracking(WrappedComponent, trackingTest)
);

export const testing = {
  TrackLink: TrackLinkTest,
  Tracking: TrackingTest,
  TrackOnClick: TrackOnClickTest,
  testInitializeTracking,
  tracking: () => trackingTest,
  useComponentTracking: useComponentTrackingTest,
  withComponentTracking: withComponentTrackingTest
};
