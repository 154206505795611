/* globals initialAppValues */

import React from 'react';
import { PermissionsRequest } from './permissions_request';
import { permissionsRequests, updatePermissions } from './permissions_actions';
import { AcceptEmployment } from './accept_employment';
import { PERMISSIONS_STATES } from './permissions_utils';
import './permissions_manager.sass';

/**
 * Manage permissions requests, such as employment verifications.
 */
export const PermissionsManager = () => {
  const [permissionsAction] = permissionsRequests.useAction();
  const [_updatePermissionsState, updatePermission] = updatePermissions.useAction();

  const getCurrent = React.useCallback(() => {
    // We need to return the most recent/last in the array, but Array.prototype.reverse() is
    // destructive, therefore we need to make a copy.
    const permissionsRequests = [...permissionsAction.resources];
    return permissionsRequests.reverse().find(permission =>
      permission.state === PERMISSIONS_STATES.pending);
  }, [...permissionsAction.resources]);

  const onComplete = (state) => updatePermission({ ...getCurrent(), state: state });

  const renderRequestContent = request => {
    if (request.type === 'employment') {
      return (
        <AcceptEmployment
          id={request.id}
          requesterName={request.requester_name}
          requesterImageUrl={request.requester_image_url}
          onComplete={onComplete}
        />);
    }

    throw new Error(`Error: Unsupported type ${request.type} for PermissionsRequest`);
  };

  if (getCurrent()) {
    return (
      <PermissionsRequest>
        {renderRequestContent(getCurrent())}
      </PermissionsRequest>
    );
  } else {
    return '';
  }
};
