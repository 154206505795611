export function trackPendoEvent(eventType, properties = {}) {
  if (typeof pendo === 'undefined') {
    return;
  }
  if (pendo && pendo.isReady && pendo.isReady()) {
    try {
      return pendo.track(eventType, properties);
    } catch (err) {
      console.log('Pendo event not logged due to: ', err);
    }
  }
};
