import * as routes from 'utils/routes';
import urlUtils from 'utils/url';
import * as objUtils from 'utils/object';

export const isSignInUrlOrRootPath = () => {
  return routes.isSigninUrl(routes.currentHref()) || /^\/?$/.test(routes.currentPath());
};

/**
 * Redirect after successful sign in.
 */
export const redirectAfterSignin = (redirectUrl) => {
  let reloadUrl;

  if (isSignInUrlOrRootPath()) {
    // If we're at a generic sign in url (/users/sign_in, /users/sign_in?twoFactorAuth=1)
    // then redirect a given redirect url if supplied otherwise fallback to a default url.
    // In cases of a one step sign in flow (non 2fa) this will most likely be supplied by the
    // backend (see authentication.rb), in multi step cases such as 2fa this may derive from
    // a stashed redirect url if the user attempted to access a bookmarked link originally
    reloadUrl = redirectUrl || routes.DEFAULT_URL;
  } else {
    // If at redirect time the current href is some other link besides a sign in page,
    // i.e. the user is attempting to directly access a bookmarked link.
    // Then we can just grab the current href and treat that as
    // the redirect url over-riding the supplied redirect url. Note this will result in a refactor
    // if you actually want to still follow the supplied redirectUrl
    reloadUrl = urlUtils.removeParam(routes.currentHref(), 'twoFactorAuth');
  }

  // Force a page reload, since we don't currently have way to reload initialAppValues.
  if (reloadUrl === routes.currentHref()) {
    routes.reload();
  } else {
    routes.setHref(reloadUrl);
  }
};

/**
 * Function to check whether Sign in with Apple is enabled.
 */
export const isAppleSSOEnabled = () => !!window?.initialAppValues?.sso?.apple?.clientId;

/**
 * Function to check whether Sign in with Google is enabled.
 */
export const isGoogleSSOEnabled = () => !!window?.initialAppValues?.sso?.google?.clientId;

/**
 * Function to check whether Sign in with Microsoft is enabled.
 */
export const isMicrosoftSSOEnabled = () => !!window?.initialAppValues?.sso?.microsoft?.clientId;


/**
 * Devise requires all signin params to be in a user object. This function can be used to
 * intercept Form.submit() and adjust params appropriately.
 *
 * @param {Object} values - The values passed to Form.submit().
 * @returns {Object} The modified values object.
 */
export const prepareSigninSubmit = values => {
  let modValues = {user: {}};

  objUtils.forEach(values, (key, val) => {
    if (key in signinKeys) {
      modValues.user[key] = val;
    }
    else {
      modValues[key] = val;
    }
  });

  return modValues;
};
const signinKeys = {
  email: 1,
  password: 1,
  password_confirmation: 1,
  reset_password_token: 1,
  nonce_token: 1,
  otp_attempt: 1,
  data: 1,
  remember_me: 1
};
